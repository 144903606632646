//System Imports
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//sazzui imports
import { NotificationLayer } from 'sazzui/lib/main';
//WIDGET imports
import CustomerSupport from 'com/widgets/CustomerSupport';
//ICON imports
import IPEER_LOGO_WHITE from 'assets/images/ipeer_logo_white.svg';
//redux
import { toastsRemoveAll, toastsRemove } from 'redux/ducks/notification_toasts';
//Style imports
import './style.css';

const AuthTemplateIPeer = (props) => {
	const notificationToasts = useSelector((state) => state.notification_toasts);
	const dispatch = useDispatch();

	useEffect(() => {
		loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_KEY}`, function () {
			console.log('Script loaded!');
		});
	}, []);

	const loadScriptByURL = (id, url, callback) => {
		const scriptExists = document.getElementById(id);
		if (!scriptExists) {
			var script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = url;
			script.id = id;
			script.onload = function () {
				if (callback) callback();
			};
			document.querySelector('.auth-template-ipeer').appendChild(script);
		}
		if (scriptExists && callback) callback();
	};

	return (
		<>
			<div className="auth-template-ipeer">
				<div className={`auth-template-ipeer__welcome-container`}>
					<img className="auth-template-ipeer__welcome-container-logo" src={IPEER_LOGO_WHITE} alt="logo" />
					<h1 className="auth-template-ipeer__welcome-container-header">
						<span>Welcome</span>&nbsp;
						<span>to iPeer.</span>
					</h1>
					<p className="auth-template-ipeer__welcome-container-message">Sign up to get the most out of your intellectual properties network.</p>
				</div>
				<div className={`auth-template-ipeer__content`}>
					<CustomerSupport />
					{props.children}
				</div>
			</div>
			<NotificationLayer notificationToasts={notificationToasts} removeAllToasts={() => dispatch(toastsRemoveAll())} removeAction={(id) => dispatch(toastsRemove(id))} />
		</>
	);
};

export default AuthTemplateIPeer;
