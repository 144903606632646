import { jsx as _jsx } from "react/jsx-runtime";
//System Imports
import { useState, useEffect } from "react";
export const PointsCell = ({ adjusted = false, isAdjustmentRow = false, children = null, }) => {
    const [points, setPoints] = useState('0');
    const [color, setColor] = useState('green');
    useEffect(() => {
        const p = Number(children);
        if (p > 0) {
            setPoints(`+${p}`);
            setColor('green');
        }
        else {
            setPoints(`${p}`);
            setColor('red');
        }
    }, [children]);
    return (_jsx("td", { children: _jsx("div", { className: "table-cell table-cell-points", children: _jsx("span", { className: `${adjusted && 'points-adjusted'} ${isAdjustmentRow && 'points-text-italic'} points-color-${color}`, children: points }) }) }));
};
