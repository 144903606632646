import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { MILESTONES } from 'data/constants';

import ICON_MILESTONE_CHECKED from 'assets/images/icons/ico_milestone_checked.svg';
import ICON_MILESTONE_CURRENT from 'assets/images/icons/ico_milestone_checked_collored.svg';
import ICON_MILESTONE_EMPTY from 'assets/images/icons/ico_milestone_empty.svg';

import './style.css';

const StatusProgressBar = (props) => {
	let [toolTip, setToolTip] = useState({ show: false, order: 0 });

	const onMouseEnter = (e) => {
		setToolTip({ show: true, order: e.currentTarget.dataset.order });
	};

	const onMouseLeave = () => {
		setToolTip({ show: false, order: 0 });
	};

	return (
		<div className="status-progress-bar__wrapper">
			<div className="status-progress-bar">
				{MILESTONES.map((m, i) => {
					let current = i + 1;
					return (
						<React.Fragment key={i}>
							<div className="status-progress-bar__circle" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} data-order={current}>
								<Tooltip text={m.name} toolTip={toolTip} order={current} />
								<img
									src={
										props.order == current ? ICON_MILESTONE_CURRENT
										: props.order > current ?
											ICON_MILESTONE_CHECKED
										:	ICON_MILESTONE_EMPTY
									}
									alt="milestone checkmark"
								/>
							</div>
							<div className="status-progress-bar__line"></div>
						</React.Fragment>
					);
				})}
			</div>
			<span className="status-progress-bar__wrapper__current_status">{props.status}</span>
		</div>
	);
};

const Tooltip = (props) => {
	return (
		<>
			{props.toolTip.show === true && props.toolTip.order == props.order ?
				<span className="status-progress-bar__tooltip">{props.text}</span>
			:	null}
		</>
	);
};

StatusProgressBar.propTypes = {
	status: PropTypes.string,
	order: PropTypes.number,
};

StatusProgressBar.defaultProps = {
	status: '',
	order: 0,
};

Tooltip.propTypes = {
	text: PropTypes.string,
	toolTip: PropTypes.object,
	order: PropTypes.number,
};

Tooltip.defaultProps = {
	text: '',
	toolTip: {},
	order: 0,
};

export default StatusProgressBar;

export { Tooltip };
