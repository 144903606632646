import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//templates
import AppTemplate from 'com/templates/ApplicationTemplate';
// util
import { RoleBox } from 'com/util/RoleBox';
import { OwnerContextProvider } from 'util/ownerContext';
// sazzui
import { DataTable, Button, Input, Select, LoaderOverlay } from 'sazzui/lib/main';
// widgets
import PricePoints from 'com/widgets/PricePoints';
import StarsRating from 'com/widgets/StarsRating';
import CreateAgentModal from 'com/widgets/CreateAgentModal';
// services
import Settings from 'services/rest/settings';
import useNotification from 'services/hooks/use_notification';
import useTitle from 'services/hooks/useTitle';
import FirmAgents from 'services/rest/firm_agents';
import EventLoggerEvents from 'services/rest/event_logger';
import { roundDecimals } from 'services/strings';
//data
import { PAGE_TITLE } from 'data/constants';
// icons
import ICON_FUNNEL from 'assets/images/icons/ico_funnel.svg';
import ICON_UPLOAD from 'assets/images/icons/ico-upload.svg';
import ICON_BLOCK_ACTION from 'assets/images/icons/ico_block.svg';
import ICON_ACTIVE_ACTION from 'assets/images/icons/ico_eye_visible.svg';
import ICON_INACTIVE_ACTION from 'assets/images/icons/ico_eye_not_visible.svg';
import ICON_STAR_ACTION from 'assets/images/icons/ico_star.svg';

import './style.css';

const AgentsList = (props) => {
	useTitle(PAGE_TITLE.AGENTS);

	const filtersInit = {
		region: '',
		firm_name: '',
		firm_size_id: '',
		technology: '',
		service: '',
	};

	const user = useSelector((state) => state.auth.user);
	const id = user.fid;
	const sendNotification = useNotification();
	const [agents, setAgents] = useState([]);
	const [firmSizes, setFirmSizes] = useState([]);
	const [regions, setRegions] = useState([]);
	const [countries, setCountries] = useState([]);
	const [filters, setFilters] = useState(filtersInit);
	const [filterData, setFilterData] = useState(filtersInit);
	const [showLoader, setShowLoader] = useState(false);
	const [services, setServices] = useState([]);
	const [isNewAgentModalOpen, setIsNewAgentModalOpen] = useState(false);
	const history = useHistory();

	useEffect(() => {
		EventLoggerEvents.EventLogger({ event: 'User Visited Own Agents Page', data: { user: user } });
		(async () => {
			await fetchData();
		})();
	}, []);

	useEffect(() => {
		(async () => {
			await fetchAgents();
		})();
	}, [filterData]);

	const fetchData = async () => {
		try {
			const [regions, firmSizes, technologies, services] = await Promise.all([Settings.GetRegions(), Settings.GetFirmSizes(), Settings.GetTechnologies(), Settings.GetServices()]);

			setCountries([{ label: '', value: '' }, ...regions.filter((r) => r.is_country).map((r) => ({ label: r.name, value: r.code, id: r.id, is_country: r.is_country }))]);
			setRegions([{ label: 'All', value: '' }, ...regions.map((r) => ({ label: r.name, value: r.id }))]);
			setFirmSizes([
				{ label: 'All', value: '' },
				...firmSizes.map((fs) => {
					return { ...fs, label: fs.label, value: fs.id };
				}),
			]);
			setServices([{ label: 'All', value: '' }, ...services.map((s) => ({ label: s.name, value: s.id, id: s.id }))]);
		} catch (error) {
			console.log('error', error);
		}
	};

	const fetchAgents = async () => {
		setShowLoader(true);
		try {
			const agents = await FirmAgents.GetAllByClientID(id, filters);
			setAgents(agents);
		} catch (error) {
			console.log('error', error);
		} finally {
			setShowLoader(false);
		}
	};

	const filterChange = (e) => {
		setFilters({
			...filters,
			[e.target.name]: e.target.value,
		});
	};

	const filterSubmit = () => {
		setFilterData(filters);
		EventLoggerEvents.EventLogger({ event: `Search Agents`, data: { user: user } });
	};

	const clearForm = () => {
		setFilters(filtersInit);
		setFilterData(filtersInit);
	};

	const tableHeaders = [
		{ title: 'Region', field: 'region', type: 'string', sortable: false },
		{ title: 'Name', field: 'name', type: 'string', sortable: false },
		{ title: 'Responsible Attorney', field: 'dedicated_associate', type: 'string', sortable: false },
		{ title: 'Firm size', field: 'firm_size', type: 'string', sortable: false },
		// { title: 'Service & Technology', field: 'technology', type: 'string', sortable: false }, // keep it for future use.
		{ title: 'Service', field: 'service', type: '', sortable: false },
		{ title: 'Price', field: 'price', type: '', sortable: false },
		{ title: 'Reciprocity', field: 'reciprocity', type: 'string', sortable: false },
		{ title: '', field: 'action', type: 'string', sort: 'none', sortable: false },
	];

	const getFirmSizeLabel = (numOfAtt) => {
		for (let fs of firmSizes) {
			if (fs.min && numOfAtt >= fs.min && ((fs.max && numOfAtt <= fs.max) || !fs.max)) {
				return fs.label;
			}
		}
	};

	const onChangeDefaultAssociateUser = async (id, uid) => {
		try {
			await FirmAgents.updateDefaultAssociateUserByID(id, uid);
			setAgents(agents.map((a) => (a.id === id ? { ...a, default_associate_user: uid } : a)));
			sendNotification({ type: 'success', title: 'Responsible attorney updated successfully' });
			EventLoggerEvents.EventLogger({ event: 'Manually Set Default Associate', data: { user: user } });
		} catch (error) {
			console.log('error', error);
		}
	};

	const getRegionsName = (regions) => {
		if (regions.length > 0) {
			return regions.map((r) => r.region_name);
		}
		return [];
	};

	const getAgentActions = (id) => {
		if (!agents) return [];

		let agent = agents.find((a) => a.id === id);
		let isBlockEmails = !!agent.is_block_emails;
		let isActive = !!agent.is_active;
		let isFavorite = !!agent.is_favorite;

		let dataset = { id };
		let roles = ['saas_user', 'user', 'member', 'member_assistant'];

		return [
			isBlockEmails ?
				{ label: 'Unblock Email Notifications', action: unblockAgentEmail, dataset, ico: ICON_BLOCK_ACTION, roles }
			:	{ label: 'Skip Email updates', action: blockAgentEmail, dataset, ico: ICON_BLOCK_ACTION, roles },
			isActive ?
				!isFavorite && { label: 'Mark As Inactive', action: markAgentInactive, dataset, ico: ICON_INACTIVE_ACTION, roles }
			:	{ label: 'Mark As Active', action: markAgentActive, dataset, ico: ICON_ACTIVE_ACTION, roles },
			isFavorite ?
				{ label: 'Mark As Not Favorite', action: markAgentNotFavorite, dataset, ico: ICON_STAR_ACTION, roles }
			:	isActive && { label: 'Mark As Favorite', action: markAgentFavorite, dataset, ico: ICON_STAR_ACTION, roles },
		].filter(Boolean);
	};

	const blockAgentEmail = (e) => updateAgent(e, 'is_block_emails', 1, 'User marked Agent as having Blocked Emails');

	const unblockAgentEmail = (e) => updateAgent(e, 'is_block_emails', 0, 'User marked Agent as Unblocked Emails');

	const markAgentActive = (e) => updateAgent(e, 'is_active', 1, 'User marked Agent as Active');

	const markAgentInactive = (e) => updateAgent(e, 'is_active', 0, 'User marked Agent as Inactive');

	const markAgentFavorite = (e) => updateAgent(e, 'is_favorite', 1, 'User marked Agent as Favorite');

	const markAgentNotFavorite = (e) => updateAgent(e, 'is_favorite', 0, 'User marked Agent as Not Favorite');

	const updateAgent = async (e, field, value, event) => {
		e.stopPropagation();
		let { id } = e.currentTarget.parentNode.dataset;
		let agent = agents.find((a) => a.id === id);
		let payload = { ...agent, [field]: value };

		try {
			await FirmAgents.updateAgent(id, payload);
			setAgents(agents.map((a) => (a.id === id ? { ...a, [field]: value } : a)));
			EventLoggerEvents.EventLogger({ event: event, data: { user: user } });
			sendNotification({ type: 'success', title: 'Agent Setting updated successfully' });
		} catch (error) {
			console.log('error', error);
		}
	};
	const onNewAgentModalOpen = () => setIsNewAgentModalOpen(true);

	const onNewAgentModalClose = () => setIsNewAgentModalOpen(false);

	const goToDetails = (e) => {
		let associateID = e.target.closest('tr').dataset.aid;
		EventLoggerEvents.EventLogger({ event: `Client Loads Their OON Assoc's Firm Profile`, data: { associate_id: associateID, user: user } });
		history.push(`/firm-management/${associateID}`);
	};

	return (
		<AppTemplate title="My Agents" headerIcon="firm_management" collapsibleSidebar={false}>
			<AppTemplate.Sidebar>
				<div className="agents-sidebar">
					<h4 className="agents-sidebar__header">
						<img src={ICON_FUNNEL} alt="" />
						filters
					</h4>
					<Select name="region" label="Region" options={regions} value={filters.region} onChange={filterChange} theme="dark" ariaLabel="Region Select" />
					<Input name="firm_name" label="Name" value={filters.firm_name} onChange={filterChange} theme="dark" ariaLabel="Firm Name Input" />
					<Select name="firm_size_id" label="Firm Size" options={firmSizes} onChange={filterChange} value={filters.firm_size_id} theme="dark" ariaLabel="Firm Size Select" />
					<Select name="service" label="Service" options={services} onChange={filterChange} value={filters.service} theme="dark" ariaLabel="Service Select" />
					{/* we are keeping the code below */}
					{/* <Select name="technology" label="Technology" options={technologies} value={filters.technology} onChange={filterChange}/>  */}
					<div className="agents-sidebar_actions sidebar_actions">
						<Button theme="ghost-primary" onClick={clearForm} ariaLabel="Clear Filters Button">
							Clear
						</Button>
						<Button onClick={filterSubmit} ariaLabel="Apply Filters Button">
							Apply Filters
						</Button>
					</div>
				</div>
			</AppTemplate.Sidebar>
			<AppTemplate.Header>
				{/* COMMENTING OUT BECAUSE IT IS NOT READY FOR PRODUCTION */}
				{/* <AppLink type="regular" to="/tools/client-onboarding" className="form-button azami-blue agents_bulk-upload">
					<img src={ICON_UPLOAD} />
					Bulk Upload
				</AppLink> */}
				<Button className="agents_add-agent" onClick={onNewAgentModalOpen}>
					Add Agent
				</Button>
			</AppTemplate.Header>
			<AppTemplate.Content>
				<OwnerContextProvider firmId={id}>
					<div className="main-screen-section white">
						<LoaderOverlay showLoader={showLoader} />
						<div className="agents-list">
							<div className="agents-list-body">
								<DataTable fixedHeader={true} columns={tableHeaders}>
									{Array.isArray(agents) &&
										agents.length > 0 &&
										agents.map((a, i) => {
											let mappedMembers = a.members ? [...a.members?.map((am) => ({ value: am.id, label: `${am.first_name} ${am.last_name}` }))] : [];
											return (
												<tr key={i} data-aid={a.associate_id} onClick={goToDetails} className={`pointer ${a.is_active ? '' : 'inactive'} ${a.is_favorite ? 'favorite' : ''}`}>
													<DataTable.RuleRegionCell
														countryCode={a?.regions[0]?.region_code}
														cellTitle={getRegionsName(a.regions).join(', ')}
														countryCount={a?.regions?.length - 1}
													>
														{a.regions[0]?.region_code}
													</DataTable.RuleRegionCell>
													<DataTable.PersonCell ico={a.associate_image} showIco={true}>
														{a.associate_name}
													</DataTable.PersonCell>
													<DataTable.Cell>
														<Select
															customClassName="associate_user"
															name="default_associate_user"
															options={mappedMembers}
															onChange={(e) => onChangeDefaultAssociateUser(a.id, e.target.value)}
															value={a.default_associate_user}
															onClick={(e) => e.stopPropagation()}
															ariaLabel="Associate Select"
														/>
													</DataTable.Cell>
													<DataTable.Cell>{getFirmSizeLabel(a.associate_number_of_attorneys)}</DataTable.Cell>
													{/* we are keeping the code below */}
													{/* <DataTable.Cell customClassName='agents-table-cell__technology-service'>
														<div className='agents-table-cell__technology-service-info'>
															{a.services?.map((s, i) => <span className="name" key={i}>{s.service_name}</span>)}
														</div>
														<div className='table-cell-tags'>
															{a.technologies?.map((t, i) => <span key={i}>{t.name}</span>)}
														</div>
													</DataTable.Cell> */}
													<DataTable.Cell>
														<StarsRating rating={a.rating} />
													</DataTable.Cell>
													<DataTable.Cell>
														<PricePoints length={5} value={roundDecimals(a.points.value, 2)} threshold={a.points.threshold} />
													</DataTable.Cell>
													<DataTable.Cell>
														<div className="agents-table-cell__reciprocity" title="Number of Cases Sent/Number of Cases Received">
															{a.sent_cases || 0}:{a.received_cases || 0}
														</div>
													</DataTable.Cell>
													<RoleBox roles={['saas_user']}>
														<DataTable.ActionsMenuCell actions={getAgentActions(a.id)}></DataTable.ActionsMenuCell>
													</RoleBox>
												</tr>
											);
										})}
								</DataTable>
							</div>
							{isNewAgentModalOpen && (
								<CreateAgentModal
									clientFirmId={id}
									closeHandler={onNewAgentModalClose}
									countries={countries}
									redirect={true}
									createAllRates={true}
									activeFirm={true}
									services={services}
									agents={agents}
								/>
							)}
						</div>
					</div>
				</OwnerContextProvider>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default AgentsList;
