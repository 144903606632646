// System Imports
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
// SazzUI Imports
import { Input, ErrorMessage, Button, BackButton } from 'sazzui/lib/main';
// Util Imports
import AppLink from 'com/util/AppLink';
// Template Imports
import AuthBox from 'com/templates/AuthBox';
// Service Imports
import { ForgotPasswordSchema } from 'services/validators/forgotPassword';
// Style Imports
import './style.css';

const ForgotPasswordIPeer = (props) => {
	return (
		<div className="forgot-password-container">
			<AuthBox title="Forgot Password" titleColor="dark-grey" icon="password" footer={<BackButton to="/" AppLink={AppLink} label="Back to login" />}>
				<Formik initialValues={props.initialState} validationSchema={ForgotPasswordSchema} onSubmit={props.forgotPasswordSubmit} validateOnChange={false}>
					{({ values, handleChange, validateField, errors, status }) => {
						const validate = (e) => validateField(e.target.name);

						return (
							<Form>
								<span className="forgot-password-container-ipeer__text">Please enter your email, and we will send a password reset link.</span>
								<Input
									name="email"
									value={values.email}
									onChange={handleChange}
									onBlur={validate}
									error={errors.email}
									label="Email"
									placeholder="email@domain.com"
									type="text"
									ariaLabel="Email Input"
								/>
								<div className="forgot-password-container-ipeer__actions">
									<Button type="submit" showSpinner={props.spinner} ariaLabel="Submit Button">
										Submit
									</Button>
								</div>
								{status && <ErrorMessage error={status} />}
							</Form>
						);
					}}
				</Formik>
			</AuthBox>
		</div>
	);
};

ForgotPasswordIPeer.propTypes = {
	initialState: PropTypes.object,
	forgotPasswordSubmit: PropTypes.func,
};

ForgotPasswordIPeer.defaultProps = {
	initialState: {},
	forgotPasswordSubmit: () => {},
};

export default ForgotPasswordIPeer;
