import { Button } from 'sazzui/lib/main';
import ICON_SAVE from 'assets/images/icons/ico_save.svg';

import './style.css';

const QuoteActionsHeader = (props) => {
	return (
		<div className="quote-actions-header">
			<Button
				theme="ghost-primary"
				onClick={props.saveAsDraft}
				showSpinner={props.draftSpinner}
				customClassName="new-quote__footer__btn-margin"
				ariaLabel="Save As Draft Quote Button"
				size="small"
			>
				<img src={ICON_SAVE} alt="" />
				Save As Draft
			</Button>
			<Button theme="ghost-primary" onClick={props.headerCancel} ariaLabel="Close Button" size="small">
				Close
			</Button>
		</div>
	);
};

export default QuoteActionsHeader;
