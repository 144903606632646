import React, { useEffect, useState } from 'react';

import AppTemplate from 'com/templates/ApplicationTemplate';

import Settings from 'services/rest/settings';
import Users from 'services/rest/users';
import { GetOwedAssociatesReport, DownloadOwedAssociatesReport } from 'services/rest/reports';
import { FIRM_TAGS, PAGE_TITLE } from 'data/constants';
import { random } from 'services/strings';

import { Select, Input, Button, IconButton, LoaderOverlay, Switch, TagSelect, DataTable } from 'sazzui/lib/main';

import AppLink from 'com/util/AppLink';

import { SERVICE_KEYS, COLORS } from 'data/constants';
import useTitle from 'services/hooks/useTitle';
import useFilterStorage from 'services/hooks/useFilterStorage';

import './style.css';

import ICON_FUNNEL from 'assets/images/icons/ico_funnel.svg';
import ICON_PDF from 'assets/images/icons/ico_pdf.svg';
import ICON_RATES_CONFIRMATION from 'assets/images/icons/ico_rates.svg';
import ICON_FIRM_SIZE from 'assets/images/icons/ico_firm_size.svg';
import ICON_TECHNOLOGIES from 'assets/images/icons/ico_technologies.svg';

const OwedAssociatesReport = () => {
	useTitle(PAGE_TITLE.OWED_ASSOCIATES_REPORT);

	const reportTableHeadersInit = [
		{ title: 'Firm', field: 'firm_name', type: 'string', sort: 'none', sortable: true },
		{ title: 'Missing Info.', field: 'missing_info', type: 'string', sort: 'none', sortable: false },
		{ title: 'Points', field: 'points', type: 'number', sort: 'none', sortable: true },
		{ title: 'Base Points', field: 'account_base_points', type: 'number', sort: 'none', sortable: true },
		{ title: 'Owed Cases', field: 'owed_cases', type: 'number', sort: 'none', sortable: true },
		{ title: 'Reciprocity Deadline', field: 'reciprocity_deadline', type: 'date', sort: 'none', sortable: true },
		{ title: 'Account Executive', field: 'account_executive', type: 'string', sort: 'none', sortable: true },
	];

	const initData = [];
	const initRegions = [];
	const initServices = [];
	const initAccountsExecutives = [{ label: 'All Account Executives', value: '' }];
	const filterInit = {
		region: '',
		service: '',
		account_executive: '',
		points_from: '',
		points_to: '',
		is_active: 1,
		firms_without_tags: false,
		tags: [],
	};

	const [reportTableHeaders, setReportTableHeaders] = useState(reportTableHeadersInit);
	const [data, setData] = useState(initData);
	const [filter, setFilter] = useState(filterInit);
	const [regions, setRegions] = useState(initRegions);
	const [services, setServices] = useState(initServices);
	const [accountsExecutives, setAccountExecutives] = useState(initAccountsExecutives);
	const [showLoader, setShowLoader] = useState(false);
	const { getFilterValue, setFilterValue } = useFilterStorage();

	useEffect(async () => {
		try {
			let [rs, ss, sales] = await Promise.all([Settings.GetRegions(), Settings.GetServices(), getSalesUsers()]);
			// set regions
			setRegions(rs.map((r) => ({ label: r.name, value: r.id })));
			// set services
			setServices(ss.map((s) => ({ label: s.name, value: s.id })));
			// check for existing filter and set if exist or set default region and service filter value
			let f = getFilterValue();
			setFilter(f ? f : (prevState) => ({ ...prevState, service: ss.find((s) => s.key === SERVICE_KEYS.PCT).id, region: rs[0].id }));
			if (f) fetchData(f);
			// set account executives
			setAccountExecutives([...accountsExecutives, ...sales.map((u) => ({ label: `${u.first_name} ${u.last_name}`, value: u.id }))]);
		} catch (err) {
			console.log(err);
		}
	}, []);

	const firmStatus = [
		{ label: 'Active', value: '1' },
		{ label: 'Inactive', value: '0' },
	];

	const badges = {
		size: {
			icon: ICON_FIRM_SIZE,
			info: 'Firm Size',
		},
		technologies: {
			icon: ICON_TECHNOLOGIES,
			info: 'Technologies',
		},
		rates: {
			icon: ICON_RATES_CONFIRMATION,
			info: 'Rates',
		},
	};

	const getSalesUsers = async () => {
		let roles = await Settings.GetUserRoles();
		if (!roles) return [];
		let salesRole = roles.find((r) => r.name == 'sales');
		let filters = {
			role: salesRole.id,
			order_by: ['first_name:asc', 'last_name:asc'].join(','),
		};
		let users = await Users.GetAllRaw(filters);
		return users;
	};

	const filterChange = (e) => {
		let { name, value } = e.target;
		switch (name) {
			case 'firms_without_tags':
				setFilter({
					...filter,
					[name]: !filter[name],
					tags: [],
				});
				break;
			case 'tags':
				setFilter({
					...filter,
					[name]: value,
					firms_without_tags: false,
				});
				break;
			default:
				setFilter({
					...filter,
					[name]: value,
				});
				break;
		}
	};

	const fetchData = async (filter) => {
		try {
			setShowLoader(true);
			let res = await GetOwedAssociatesReport(filter);
			// assign badges
			res.map((r) => {
				let firmBadges = [];
				if (!r.number_of_attorneys) {
					firmBadges.push(badges.size);
				}
				if (r.has_technologies === false) {
					firmBadges.push(badges.technologies);
				}
				if (r.has_rates === false) {
					firmBadges.push(badges.rates);
				}
				r.badges = firmBadges;
				return r;
			});
			setData(res);
			setShowLoader(false);
		} catch (err) {
			console.log(err);
			setShowLoader(false);
		}
	};

	const filterSubmit = () => {
		fetchData(filter);
		setFilterValue(filter);
	};

	const clearFilter = () => {
		setFilter(filterInit);
		setData(initData);
		setFilterValue(null);
	};

	const printReport = async () => {
		await DownloadOwedAssociatesReport(data);
	};

	const columnSort = (f, t) => {
		let dir = 'up';

		setReportTableHeaders(
			reportTableHeaders.map((e) => {
				if (e.field === f) {
					e.sort = e.sort === 'up' ? 'down' : 'up';
					dir = e.sort === 'up' ? 'down' : 'up';
				}
				return e;
			}),
		);

		let sorted = data.sort((a, b) => {
			let x = null;
			let y = null;

			switch (t) {
				case 'date':
					x = new Date(a[f]).getTime();
					y = new Date(b[f]).getTime();
					break;
				case 'number':
					x = Number(a[f]);
					y = Number(b[f]);
					break;
				case 'string':
					x = a[f]?.toLowerCase();
					y = b[f]?.toLowerCase();
					break;
				default:
			}

			if (dir === 'up') {
				if (x > y) {
					return -1;
				}
				if (x < y) {
					return 1;
				}
			} else if (dir === 'down') {
				if (y > x) {
					return -1;
				}
				if (y < x) {
					return 1;
				}
			}
			return 0;
		});

		setData(sorted);
	};

	const setRowBackgroundColor = (points, reciprocityDeadline) => {
		let now = new Date().getTime();
		let deadline = new Date(reciprocityDeadline).getTime();
		// deadline has passed if it's within the next two weeks (14 days)
		let deadlineHasPassed = parseInt((deadline - now) / (1000 * 3600 * 24)) <= 14;
		if (deadlineHasPassed) {
			return COLORS.RED;
		}
		if (points >= 15000 && points <= 29999) {
			return COLORS.GREEN;
		} else if (points >= 30000) {
			return COLORS.YELLOW;
		} else {
			return null;
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			filterSubmit();
		}
	};

	return (
		<AppTemplate title="Owed Associates Report">
			<AppTemplate.Sidebar>
				<div className="reports-sidebar">
					<h4 className="reports-sidebar__header">
						<img src={ICON_FUNNEL} alt="" />
						filters
					</h4>
					<Select options={regions} label="Region" theme="dark" onChange={filterChange} name="region" value={filter.region} />
					<Select options={services} label="Service" theme="dark" onChange={filterChange} name="service" value={filter.service} />
					<Select options={accountsExecutives} label="Accounts Executives" theme="dark" onChange={filterChange} name="account_executive" value={filter.account_executive} />
					<div className="reports-sidebar_hgroup">
						<Input label="Points from" theme="dark" type="number" onChange={filterChange} name="points_from" value={filter.points_from} onKeyDown={handleKeyDown} />
						<Input label="Points to" theme="dark" type="number" onChange={filterChange} name="points_to" value={filter.points_to} onKeyDown={handleKeyDown} />
					</div>
					<Select options={firmStatus} label="Status" theme="dark" onChange={filterChange} name="is_active" value={filter.is_active} />
					<div className="reports-sidebar__firms-without-tags-switch">
						<Switch onChange={filterChange} value={filter.firms_without_tags} name="firms_without_tags" label="Firms Without Tags" />
					</div>
					<TagSelect disabled={filter.firms_without_tags} label="Tags" options={FIRM_TAGS} theme="dark" values={filter.tags} onChange={filterChange} />
					<div className="reports-sidebar_actions sidebar_actions">
						<Button onClick={filterSubmit} size="small">
							Generate Report
						</Button>
						<Button theme="ghost-primary" onClick={clearFilter} size="small">
							Clear
						</Button>
					</div>
				</div>
			</AppTemplate.Sidebar>
			<AppTemplate.Content>
				<div className="main-screen-section subheader">
					<h2 className="main-screen-section__title">Report Summary</h2>
					<IconButton icon={ICON_PDF} onClick={printReport} />
				</div>
				<div className="main-screen-section white">
					<LoaderOverlay showLoader={showLoader} />
					<DataTable fixedHeader={true} columns={reportTableHeaders} onColumnSort={columnSort} customClassName="owed-associates__data-table">
						{data.map((d, i) => (
							<DataTable.Row key={i} color={setRowBackgroundColor(d.points, d.reciprocity_deadline)}>
								<DataTable.PersonCell subtext={d.firm_account_name} showIco={false}>
									<AppLink type="regular" to={`/firm-management/${d.firm_id}`}>
										{d.firm_name}
									</AppLink>
								</DataTable.PersonCell>
								<DataTable.Cell>
									<div className="owed-associates__data-table__missing-info-wrapper">
										{d.badges.length > 0 && d.badges.map((b) => <img src={b.icon} alt="" title={b.info} key={random(8)} />)}
									</div>
								</DataTable.Cell>
								<DataTable.Cell>{d.points}</DataTable.Cell>
								<DataTable.Cell>{d.account_base_points}</DataTable.Cell>
								<DataTable.Cell>{d.owed_cases}</DataTable.Cell>
								<DataTable.DateCell date={d.reciprocity_deadline} />
								<DataTable.Cell>{d.account_executive}</DataTable.Cell>
							</DataTable.Row>
						))}
					</DataTable>
				</div>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default OwedAssociatesReport;
