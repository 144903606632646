import React, { useEffect, useState } from 'react';

import useNotification from 'services/hooks/use_notification';

import { DataTable, LoaderOverlay, Button, TagSelect, Modal } from 'sazzui/lib/main';

import AppTemplate from 'com/templates/ApplicationTemplate';

import CreateRuleModal from './CreateRuleModal';

import { IP_TYPE_OPTIONS, TRANSFORMATION_RULE_LABELS, TRANSFORMATION_OBJECT_TYPE } from 'data/constants';

import TransformationRules from 'services/rest/renewals_transformation_rules';
import Settings from 'services/rest/settings';

import ICON_DELETE from 'assets/images/icons/ico_btn_delete_lightgrey.svg';
import ICON_EDIT from 'assets/images/icons/ico_btn_edit_lightgrey.svg';
import ICON_FUNNEL from 'assets/images/icons/ico_funnel.svg';

import './style.css';

const RenewalsTransformationRules = () => {
	const tableHeadersInit = [
		{ title: 'REGION', field: 'region_name', type: 'string', sort: 'none', sortable: true },
		{ title: 'NAME', field: 'type', type: 'string', sort: 'none', sortable: true },
		{ title: 'IP TYPE', field: 'ip_type', type: 'string', sort: 'none', sortable: false },
		{ title: 'FOR', field: 'object_type', type: 'string', sort: 'none', sortable: false },
		{ title: 'BASE', field: 'base_object', type: 'string', sort: 'none', sortable: false },
		{ title: 'ALERT MESSAGE', field: 'alert_message', type: 'string', sort: 'none', sortable: false },
		{ title: '', field: '', type: '', sort: 'none', sortable: false },
	];

	const filterInit = {
		regions: [],
		type: [],
		ip_type: [],
		object_type: [],
		order_by: [],
	};

	const newRuleInit = {
		type: '',
		region_ids: [],
		region_id: '',
		object_type: '',
		base_object: '',
		ip_type: 'ALL',
		prefix: '',
		suffix: '',
		remove_at_front: '',
		remove_at_back: '',
		separators: [],
		digits: '',
		digits_front: '',
		digits_back: '',
		decimals: '',
		replace_with: '',
		alert_message: '',
	};

	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [newRule, setNewRule] = useState(newRuleInit);
	const [rules, setRules] = useState([]);
	const [regions, setRegions] = useState([]);
	const [filter, setFilter] = useState(filterInit);
	const [createRuleModalOpen, setCreateRuleModalOpen] = useState(false);
	const [deleteRuleModalOpen, setDeleteRuleModalOpen] = useState(false);
	const [ruleForDelete, setRuleForDelete] = useState({});
	const [editMode, setEditMode] = useState(false);
	const [showLoader, setShowLoader] = useState(true);
	const sendNotification = useNotification();

	useEffect(() => {
		(async () => {
			try {
				setShowLoader(true);
				await Promise.all([getRules(filter), getRegions()]);
				setShowLoader(false);
			} catch (error) {
				sendNotification({ type: 'error', title: 'Could not fetch data' });
				console.log(error);
				setShowLoader(false);
			}
		})();
	}, []);

	const getRules = async (filterData = {}) => {
		const rules = await TransformationRules.GetAll(filterData);
		setRules(rules);
	};

	const getRegions = async () => {
		const regionsData = await Settings.GetRegions({ is_renewal: 1 });
		setRegions((prevState) => {
			return [
				{ label: '', value: '' },
				...regionsData.map((r) => {
					return { label: r.name, value: r.id };
				}),
			];
		});
	};

	const filterChange = (e) => {
		const { name, value } = e.target;
		setFilter(!!filter[name].includes(value) ? filter[name].filter((f) => f !== value) : { ...filter, [name]: value });
	};

	const clearFilter = async () => {
		try {
			setShowLoader(true);
			await getRules();
			setFilter(filterInit);
			setShowLoader(false);
		} catch (error) {
			console.log(error);
			setShowLoader(false);
		}
	};

	const applyFilter = async () => {
		try {
			setShowLoader(true);
			await getRules(filter);
			setShowLoader(false);
		} catch (error) {
			console.log(error);
			setShowLoader(false);
		}
	};

	const openCreateModal = () => {
		setCreateRuleModalOpen(true);
	};

	const closeCreateModal = () => {
		if (editMode) {
			setEditMode(false);
			setNewRule(newRuleInit);
		}
		setCreateRuleModalOpen(false);
	};

	const openEditModal = (e) => {
		const r = rules.find((r) => r.id === e.target.closest('tr').dataset.rid);
		setNewRule(r);
		setEditMode(true);
		setCreateRuleModalOpen(true);
	};

	const openDeleteModal = (e) => {
		const r = rules.find((r) => r.id === e.target.closest('tr').dataset.rid);
		setRuleForDelete(r);
		setDeleteRuleModalOpen(true);
	};

	const closeDeleteModal = () => {
		setRuleForDelete({});
		setDeleteRuleModalOpen(false);
	};

	const handleDeleteRule = async () => {
		try {
			setShowLoader(true);
			await TransformationRules.Delete(ruleForDelete.id);
			await getRules(filter);
			setRuleForDelete({});
			setDeleteRuleModalOpen(false);
			setShowLoader(false);
			sendNotification({ type: 'success', title: 'Rule deleted' });
		} catch (error) {
			sendNotification({ type: 'error', title: 'Rule delete failed' });
			console.log(error);
			setShowLoader(false);
		}
	};

	const deleteModalFooterActions = [
		{ primary: false, label: 'Cancel', action: closeDeleteModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Confirm', action: handleDeleteRule, theme: 'azami-blue' },
	];

	return (
		<AppTemplate title="Transformation Rules" headerIcon="transformation-rules">
			<AppTemplate.Sidebar>
				<div className="transformation-rules__sidebar">
					<h4 className="transformation-rules__sidebar__header">
						<img src={ICON_FUNNEL} alt="" />
						Filters
					</h4>
					<TagSelect label="Name" name="type" theme="dark" options={TRANSFORMATION_RULE_LABELS} values={filter.type} onChange={filterChange} ariaLabel="Name TagSelect" />
					<TagSelect label="IP Type" name="ip_type" theme="dark" options={IP_TYPE_OPTIONS} values={filter.ip_type} onChange={filterChange} ariaLabel="IP Type TagSelect" />
					<TagSelect label="For" name="object_type" theme="dark" options={TRANSFORMATION_OBJECT_TYPE} values={filter.object_type} onChange={filterChange} ariaLabel="For TagSelect" />
					<div className="transformation-rules__sidebar_actions">
						<Button theme="ghost-primary" onClick={clearFilter} ariaLabel="Clear Filters Button">
							Clear
						</Button>
						<Button onClick={applyFilter} ariaLabel="Apply Filters Button">
							Apply Filters
						</Button>
					</div>
				</div>
			</AppTemplate.Sidebar>
			<AppTemplate.Header>
				<Button onClick={openCreateModal} ariaLabel="Add New Rule Button">
					Add New Rule
				</Button>
			</AppTemplate.Header>
			<AppTemplate.Content>
				<LoaderOverlay showLoader={showLoader} />
				<div className="main-screen-section white">
					<DataTable fixedHeader={true} columns={tableHeaders} customClassName="transformation-rules__table">
						{rules.map((r) => {
							return (
								<tr key={r.id} data-rid={r.id}>
									<DataTable.CountryCell code={r.region_code}>
										<span>{r.region_name}</span>
									</DataTable.CountryCell>
									<DataTable.Cell>
										<span>{r.type}</span>
									</DataTable.Cell>
									<DataTable.Cell>
										<span>{r.ip_type}</span>
									</DataTable.Cell>
									<DataTable.Cell>
										<span>{r.object_type}</span>
									</DataTable.Cell>
									<DataTable.Cell>
										<span>{r.base_object}</span>
									</DataTable.Cell>
									<DataTable.Cell>
										<span>{r.alert_message}</span>
									</DataTable.Cell>
									<DataTable.Cell customClassName="transformation-rules__actions">
										<button onClick={openEditModal}>
											<img src={ICON_EDIT} alt="Icon edit"></img>
										</button>
										<button onClick={openDeleteModal}>
											<img src={ICON_DELETE} alt="Icon delete"></img>
										</button>
									</DataTable.Cell>
								</tr>
							);
						})}
					</DataTable>
				</div>
				{createRuleModalOpen ?
					<CreateRuleModal
						regions={regions}
						setShowLoader={setShowLoader}
						getRules={getRules}
						closeModal={closeCreateModal}
						newRuleInit={newRuleInit}
						newRule={newRule}
						setNewRule={setNewRule}
						editMode={editMode}
						setEditMode={setEditMode}
					/>
				:	null}
				{deleteRuleModalOpen ?
					<Modal title="Delete Rule" closeHandler={closeDeleteModal} footerActions={deleteModalFooterActions}>
						<p className="transformation-rules__delete-modal__confirm-msg">
							Are you sure you want to delete rule <span>{ruleForDelete.type}</span> for <span>{ruleForDelete.region_name}</span>?
						</p>
					</Modal>
				:	null}
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default RenewalsTransformationRules;
