import React, { useEffect, useState } from 'react';
import AppSwitch from 'com/util/AppSwitch';

import { AppRoute } from 'com/util/AppRoute';
import Routes from 'data/routes';

import AppTemplate from 'com/templates/ApplicationTemplate';

import { LoaderOverlay, TagSelect, Button, Checkbox } from 'sazzui/lib/main';

import { PAGE_TITLE } from 'data/constants';

import Settings from 'services/rest/settings';
import Firms from 'services/rest/firms';
import Users from 'services/rest/users';
import AssignmentOverride from 'services/rest/assignment_override';

import useTitle from 'services/hooks/useTitle';
import useFilterStorage from 'services/hooks/useFilterStorage';
import useQuery from 'services/hooks/useQuery';

import './style.css';

import ICON_FUNNEL from 'assets/images/icons/ico_funnel.svg';

import AssignmentOverrideRequestsMain from './AssignmentOverrideRequestsMain';
import AssignmentOverrideRequestsForm from './AssignmentOverrideRequestsForm';

const status = {
	APPROVED: 'APPROVED',
	DECLINED: 'DECLINED',
	PENDING: 'PENDING',
};

const AssignmentOverrideRequests = () => {
	useTitle(PAGE_TITLE.ASSIGNMENT_OVERRIDE_REQUESTS);
	const query = useQuery();

	const filterInit = {
		submitted_by: [],
		firms: [],
		regions: [],
		status: [],
		orderBy: [],
		id: query.get('id'),
	};

	const [filter, setFilter] = useState(filterInit);
	const [priorities, setPriorities] = useState([{ label: '', value: '' }]);
	const [firms, setFirms] = useState([{ label: '', value: '' }]);
	const [users, setUsers] = useState([{ label: '', value: '' }]);
	const [accountsExecutives, setAccountsExecutives] = useState([]);
	const [regions, setRegions] = useState([{ label: '', value: '' }]);
	const [statusList, setStatusList] = useState([{ label: '', value: '' }]);
	const [requestsList, setRequestsList] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	const { getFilterValue, setFilterValue } = useFilterStorage();

	useEffect(() => {
		(async () => {
			setShowLoader(true);
			let f = getFilterValue();
			if (f) setFilter(f);
			await fetchRequestsList(f);
			await fetchData();
			setStatusList((prevState) => [
				...prevState,
				...Object.keys(status).map(function (v, l) {
					return { label: status[v], value: v };
				}),
			]);
			setShowLoader(false);
		})();
	}, []);

	const fetchData = async () => {
		try {
			let prioritiesData = await AssignmentOverride.GetPriorites();
			setPriorities((prevState) => [
				...prevState,
				...prioritiesData.map((d) => {
					return { label: d.name, value: d.id };
				}),
			]);

			let firmsData = await Firms.GetAllRaw();
			setFirms((prevState) => [
				...prevState,
				...firmsData.map((f) => {
					return { label: f.name, value: f.id };
				}),
			]);

			let usersData = await Users.GetAllRaw();
			setUsers((prevState) => [
				...prevState,
				...usersData
					.map((u) => {
						return !!(u.first_name && u.last_name) ?
								{
									label: `${u.first_name} ${u.last_name}`,
									value: u.id,
									firm_id: u.firm_id,
								}
							:	false;
					})
					.filter(Boolean),
			]);

			let regionsData = await Settings.GetRegions();
			setRegions((prevState) => [
				...prevState,
				...regionsData.map((d) => {
					return { label: d.name, value: d.id };
				}),
			]);

			let sales = await getSalesUsers();
			setAccountsExecutives([
				{ label: '', value: '' },
				...sales.map((u) => ({
					label: `${u.first_name} ${u.last_name}`,
					value: u.id,
				})),
			]);
		} catch (err) {
			console.log('err', err);
		}
	};

	const getSalesUsers = async () => {
		try {
			let roles = await Settings.GetUserRoles();
			if (!roles) return [];
			let salesRole = roles.find((r) => r.name == 'sales');
			let filters = {
				role: salesRole.id,
				order_by: ['first_name:asc', 'last_name:asc'].join(','),
			};
			let users = await Users.GetAllRaw(filters);
			return users;
		} catch (err) {
			console.log('err', err);
		}
	};

	const fetchRequestsList = async (f) => {
		try {
			if (!!filter.orderBy) {
				filter.order_by = filter.orderBy.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(',');
			}
			let f = getFilterValue();
			let aors = await AssignmentOverride.GetRequests(f ? f : filter);
			setRequestsList(aors);
		} catch (err) {
			console.log('err', err);
		}
	};

	const filterChange = (e) => {
		const { name, value, checked } = e.target;

		if (name === 'show_zero_remaining_cases') {
			setFilter({ ...filter, [name]: checked });
			return;
		}

		setFilter(!!filter[name].includes(value) ? filter[name].filter((f) => f !== value) : { ...filter, [name]: value });
	};

	const filterSubmit = async () => {
		try {
			setShowLoader(true);
			setFilterValue(filter);
			await fetchRequestsList();
			setShowLoader(false);
		} catch (error) {
			console.log('err', error);
		}
	};

	const clearForm = () => {
		setFilter(filterInit);
		filterSubmit();
		setFilterValue(null);
	};

	const sortChange = (orderBy) => {
		setFilter({
			...filter,
			orderBy: orderBy,
		});
	};

	const onChangeRequest = async (data, action) => {
		switch (action) {
			case 'add':
				await AssignmentOverride.CreateRequest(data);
				await fetchRequestsList();
				break;
			case 'edit':
				await AssignmentOverride.UpdateRequest(data.id, data);
				await fetchRequestsList();
				break;
			case 'update_status':
				await AssignmentOverride.UpdateRequestStatus(data.id, data);
				await fetchRequestsList();
				break;
			case 'delete':
				await AssignmentOverride.DeleteRequest(data.id);
				setRequestsList(requestsList.filter((r) => r.id !== data.id));
				break;
		}
	};

	return (
		<AppTemplate title="Assignment Override Requests" headerIcon="assignment_override_requests">
			<AppTemplate.Sidebar>
				<div className="assignment-override-requests__sidebar">
					<h4 className="assignment-override-requests__sidebar__header">
						<img src={ICON_FUNNEL} alt="" />
						Filters
					</h4>
					<Checkbox label='Show "Cases Remaining" rows with 0' name="show_zero_remaining_cases" onChange={filterChange} checked={filter.show_zero_remaining_cases} />
					<TagSelect label="Submitted By" name="submitted_by" options={accountsExecutives} values={filter.submitted_by} onChange={filterChange} theme="dark" />
					<TagSelect label="Region" name="regions" options={regions} values={filter.regions} onChange={filterChange} theme="dark" />
					<TagSelect label="Firm Name" name="firms" options={firms} values={filter.firms} onChange={filterChange} theme="dark" />
					<TagSelect label="Approval" name="status" options={statusList} values={filter.status} onChange={filterChange} theme="dark" />
					<div className="assignment-override-requests__sidebar_actions sidebar_actions">
						<Button theme="ghost-primary" onClick={clearForm}>
							Clear
						</Button>
						<Button onClick={filterSubmit}>Apply Filters</Button>
					</div>
				</div>
			</AppTemplate.Sidebar>
			<AppTemplate.Content>
				<div>
					<LoaderOverlay showLoader={showLoader} />
					<AppSwitch>
						<AppRoute
							route={Routes.ASSIGNMENT_OVERRIDE_REQUESTS_MAIN}
							priorities={priorities}
							firms={firms}
							users={users}
							regions={regions}
							status={status}
							requestsList={requestsList}
							onSortChange={sortChange}
							onChange={onChangeRequest}
							exact
						/>
						<AppRoute
							route={Routes.ASSIGNMENT_OVERRIDE_REQUESTS_ADD}
							priorities={priorities}
							firms={firms}
							users={users}
							regions={regions}
							status={status}
							onChange={onChangeRequest}
							exact
						/>
						<AppRoute
							route={Routes.ASSIGNMENT_OVERRIDE_REQUESTS_EDIT}
							priorities={priorities}
							firms={firms}
							users={users}
							regions={regions}
							status={status}
							onChange={onChangeRequest}
							exact
						/>
					</AppSwitch>
				</div>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export { AssignmentOverrideRequestsMain, AssignmentOverrideRequestsForm };
export default AssignmentOverrideRequests;
