// System Imports
import PropTypes from 'prop-types';
// Template Imports
import AuthBox from 'com/templates/AuthBox';
// SazzUI Imports
import { BackButton } from 'sazzui/lib/main';
// Constant Imports
import { ACCOUNT_CONFIMATION_MESSAGES } from 'data/constants';
// Util Imports
import AppLink from 'com/util/AppLink';
// ICON imports
import ICON_BACK_LOGIN from 'assets/images/icons/login_back_light_purple_blue.svg';
// Style Imports
import './style.css';

const ConfirmAccountIPeer = (props) => {
	return (
		<div className="confirm-account-container">
			<AuthBox title="Account confirmation" titleColor="dark-grey" icon="password" footer={<BackButton icon={ICON_BACK_LOGIN} to="/" AppLink={AppLink} label="Back to login" />}>
				{props.message && ACCOUNT_CONFIMATION_MESSAGES[props.message]}
			</AuthBox>
		</div>
	);
};

ConfirmAccountIPeer.propTypes = {
	message: PropTypes.string,
};

ConfirmAccountIPeer.defaultProps = {
	message: '',
};

export default ConfirmAccountIPeer;
