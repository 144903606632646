import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
export const AppDate = (props) => {
    const locale = navigator.language;
    const localeInput = locale ? locale : undefined;
    const date = props.children ? new Date(props.children) : '';
    const day = new Intl.DateTimeFormat(localeInput, { day: '2-digit' });
    const month = new Intl.DateTimeFormat(localeInput, { month: 'short' });
    const year = new Intl.DateTimeFormat(localeInput, { year: '2-digit' });
    if (!date || typeof date === 'string')
        return _jsx(_Fragment, {});
    const d = day.format(date);
    const m = month.format(date);
    const y = year.format(date);
    return _jsx(_Fragment, { children: `${d}-${m}-${y}` });
};
