import React from 'react';
import { useState, useEffect } from 'react';
import Payments from 'services/rest/payments';
import AppTemplate from 'com/templates/ApplicationTemplate';
import DropIn from 'com/widgets/Airwallex';

import './style.css';

const Payment = () => {
	const [paymentsToken, setPaymentsToken] = useState({});
	const [paymentsIntent, setPaymentsIntent] = useState({});
	const [paymentsIntentId, setPaymentsIntentId] = useState({});

	useEffect(() => {
		(async () => {
			try {
				let token = await Payments.GetPaymentsToken();
				setPaymentsToken(token);

				let customer = await Payments.CreateCustomer();

				let paymentsIntentData = await Payments.CreatePaymentsIntent();
				setPaymentsIntent(paymentsIntentData);
				setPaymentsIntentId(paymentsIntentData?.id);

				await Payments.CreatePaymentsConsent();
			} catch (err) {
				console.log(err);
			}
		})();
	}, []);

	return (
		<AppTemplate title="Payments" headerIcon="" collapsibleSidebar={false}>
			<AppTemplate.Content>{paymentsToken && paymentsIntent && <DropIn intent_id={paymentsIntentId} client_secret={paymentsToken} currency="usd" />}</AppTemplate.Content>
		</AppTemplate>
	);
};
export default Payment;
