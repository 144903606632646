import React, { useState } from 'react';
import { setUserDataAsync } from 'redux/ducks/auth';
import { useDispatch } from 'react-redux';
// templates
import AppTemplate from 'com/templates/ApplicationTemplate';
// sazzui
import { DocumentUploader, Button } from 'sazzui/lib/main';
// services
import OnboardDataImporter from 'services/rest/onboard_data_importer';
import useNotification from 'services/hooks/use_notification';
import JWT from 'services/jwt';
import Auth from 'services/rest/auth';
// data
import { DOCUMENT_OBJECT_CATEGORY, DOCUMENT_OBJECT_TYPE } from 'data/constants';
// util
import { getError } from 'util/errors';
// icons
import ICON_DOWNLOAD_BLURPLE from 'assets/images/icons/ico_download_blurple.svg';
import ICON_INFO_MAGENTA from 'assets/images/icons/ico_info_magenta.svg';
// styles
import './style.css';

import { API_HOST } from 'data/constants';

const ClientOnboarding = () => {
	const uploadBoxIndicatorsInit = { message: '', noFilesSelected: false };
	const sendNotification = useNotification();

	const [files, setFiles] = useState([]);
	const [uploadBoxIndicators, setUploadBoxIndicators] = useState(uploadBoxIndicatorsInit);
	const [showSpinner, setShowSpinner] = useState(false);
	const dispatch = useDispatch();

	const handleUploadFile = async () => {
		setUploadBoxIndicators(uploadBoxIndicatorsInit);
		if (validate()) return;
		setShowSpinner(true);
		try {
			let payload = {
				object_type: DOCUMENT_OBJECT_TYPE.CLIENT_ONBOARD_DATA,
				object_id: '',
				category: DOCUMENT_OBJECT_CATEGORY.CLIENT_ONBOARD_DATA_CATEGORY,
				client_onboard_upload: true,
			};
			// adding timestamp as a prefix of the filename, so we wont have duplicate files in the bucket
			let now = new Date().getTime();
			let fp = files.map((f) => {
				let modifiedFile = new File([f], `${now}__${f.name}`, { type: f.type });
				return modifiedFile;
			});
			let res = await OnboardDataImporter.ParseAndCreate(fp, payload);
			if (res.id) {
				window.location.href = `${API_HOST}/storage/document/${res.name}?id=${res.id}`;
				setUploadBoxIndicators({
					message: 'File uploaded with errors. Please check the downloaded file for more details. Note that rows without errors were successfully created in our system.',
					noFilesSelected: false,
				});
			}
			// generate new token that would include the new agents and set it to user data after validation
			let token = await Auth.UpdateTokenData();
			window.localStorage.setItem('token', token);
			let t = await Auth.ValidateToken();
			if (t === token) {
				const user = JWT.parseJWT(t);
				await setUserDataAsync(dispatch, user);
			}

			sendNotification({ type: 'success', title: 'File uploaded successfully' });
		} catch (error) {
			console.log('error', error);
			sendNotification({ type: 'error', title: getError(error.code) });
			setUploadBoxIndicators({
				message: getError(error.code),
				noFilesSelected: false,
			});
		} finally {
			setFiles([]);
			setShowSpinner(false);
		}
	};

	const validate = () => {
		let error = false;
		if (files.length === 0) {
			error = true;
			setUploadBoxIndicators({ message: 'Please select a file', noFilesSelected: true });
		}
		return error;
	};

	return (
		<AppTemplate title="Client onboarding data" headerIcon="assignment_override_requests">
			<AppTemplate.Content>
				<div className="main-screen-section white full-height no-padding tools-details-content">
					<div className="tools-details-content__body">
						<div className="client-onboarding">
							<div className="client-onboarding__group">
								<div className="client-onboarding__group-main">
									<span className="client-onboarding__group-main__label">1. Excel Template</span>
									<a href={process.env.REACT_APP_CLIENT_ONBOARDING_DATA_TEMPLATE} className="client-onboarding__group-main__download">
										<img src={ICON_DOWNLOAD_BLURPLE} alt="icon download purple" />
										Download template
									</a>
								</div>
								<span className="client-onboarding__group-info">Kindly download the template, complete it with the required details, and then proceed to upload here.</span>
							</div>
							<div className="client-onboarding__group">
								<div className="client-onboarding__group-main columns">
									<span className="client-onboarding__group-main__label">2. Upload File</span>
									<DocumentUploader setFiles={setFiles} files={files} uploadBoxIndicators={uploadBoxIndicators} />
								</div>
								<span className="client-onboarding__group-info italic">
									<img src={ICON_INFO_MAGENTA} alt="icon info magenta" className="client-onboarding__group-info-icon" />
									Just upload your data and select the data type, and our AI will anylize your upload and <br /> add your data to your profile for you.
								</span>
							</div>
						</div>
					</div>
					<footer className="tools-details-content__footer">
						<Button onClick={handleUploadFile} showSpinner={showSpinner}>
							Upload
						</Button>
					</footer>
				</div>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default ClientOnboarding;
