import { Modal } from 'sazzui/lib/main';

const ChinaExtensionModal = (props) => {
	return (
		<Modal footerActions={props.footerActions} title="China Extension Fee">
			<div className="quote-details__china-extension-modal_content">
				<span>The Priority Date or International Filing Date has been modified.</span>
				<span>Adjust the switch for the 2-month extension into China, if necessary.</span>
			</div>
		</Modal>
	);
};

export default ChinaExtensionModal;
