import { useEffect, useState } from 'react';

import { Modal, Select, LoaderOverlay } from 'sazzui/lib/main';

import Settings from 'services/rest/settings';

import './style.css';

const QuotesDetailsViewOverridesModal = (props) => {
	const [showLoader, setShowLoader] = useState(true);
	const [currencies, setCurrencies] = useState([]);
	const [selectedCurrency, setSelectedCurrency] = useState('');

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, []);

	const fetchData = async () => {
		try {
			setShowLoader(true);
			let currenciesData = await Settings.GetExchangeRates();
			if (currenciesData) {
				let displayCurrency = currenciesData.map((c) => {
					return {
						label: c.code,
						value: c.currency_id,
					};
				});
				setCurrencies(displayCurrency);
				if (props.selectedCurrency) {
					let c = currenciesData.find((cr) => cr.currency_id === props.selectedCurrency);
					if (c) {
						setSelectedCurrency(c.currency_id);
					}
				}
			}
			setShowLoader(false);
		} catch (err) {
			setShowLoader(false);
			console.log(err);
		}
	};

	const submit = () => {
		props.submitCurrency(selectedCurrency);
		props.closeHandler();
	};

	const modalActions = [
		{ primary: false, label: 'Cancel', action: props.closeHandler, theme: 'primary' },
		{ primary: true, label: 'Save', action: submit, theme: 'primary' },
	];

	const currencyChange = (e) => {
		setSelectedCurrency(e.target.value);
	};

	return (
		<Modal title="Convert Currency" closeHandler={props.closeHandler} footerActions={modalActions}>
			<LoaderOverlay showLoader={showLoader} />
			<div className="modify-currency-modal">
				<div className="modify-currency-modal__body">
					<div>Lorem Ipsum is simply dummy text fo the printing and typesetting industry.</div>
					<div className="modify-currency-modal__body-select">
						<Select label="Convert Currency" options={currencies} name="currency" value={selectedCurrency} onChange={currencyChange} />
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default QuotesDetailsViewOverridesModal;
