import { useSelector, useDispatch } from 'react-redux';
import { setQuoteData } from 'redux/ducks/quote';
import { setIsUpdated, REDIRECT_FROM_QUOTE_MSG } from 'redux/ducks/redirect';

import { RadioButton, Checkbox, Switch } from 'sazzui/lib/main';
import { UTILITY_MODEL_PRIORITY_TYPE, SERVICE_KEYS, ROLE_TYPES, RECORDAL_TYPES, INTERNAL_ROLES, EXTERNAL_ROLES } from 'data/constants';
import { RoleBox } from 'com/util/RoleBox';

import './style.css';

const Services = (props) => {
	const user = useSelector((state) => state.auth.user);
	const quote = useSelector((state) => state.quote);
	const dispatch = useDispatch();

	const showInternalOnlyService = (service) => {
		if (user.role.includes('saas_user') && service.key === SERVICE_KEYS.RECORDAL) {
			return false;
		}

		if (service.key !== SERVICE_KEYS.PROSECUTION && service.key !== SERVICE_KEYS.OTHER) {
			return true;
		}

		return user.roleType === ROLE_TYPES.INTERNAL ? true : false;
	};

	const serviceUpdate = async (e) => {
		if (user.roleType === ROLE_TYPES.EXTERNAL) {
			let service = props.services.find((s) => s.id === e.target.value);
			let date;
			if (service.key === SERVICE_KEYS.PCT) {
				date = quote.earliest_priority_date || quote.intl_filing_date;
			} else {
				date = quote.earliest_priority_date;
			}
			if (date) {
				props.validateInstructionsDeadline(new Date(date), e.target.value, props.services, quote.priority_type);
			}
		}

		dispatch(setQuoteData({ name: e.target.name, value: e.target.value }));
		dispatch(setQuoteData({ name: 'regions', value: [] }));
		dispatch(setIsUpdated({ isUpdated: true, checkExact: true, message: REDIRECT_FROM_QUOTE_MSG }));

		if (quote.application_number && quote.client_firm_id) {
			let duplicate = await props.checkDuplicates(e.target.value, quote.application_number, quote.client_firm_id);
			if (duplicate) {
				props.setErrors({
					...props.errors,
					application_number: 'Already Existing',
				});
				return;
			}
		}

		if (props.errors.application_number) {
			props.setErrors({
				...props.errors,
				application_number: '',
			});
		}
	};

	const priorityTypeUpdate = (e) => {
		if (quote.earliest_priority_date && user.roleType === ROLE_TYPES.EXTERNAL) {
			props.validateInstructionsDeadline(new Date(quote.earliest_priority_date), quote.service_id, props.services, e.target.value);
		}
		dispatch(setQuoteData({ name: e.target.name, value: e.target.value }));
		dispatch(setIsUpdated({ isUpdated: true, checkExact: true, message: REDIRECT_FROM_QUOTE_MSG }));
	};

	const showByService = (service_id, service_keys) => {
		let service = props.services.find((s) => s.id == service_id);
		if (service) {
			if (service_keys.includes(service.key)) {
				return true;
			}
		}
		return false;
	};

	const technologyChange = (e) => {
		let application_technologies = [];
		if (quote.application_technologies) {
			if (e.target.checked) {
				application_technologies = [...quote.application_technologies, e.target.name];
			} else {
				application_technologies = [...quote.application_technologies.filter((ds) => ds !== e.target.name)];
			}
		} else {
			application_technologies = [e.target.name];
		}
		dispatch(setQuoteData({ name: 'application_technologies', value: application_technologies }));
		dispatch(setIsUpdated({ isUpdated: true, checkExact: true, message: REDIRECT_FROM_QUOTE_MSG }));
	};

	return (
		<div className="quote-details__section-container">
			{props.newQuote ?
				<div className="quote-details__section-container-services-top">
					<div className="quote-details__section-container-services-top-left right-separator">
						<h4 className={`quote-details__subtitle ${props.errors.application_technologies ? 'section-title-error' : ''}`}>Services</h4>
						<ul className="quote-details__service-list">
							{props.services.map((s) => {
								if (showInternalOnlyService(s)) {
									return (
										<li key={s.id}>
											<RadioButton
												label={s.name}
												name="service_id"
												value={s.id}
												checked={quote.service_id === s.id}
												onChange={serviceUpdate}
												customClassName="quote-details__service-list-service"
												ariaLabel="Service RadioButton"
											/>
										</li>
									);
								} else {
									return null;
								}
							})}
						</ul>
					</div>
					<div className="quote-details__section-container-services-top-right">
						{showByService(quote.service_id, [SERVICE_KEYS.PCT, SERVICE_KEYS.PARIS, SERVICE_KEYS.UTILITY]) ?
							<>
								<h4 className={`quote-details__subtitle ${props.errors.application_technologies ? 'error' : ''}`}>
									Technologies
									<RoleBox roles={[...INTERNAL_ROLES, ...EXTERNAL_ROLES.filter((r) => r !== 'saas_user')]}>
										<span className="quote-details__service-list-techologies-required" title={'This field is required.'}>
											&#42;
										</span>
									</RoleBox>
								</h4>
								<ul className="quote-details__service-list">
									{props.technologiesData.map((s) => {
										let checked = quote.application_technologies && quote.application_technologies.includes(s.id);
										return (
											<li key={s.id}>
												<Checkbox
													customClassName="quote-details__service-list-technologies-checkbox"
													label={s.name}
													name={s.id}
													onChange={technologyChange}
													value={checked}
													checked={checked}
													ariaLabel="Technology Checkbox"
												/>
											</li>
										);
									})}
								</ul>
							</>
						:	null}
					</div>
				</div>
			:	null}
			{!props.newQuote ?
				<>
					<h4 className={`quote-details__subtitle ${props.errors.application_technologies ? 'section-title-error' : ''}`}>Services</h4>
					<ul className="quote-details__service-list">
						{props.services.map((s) => {
							if (showInternalOnlyService(s)) {
								return (
									<li key={s.id}>
										<RadioButton
											label={s.name}
											name="service_id"
											value={s.id}
											checked={quote.service_id === s.id}
											onChange={serviceUpdate}
											customClassName="quote-details__service-list-service"
											ariaLabel="Service RadioButton"
										/>
									</li>
								);
							} else {
								return null;
							}
						})}
					</ul>
					{showByService(quote.service_id, [SERVICE_KEYS.PCT, SERVICE_KEYS.PARIS, SERVICE_KEYS.UTILITY]) ?
						<div>
							<h4 className={`quote-details__subtitle ${props.errors.application_technologies ? 'error' : ''}`}>
								Technologies
								<span className="quote-details__service-list-techologies-required" title={'This field is required.'}>
									&#42;
								</span>
							</h4>
							<ul className="quote-details__service-list">
								{props.technologiesData.map((s) => {
									let checked = quote.application_technologies && quote.application_technologies.includes(s.id);
									return (
										<li key={s.id}>
											<Switch label={s.name} name={s.id} onChange={technologyChange} value={checked} ariaLabel="Technology Switch" />
										</li>
									);
								})}
							</ul>
						</div>
					:	null}
				</>
			:	null}
			{showByService(quote.service_id, [SERVICE_KEYS.UTILITY]) ?
				<div>
					<h4 className={`quote-details__subtitle ${props.errors.priority_type ? 'error' : ''}`}>
						Priority Type
						<span className="quote-details__service-list-techologies-required" title={'This field is required.'}>
							&#42;
						</span>
					</h4>
					<ul className="quote-details__service-list">
						<li>
							<RadioButton
								label="PCT NPE"
								name="priority_type"
								value={UTILITY_MODEL_PRIORITY_TYPE.PCT_NPE}
								checked={quote.priority_type === UTILITY_MODEL_PRIORITY_TYPE.PCT_NPE}
								onChange={priorityTypeUpdate}
								ariaLabel="PCT NPE RadioButton"
							/>
						</li>
						<li>
							<RadioButton
								label={'Paris Convention/Direct'}
								name="priority_type"
								value={UTILITY_MODEL_PRIORITY_TYPE.PARIS_CONVENTION}
								checked={quote.priority_type === UTILITY_MODEL_PRIORITY_TYPE.PARIS_CONVENTION}
								onChange={priorityTypeUpdate}
								ariaLabel="Paris COnvention/Direct RadioButton"
							/>
						</li>
					</ul>
				</div>
			:	null}

			{showByService(quote.service_id, [SERVICE_KEYS.RECORDAL]) ?
				<div>
					<h4 className={`quote-details__subtitle ${props.errors.recordal_type ? 'error' : ''}`}>
						Recordal type
						<span className="quote-details__service-list-techologies-required" title="This field is required">
							&#42;
						</span>
					</h4>
					<ul className="quote-details__service-list">
						{RECORDAL_TYPES.map((r, i) => {
							return (
								<li key={i}>
									<RadioButton
										label={r.label}
										name="recordal_type"
										value={r.value}
										checked={quote.recordal_type === r.value}
										onChange={serviceUpdate}
										ariaLabel="Recordal Type RadioButton"
									/>
								</li>
							);
						})}
					</ul>
				</div>
			:	null}
		</div>
	);
};

export default Services;
