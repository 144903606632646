import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
// util
import { AppDate } from '../../util/AppDate';
// styles
import './style.css';
export const Input = forwardRef(({ theme = 'primary', customClassName = '', label = '', placeholder = '', type = 'text', name = '', error = '', previewToolTip = '', description = '', info = '', value = undefined, previewValue = '', icons = [], required = false, disabled = false, privateField = false, onChange = () => { }, onBlur = () => { }, onKeyDown = () => { }, onMouseDown = () => { }, onKeyUp = () => { }, ariaLabel = '', }, ref) => {
    const disableScroll = (e) => e.target.blur();
    const ariaLabelValue = ariaLabel || label || '';
    return (_jsxs("label", { className: `form-input ${customClassName} ${error ? 'error' : ''}`, children: [_jsxs("span", { className: `label ${theme} ${info ? 'info' : ''}`, children: [label, required ? (_jsx("span", { className: "required", title: 'This field is required.', children: "*" })) : null, icons.length > 0 &&
                        icons.map((icon) => {
                            if (icon.icon) {
                                return _jsx("img", { src: icon.icon, alt: `${icon.tooltip || ''}`, title: `${icon.tooltip || ''}` });
                            }
                            return null;
                        })] }), description ? _jsx("span", { className: "description", children: description }) : null, _jsx("input", { ...(privateField ? { 'data-private': 'true' } : {}), disabled: disabled, className: `input ${theme} ${disabled ? 'disabled' : ''}`, placeholder: placeholder, type: type, onChange: onChange, onBlur: onBlur, onKeyUp: onKeyUp, value: typeof value !== 'undefined'
                    ? type === 'number'
                        ? typeof value === 'string'
                            ? value.replace(/^(?:0+(?=[1-9])|0+(?=0$))/gm, '')
                            : Number(value).toString()
                        : value
                    : undefined, name: name, onKeyDown: onKeyDown, ref: ref, onWheel: disableScroll, onMouseDown: onMouseDown, "aria-label": ariaLabelValue }), error && _jsx("span", { className: "error-label", children: error }), !error && previewValue && (_jsx("span", { className: "preview", title: previewToolTip, children: type === 'date' ? _jsx(AppDate, { children: previewValue }) : previewValue }))] }));
});
