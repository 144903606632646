import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//System Imports
import { useState, useEffect } from "react";
//Icon Imports
import ICON_AVATAR from '../../../../src/assets/images/icons/avatar_placeholder_invert.svg';
;
export const PersonCell = ({ ico = '', customClassName = '', showIco = false, theme = 'default', children = null, subtext = '', }) => {
    const [avatarUrl, setAvatarUrl] = useState(ICON_AVATAR);
    useEffect(() => {
        GetImageUrl(ico);
    }, [ico]);
    const GetImageUrl = (image) => {
        if (image) {
            setAvatarUrl(`${process.env.REACT_APP_PUBLIC_ASSETS_URL}/${image}`);
        }
    };
    return (_jsx("td", { children: _jsxs("div", { className: `table-cell table-cell-person ${customClassName}`, children: [showIco && (_jsxs("picture", { className: `avatar-${theme}`, children: [_jsx("source", { srcSet: "" }), _jsx("img", { src: avatarUrl, alt: "avatar" })] })), _jsxs("div", { className: "person-info", children: [_jsx("span", { className: "full-name", children: children }), _jsx("span", { className: "subtext", children: subtext })] })] }) }));
};
