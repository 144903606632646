//vendor imports
import { useEffect, useState } from 'react';
import { MapContainer, GeoJSON, Pane } from 'react-leaflet';
import { useSelector } from 'react-redux';

import RegionSearchSelector from 'com/widgets/RegionSearchSelector';
import { LoaderOverlay } from 'sazzui/lib/ui/LoaderOverlay';

import Markers from './Markers';
import RegionDetails from './RegionDetails';

import BI from 'services/rest/bi';

import ICON_HEADER_CASES from 'assets/images/icons/ico_case_gray.svg';

import 'leaflet/dist/leaflet.css';
import './style.css';

const DashboardMap = (props) => {
	const selectedRegionInit = {
		id: '',
		center: [],
	};
	const user = useSelector((state) => state.auth.user);
	const [selectedRegion, setSelectedRegion] = useState(selectedRegionInit);
	const [firmAgentRegions, setFirmAgentRegions] = useState([]);
	const [regions, setRegions] = useState([]);
	const [regionsGeoJson, setRegionsGeoJson] = useState(null);
	const [showLoader, setShowLoader] = useState(false);

	const geoJSONStyle = {
		fillColor: '#3E369A',
		fillOpacity: 1,
		color: '#E5EDF6',
		weight: 1.3,
	};

	useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {
		try {
			setShowLoader(true);
			let [firmRegionsData, regionsGeoData, regions] = await Promise.all([BI.GetFirmRegionsData(user.fid), BI.GetGeoJsonData(), BI.GetCountriesData()]);
			setRegions(regions);
			setFirmAgentRegions(firmRegionsData);
			setRegionsGeoJson(regionsGeoData);
		} catch (err) {
			console.log(err);
		} finally {
			setShowLoader(false);
		}
	};

	const onClick = (event) => {
		let region = regions.find((r) => r.code === event.layer.feature.properties.ISO_A2);
		if (region) return setSelectedRegion({ id: region.id, center: region.center });
		setSelectedRegion(selectedRegionInit);
	};

	const closePanel = () => {
		setSelectedRegion(selectedRegionInit);
	};

	const onRegionSearchClick = (rid, center) => {
		if (rid && center) return setSelectedRegion({ id: rid, center: center });
	};

	return (
		<div className="dashboard-map">
			<div className="dashboard-map__header">
				<span className="dashboard-map__header__title">
					<img className="dashboard-map__header-icon" src={ICON_HEADER_CASES} />
					My Agent Network
				</span>
				{/* <Input placeholder='Search for a partner' customClassName="map__search-input"/> */}
			</div>
			<div className="dashboard-map__map-container">
				<LoaderOverlay showLoader={showLoader} />
				<div className="dashboard-map__map-container-search">
					<RegionSearchSelector regions={regions} customClassName="dashboard-map-regions" placeholder="Select a region on the map or specify one here" onClick={onRegionSearchClick} />
				</div>
				<RegionDetails selectedRegion={selectedRegion} closePanel={closePanel} />
				<MapContainer center={[51.505, -0.09]} zoom={3} maxZoom={10} scrollWheelZoom={false}>
					<Pane name="top" style={{ zIndex: 500 }}>
						<Markers regions={firmAgentRegions} selectedRegion={selectedRegion} setSelectedRegion={setSelectedRegion} />
					</Pane>
					<Pane name="bottom" style={{ zIndex: 200 }}>
						{regionsGeoJson ?
							<GeoJSON style={geoJSONStyle} data={regionsGeoJson} eventHandlers={{ click: onClick }} />
						:	null}
					</Pane>
				</MapContainer>
			</div>
		</div>
	);
};

export default DashboardMap;
