// System Imports
import PropTypes from 'prop-types';
// Template Imports
import AuthBox from 'com/templates/AuthBox';
// SazzUI Imports
import { BackButton } from 'sazzui/lib/main';
// Util Imports
import AppLink from 'com/util/AppLink';
// Constant Imports
import { ACCOUNT_CONFIMATION_MESSAGES } from 'data/constants';
// Style Imports
import './style.css';

const ConfirmAccountAzami = (props) => {
	return (
		<div className="confirm-account-container">
			<AuthBox title="Account confirmation" titleColor="azami-blurple" icon="password" footer={<BackButton to="/" AppLink={AppLink} label="Back to login" />}>
				{props.message && ACCOUNT_CONFIMATION_MESSAGES[props.message]}
			</AuthBox>
		</div>
	);
};

ConfirmAccountAzami.propTypes = {
	message: PropTypes.string,
};

ConfirmAccountAzami.defaultProps = {
	message: '',
};

export default ConfirmAccountAzami;
