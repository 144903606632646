// System Imports
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';
// SazzUI Imports
import { Input } from 'sazzui/lib/main';
import { ErrorMessage } from 'sazzui/lib/main';
import { Checkbox } from 'sazzui/lib/main';
import { Button } from 'sazzui/lib/main';
import { BackButton } from 'sazzui/lib/main';
// Util Imports
import AppLink from 'com/util/AppLink';
// Template Imports
import AuthBox from 'com/templates/AuthBox';
// Service Imports
import { ChangePasswordSchema } from 'services/validators/changePassword';
// Data Imports
import Routes from 'data/routes';
// ICON imports
import ICON_BACK_LOGIN from 'assets/images/icons/login_back_light_purple_blue.svg';
// Style Imports
import './style.css';

const ChangePasswordIPeer = (props) => {
	return (
		<div className="change-password-container">
			<AuthBox title="Reset Password" titleColor="dark-grey" icon="password" footer={<BackButton to="/" AppLink={AppLink} label={'Back to login'} theme="primary" />}>
				<Formik initialValues={props.initialState} validationSchema={ChangePasswordSchema} onSubmit={props.changePasswordSubmit} validateOnChange={false}>
					{({ values, handleChange, validateField, errors, status }) => {
						const validate = (e) => validateField(e.target.name);

						return (
							<Form>
								<span className="change-password-container-ipeer__text">Please enter new password.</span>
								<Input name="password" value={values.password} onChange={handleChange} error={errors.password} label="Password" type="password" onBlur={validate} />
								<Input
									name="password_repeat"
									value={values.password_repeat}
									onChange={handleChange}
									error={errors.password_repeat}
									label="Retype Password"
									type="password"
									onBlur={validate}
								/>
								{!props.tc && (
									<Checkbox
										customClassName="user-details-content__footer__checkbox-label change-password-container__checkbox"
										label={
											<>
												I Agree to the{' '}
												<AppLink type="nav" to={Routes.LEGAL_INFO_TERMS_AND_CONDITIONS.path} target="_blank">
													Terms &amp; Conditions
												</AppLink>{' '}
												and{' '}
												<AppLink type="nav" to={Routes.LEGAL_INFO_ENGAGEMENT_LETTER.path} target="_blank">
													Engagement Letter
												</AppLink>{' '}
												of iPeer
											</>
										}
										name="tos_agreement"
										checked={values.tos_agreement}
										onChange={handleChange}
										error={errors.tos_agreement}
										onBlur={validate}
										themeChecked="blue"
										title="Terms and Conditions agreement"
									/>
								)}
								<div className="change-password-container-ipeer__actions">
									<Button type="submit" showSpinner={props.spinner}>
										Change Password
									</Button>
								</div>
								{status && <ErrorMessage error={status} />}
							</Form>
						);
					}}
				</Formik>
			</AuthBox>
		</div>
	);
};

ChangePasswordIPeer.propTypes = {
	initialState: PropTypes.object,
	changePasswordSubmit: PropTypes.func,
	spinner: PropTypes.bool,
	tc: PropTypes.string,
};

ChangePasswordIPeer.defaultProps = {
	initialState: {},
	changePasswordSubmit: () => {},
	spinner: false,
	tc: '',
};

export default ChangePasswordIPeer;
