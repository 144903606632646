import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import ICON_INFO_MAGENTA from '../../../src/assets/images/icons/ico_toast_info_violet.svg';
import './style.css';
export const Select = forwardRef(({ theme = 'primary', customClassName = '', label = '', name = '', value = '', options = [], disabled = false, required = false, error = '', previewValue = '', previewToolTip = '', infoHint = '', showInfoHint = false, onChange = () => { }, onBlur = () => { }, onMouseDown = () => { }, ariaLabel = '', onClick = () => { }, }, ref) => {
    const ariaLabelValue = ariaLabel || label || '';
    return (_jsxs("label", { className: `form-select ${error ? 'error' : ''} ${customClassName ? customClassName : ''}`, onClick: onClick, onMouseDown: onMouseDown, children: [_jsxs("span", { className: "label", children: [label, required ? (_jsx("span", { className: "required", title: "This field is required.", children: "*" })) : null] }), infoHint ? (_jsxs("span", { className: `info-hint ${showInfoHint ? 'show-info-hint' : ''}`, children: [_jsx("img", { src: ICON_INFO_MAGENTA, alt: "" }), _jsx("span", { children: infoHint })] })) : null, _jsx("select", { disabled: disabled, className: `input ${theme} ${disabled ? 'disabled' : ''}`, onChange: onChange, onBlur: onBlur, value: String(value), name: name, ref: ref, "aria-label": ariaLabelValue, children: options &&
                    options.map((o, i) => {
                        return (_jsx("option", { value: o.value, children: o.label }, i));
                    }) }), error && _jsx("span", { className: "error-label", children: error }), !error && previewValue && (_jsx("span", { className: "preview", title: previewToolTip, children: previewValue }))] }));
});
