import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import propTypes from 'prop-types';
import AppLink from 'com/util/AppLink';

import { RoleBox } from 'com/util/RoleBox';
import { NotificationLayer } from 'sazzui/lib/main';

import { EXTERNAL_ROLES, INTERNAL_ROLES, RENEWALS_URL, PATENT_SEARCHES_URL } from 'data/constants';

import { formatWebLink } from 'services/strings';
import { random } from 'services/strings';
import Auth from 'services/rest/auth';
import { clearUserData } from 'redux/ducks/auth';
import { toastsRemoveAll, toastsRemove } from 'redux/ducks/notification_toasts';

import Banner from 'com/pages/SystemSettingsBanners/Banner';
import { UserMenu, Button } from 'sazzui/lib/main';
import Footer from 'com/widgets/Footer';
import CustomerSupport from 'com/widgets/CustomerSupport';

import Routes from 'data/routes';

import useClickOutside from 'services/hooks/useClickOutside';
import { redirectInit, setIsUpdated } from 'redux/ducks/redirect';

import ICON_AVATAR_PLACEHOLDER from 'assets/images/icons/ico_avatar_placeholder_blurple.svg';
import ICON_FIRM_MANAGEMENT from 'assets/images/icons/ico_firm_management_blurple.svg';
import ICON_ARROWS_LEFT from 'assets/images/icons/ico_arrows_left.svg';
import ICON_ARROWS_RIGHT from 'assets/images/icons/ico_arrows_right.svg';
import ICON_HEADER_REPORTS from 'assets/images/icons/ico_header_reports.svg';
import ICON_HEADER_CASES from 'assets/images/icons/ico_header_cases_azami.svg';
// import ICON_HEADER_USER_MANAGEMENT from 'assets/images/icons/ico_user_management_grey.svg';
import ICON_HEADER_USER_MANAGEMENT from 'assets/images/icons/ico_user_management_blurple.svg';
import ICON_HEADER_TOOLS from 'assets/images/icons/ico_header_tools.svg';
import ICON_SETTINGS from 'assets/images/icons/settings.svg';
import ICON_SETTINGS_GRAY from 'assets/images/icons/settings_gray.svg';
import ICON_TOOLS_TRANSLATIONS from 'assets/images/icons/ico_tools_translations.svg';
import ICON_QUOTES from 'assets/images/icons/ico_quotes.svg';
import ICON_TOOLS_ASSIGMENT_OVERRIDE from 'assets/images/icons/ico_tools_assignment_override.svg';
import ICON_TOOLS_DUPLICATE_MANAGEMENT from 'assets/images/icons/ico_tools_duplicate_management.svg';
import ICON_GEAR_BLURPLE from 'assets/images/icons/ico_gear_blurple.svg';
import VALIDATION_RULES from 'assets/images/icons/ico_validation_rules.svg';

import './style.css';

const AppTemplate = (props) => {
	let ssb = window.localStorage.getItem('showSidebar') === 'true' ? true : false;
	const notificationToasts = useSelector((state) => state.notification_toasts);
	const dispatch = useDispatch();

	const userMenuBtn = useClickOutside(() => setMenuVisibility(false));
	const user = useSelector((state) => state.auth.user);
	const params = useParams();
	const history = useHistory();

	const location = useLocation();
	const [showSidebar, setShowSidebar] = useState(ssb);
	const [headerIcon, setHeaderIcon] = useState(ICON_HEADER_REPORTS);
	const isUpdated = useSelector((state) => state.redirect.isUpdated);
	const branding = useSelector((state) => state.branding);
	const [menuVisibility, setMenuVisibility] = useState(false);
	const [showModalLogOut, setShowModalLogOut] = useState(false);
	const [avatarUrl, setAvatarUrl] = useState(null);

	const toggleSidebar = () => {
		window.localStorage.setItem('showSidebar', !showSidebar);
		setShowSidebar(!showSidebar);
	};

	const onConfirmLogout = () => {
		onClickLogout();
		dispatch(setIsUpdated(redirectInit));
	};

	const onClickUserMenu = (e) => {
		setMenuVisibility(!menuVisibility);
	};

	const onClickLogout = async () => {
		try {
			await Auth.Logout();
			window.localStorage.removeItem('token');
			window.localStorage.clear();
			// clear redux
			dispatch(clearUserData());
			// clear closed banners cookie (to be displayed again on login)
			document.cookie = 'closedBanners=[]; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
			// Destroy Userpilot session
			Userpilot.destroy();
			history.push('/');
		} catch (err) {
			console.log(err);
		}
	};

	const onCancelLogout = () => {
		setShowModalLogOut(false);
	};

	const logOutNavigationConfirmation = () => {
		if (isUpdated) {
			setShowModalLogOut(true);
		} else {
			onClickLogout();
		}
	};

	const GetImageUrl = async (image) => {
		if (image) {
			let string = random(20);
			setAvatarUrl(`${process.env.REACT_APP_PUBLIC_ASSETS_URL}/${image}?${string}`);
		} else {
			setAvatarUrl(ICON_AVATAR_PLACEHOLDER);
		}
	};

	useEffect(() => {
		GetImageUrl(user.image);
	}, [user.image]);

	useEffect(() => {
		switch (props.headerIcon) {
			case 'home':
				setHeaderIcon(ICON_HEADER_REPORTS);
				break;
			case 'reports':
				setHeaderIcon(ICON_HEADER_REPORTS);
				break;
			case 'associate_assignment':
				setHeaderIcon(ICON_HEADER_CASES);
				break;
			case 'quotes':
				setHeaderIcon(ICON_QUOTES);
				break;
			case 'notification':
				setHeaderIcon(ICON_HEADER_REPORTS);
				break;
			case 'emails':
				setHeaderIcon(ICON_HEADER_REPORTS);
				break;
			case 'user_management':
				setHeaderIcon(ICON_HEADER_USER_MANAGEMENT);
				break;
			case 'firm_management':
				setHeaderIcon(ICON_FIRM_MANAGEMENT);
				break;
			case 'tools':
				setHeaderIcon(ICON_HEADER_TOOLS);
				break;
			case 'cases':
				setHeaderIcon(ICON_HEADER_CASES);
				break;
			case 'assignment_override_requests':
				setHeaderIcon(ICON_TOOLS_ASSIGMENT_OVERRIDE);
				break;
			case 'translation_requests':
				setHeaderIcon(ICON_TOOLS_TRANSLATIONS);
				break;
			case 'duplicate_management':
				setHeaderIcon(ICON_TOOLS_DUPLICATE_MANAGEMENT);
				break;
			case 'system_settings':
				setHeaderIcon(ICON_GEAR_BLURPLE);
				break;
			case 'validation-rules':
				setHeaderIcon(VALIDATION_RULES);
				break;
			default:
				setHeaderIcon('');
				break;
		}
		// remove recaptcha
		if (document.querySelector('#recaptcha-key')) {
			document.querySelector('#recaptcha-key').remove();
		}
		if (document.querySelector('.grecaptcha-badge')) {
			document.querySelector('.grecaptcha-badge').parentNode.remove();
		}
	}, []);

	const filterSaasUser = () => {
		let arr = [...EXTERNAL_ROLES.filter((r) => r !== 'saas_user').map((r) => `${r}:dev`)];
		return arr;
	};

	const content = React.Children.map(props.children, (child) => (child.type.displayName === 'ApplicationContent' ? child : null));
	const sidebar = React.Children.map(props.children, (child) => (child.type.displayName === 'ApplicationSidebar' ? child : null));
	const header = React.Children.map(props.children, (child) => (child.type.displayName === 'ApplicationHeader' ? child : null));

	return (
		<>
			<div className={`application-template ${props.customClassName}`}>
				<div className="application-template__sidenav">
					{sidebar.length > 0 && props.collapsibleSidebar ?
						<>
							<button className={`application-template__btn-sidebar-hide ${showSidebar ? 'hide' : ''}`} onClick={toggleSidebar}>
								<img src={ICON_ARROWS_LEFT} alt="" />
								Collapse
							</button>
							<button className={`application-template__btn-sidebar-show ${showSidebar ? '' : 'hide'}`} onClick={toggleSidebar}>
								<img src={ICON_ARROWS_RIGHT} alt="" />
							</button>
						</>
					:	null}
					<AppLink type="nav" to="/" exact={true} checkRedirect={isUpdated} checkRedirectFrom={['user-management', 'firm-management', '/quotes/add']} className="nohover">
						<img src={branding.logoSmall} className="application-template__sidenav__logo" alt="" />
					</AppLink>
					<nav className={`application-template__sidenav__links ${props.customClassName}`}>
						<AppLink type="nav" to="/" exact={true} checkRedirect={isUpdated} checkRedirectFrom={['user-management', 'firm-management', '/quotes/add']}>
							Home
						</AppLink>
						<RoleBox roles={['saas_user']}>
							<AppLink type="nav" to={`/agents`} exact={true} checkRedirect={isUpdated} checkRedirectFrom={['user-management', 'firm-management', '/quotes/add']}>
								My Agents
							</AppLink>
							<AppLink type="nav" to="/quotes/add" exact={true} checkRedirect={isUpdated} checkRedirectFrom={['user-management', 'firm-management', '/quotes/add']}>
								Quote Builder
							</AppLink>
						</RoleBox>
						<AppLink type="nav" to="/quotes" exact={true} checkRedirect={isUpdated} checkRedirectFrom={['user-management', 'firm-management', '/quotes/add']} trackClick={true}>
							{branding.header?.quotes}
						</AppLink>
						<AppLink type="nav" to="/cases" checkRedirect={isUpdated} checkRedirectFrom={['user-management', 'firm-management', '/quotes/add']} trackClick={true}>
							Cases
						</AppLink>
						<RoleBox roles={INTERNAL_ROLES}>
							<AppLink type="nav" to="/reports" checkRedirect={isUpdated} checkRedirectFrom={['user-management', 'firm-management', '/quotes/add']} trackClick={true}>
								Reports
							</AppLink>
						</RoleBox>
						<RoleBox roles={[...INTERNAL_ROLES, ...filterSaasUser()]}>
							<AppLink type="nav" to="/associate-assignment" checkRedirect={isUpdated} checkRedirectFrom={['user-management', 'firm-management', '/quotes/add']} trackClick={true}>
								Associate Assignment
							</AppLink>
						</RoleBox>
						<RoleBox roles={['admin', 'sales', 'sales-manager']}>
							<AppLink type="nav" to="/user-management" checkRedirect={isUpdated} checkRedirectFrom={['user-management', 'firm-management', '/quotes/add']}>
								Users
							</AppLink>
							<AppLink type="nav" to="/firm-management" checkRedirect={isUpdated} checkRedirectFrom={['user-management', 'firm-management', '/quotes/add']}>
								Firms
							</AppLink>
						</RoleBox>
						<RoleBox roles={INTERNAL_ROLES}>
							<AppLink type="nav" to="/tools" checkRedirect={isUpdated} checkRedirectFrom={['user-management', 'firm-management', '/quotes/add']}>
								Tools
							</AppLink>
						</RoleBox>
						<RoleBox roles={EXTERNAL_ROLES.filter((er) => er !== 'saas_user')}>
							<AppLink className="external-link" type="regular" to={{ pathname: formatWebLink(RENEWALS_URL) }} trackClick={true} target="_blank">
								Renewals
							</AppLink>
							<AppLink className="external-link" type="regular" to={{ pathname: formatWebLink(PATENT_SEARCHES_URL) }} trackClick={true} target="_blank">
								Patent Searches
							</AppLink>
						</RoleBox>
					</nav>
					<CustomerSupport />
					<nav className="application-template__sidenav__links__bottom">
						<RoleBox roles={['admin', 'sales', 'sales-manager']}>
							<AppLink type="regular" to="/system-settings" className="nohover" checkRedirect={isUpdated} checkRedirectFrom={['user-management', 'firm-management', '/quotes/add']}>
								<img src={location.pathname.includes(Routes.SYSTEM_SETTINGS.path) ? ICON_SETTINGS : ICON_SETTINGS_GRAY} className="application-template__sidenav__icon" />
							</AppLink>
						</RoleBox>
					</nav>
					<UserMenu
						onClickUserMenu={onClickUserMenu}
						menuVisibility={menuVisibility}
						AppLink={AppLink}
						userMenuRef={userMenuBtn}
						userFirmID={user.fid}
						onClickLogout={logOutNavigationConfirmation}
						checkRedirectFromUserDetails={['firm-management', '/quotes/add']}
						checkRedirectFromFirmProfile={['user-management', 'firm-management', '/quotes/add']}
						checkRedirectUserDetails={isUpdated}
						checkRedirectFirmProfile={user.fid === params.id ? false : isUpdated}
						onConfirmLogout={onConfirmLogout}
						onCancelLogout={onCancelLogout}
						showModalLogout={showModalLogOut}
						image={avatarUrl}
					/>
				</div>
				<Banner />
				<div className="application-template__main-screen">
					{sidebar.length > 0 ?
						<div className={`application-template__main-screen__left ${showSidebar && props.collapsibleSidebar ? 'hide' : ''}`}>{sidebar}</div>
					:	null}
					<div className="application-template__main-screen__right">
						<div className={`application-template__main-screen__header ${showSidebar || sidebar.length == 0 ? 'hide' : ''}`}>
							<img src={headerIcon} className="application-template__main-screen__header__icon" alt="" />
							<h2>{props.title}</h2>
							{props.countBadge && <span className="application-template__main-screen__header__count-badge">{props.countBadge}</span>}

							<div className="application-template__main-screen__header__content">
								{header}
								{props.button && <Button ariaLabel="Header Button">{props.button}</Button>}
							</div>
						</div>
						<div className="application-template__main-content">{content}</div>
					</div>
				</div>
				<Footer />
			</div>
			<NotificationLayer notificationToasts={notificationToasts} removeAllToasts={() => dispatch(toastsRemoveAll())} removeAction={(id) => dispatch(toastsRemove(id))} />
		</>
	);
};

const AppSidebar = (props) => props.children;
AppSidebar.displayName = 'ApplicationSidebar';
AppTemplate.Sidebar = AppSidebar;

const AppContent = (props) => props.children;
AppContent.displayName = 'ApplicationContent';
AppTemplate.Content = AppContent;

const AppHeader = (props) => props.children;
AppHeader.displayName = 'ApplicationHeader';
AppTemplate.Header = AppHeader;

AppTemplate.propTypes = {
	customClassName: propTypes.string,
	collapsibleSidebar: propTypes.bool,
};

AppTemplate.defaultProps = {
	customClassName: '',
	collapsibleSidebar: true,
};

export default AppTemplate;
