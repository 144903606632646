import { Modal } from 'sazzui/lib/main';

const ApplicationNumberFormatModal = (props) => {
	return (
		<Modal footerActions={props.footerActions} title="Wrong Application Number Format">
			<div className="quote-details__appnumber-format-modal_content">
				<span>{'Enter a PCT application or publication number in the correct format. For example:'}</span>
				<span>{'PCT/US2022/123456'}</span>
				<span>{'WO/2022/123456'}</span>
				<span className="quote-details__appnumber-format-modal_content-footer-note">{`If you don't know the application number, enter "None".`}</span>
			</div>
		</Modal>
	);
};

export default ApplicationNumberFormatModal;
