//vendor imports
import React, { useEffect, useState } from 'react';
//utils
import { AppRoute } from 'com/util/AppRoute';
import Routes from 'data/routes';

import PricingLevel from 'services/rest/pricing_levels';
import SystemSettingsPricingLevelsMain from './SystemSettingsPricingLevelsMain';
//ui
import { LoaderOverlay } from 'sazzui/lib/main';
//styles
import './style.css';

const SystemSettingsPricingLevels = () => {
	const filterInit = {
		orderBy: [],
	};

	const [pricingLevels, setPricingLevels] = useState([]);
	const [showArchived, setShowArchived] = useState('0');
	const [filter, setFilter] = useState(filterInit);
	const [showLoader, setShowLoader] = useState(false);

	useEffect(async () => {
		try {
			await fetchData();
		} catch (err) {
			console.error(err);
		}
	}, [filter, showArchived]);

	const fetchData = async () => {
		try {
			setShowLoader(true);
			let filters = {
				show_is_archived: showArchived,
			};
			if (!!filter.orderBy) {
				filters.order_by = filter.orderBy.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(',');
			}
			let pricingLevelsData = await PricingLevel.GetAll(filters);
			setPricingLevels(pricingLevelsData);
			setShowLoader(false);
		} catch (err) {
			console.error(err);
		}
	};

	const sortChange = (orderBy) => {
		setFilter({
			...filter,
			orderBy: orderBy,
		});
	};

	const onChangePricingLevel = async (data, action) => {
		try {
			switch (action) {
				case 'add':
					await PricingLevel.Create(data);
					await fetchData();
					break;
				case 'edit':
					await PricingLevel.UpdateByID(data.id, data);
					await fetchData();
					break;
				case 'delete':
					await PricingLevel.DeleteByID(data.id);
					setPricingLevels(pricingLevels.filter((r) => r.id !== data.id));
					break;
			}
			return true;
		} catch (error) {
			console.log(error);
			return false;
		}
	};

	return (
		<div>
			<LoaderOverlay showLoader={showLoader} />
			<AppRoute
				route={Routes.SYSTEM_SETTINGS_PRICING_LEVELS_MAIN}
				pricingLevels={pricingLevels}
				onChange={onChangePricingLevel}
				showArchived={showArchived}
				setShowArchived={setShowArchived}
				onSortChange={sortChange}
				exact
			/>
			<AppRoute
				route={Routes.SYSTEM_SETTINGS_PRICING_LEVELS_ADD}
				pricingLevels={pricingLevels}
				onChange={onChangePricingLevel}
				showArchived={showArchived}
				setShowArchived={setShowArchived}
				onSortChange={sortChange}
				exact
			/>
			<AppRoute
				route={Routes.SYSTEM_SETTINGS_PRICING_LEVELS_EDIT}
				pricingLevels={pricingLevels}
				onChange={onChangePricingLevel}
				showArchived={showArchived}
				setShowArchived={setShowArchived}
				onSortChange={sortChange}
				exact
			/>
		</div>
	);
};

export { SystemSettingsPricingLevelsMain };

export default SystemSettingsPricingLevels;
