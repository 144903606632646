import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Firms from 'services/rest/firms';
import Users from 'services/rest/users';
import Settings from 'services/rest/settings';
import useQuery from 'services/hooks/useQuery';
import useNotification from 'services/hooks/use_notification';
import { isoStringToMySQLDate } from 'services/dates';

import { Input, Select, Button, Checkbox, MultiSelect, LoaderOverlay, DataTable, Modal } from 'sazzui/lib/main';

import './style.css';

const POINTS_TRANSACTION_TYPE = {
	CASE: 'CASE',
	ACCOUNT: 'ACCOUNT',
};

const PointsAdjustment = (props) => {
	const history = useHistory();
	const location = useLocation();
	const params = useParams();
	const query = useQuery();

	const filterInit = {
		date_from: '',
		date_to: '',
		case_number: query.get('case_number') ? query.get('case_number') : '',
		regions: query.get('regions') ? query.get('regions').split(',') : [],
		amount: '',
		reason_id: '',
	};

	const multieditDataInit = {
		amount: 0,
		reason_id: '',
		custom_reason: '',
	};

	const user = useSelector((state) => state.auth.user);

	const [filter, setFilter] = useState(filterInit);
	const [multieditData, setMultieditData] = useState(multieditDataInit);
	const [regionData, setRegionData] = useState([]);
	const [adjusmentReasons, setAdjusmentReasons] = useState([]);

	const [tableData, setTableData] = useState([]);
	const [tableDataCache, setTableDataCache] = useState({});
	const [selectedCases, setSelectedCases] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [showSpinner, setShowSpinner] = useState(false);
	const sendNotification = useNotification();

	const selectAllToggle = (e) => {
		setSelectAll(e.target.checked);
	};

	const tableHeaders = [
		{ title: <Checkbox name="selectAll" onChange={selectAllToggle} value="SELECT_ALL" checked={selectAll} />, field: '', type: '', sort: 'none', sortable: false },
		{ title: 'Date', field: 'case_created', type: 'date', sort: 'none', sortable: false },
		{ title: 'Case No.', field: 'case_number', type: 'casenum', sort: 'none', sortable: false },
		{ title: 'Service', field: 'service', type: 'string', sort: 'none', sortable: false },
		{ title: 'Region', field: 'region', type: 'string', sort: 'none', sortable: false },
		{ title: 'Points', field: 'points', type: 'number', sort: 'none', sortable: false },
		{ title: 'Reason', field: 'reason', type: 'string', sort: 'none', sortable: false },
		{ title: '', field: '', type: 'string', sort: 'none', sortable: false },
	];

	useEffect(() => {
		(async () => {
			try {
				setShowLoader(true);
				let regions = await Settings.GetRegions();
				let reasons = await Settings.GetPointsTransactionReasons();
				setRegionData(
					regions.map((r) => {
						return {
							value: r.code,
							label: r.name,
						};
					}),
				);
				setAdjusmentReasons([
					{ value: '', label: 'Select Reason' },
					...reasons.map((r) => {
						return {
							value: r.id,
							label: r.name,
							operation: r.operation,
							calc_by_value: r.calc_by_value,
						};
					}),
					{ value: 'CUSTOM', label: 'Custom Reason' },
				]);
				setShowLoader(false);
			} catch (err) {
				setShowLoader(false);
				console.log(err);
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				let points;
				let url = location.pathname.split('/');
				switch (url[1]) {
					case 'firm-management':
						points = await Firms.GetPoints(url[2], filter);
						break;
					case 'user-management':
						points = await Users.GetPoints(url[2], filter);
						break;
					case 'reports':
						points = await Firms.GetPoints(url[4], filter);
						break;
					default:
						throw Error(`Unknown points type: ${url[1]}`);
				}
				// set points
				let gp = groupPoints(points);
				setTableData(
					gp
						.map((p) => {
							p.amount = 0;
							p.custom_reason = '';
							p.reason_id = '';
							p.error_reason_id = '';
							p.error_custom_reason = '';
							p.error_amount = '';
							return p;
						})
						.filter((p) => p.object_type === 'CASE'),
				);
				// create cache for determine whether the row has changed or not
				let cache = {};
				for (let i of gp) {
					cache[i.id] = JSON.stringify(i);
				}
				setTableDataCache(cache);
			} catch (err) {
				console.log(err);
			}
		})();
	}, [filter]);

	useEffect(() => {
		if (selectAll && selectedCases.length < tableData.length) {
			setSelectedCases(tableData.map((td) => td.id));
		} else if (!selectAll && selectedCases.length > 0) {
			setSelectedCases([]);
		}
	}, [selectAll]);

	const groupPoints = (records) => {
		let parentRecords = records
			.filter((r) => !r.parent_id)
			.map((r) => {
				r.total = records
					.filter((i) => {
						return i.id === r.id || i.parent_id === r.id;
					})
					.reduce((prev, cur) => {
						return Number(prev) + Number(cur.amount);
					}, 0);
				return r;
			});
		return parentRecords;
	};

	const close = () => {
		let lParts = location.pathname.split('/');
		let l = lParts.slice(0, -1).join('/');
		history.push(l);
	};

	const getAdjustedCases = () => {
		let adjusted = tableData.filter((td) => {
			return JSON.stringify(td) !== tableDataCache[td.id];
		});
		return adjusted;
	};

	const validateAdjustedCases = (adjusted) => {
		let errNum = 0;
		for (let i of adjusted) {
			let errors = {
				error_reason_id: '',
				error_custom_reason: '',
				error_amount: '',
			};
			if (!i.reason_id) {
				errors.error_reason_id = 'Missing reason';
				errNum++;
			}
			if (i.reason_id === 'CUSTOM' && !i.custom_reason) {
				errors.error_custom_reason = 'Missing custom reason';
				errNum++;
			}
			if (i.amount === null || i.amount === undefined) {
				errors.error_amount = 'Missing amount';
				errNum++;
			}
			setTableData(
				tableData.map((td) => {
					if (td.id === i.id) {
						return { ...td, ...errors };
					}
					return td;
				}),
			);
		}
		return errNum === 0;
	};

	const applyPoints = async () => {
		// find adjusted cases
		let adjusted = getAdjustedCases();
		if (adjusted.length === 0) return;
		// validate if everything is in it's place
		if (!validateAdjustedCases(adjusted)) return;
		setShowSpinner(true);
		// generate final list
		let final = adjusted.map((td) => {
			return {
				object_type: POINTS_TRANSACTION_TYPE.CASE,
				object_id: td.object_id,
				direction: td.direction,
				case_id: td.case_id,
				case_number: td.case_number,
				case_region_id: td.case_region_id,
				service_id: td.service_id,
				region_id: td.region_id,
				internal_user_id: user.uid,
				amount: td.amount,
				parent_id: td.id,
				reason_id: td.reason_id,
				custom_reason: td.custom_reason,
			};
		});
		// send data to backend
		try {
			let url = location.pathname.split('/');
			switch (url[1]) {
				case 'firm-management':
				case 'reports':
					for (let adjusted_points of final) {
						await Firms.ApplyAdjustedPoints(params.id, adjusted_points);
					}
					break;
				case 'user-management':
					for (let adjusted_points of final) {
						await Users.ApplyAdjustedPoints(params.id, adjusted_points);
					}
					break;
				default:
					throw Error(`Unknown points type: ${url[1]}`);
			}
			setShowSpinner(false);
			props.onChange();
			close();
			sendNotification({ type: 'success', title: 'Successfully adjusted points' });
		} catch (err) {
			console.log(err);
			sendNotification({ type: 'error', title: 'Unsuccessfully adjusted points' });
		}
	};

	const modalActions = [
		{ primary: false, label: 'Cancel', action: close, theme: 'primary' },
		{ primary: true, label: 'Apply Points', action: applyPoints, showSpinner: showSpinner, theme: 'primary' },
	];

	const filterChange = (e) => {
		let field = e.target.name;
		let val = e.target.value;

		if (['date_from', 'date_to'].includes(field) && val !== '') {
			val = isoStringToMySQLDate(e.target.value);
		}

		setFilter({
			...filter,
			[field]: val,
		});
	};

	const toggleSelectRow = (e) => {
		if (selectedCases.includes(e.target.name)) {
			setSelectedCases(selectedCases.filter((sc) => sc != e.target.name));
		} else {
			setSelectedCases([...selectedCases, e.target.name]);
		}
	};

	const closeMultiedit = () => {
		setSelectedCases([]);
		setSelectAll(false);
		setMultieditData(multieditDataInit);
	};

	const multieditDataChange = (e) => {
		setMultieditData({
			...multieditData,
			[e.target.name]: e.target.value,
		});
		switch (e.target.name) {
			case 'reason_id':
				setTableData(
					tableData.map((td) => {
						if (selectedCases.includes(td.id)) {
							td.reason_id = e.target.value;
							td.custom_reason = td.reason_id === 'CUSTOM' ? '' : td.custom_reason;
							let amount = calculateAmountForReason(e.target.value, td.total);
							if (amount) td.amount = amount;
						}
						return td;
					}),
				);
				break;
			case 'amount':
				setTableData(
					tableData.map((td) => {
						if (selectedCases.includes(td.id)) {
							td.amount = e.target.value;
						}
						return td;
					}),
				);
				break;
			default:
				setTableData(
					tableData.map((td) => {
						if (selectedCases.includes(td.id)) {
							td[e.target.name] = e.target.value;
						}
						return td;
					}),
				);
				break;
		}
	};

	const rowChange = (e) => {
		const [name, id] = e.target.name.split('__');
		switch (name) {
			case 'reason_id':
				setTableData(
					tableData.map((td) => {
						if (td.id === id) {
							td.reason_id = e.target.value;
							let amount = calculateAmountForReason(e.target.value, td.total);
							if (amount) td.amount = amount;
						}
						return td;
					}),
				);
				break;
			case 'amount':
				setTableData(
					tableData.map((td) => {
						if (td.id === id) {
							td.amount = e.target.value;
						}
						return td;
					}),
				);
				break;
			default:
				setTableData(
					tableData.map((td) => {
						if (td.id === id) {
							td[name] = e.target.value;
						}
						return td;
					}),
				);
				break;
		}
	};

	const calculateAmountForReason = (id, total) => {
		let reason = adjusmentReasons.filter((r) => r.value === id)[0];
		let amount;
		switch (reason?.operation) {
			case 'substract':
				amount = -(Number(total) * Number(reason.calc_by_value));
				break;
			case 'add':
				amount = Number(total) * Number(reason.calc_by_value);
				break;
			default:
				amount = null;
		}
		return amount;
	};

	const revertRow = (e) => {
		if (e.target.dataset.status === 'active') {
			setTableData(
				tableData.map((td) => {
					if (td.id === e.target.dataset.id) {
						return JSON.parse(tableDataCache[e.target.dataset.id]);
					}
					return td;
				}),
			);
		}
	};

	return (
		<Modal title="Points Adjustment" closeHandler={close} footerActions={modalActions}>
			<div className="points-adjustment">
				<LoaderOverlay showLoader={showLoader} />
				<div className="points-adjustment__filter">
					<Input name="date_from" type="date" label="Date From" value={filter.date_from} onChange={filterChange} />
					<Input name="date_to" type="date" label="Date To" value={filter.date_to} onChange={filterChange} />
					<Input name="case_number" label="Case #" value={filter.case_number} onChange={filterChange} />
					<MultiSelect
						label="Regions"
						name="regions"
						options={regionData}
						onChange={filterChange}
						values={filter.regions}
						selectAllEnabled={true}
						customClassName="points-adjustment__regions-filter"
					/>
					<Input name="amount" label="Amount" value={filter.amount} onChange={filterChange} />
					<Select name="reason_id" label="Reason" value={filter.reason_id} onChange={filterChange} options={adjusmentReasons} />
				</div>
				<div className="points-adjustment__body">
					<DataTable fixedHeader={true} columns={tableHeaders} customClassName="points-adjustment__body__table">
						{tableData.map((td) => {
							let checked = selectedCases.includes(td.id);
							let updated = tableDataCache[td.id] !== JSON.stringify(td) ? 'active' : '';

							return (
								<tr key={td.id} className={`${updated}`}>
									<DataTable.Cell>
										<Checkbox checked={checked} name={td.id} onChange={toggleSelectRow} />
									</DataTable.Cell>
									<DataTable.DateCell date={td.case_created} />
									<DataTable.Cell>{td.case_number}</DataTable.Cell>
									<DataTable.Cell>{td.service_name}</DataTable.Cell>
									<DataTable.CountryCell code={td.region_code}>{td.region_name}</DataTable.CountryCell>
									<DataTable.Cell>
										<AdjustmentCalculator data={td} onChange={rowChange} reasons={adjusmentReasons} />
									</DataTable.Cell>
									<DataTable.Cell>
										<ReasonChooser data={td} onChange={rowChange} adjusmentReasons={adjusmentReasons} />
									</DataTable.Cell>
									<DataTable.Cell>
										<button className={`points-adjustment__btn-rollback ${updated}`} data-id={td.id} data-status={updated} onClick={revertRow} aria-label="rollback"></button>
									</DataTable.Cell>
								</tr>
							);
						})}
					</DataTable>
				</div>
				{selectedCases.length > 1 ?
					<div className="points-adjustment__multiedit-form">
						<Input label="Add/Substract Amount" customClassName="points-adjustment__multiedit-form__input" type="number" onChange={multieditDataChange} name="amount" />
						<Select
							name="reason_id"
							label="Reason"
							value={multieditData.reason_id}
							onChange={multieditDataChange}
							options={adjusmentReasons}
							customClassName="points-adjustment__multiedit-form__select"
						/>
						{multieditData.reason_id === 'CUSTOM' ?
							<Input
								label="Custom reason"
								customClassName="points-adjustment__multiedit-form__reason"
								name="custom_reason"
								value={multieditData.custom_reason}
								onChange={multieditDataChange}
							/>
						:	null}
						<div className="points-adjustment__multiedit-form__actions">
							<Button theme="ghost-primary" onClick={closeMultiedit}>
								Close
							</Button>
						</div>
					</div>
				:	null}
			</div>
		</Modal>
	);
};

const AdjustmentCalculator = (props) => {
	const onChange = (e) => {
		let out = {
			target: {
				name: `amount__${props.data.id}`,
				value: e.target.value,
			},
		};
		props.onChange(out);
	};
	return (
		<div className="adjustment-calculator">
			<span className="adjustment-calculator__total">{props.data.total}</span>
			<span className="adjustment-calculator__operator">+</span>
			<Input customClassName="adjustment-calculator__points-input" type="number" value={props.data.amount} onChange={onChange} name="amount" error={props.data.error_amount} ariaLabel="points" />
			<span className="adjustment-calculator__operator">=</span>
			<span className="adjustment-calculator__result">{Number(props.data.total) + Number(props.data.amount)}</span>
		</div>
	);
};

const ReasonChooser = (props) => {
	const onChange = (e) => {
		let out = {
			target: {
				name: `${e.target.name}__${props.data.id}`,
				value: e.target.value,
			},
		};
		props.onChange(out);
	};
	const cancelCustom = () => {
		let out = {
			target: {
				name: `reason_id__${props.data.id}`,
				value: null,
			},
		};
		props.onChange(out);
	};
	return props.data.reason_id === 'CUSTOM' ?
			<div className="points-adjustment__row-reason-input">
				<Input name="custom_reason" placeholder="Custom reason" onChange={onChange} value={props.data.custom_reason} error={props.data.error_custom_reason} ariaLabel="custom reason" />
				<button onClick={cancelCustom}>&times;</button>
			</div>
		:	<Select
				name="reason_id"
				onChange={onChange}
				options={props.adjusmentReasons}
				customClassName="points-adjustment__row-reason-dropdown"
				value={props.data.reason_id}
				error={props.data.error_reason_id}
				ariaLabel="reason dropdown"
			/>;
};

export default PointsAdjustment;
