import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//Icon Imports
import ICON_DATE from '../../../../src/assets/images/icons/ico_date.svg';
import ICON_CLOCK from '../../../../src/assets/images/icons/ico_clock.svg';
export const DateTimeCell = ({ date = '', customClassName = '', }) => {
    const d = new Date(date);
    const dl = d.toLocaleDateString();
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    return (_jsx("td", { children: _jsxs("div", { className: `table-cell table-cell-date-time ${customClassName ? customClassName : ''}`, children: [_jsx("img", { src: ICON_DATE, alt: "date" }), _jsx("span", { className: 'date', children: `${dl}` }), _jsx("img", { src: ICON_CLOCK, alt: "time" }), _jsx("span", { children: `${hours}:${minutes}` })] }) }));
};
