import AD from '../assets/images/flags/ad.png';
import AE from '../assets/images/flags/ae.png';
import AF from '../assets/images/flags/af.png';
import AG from '../assets/images/flags/ag.png';
import AI from '../assets/images/flags/ai.png';
import AL from '../assets/images/flags/al.png';
import AM from '../assets/images/flags/am.png';
import AO from '../assets/images/flags/ao.png';
import AQ from '../assets/images/flags/aq.png';
import AR from '../assets/images/flags/ar.png';
import AS from '../assets/images/flags/as.png';
import AT from '../assets/images/flags/at.png';
import AU from '../assets/images/flags/au.png';
import AW from '../assets/images/flags/aw.png';
import AX from '../assets/images/flags/ax.png';
import AZ from '../assets/images/flags/az.png';
import BA from '../assets/images/flags/ba.png';
import BB from '../assets/images/flags/bb.png';
import BD from '../assets/images/flags/bd.png';
import BE from '../assets/images/flags/be.png';
import BF from '../assets/images/flags/bf.png';
import BG from '../assets/images/flags/bg.png';
import BH from '../assets/images/flags/bh.png';
import BI from '../assets/images/flags/bi.png';
import BJ from '../assets/images/flags/bj.png';
import BL from '../assets/images/flags/bl.png';
import BM from '../assets/images/flags/bm.png';
import BN from '../assets/images/flags/bn.png';
import BO from '../assets/images/flags/bo.png';
import BQ from '../assets/images/flags/bq.png';
import BR from '../assets/images/flags/br.png';
import BS from '../assets/images/flags/bs.png';
import BT from '../assets/images/flags/bt.png';
import BV from '../assets/images/flags/bv.png';
import BW from '../assets/images/flags/bw.png';
import BY from '../assets/images/flags/by.png';
import BZ from '../assets/images/flags/bz.png';
import CA from '../assets/images/flags/ca.png';
import CC from '../assets/images/flags/cc.png';
import CD from '../assets/images/flags/cd.png';
import CF from '../assets/images/flags/cf.png';
import CG from '../assets/images/flags/cg.png';
import CHX from '../assets/images/flags/chx.png';
import CI from '../assets/images/flags/ci.png';
import CK from '../assets/images/flags/ck.png';
import CL from '../assets/images/flags/cl.png';
import CM from '../assets/images/flags/cm.png';
import CN from '../assets/images/flags/cn.png';
import CO from '../assets/images/flags/co.png';
import CR from '../assets/images/flags/cr.png';
import CU from '../assets/images/flags/cu.png';
import CV from '../assets/images/flags/cv.png';
import CW from '../assets/images/flags/cw.png';
import CX from '../assets/images/flags/cx.png';
import CY from '../assets/images/flags/cy.png';
import CZ from '../assets/images/flags/cz.png';
import DE from '../assets/images/flags/de.png';
import DJ from '../assets/images/flags/dj.png';
import DK from '../assets/images/flags/dk.png';
import DM from '../assets/images/flags/dm.png';
import DO from '../assets/images/flags/do.png';
import DZ from '../assets/images/flags/dz.png';
import EC from '../assets/images/flags/ec.png';
import EE from '../assets/images/flags/ee.png';
import EG from '../assets/images/flags/eg.png';
import EH from '../assets/images/flags/eh.png';
import ER from '../assets/images/flags/er.png';
import ES from '../assets/images/flags/es.png';
import ET from '../assets/images/flags/et.png';
import FI from '../assets/images/flags/fi.png';
import FJ from '../assets/images/flags/fj.png';
import FK from '../assets/images/flags/fk.png';
import FM from '../assets/images/flags/fm.png';
import FO from '../assets/images/flags/fo.png';
import FR from '../assets/images/flags/fr.png';
import GA from '../assets/images/flags/ga.png';
import GB from '../assets/images/flags/gb.png';
import GD from '../assets/images/flags/gd.png';
import GE from '../assets/images/flags/ge.png';
import GF from '../assets/images/flags/gf.png';
import GG from '../assets/images/flags/gg.png';
import GH from '../assets/images/flags/gh.png';
import GI from '../assets/images/flags/gi.png';
import GL from '../assets/images/flags/gl.png';
import GM from '../assets/images/flags/gm.png';
import GN from '../assets/images/flags/gn.png';
import GP from '../assets/images/flags/gp.png';
import GQ from '../assets/images/flags/gq.png';
import GR from '../assets/images/flags/gr.png';
import GS from '../assets/images/flags/gs.png';
import GT from '../assets/images/flags/gt.png';
import GU from '../assets/images/flags/gu.png';
import GW from '../assets/images/flags/gw.png';
import GY from '../assets/images/flags/gy.png';
import HK from '../assets/images/flags/hk.png';
import HM from '../assets/images/flags/hm.png';
import HN from '../assets/images/flags/hn.png';
import HR from '../assets/images/flags/hr.png';
import HT from '../assets/images/flags/ht.png';
import HU from '../assets/images/flags/hu.png';
import ID from '../assets/images/flags/id.png';
import IE from '../assets/images/flags/ie.png';
import IL from '../assets/images/flags/il.png';
import IM from '../assets/images/flags/im.png';
import IN from '../assets/images/flags/in.png';
import IO from '../assets/images/flags/io.png';
import IQ from '../assets/images/flags/iq.png';
import IR from '../assets/images/flags/ir.png';
import IS from '../assets/images/flags/is.png';
import IT from '../assets/images/flags/it.png';
import JE from '../assets/images/flags/je.png';
import JM from '../assets/images/flags/jm.png';
import JO from '../assets/images/flags/jo.png';
import JP from '../assets/images/flags/jp.png';
import KE from '../assets/images/flags/ke.png';
import KG from '../assets/images/flags/kg.png';
import KH from '../assets/images/flags/kh.png';
import KI from '../assets/images/flags/ki.png';
import KM from '../assets/images/flags/km.png';
import KN from '../assets/images/flags/kn.png';
import KP from '../assets/images/flags/kp.png';
import KR from '../assets/images/flags/kr.png';
import KW from '../assets/images/flags/kw.png';
import KY from '../assets/images/flags/ky.png';
import KZ from '../assets/images/flags/kz.png';
import LA from '../assets/images/flags/la.png';
import LB from '../assets/images/flags/lb.png';
import LC from '../assets/images/flags/lc.png';
import LI from '../assets/images/flags/li.png';
import LK from '../assets/images/flags/lk.png';
import LR from '../assets/images/flags/lr.png';
import LS from '../assets/images/flags/ls.png';
import LT from '../assets/images/flags/lt.png';
import LU from '../assets/images/flags/lu.png';
import LV from '../assets/images/flags/lv.png';
import LY from '../assets/images/flags/ly.png';
import MA from '../assets/images/flags/ma.png';
import MC from '../assets/images/flags/mc.png';
import MD from '../assets/images/flags/md.png';
import ME from '../assets/images/flags/me.png';
import MF from '../assets/images/flags/mf.png';
import MG from '../assets/images/flags/mg.png';
import MH from '../assets/images/flags/mh.png';
import MK from '../assets/images/flags/mk.png';
import ML from '../assets/images/flags/ml.png';
import MM from '../assets/images/flags/mm.png';
import MN from '../assets/images/flags/mn.png';
import MO from '../assets/images/flags/mo.png';
import MP from '../assets/images/flags/mp.png';
import MQ from '../assets/images/flags/mq.png';
import MR from '../assets/images/flags/mr.png';
import MS from '../assets/images/flags/ms.png';
import MT from '../assets/images/flags/mt.png';
import MU from '../assets/images/flags/mu.png';
import MV from '../assets/images/flags/mv.png';
import MW from '../assets/images/flags/mw.png';
import MX from '../assets/images/flags/mx.png';
import MY from '../assets/images/flags/my.png';
import MZ from '../assets/images/flags/mz.png';
import NA from '../assets/images/flags/na.png';
import NC from '../assets/images/flags/nc.png';
import NE from '../assets/images/flags/ne.png';
import NF from '../assets/images/flags/nf.png';
import NG from '../assets/images/flags/ng.png';
import NI from '../assets/images/flags/ni.png';
import NL from '../assets/images/flags/nl.png';
import NO from '../assets/images/flags/no.png';
import NP from '../assets/images/flags/np.png';
import NR from '../assets/images/flags/nr.png';
import NU from '../assets/images/flags/nu.png';
import NZ from '../assets/images/flags/nz.png';
import OM from '../assets/images/flags/om.png';
import PA from '../assets/images/flags/pa.png';
import PE from '../assets/images/flags/pe.png';
import PF from '../assets/images/flags/pf.png';
import PG from '../assets/images/flags/pg.png';
import PH from '../assets/images/flags/ph.png';
import PK from '../assets/images/flags/pk.png';
import PL from '../assets/images/flags/pl.png';
import PM from '../assets/images/flags/pm.png';
import PN from '../assets/images/flags/pn.png';
import PR from '../assets/images/flags/pr.png';
import PS from '../assets/images/flags/ps.png';
import PT from '../assets/images/flags/pt.png';
import PW from '../assets/images/flags/pw.png';
import PY from '../assets/images/flags/py.png';
import QA from '../assets/images/flags/qa.png';
import RE from '../assets/images/flags/re.png';
import RO from '../assets/images/flags/ro.png';
import RS from '../assets/images/flags/rs.png';
import RU from '../assets/images/flags/ru.png';
import RW from '../assets/images/flags/rw.png';
import SA from '../assets/images/flags/sa.png';
import SB from '../assets/images/flags/sb.png';
import SC from '../assets/images/flags/sc.png';
import SD from '../assets/images/flags/sd.png';
import SE from '../assets/images/flags/se.png';
import SG from '../assets/images/flags/sg.png';
import SH from '../assets/images/flags/sh.png';
import SI from '../assets/images/flags/si.png';
import SJ from '../assets/images/flags/sj.png';
import SK from '../assets/images/flags/sk.png';
import SL from '../assets/images/flags/sl.png';
import SM from '../assets/images/flags/sm.png';
import SN from '../assets/images/flags/sn.png';
import SO from '../assets/images/flags/so.png';
import SR from '../assets/images/flags/sr.png';
import SS from '../assets/images/flags/ss.png';
import ST from '../assets/images/flags/st.png';
import SV from '../assets/images/flags/sv.png';
import SX from '../assets/images/flags/sx.png';
import SY from '../assets/images/flags/sy.png';
import SZ from '../assets/images/flags/sz.png';
import TC from '../assets/images/flags/tc.png';
import TD from '../assets/images/flags/td.png';
import TF from '../assets/images/flags/tf.png';
import TG from '../assets/images/flags/tg.png';
import TH from '../assets/images/flags/th.png';
import TJ from '../assets/images/flags/tj.png';
import TK from '../assets/images/flags/tk.png';
import TL from '../assets/images/flags/tl.png';
import TM from '../assets/images/flags/tm.png';
import TN from '../assets/images/flags/tn.png';
import TO from '../assets/images/flags/to.png';
import TR from '../assets/images/flags/tr.png';
import TT from '../assets/images/flags/tt.png';
import TV from '../assets/images/flags/tv.png';
import TW from '../assets/images/flags/tw.png';
import TZ from '../assets/images/flags/tz.png';
import UA from '../assets/images/flags/ua.png';
import UG from '../assets/images/flags/ug.png';
import UM from '../assets/images/flags/um.png';
import US from '../assets/images/flags/us.png';
import UY from '../assets/images/flags/uy.png';
import UZ from '../assets/images/flags/uz.png';
import VA from '../assets/images/flags/va.png';
import VC from '../assets/images/flags/vc.png';
import VE from '../assets/images/flags/ve.png';
import VG from '../assets/images/flags/vg.png';
import VI from '../assets/images/flags/vi.png';
import VN from '../assets/images/flags/vn.png';
import VU from '../assets/images/flags/vu.png';
import WF from '../assets/images/flags/wf.png';
import WS from '../assets/images/flags/ws.png';
import XK from '../assets/images/flags/xk.png';
import YE from '../assets/images/flags/ye.png';
import YT from '../assets/images/flags/yt.png';
import ZA from '../assets/images/flags/za.png';
import ZM from '../assets/images/flags/zm.png';
import ZW from '../assets/images/flags/zw.png';
import UN from '../assets/images/flags/un.png';
export const flags = {
    ad: AD,
    ae: AE,
    af: AF,
    ag: AG,
    ai: AI,
    al: AL,
    am: AM,
    ao: AO,
    aq: AQ,
    ar: AR,
    as: AS,
    at: AT,
    au: AU,
    aw: AW,
    ax: AX,
    az: AZ,
    ba: BA,
    bb: BB,
    bd: BD,
    be: BE,
    bf: BF,
    bg: BG,
    bh: BH,
    bi: BI,
    bj: BJ,
    bl: BL,
    bm: BM,
    bn: BN,
    bo: BO,
    bq: BQ,
    br: BR,
    bs: BS,
    bt: BT,
    bv: BV,
    bw: BW,
    by: BY,
    bz: BZ,
    ca: CA,
    cc: CC,
    cd: CD,
    cf: CF,
    cg: CG,
    chx: CHX,
    ci: CI,
    ck: CK,
    cl: CL,
    cm: CM,
    cn: CN,
    co: CO,
    cr: CR,
    cu: CU,
    cv: CV,
    cw: CW,
    cx: CX,
    cy: CY,
    cz: CZ,
    de: DE,
    dj: DJ,
    dk: DK,
    dm: DM,
    do: DO,
    dz: DZ,
    ec: EC,
    ee: EE,
    eg: EG,
    eh: EH,
    er: ER,
    es: ES,
    et: ET,
    fi: FI,
    fj: FJ,
    fk: FK,
    fm: FM,
    fo: FO,
    fr: FR,
    ga: GA,
    gb: GB,
    gd: GD,
    ge: GE,
    gf: GF,
    gg: GG,
    gh: GH,
    gi: GI,
    gl: GL,
    gm: GM,
    gn: GN,
    gp: GP,
    gq: GQ,
    gr: GR,
    gs: GS,
    gt: GT,
    gu: GU,
    gw: GW,
    gy: GY,
    hk: HK,
    hm: HM,
    hn: HN,
    hr: HR,
    ht: HT,
    hu: HU,
    id: ID,
    ie: IE,
    il: IL,
    im: IM,
    in: IN,
    io: IO,
    iq: IQ,
    ir: IR,
    is: IS,
    it: IT,
    je: JE,
    jm: JM,
    jo: JO,
    jp: JP,
    ke: KE,
    kg: KG,
    kh: KH,
    ki: KI,
    km: KM,
    kn: KN,
    kp: KP,
    kr: KR,
    kw: KW,
    ky: KY,
    kz: KZ,
    la: LA,
    lb: LB,
    lc: LC,
    li: LI,
    lk: LK,
    lr: LR,
    ls: LS,
    lt: LT,
    lu: LU,
    lv: LV,
    ly: LY,
    ma: MA,
    mc: MC,
    md: MD,
    me: ME,
    mf: MF,
    mg: MG,
    mh: MH,
    mk: MK,
    ml: ML,
    mm: MM,
    mn: MN,
    mo: MO,
    mp: MP,
    mq: MQ,
    mr: MR,
    ms: MS,
    mt: MT,
    mu: MU,
    mv: MV,
    mw: MW,
    mx: MX,
    my: MY,
    mz: MZ,
    na: NA,
    nc: NC,
    ne: NE,
    nf: NF,
    ng: NG,
    ni: NI,
    nl: NL,
    no: NO,
    np: NP,
    nr: NR,
    nu: NU,
    nz: NZ,
    om: OM,
    pa: PA,
    pe: PE,
    pf: PF,
    pg: PG,
    ph: PH,
    pk: PK,
    pl: PL,
    pm: PM,
    pn: PN,
    pr: PR,
    ps: PS,
    pt: PT,
    pw: PW,
    py: PY,
    qa: QA,
    re: RE,
    ro: RO,
    rs: RS,
    ru: RU,
    rw: RW,
    sa: SA,
    sb: SB,
    sc: SC,
    sd: SD,
    se: SE,
    sg: SG,
    sh: SH,
    si: SI,
    sj: SJ,
    sk: SK,
    sl: SL,
    sm: SM,
    sn: SN,
    so: SO,
    sr: SR,
    ss: SS,
    st: ST,
    sv: SV,
    sx: SX,
    sy: SY,
    sz: SZ,
    tc: TC,
    td: TD,
    tf: TF,
    tg: TG,
    th: TH,
    tj: TJ,
    tk: TK,
    tl: TL,
    tm: TM,
    tn: TN,
    to: TO,
    tr: TR,
    tt: TT,
    tv: TV,
    tw: TW,
    tz: TZ,
    ua: UA,
    ug: UG,
    um: UM,
    us: US,
    uy: UY,
    uz: UZ,
    va: VA,
    vc: VC,
    ve: VE,
    vg: VG,
    vi: VI,
    vn: VN,
    vu: VU,
    wf: WF,
    ws: WS,
    xk: XK,
    ye: YE,
    yt: YT,
    za: ZA,
    zm: ZM,
    zw: ZW,
    'gb-eng': '../assets/images/flags/gb-eng.png',
    'gb-nir': '../assets/images/flags/gb-nir.png',
    'gb-sct': '../assets/images/flags/gb-sct.png',
    'gb-wls': '../assets/images/flags/gb-wls.png',
};
export const getFlag = (code) => {
    if (code) {
        code = code.toLowerCase();
        if (flags[code]) {
            return flags[code];
        }
    }
    return UN;
};
