import { Modal } from './Base';

// import './style.css';

const BackdropContainer = (props) => {
	return (
		<Modal>
			<div className="modal-backdrop">{props.children}</div>
		</Modal>
	);
};

export default BackdropContainer;
