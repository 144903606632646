import React, { useEffect, useState, useRef } from 'react';

import AppTemplate from 'com/templates/ApplicationTemplate';

import { DataTable, Select, TagSelect, Button, IconButton, LoaderOverlay, Switch } from 'sazzui/lib/main';
import { GetRegionReport, DownloadRegionalReport } from 'services/rest/reports';
import Settings from 'services/rest/settings';
import { FIRM_TAGS, SERVICE_KEYS, PAGE_TITLE, COLORS } from 'data/constants';
import Users from 'services/rest/users';

import AppLink from 'com/util/AppLink';

import useTitle from 'services/hooks/useTitle';
import useFilterStorage from 'services/hooks/useFilterStorage';

import './style.css';

import ICON_FUNNEL from 'assets/images/icons/ico_funnel.svg';
import ICON_PDF from 'assets/images/icons/ico_pdf.svg';

// tags icons
import ICON_BLOCKED from 'assets/images/icons/ico_regional_report_blocked.svg';
import ICON_DNOR from 'assets/images/icons/ico_regional_report_dnor.svg';
import ICON_LARGE from 'assets/images/icons/ico_regional_report_large.svg';
import ICON_MEDIUM from 'assets/images/icons/ico_regional_report_medium.svg';
import ICON_PORTFOLIO from 'assets/images/icons/ico_regional_report_portfolio.svg';
import ICON_PRIMARY_VENDOR from 'assets/images/icons/ico_regional_report_primary_vendor.svg';
import ICON_SECONDARY_VENDOR from 'assets/images/icons/ico_regional_report_secondary_vendor.svg';
import ICON_SMALL from 'assets/images/icons/ico_regional_report_small.svg';
import ICON_SOLE_PRAC from 'assets/images/icons/ico_regional_report_sole_prac.svg';
import ICON_VIP from 'assets/images/icons/ico_regional_report_vip.svg';
import ICON_XLARGE from 'assets/images/icons/ico_regional_report_xlarge.svg';

const RegionalReport = () => {
	useTitle(PAGE_TITLE.REGIONAL_REPORT);

	const initData = [];
	const initRegions = [];
	const initServices = [];
	const initTechnologies = [{ label: 'All Technologies', value: '' }];
	const initFirmSizes = [{ label: 'All Sizes', value: '' }];
	const initAccountExecutives = [{ label: 'All Account Executives', value: '' }];
	const filterInit = {
		region: '',
		service: '',
		technology: '',
		is_active: 1,
		firms_without_tags: false,
		tags: [],
		firm_size: '',
		is_vendor: false,
		account_executive: '',
	};

	const [data, setData] = useState(initData);
	const [filter, setFilter] = useState(filterInit);
	const [regions, setRegions] = useState(initRegions);
	const [services, setServices] = useState(initServices);
	const [technologies, setTechnologies] = useState(initTechnologies);
	const [showLoader, setShowLoader] = useState(false);
	const [accountExecutives, setAccountExecutives] = useState(initAccountExecutives);
	const [firmSizes, setFirmSizes] = useState(initFirmSizes);
	const firmTagsMap = useRef({
		SOLE_PRAC: { icon: ICON_SOLE_PRAC, title: '' },
		SMALL: { icon: ICON_SMALL, title: '' },
		MEDIUM: { icon: ICON_MEDIUM, title: '' },
		LARGE: { icon: ICON_LARGE, title: '' },
		XLARGE: { icon: ICON_XLARGE, title: '' },
		PRIMARY_VENDOR: { icon: ICON_PRIMARY_VENDOR, title: 'Primary Vendor' },
		SECONDARY_VENDOR: { icon: ICON_SECONDARY_VENDOR, title: 'Secondary Vendor' },
		PORTFOLIO: { icon: ICON_PORTFOLIO, title: 'Portfolio' },
		VIP: { icon: ICON_VIP, title: 'VIP' },
		BLOCKED: { icon: ICON_BLOCKED, title: 'Blocked' },
		DNOR: { icon: ICON_DNOR, title: 'DNOR' },
	});
	const { getFilterValue, setFilterValue } = useFilterStorage();

	useEffect(async () => {
		try {
			let [rs, ss, ts, fs, sales] = await Promise.all([Settings.GetRegions(), Settings.GetServices(), Settings.GetTechnologies(), Settings.GetFirmSizes(), getSalesUsers()]);

			// set regions
			setRegions(rs.map((r) => ({ label: r.name, value: r.id })));
			// set services
			setServices(ss.map((s) => ({ label: s.name, value: s.id })));
			// set technologies
			setTechnologies([...technologies, ...ts.map((t) => ({ label: t.name, value: t.id }))]);
			// set firm sizes
			setFirmSizes([...firmSizes, ...fs.map((f) => ({ label: f.label, value: f.id }))]);
			// set account executives
			setAccountExecutives([...accountExecutives, ...sales.map((u) => ({ label: `${u.first_name} ${u.last_name}`, value: u.id }))]);
			// check for existing filter and set if exist or set default region and service filter value
			let f = getFilterValue();
			setFilter(f ? f : (prevState) => ({ ...prevState, service: ss.find((s) => s.key === SERVICE_KEYS.PCT).id, region: rs[0].id }));
			if (f) fetchData(f);
			// get firm size labels and set them into the firmTagsMap
			fs.forEach((f) => {
				if (firmTagsMap.current[f.key]) {
					firmTagsMap.current[f.key].title = f.label;
				}
			});
		} catch (e) {
			console.log(e);
		}
	}, []);

	const firmStatus = [
		{ label: 'Active', value: '1' },
		{ label: 'Inactive', value: '0' },
	];

	const filterChange = (e) => {
		let { name, value } = e.target;
		switch (name) {
			case 'firms_without_tags':
				setFilter({
					...filter,
					[name]: !filter[name],
					tags: [],
				});
				break;
			case 'is_vendor':
				setFilter({
					...filter,
					[name]: !filter[name],
				});
				break;
			case 'tags':
				setFilter({
					...filter,
					tags: value,
					firms_without_tags: false,
				});
				break;
			default:
				setFilter({
					...filter,
					[name]: value,
				});
				break;
		}
	};

	const fetchData = async (filter) => {
		try {
			setShowLoader(true);
			const data = await GetRegionReport(filter.region, filter);
			setData(data);
			setShowLoader(false);
		} catch (err) {
			console.log('err', err);
			setShowLoader(false);
		}
	};

	const filterSubmit = () => {
		fetchData(filter);
		setFilterValue(filter);
	};

	const clearFilter = () => {
		setFilter(filterInit);
		setData(initData);
		setFilterValue(null);
	};

	const reportTableHeadersInit = [
		{ title: 'Firm', field: 'firm_name', type: 'string', sort: 'none', sortable: true },
		{ title: 'Service & Technology', field: 'owed_cases', type: 'number', sort: 'none', sortable: true },
		{ title: 'Last Received Case', field: 'last_received_case_date', type: 'date', sort: 'none', sortable: true },
		{ title: 'Last Sent Case', field: 'last_sent_case_date', type: 'date', sort: 'none', sortable: true },
		{ title: 'Points', field: 'points', type: 'number', sort: 'down', sortable: true },
		{ title: 'Reciprocity Deadline', field: 'reciprocity_deadline', type: 'date', sort: 'none', sortable: true },
		{ title: 'Rates', field: 'rates', type: 'date', sort: 'none', sortable: true },
	];

	const [reportTableHeaders, setReportTableHeaders] = useState(reportTableHeadersInit);

	const columnSort = (f, t) => {
		let dir = 'up';

		setReportTableHeaders(
			reportTableHeaders.map((e) => {
				if (e.field === f) {
					e.sort = e.sort === 'up' ? 'down' : 'up';
					dir = e.sort === 'up' ? 'down' : 'up';
				}
				return e;
			}),
		);

		let sorted = data.sort((a, b) => {
			let x = null;
			let y = null;

			switch (t) {
				case 'date':
					x = new Date(a[f]).getTime();
					y = new Date(b[f]).getTime();
					break;
				case 'number':
					x = Number(a[f]);
					y = Number(b[f]);
					break;
				case 'string':
					x = a[f].toLowerCase();
					y = b[f].toLowerCase();
					break;
				case 'casenum':
					x = Number(a[f].replace('PN', ''));
					y = Number(b[f].replace('PN', ''));
					break;
				case 'array':
					x = a[f].length;
					y = b[f].length;
					break;
				case 'firmsize':
					let sizes = {};
					firmSizes.forEach((e, i) => (sizes[e.label] = i));
					x = sizes[a[f]];
					y = sizes[b[f]];
					break;
				default:
			}

			if (dir === 'up') {
				if (x > y) {
					return -1;
				}
				if (x < y) {
					return 1;
				}
			} else if (dir === 'down') {
				if (y > x) {
					return -1;
				}
				if (y < x) {
					return 1;
				}
			}
			return 0;
		});

		setData(sorted);
	};

	const printReport = async () => {
		await DownloadRegionalReport(data);
	};

	const extractServiceRate = (rates) => {
		let rate_professional_firm = 0;
		let rate_translation_firm = 0;
		let rate_professional_user = 0;
		let rate_translation_user = 0;
		let currency_user = '';
		let currency_firm = '';

		for (let r of rates) {
			if (r.object_type === 'USER') {
				rate_professional_user = r.rate_professional;
				rate_translation_user = r.rate_translation;
				currency_user = r.currency;
			}
			if (r.object_type === 'FIRM') {
				rate_professional_firm = r.rate_professional;
				rate_translation_firm = r.rate_translation;
				currency_firm = r.currency;
			}
		}
		let out = {
			rateProfessional: rate_professional_user ? rate_professional_user : rate_professional_firm,
			rateTranslation: rate_translation_user ? rate_translation_user : rate_translation_firm,
			currency: currency_user ? currency_user : currency_firm,
		};
		return out;
	};

	const getSalesUsers = async () => {
		let roles = await Settings.GetUserRoles();
		if (!roles) return [];
		let salesRole = roles.find((r) => r.name == 'sales');
		let filters = {
			role: salesRole.id,
			order_by: ['first_name:asc', 'last_name:asc'].join(','),
		};
		let users = await Users.GetAllRaw(filters);
		if (!users || !users.length) return [];
		return users;
	};

	const setPointsColor = (points, reciprocityDeadline) => {
		let now = new Date();

		let deadLine = new Date(reciprocityDeadline);
		deadLine.setHours(23, 59, 59, 999);

		let range = new Date();
		//set range to 1 month ahead from current date
		range.setMonth(now.getMonth() + 1);
		range.setHours(23, 59, 59, 999);

		if (deadLine.getTime() - now.getTime() < 0) {
			return COLORS.RED;
		}

		if (deadLine.getTime() > range.getTime() && points > 30000) {
			return COLORS.YELLOW;
		}

		if (deadLine.getTime() <= range.getTime() && points > 30000) {
			return COLORS.GREEN;
		}
	};

	const setFirmTags = (tags = [], firmSize = '') => {
		return (
			<>
				{firmTagsMap.current[firmSize] ?
					<li>
						<img src={firmTagsMap.current[firmSize].icon} alt="" title={firmTagsMap.current[firmSize].title} />
					</li>
				:	null}
				{tags.map((t) =>
					firmTagsMap.current[t] ?
						<li key={t}>
							<img src={firmTagsMap.current[t].icon} alt="" title={firmTagsMap.current[t].title} />
						</li>
					:	null,
				)}
			</>
		);
	};

	return (
		<AppTemplate title="Regional Report">
			<AppTemplate.Sidebar>
				<div className="reports-sidebar">
					<h4 className="reports-sidebar__header">
						<img src={ICON_FUNNEL} alt="" />
						filters
					</h4>
					<Select options={regions} label="Region" theme="dark" onChange={filterChange} name="region" value={filter.region} ariaLabel="Region Select" />
					<Select options={services} label="Service" theme="dark" onChange={filterChange} name="service" value={filter.service} ariaLabel="Service Select" />
					<Select options={technologies} label="Technology" theme="dark" onChange={filterChange} name="technology" value={filter.technology} ariaLabel="Technology Select" />
					<Select options={firmStatus} label="Status" theme="dark" onChange={filterChange} name="is_active" value={filter.is_active} ariaLabel="Status Select" />
					<div className="reports-sidebar__firms-without-tags-switch">
						<Switch onChange={filterChange} value={filter.firms_without_tags} name="firms_without_tags" label="Firms Without Tags" ariaLabel="Firms Without Tags Switch" />
					</div>
					<div className="reports-sidebar__vendor-switch">
						<Switch onChange={filterChange} value={filter.is_vendor} name="is_vendor" label="Vendor" ariaLabel="Vendor Switch" />
					</div>
					<TagSelect disabled={filter.firms_without_tags} label="Tags" options={FIRM_TAGS} theme="dark" values={filter.tags} onChange={filterChange} ariaLabel="Firm tags Select" />
					<Select options={firmSizes} label="Size" name="firm_size" theme="dark" value={filter.firm_size} onChange={filterChange} ariaLabel="Firm size Select" />
					<Select
						options={accountExecutives}
						label="Account Executives"
						name="account_executive"
						theme="dark"
						value={filter.account_executive}
						onChange={filterChange}
						ariaLabel="Account Executive Select"
					/>
					<div className="reports-sidebar_actions sidebar_actions">
						<Button onClick={filterSubmit} arielSelect="Generate Report Button" size="small">
							Generate Report
						</Button>
						<Button theme="ghost-primary" onClick={clearFilter} arielSelect="Clear Filter Button" size="small">
							Clear
						</Button>
					</div>
				</div>
			</AppTemplate.Sidebar>
			<AppTemplate.Content>
				<div className="main-screen-section subheader">
					<h2 className="main-screen-section__title">Report Summary</h2>
					<IconButton icon={ICON_PDF} onClick={printReport} ariaLabel="Print report Button" />
				</div>
				<div className="main-screen-section white">
					<LoaderOverlay showLoader={showLoader} />
					<DataTable fixedHeader={true} columns={reportTableHeaders} onColumnSort={columnSort}>
						{data.map((d, i) => {
							return (
								<tr key={i}>
									<DataTable.Cell customClassName="firm-column">
										<AppLink type="regular" to={`/firm-management/${d.firm_id}`}>
											{d.firm_name}
										</AppLink>
										<span className="firm-account">{d.firm_account_name}</span>
										<ul className="tags">{setFirmTags(d.firm_tags, d.firm_size_key)}</ul>
									</DataTable.Cell>
									<DataTable.Cell customClassName="service-technology-column">
										<div className="service-technology-column__service-info">
											<span className="name">{d.service_name}</span>
											<span className="owed-cases">{d.owed_cases}</span>
										</div>
										<div className="table-cell-tags">
											{d.technologies?.map((t, i) => (
												<span key={i}>{t}</span>
											))}
										</div>
									</DataTable.Cell>
									<DataTable.DateCell date={d.last_received_case_date} />
									<DataTable.DateCell date={d.last_sent_case_date} />
									<DataTable.Cell textColor={setPointsColor(d.points, d.reciprocity_deadline)}>{d.points}</DataTable.Cell>
									<DataTable.DateCell date={d.reciprocity_deadline} />
									<DataTable.RatesCell emptyMessage="Please select a service for rate info" rates={extractServiceRate(d.rates)} />
								</tr>
							);
						})}
					</DataTable>
				</div>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default RegionalReport;
