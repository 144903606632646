import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './style.css';
export const Editor = (props) => {
    let theme = props.theme ? props.theme : 'snow';
    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image'],
            ['clean'],
        ],
    };
    const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image'];
    return (_jsxs("label", { className: `form-editor ${props.customClassName ? props.customClassName : ''}`, children: [_jsx("span", { className: `label ${theme}` }), _jsx(ReactQuill, { theme: theme, value: props.value, onChange: props.onChange, className: `input ${theme}`, modules: modules, formats: formats })] }));
};
