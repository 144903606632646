import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
;
export const DateRangeCell = ({ start = '', end = '', }) => {
    const dateFrom = start ? new Date(start).toLocaleDateString() : null;
    const dateTo = end ? new Date(end).toLocaleDateString() : null;
    let title = '';
    let range = '';
    if (dateFrom && dateTo && dateFrom !== dateTo) {
        title = 'Between';
        range = `${dateFrom} \u2013 ${dateTo}`;
    }
    else if (dateFrom && dateTo && dateFrom === dateTo) {
        title = 'On Date';
        range = `${dateFrom}`;
    }
    else if (dateFrom) {
        title = 'Starting From';
        range = `${dateFrom}`;
    }
    else if (dateTo) {
        title = 'Until Date';
        range = `${dateTo}`;
    }
    return (_jsx("td", { children: _jsxs("div", { className: "table-cell table-cell-date-range", children: [_jsx("span", { className: "range-title", children: title }), _jsx("span", { children: range })] }) }));
};
