//Template Imports
import AuthBox from 'com/templates/AuthBox';
//UI Imports
import { BackButton } from 'sazzui/lib/ui/BackButton';
import AppLink from 'com/util/AppLink';
//Style Imports
import './style.css';

const ForgotPasswordMessageAzami = () => {
	return (
		<div className="thankyou-container">
			<AuthBox title="A password reset link is on it's way" titleColor="azami-blurple" icon="password" footer={<BackButton to="/" AppLink={AppLink} label="Back To login" />}>
				An email with a password reset link has just been sent to you. Please check your email and follow the instructions in order to reset your password.
			</AuthBox>
		</div>
	);
};

export default ForgotPasswordMessageAzami;
