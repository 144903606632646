import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';

import AppTemplate from 'com/templates/ApplicationTemplate';

import { Button } from 'sazzui/lib/ui/Button';

import { PAGE_TITLE } from 'data/constants';

import Firms from 'services/rest/firms';

import { ErrorMessage } from 'sazzui/lib/ui/ErrorMessage';
import NewFirmRegisterInputs from 'com/pages/Register/NewFirmRegisterInputs';

import { FirmAddSchema } from 'services/validators/firmAdd';
import { getError } from 'util/errors';

import Settings from 'services/rest/settings';

import useTitle from 'services/hooks/useTitle';

import './style.css';

const FirmAdd = (props) => {
	useTitle(PAGE_TITLE.FIRM_CREATE);

	const [spinner, setSpinner] = useState(false);
	const history = useHistory();
	const formRef = useRef();
	const [countries, setCountries] = useState([]);

	const initialState = {
		firm_name: '',
		address_1: '',
		address2: '',
		city: '',
		state: '',
		postal_code: '',
		firm_country: '',
		_deleted: 0,
	};

	useEffect(() => {
		(async () => {
			const countriesData = await Settings.GetRegions();
			setCountries([
				{ label: '', value: '' },
				...countriesData
					.filter((c) => c.is_country === 1)
					.map((c) => ({
						label: c.name,
						value: c.code,
					})),
			]);
		})();
	}, []);

	const handleSubmit = async (fields, { setStatus }) => {
		const data = {
			name: fields.firm_name,
			email: fields.email,
			address: fields.address_1,
			address2: fields.address2,
			city: fields.city,
			country: fields.firm_country,
			state: fields.state,
			zipcode: fields.postal_code,
			_deleted: 0,
		};

		try {
			setSpinner(true);
			await Firms.Create(data);
			setSpinner(false);
			history.push('/firm-management');
		} catch (err) {
			setStatus(getError(err.code));
			setSpinner(false);
		}
	};

	const submit = () => {
		if (formRef.current) {
			formRef.current.handleSubmit();
		}
	};

	return (
		<AppTemplate title="Firm Details" headerIcon="firm_management">
			{/* <AppTemplate.Sidebar>
                <div className="firm-sidebar">
                </div>
            </AppTemplate.Sidebar> */}
			<AppTemplate.Content>
				<div className="main-screen-section white full-height no-padding firm-add-content">
					<header className="firm-add-content__header"></header>
					<div className="firm-add-content__body">
						<Formik initialValues={initialState} enableReinitialize={true} validationSchema={FirmAddSchema} onSubmit={handleSubmit} validateOnChange={false} innerRef={formRef}>
							{({ values, handleChange, validateField, errors, status }) => {
								const validate = (e) => validateField(e.target.name);
								return (
									<Form>
										<NewFirmRegisterInputs values={values} handleChange={handleChange} validate={validate} errors={errors} countries={countries} />
										{status && <ErrorMessage error={status} />}
									</Form>
								);
							}}
						</Formik>
					</div>
					<footer className="firm-add-content__footer">
						<Button onClick={submit} type="submit" showSpinner={spinner}>
							Submit
						</Button>
					</footer>
				</div>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export default FirmAdd;
