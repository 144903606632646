import React from 'react';

import { AppRoute } from 'com/util/AppRoute';
import Routes from 'data/routes';

import './style.css';

import SystemSettingsEstimatorRulesMain from './SystemSettingsEstimatorRulesMain';
import SystemSettingsEstimatorRulesDetails from './SystemSettingsEstimatorRulesDetails';

const SystemSettingsEstimatorRules = (props) => {
	return (
		<>
			<AppRoute route={Routes.SYSTEM_SETTINGS_ESTIMATOR_RULES_MAIN} exact />
			<AppRoute route={Routes.SYSTEM_SETTINGS_ESTIMATOR_RULES_EDIT} exact />
		</>
	);
};

export { SystemSettingsEstimatorRulesMain, SystemSettingsEstimatorRulesDetails };

export default SystemSettingsEstimatorRules;
