export const redirectInit = {
	isUpdated: false,
	checkExact: false,
	message: '',
};

//constants
const SET_IS_UPDATED = 'SET_IS_UPDATED';
export const REDIRECT_FROM_QUOTE_MSG = "You're about to leave the quote preview request. Would you like to save your progress \n as a draft or discard your changes?";

//actions

export const setIsUpdated = (data) => {
	return {
		type: SET_IS_UPDATED,
		payload: data,
	};
};

//reducer
const reducer = (state = redirectInit, action) => {
	switch (action.type) {
		case SET_IS_UPDATED:
			return { ...state, isUpdated: action.payload.isUpdated, checkExact: action.payload.checkExact, message: action.payload.message ? action.payload.message : '' };
		default:
			return state;
	}
};

export default reducer;
