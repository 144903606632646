import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Select, Textarea, DataTable, Modal, LoaderOverlay } from 'sazzui/lib/main';

import { API_HOST } from 'data/constants';
import { DOCUMENT_OBJECT_TYPE, ACCEPTED_FILE_TYPES } from 'data/constants';

import { random } from 'services/strings';
import Documents from 'services/rest/documents';
import useNotification from 'services/hooks/use_notification';

import ICON_PAPER_CLIP from 'assets/images/icons/ico_paperclip.svg';
import ICON_DELETE from 'assets/images/icons/ico_btn_delete_lightgrey.svg';
import ICON_UPLOAD from 'assets/images/icons/ico-upload.svg';
import ICON_DOWNLOAD from 'assets/images/icons/ico-download.svg';

import './style.css';

const AttachmentsTable = (props) => {
	const tableHeadersInit = [
		{ title: 'Region', field: 'region', type: 'string', sort: 'none', sortable: false },
		{ title: 'File', field: 'file_name', type: 'string', sort: 'none', sortable: false },
		{ title: 'Description', field: 'description', type: 'string', sort: 'none', sortable: false },
		{ title: '', field: '', type: '', sort: 'none', sortable: false },
	];
	const regionOptions = [
		{ label: 'All', value: 'all' },
		...props.regions.map((r) => {
			return { label: r.name, value: r.id };
		}),
	];
	const sendNotification = useNotification();
	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [modalOpen, setModalOpen] = useState(false);
	const [duplicatesModalOpen, setDuplicatesModalOpen] = useState(false);
	const [fileForDelete, setFileForDelete] = useState({});
	const [duplicateFiles, setDuplicateFiles] = useState([]);

	const onFileChangeHandler = (e) => {
		let filesData = Array.from(e.target.files).map((f) => {
			f.id = random(8);
			// set init regionId and quote (default level is quote)
			f.region_id = 'all';
			f.object_type = props.objectTypeInit;
			f.is_uploaded = false;
			f.description = '';
			return f;
		});
		if (props.checkFileType && filesData.some((f) => !ACCEPTED_FILE_TYPES.includes(f.type))) {
			sendNotification({ type: 'error', title: 'Wrong File Type' });
			filesData = filesData.filter((f) => ACCEPTED_FILE_TYPES.includes(f.type));
		}
		let allFiles = [...props.documents, ...filesData];

		// unique name documents
		let documents = [];
		// check for duplicates
		let duplicates = [];

		allFiles.forEach((f, i) => {
			if (documents.find((e) => e.name === f.name)) {
				duplicates.push(f);
			} else {
				documents.push(f);
			}
		});
		if (duplicates.length > 0) {
			setDuplicateFiles(duplicates);
			setDuplicatesModalOpen(true);
		}
		// sort last added record to show on top
		documents = documents.sort((a, b) => {
			if (!a.region_name) {
				return -1;
			}
		});
		props.setDocuments(documents);
	};

	const onRegionChange = async (e) => {
		const id = e.target.closest('tr').dataset.did;
		props.setDocuments((prevState) => {
			let documentData = prevState.map((d) => {
				if (id === d.id) {
					d.region_id = e.target.value;
					switch (props.objectTypeInit) {
						case DOCUMENT_OBJECT_TYPE.CASE:
							if (e.target.value === 'all') {
								d.object_type = DOCUMENT_OBJECT_TYPE.CASE;
							} else {
								d.object_type = DOCUMENT_OBJECT_TYPE.CASE_REGION;
							}
							break;
						case DOCUMENT_OBJECT_TYPE.QUOTE:
							if (e.target.value === 'all') {
								d.object_type = DOCUMENT_OBJECT_TYPE.QUOTE;
							} else {
								d.object_type = DOCUMENT_OBJECT_TYPE.QUOTE_REGION;
							}
							break;
						default:
							break;
					}
				}
				return d;
			});
			return documentData;
		});
	};

	const onDeleteHandler = async () => {
		try {
			props.setSpinner(true);
			// mark file as deleted if is previously uploaded
			if (fileForDelete.is_uploaded === true) {
				await Documents.deleteByID(fileForDelete.id);
			}
			props.setDocuments((prevState) => {
				return prevState.filter((d) => d.id !== fileForDelete.id);
			});
			setFileForDelete({});
			setModalOpen(false);
			props.setSpinner(false);
		} catch (error) {
			console.log(error);
		}
	};

	const openModalHandler = (e) => {
		setModalOpen(true);
		let f = props.documents.find((d) => d.id === e.target.closest('tr').dataset.did);
		setFileForDelete(f);
	};

	const closeModalHandler = () => {
		setModalOpen(false);
	};

	const deleteModalFooterActions = [
		{ primary: false, label: 'Cancel', action: closeModalHandler, theme: 'azami-ghost' },
		{ primary: true, label: 'Confirm', action: onDeleteHandler, theme: 'azami-blue' },
	];

	const closeDuplicatesModal = () => {
		setDuplicatesModalOpen(false);
		setDuplicateFiles([]);
	};

	const duplicatesModalFooterActions = [{ primary: false, label: 'Close', action: closeDuplicatesModal, theme: 'azami-ghost' }];

	const onDescriptionChange = (e) => {
		props.setDocuments((prevState) => {
			return prevState.map((d) => {
				if (d.id === e.target.closest('tr').dataset.did) {
					d.description = e.target.value;
					// distinguish between documents for upload and documents for update
					if (d.is_uploaded === true) {
						d.for_update = true;
					}
				}
				return d;
			});
		});
	};

	return (
		<>
			<div className="attachments__upload-actions">
				<span>Attachments</span>
				<div className="attachments__upload-actions-btn">
					<label htmlFor="upload">
						<img src={ICON_UPLOAD} alt="upload" />
						<span>Upload Files</span>
						<input id="upload" type="file" multiple onChange={onFileChangeHandler} value="" />
					</label>
					<span className="attachments__upload-actions-btn-text">
						Please upload your Excel, Word, PDF or Image File. Please limit file uploads to 50MB. For larger files, please email your AE.
					</span>
				</div>
			</div>
			<DataTable fixedHeader={false} columns={tableHeaders} customClassName="attachments__table">
				{props.documents.map((d) => {
					return (
						<React.Fragment key={d.id}>
							{d.for_delete === true ? null : (
								<tr data-did={d.id}>
									<DataTable.Cell>
										<Select
											options={regionOptions}
											name="region_id"
											value={d.region_id}
											onChange={onRegionChange}
											disabled={d.is_uploaded === true ? true : false}
											ariaLabel="Region Select"
										/>
									</DataTable.Cell>
									<DataTable.Cell customClassName="attachments__table__file-name">
										<img src={ICON_PAPER_CLIP} alt="paperclip" />
										<span>{d.name}</span>
									</DataTable.Cell>
									<DataTable.Cell>
										<Textarea value={d.description} onChange={onDescriptionChange} ariaLabel="Description Textarea" />
									</DataTable.Cell>
									<DataTable.ActionsCell>
										{d.is_uploaded === true ?
											<a href={`${API_HOST}/storage/document/${d.id}`}>
												<img src={ICON_DOWNLOAD} alt="download" />
											</a>
										:	null}

										{props.allowDelete ?
											<button className="attachments__table__action-delete" onClick={openModalHandler}>
												<img src={ICON_DELETE} alt="delete" />
											</button>
										:	null}
									</DataTable.ActionsCell>
								</tr>
							)}
						</React.Fragment>
					);
				})}
			</DataTable>
			{modalOpen ?
				<Modal title="Delete File" closeHandler={closeModalHandler} footerActions={deleteModalFooterActions}>
					<LoaderOverlay showLoader={props.spinner} />
					<p className="confirmation-modal__body__confirm-delete">
						Delete <span>{`${fileForDelete.name} ?`}</span>
					</p>
				</Modal>
			:	null}
			{duplicatesModalOpen ?
				<Modal title="Duplicate file names are not allowed" closeHandler={closeDuplicatesModal} footerActions={duplicatesModalFooterActions}>
					<div className="duplicates__modal__content">
						{duplicateFiles.map((f) => {
							return <span key={f.id}>{f.name}</span>;
						})}
					</div>
				</Modal>
			:	null}
		</>
	);
};

AttachmentsTable.propTypes = {
	regions: PropTypes.array,
	documents: PropTypes.array,
	setDocuments: PropTypes.func,
	spinner: PropTypes.bool,
	setSpinner: PropTypes.func,
	objectTypeInit: PropTypes.string,
	allowDelete: PropTypes.bool,
};

AttachmentsTable.defaultProps = {
	regions: [],
	documents: [],
	setDocuments: () => {},
	spinner: false,
	setSpinner: () => {},
	objectTypeInit: '',
	allowDelete: true,
};

export default AttachmentsTable;
