//Template Imports
import AuthBox from 'com/templates/AuthBox';
//UI Imports
import AppLink from 'com/util/AppLink';
import { BackButton } from 'sazzui/lib/ui/BackButton';
//Style Imports
import './style.css';

const ChangePasswordMessageIPeer = () => {
	return (
		<div className="change-password-message-container">
			<AuthBox title="Password successfully changed" titleColor="dark-grey" icon="password" footer={<BackButton to="/" AppLink={AppLink} label="Back To login" />}>
				Your password has been successfully changed. Please proceed to the login page and enter the system.
			</AuthBox>
		</div>
	);
};

export default ChangePasswordMessageIPeer;
