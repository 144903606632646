import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Modal, Input, Select, Button, LoaderOverlay, AppDate } from 'sazzui/lib/main';
import { RoleBox } from 'com/util/RoleBox';

import { SELECTED_ASSOCIATE_STATUS, COMMUNICATION_PREFERENCES } from 'data/constants';

import ICON_AVATAR from 'assets/images/icons/avatar_placeholder_invert.svg';
import ICON_PREFERRED from 'assets/images/icons/ico_suggested_associate_preferred.svg';
import ICON_BLOCKED from 'assets/images/icons/ico_suggested_associate_blocked.svg';
import ICON_ALREADY_ASSIGN from 'assets/images/icons/ico_suggested_associate_already_assigned.svg';
import ICON_INACTIVE from 'assets/images/icons/ico_suggested_associate_inactive.svg';
import ICON_NON_RECIPROCAL from 'assets/images/icons/ico_suggested_associate_non_reciprocal.svg';
import ICON_OWNED_CASE from 'assets/images/icons/ico_suggested_associate_owed_case.svg';
import ICON_PREVIOUSLY_USED from 'assets/images/icons/ico_suggested_associate_previously_used.svg';
import ICON_GLOBE from 'assets/images/icons/ico_globe.svg';
import ICON_AVATAR_SOLID from 'assets/images/icons/avatar_solid.svg';

import AssociateSelection from 'services/rest/associate_selection';
import Cases from 'services/rest/cases';
import Users from 'services/rest/users';
import Firms from 'services/rest/firms';
import FirmAccounts from 'services/rest/firm_accounts';
import Calculator from 'services/rest/calculator';
import Preferences from 'services/rest/preferences';
import Settings from 'services/rest/settings';

import { PREFERENCES_GROUP } from 'data/constants';
import { getFlag } from 'data/flags';

import './style.css';

const overrideOptions = [
	{
		label: 'Select Reason',
		value: '',
	},
	{
		label: 'An associate with a higher point balance is not listed',
		value: 'An associate with a higher point balance is not listed',
	},
	{
		label: 'The client prefers a specific associate',
		value: 'The client prefers a specific associate',
	},
	{
		label: 'An Override Request was not considered (in error)',
		value: 'An Override Request was not considered (in error)',
	},
	{
		label: 'Associate declined assignment',
		value: 'Associate declined assignment',
	},
	{
		label: 'No associate suggestion provided by V2',
		value: 'No associate suggestion provided by V2',
	},
];

const AssociateAssignmentModal = (props) => {
	const badgesMapper = {
		PREFERRED: {
			icon: ICON_PREFERRED,
			title: 'Preferred/whitelisted by the client',
		},
		BLACKLISTED: {
			icon: ICON_BLOCKED,
			title: 'Blacklisted by the client',
		},
		ALREADY_ASSIGNED: {
			icon: ICON_ALREADY_ASSIGN,
			title: 'Already assigned to a case in this region',
		},
		INACTIVE: {
			icon: ICON_INACTIVE,
			title: 'Inactive reciprocity Firm Status',
		},
		IS_VENDOR: {
			icon: ICON_NON_RECIPROCAL,
			title: 'Non-Reciprocal Vendor',
		},
		OWED_CASE: {
			icon: ICON_OWNED_CASE,
			title: 'Marked as "Owed a case" in the firm profile or Products List',
		},
		PREVIOUSLY_USED: {
			icon: ICON_PREVIOUSLY_USED,
			title: 'Previously used for this applicant in the last 12 months',
		},
		IS_ELIGIBLE: {
			icon: ICON_PREVIOUSLY_USED,
			title: 'Eligible associate',
		},
	};

	const [displayedAssociates, setDisplayedAssociates] = useState([]);
	const [suggestedAssociateList, setSuggestedAssociateList] = useState([]);
	const [searchAssociate, setSearchAssociate] = useState('');
	const [showLoader, setShowLoader] = useState(false);
	const [clientPreferences, setClientPreferences] = useState([]);
	const [technologiesMap, setTechnologiesMap] = useState(new Map());
	const [displayCount, setDisplayCount] = useState(3);

	useEffect(() => {
		(async () => {
			setShowLoader(true);
			try {
				const [technologies, associates] = await Promise.all([Settings.GetTechnologies(), fetchAssociates(), getClientPreferences()]);
				const tMap = new Map();
				technologies.forEach((t) => {
					tMap.set(t.id, t.name);
				});
				setTechnologiesMap(tMap);
				setShowLoader(false);
			} catch (error) {
				setShowLoader(false);
				console.log(error);
			}
		})();
	}, []);

	const fetchAssociates = async () => {
		let recommenderResult = await AssociateSelection.GetAssociates(props.caseRegionData.id);
		let associates = [];

		for (let r of recommenderResult) {
			let faQueryParams = {
				fields: ['base_points'].join(','),
				region: props.caseRegionData.region_id,
				service: props.caseRegionData.service_id,
			};
			let [uData, fData, accountData, clientPoints] = await Promise.all([
				Users.GetOneSlim(r.user_id),
				Firms.GetOneByIDSlim(r.firm_id),
				FirmAccounts.GetOneByID(r.firm_id, r.reciprocity_account_id, faQueryParams),
				Calculator.CalculateClientPoints(props.caseRegionData.id, props.caseRegionData.client_id),
			]);
			uData.firm_data = fData;
			uData.reciprocity_account_data = accountData;
			uData.client_points = clientPoints.points;
			uData.reciprocity_award = r.reciprocity_award;
			uData.assignment_override_request_id = r.assignment_override_request_id;

			// Check for additional badges flags
			if (uData.firm_data.is_vendor) {
				r.is_vendor = true;
			}
			if (uData.firm_data.is_owed_case) {
				r.is_owed_case = true;
			}
			uData.badges = assignBadges(r);
			associates.push(uData);
		}
		setSuggestedAssociateList(associates);
		setDisplayedAssociates(associates);
	};

	const getClientPreferences = async () => {
		try {
			let id = props.caseRegionData.client_id;
			let group = PREFERENCES_GROUP.GENERAL_SETTINGS;
			let cp = await Preferences.GetUserRecordsById(id, group);
			setClientPreferences(cp);
		} catch (err) {
			console.log(err);
		}
	};

	const assignBadges = (data) => {
		let badges = [];
		if (data.is_preferred) badges.push('PREFERRED');
		if (data.is_blacklisted) badges.push('BLACKLISTED');
		if (data.is_already_assigned) badges.push('ALREADY_ASSIGNED');
		if (data.is_inactive) badges.push('INACTIVE');
		if (data.is_vendor) badges.push('IS_VENDOR');
		if (data.is_owed_case) badges.push('OWED_CASE');
		if (data.is_previously_assigned) badges.push('PREVIOUSLY_USED');
		if (data.is_eligible) badges.push('IS_ELIGIBLE');
		return badges;
	};

	const assignAssociate = async (e, associate) => {
		let payload = {
			associate_id: associate.id,
			case_id: props.caseRegionData.case_id,
			case_region_id: props.caseRegionData.id,
			region_id: props.caseRegionData.region_id,
			status: SELECTED_ASSOCIATE_STATUS.PENDING,
			manual_selection_reason: props.manualSelectionReason,
			selection_type: 'MANUAL',
			reciprocity_award: associate.reciprocity_award,
			assignment_override_request_id: associate.assignment_override_request_id,
		};

		let autoApproveSettings = clientPreferences.find((p) => p.preference_key === COMMUNICATION_PREFERENCES.AUTO_APPROVE_ASSOCIATE.name);

		try {
			setShowLoader(true);
			const res = await Cases.SetSelectedAssociate(payload);
			if ((autoApproveSettings && Number(autoApproveSettings.preference_value) === 1) || props.caseRegionData.is_rush_case === true) {
				let autoApproveReason = !!props.caseRegionData.is_rush_case ? 'rush_case' : 'client_pref';
				await props.approveAssociate(res.associate_id, res.case_id, res.case_region_id, '', autoApproveReason);
			}
			props.closeHandler();
			setShowLoader(false);
			await props.getCasesRegions();
		} catch (error) {
			console.log(error);
			setShowLoader(false);
		}
	};

	const handleAssociateSearch = (e) => {
		setSearchAssociate(e.target.value);
		if (e.target.value === '') {
			setDisplayedAssociates([...suggestedAssociateList]);
			return;
		}
		let filtered = suggestedAssociateList.filter((sa) => {
			return (
				`${sa.first_name} ${sa.last_name}`.toLowerCase().includes(e.target.value) ||
				sa.reciprocity_account_data.name.toLowerCase().includes(e.target.value) ||
				sa.firm_data.name.toLowerCase().includes(e.target.value)
			);
		});
		setDisplayedAssociates([...filtered]);
	};

	const onClickShowMore = () => {
		setDisplayCount((prevState) => prevState + 3);
	};

	const modalActions = [
		{ primary: true, label: 'Create & invite New Associate', action: () => {} },
		{ primary: false, label: 'Cancel', action: props.closeHandler },
	];

	return (
		<Modal title="Manual Selection" closeHandler={props.closeHandler} footerActions={modalActions}>
			<div className="assign-associate">
				<div className="assign-associate__body">
					<LoaderOverlay showLoader={showLoader} />
					<div className="assign-associate__body__applicant-name">
						<h5>Applicant:</h5>
						<span>
							<img src={ICON_AVATAR_SOLID} alt="" />
							{props.caseRegionData.applicant_name}
						</span>
					</div>
					<div className="assign-associate__body__firm_size">
						<h5>Firm Size:</h5>
						<span>{props.caseRegionData.client_firm_size}</span>
					</div>
					<div className="assign-associate__body__technologies">
						<h5>Technology:</h5>
						<div className="assign-associate__body__technologies__list-wrapper">
							{props.caseRegionData.application_technologies ?
								props.caseRegionData.application_technologies.map((t) => {
									return (
										<span key={t} className="assign-associate__body__single-technology">
											{technologiesMap.get(t)}
										</span>
									);
								})
							:	null}
						</div>
					</div>
					<div className="assign-associate__body__intro">
						<div>
							<Select
								disabled={false}
								options={overrideOptions}
								label="Reason for manual selection"
								onChange={props.handleReasonForManualSelection}
								name="country"
								value={props.manualSelectionReason}
								customClassName="assign-associate__body__intro-select"
							/>
							<Input name="search_associate" label="Search Firm Name" value={searchAssociate} onChange={handleAssociateSearch} />
						</div>
					</div>
					<div className="suggested-associates">
						{displayedAssociates.slice(0, displayCount).map((sa, i) => {
							return (
								<details key={i} className="suggested-associates__row">
									<summary className="suggested-associates__row-intro">
										<div className="suggested-associates__row-intro__associate-wrapper">
											<div className="suggested-associates__row-intro__toggle"></div>
											<picture className="suggested-associates__row-intro__avatar">
												<img src={ICON_AVATAR} alt="" />
											</picture>

											<div className="suggested-associates__row-intro__teaser">
												<div className="suggested-associates__row-intro__attorney-name">{sa.firm_data.name}</div>
												<RoleBox roles={['admin', 'case_manager', 'case_manager_manager']}>
													<div className="suggested-associates__row-intro__teaser__subheading">
														<span className="suggested-associates__row-intro__points">{sa.reciprocity_account_data.name}</span>
														<span>{sa.reciprocity_account_data.points} pt.</span>
														<span className="suggested-associates__row-intro__separator">&#8226;</span>
														<span>Owed {sa.reciprocity_account_data.owed_cases} cases</span>
													</div>
												</RoleBox>
											</div>
										</div>
										<div className="suggested-associates__row-intro__region">
											<span>
												<img src={getFlag(props.caseRegionData.region_code)} alt="" />
											</span>
											<span>{props.caseRegionData.region_name}</span>
										</div>
										<div className="suggested-associates__row-intro__calculator-points">
											<span>+{sa.client_points} pt.</span>
										</div>
										<RoleBox roles={['admin', 'case_manager', 'case_manager_manager']}>
											<div className="suggested-associates__row-intro__badges">
												{sa.badges.map((badge, j) => {
													return <img key={j} src={badgesMapper[badge].icon} title={badgesMapper[badge].title} />;
												})}
											</div>
										</RoleBox>
										<RoleBox roles={['user']}>
											<div className="suggested-associates__row-intro__email">
												<img src={ICON_GLOBE} alt="" />
												<span>{sa.firm_data.website}</span>
											</div>
										</RoleBox>
										<div className="suggested-associates__row-intro__approve">
											<Button
												onClick={(e) => assignAssociate(e, sa)}
												data-aid={sa.id}
												theme="confirm"
												size="small"
												customClassName="suggested-associates__row-intro__approve-btn"
											>
												Assign &amp; Approve
											</Button>
										</div>
									</summary>
									<div className="suggested-associates__row-expanded">
										<RoleBox roles={['admin', 'case_manager', 'case_manager_manager']}>
											<div className="suggested-associates__row-expanded__firm-name firm-info">
												<span className="suggested-associates__row-expanded__label">Account Name:</span>
												<span className="suggested-associates__row-expanded__value">{sa.reciprocity_account_data.name}</span>
											</div>
											<div className="suggested-associates__row-expanded__firm-size firm-info">
												<span className="suggested-associates__row-expanded__label">Firm Size:</span>
												<span className="suggested-associates__row-expanded__value">{sa.firm_data.firm_size}</span>
											</div>
										</RoleBox>
										<div className="suggested-associates__row-expanded__firm-techs firm-info">
											<span className="suggested-associates__row-expanded__label">Technologies:</span>
											<ul className="suggested-associates__row-expanded__value">
												{sa.expertise &&
													sa.expertise.map((tech) => {
														return <li key={tech.id}>{tech.technology_name}</li>;
													})}
											</ul>
										</div>
										<RoleBox roles={['admin', 'case_manager', 'case_manager_manager']}>
											<div className="suggested-associates__row-expanded__last-received-case-date firm-info">
												<span className="suggested-associates__row-expanded__label">Last Case on:</span>
												<span className="suggested-associates__row-expanded__value">
													{sa.last_sent_case_date ?
														<AppDate>{sa.last_sent_case_date}</AppDate>
													:	'N/A'}
												</span>
											</div>
										</RoleBox>
									</div>
								</details>
							);
						})}
					</div>
					{displayCount < displayedAssociates.length && (
						<span className="assign-associate__show-more" onClick={onClickShowMore}>
							show more
						</span>
					)}
				</div>
			</div>
		</Modal>
	);
};

AssociateAssignmentModal.propTypes = {
	caseRegionData: PropTypes.object,
	closeHandler: PropTypes.func,
	handleReasonForManualSelection: PropTypes.func,
	manualSelectionReason: PropTypes.string,
	getCasesRegions: PropTypes.func,
	approveAssociate: PropTypes.func,
};

AssociateAssignmentModal.defaultProps = {
	caseRegionData: {},
	closeHandler: () => {},
	handleReasonForManualSelection: () => {},
	manualSelectionReason: '',
	getCasesRegions: () => {},
	approveAssociate: () => {},
};

export default AssociateAssignmentModal;
