import React, { useEffect, useState } from 'react';

import { AppRoute } from 'com/util/AppRoute';
import Routes from 'data/routes';

import { LoaderOverlay } from 'sazzui/lib/main';

import './style.css';

import Settings from 'services/rest/settings';
import SystemSettingsPoasMain from './SystemSettingsPoasMain';
import SystemSettingsPoasRegionalSetting from './SystemSettingsPoasRegionalSetting';

const SystemSettingsPoas = (props) => {
	const [showLoader, setShowLoader] = useState(false);
	const [regions, setRegions] = useState([]);

	useEffect(async () => {
		try {
			await fetchData();
		} catch (err) {
			console.log(err);
		}
	}, []);

	const fetchData = async () => {
		try {
			setShowLoader(true);
			let regions = await Settings.GetRegions();
			setRegions(
				regions.map((d) => {
					return { label: d.name, value: d.code };
				}),
			);
			setShowLoader(false);
		} catch (err) {
			setShowLoader(false);
		}
	};

	return (
		<div>
			<LoaderOverlay showLoader={showLoader} />
			<AppRoute route={Routes.SYSTEM_SETTINGS_POAS_MAIN} exact regions={regions} />
			<AppRoute route={Routes.SYSTEM_SETTINGS_POAS_ADD} exact regions={regions} />
			<AppRoute route={Routes.SYSTEM_SETTINGS_POAS_EDIT} exact regions={regions} />
		</div>
	);
};
export { SystemSettingsPoasMain, SystemSettingsPoasRegionalSetting };
export default SystemSettingsPoas;
