import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//Util Imports
import { AppDate } from '../../../util/AppDate';
//Icon Imports
import ICON_CALENDAR from '../../../../src/assets/images/icons/ico_calendar.svg';
;
export const DateCell = ({ date = '', showIco = false, }) => {
    return (_jsx("td", { children: _jsxs("div", { className: "table-cell table-cell-date", children: [date && showIco ?
                    _jsx("img", { src: ICON_CALENDAR, className: "date-icon", alt: "calendar" })
                    : null, _jsx(AppDate, { children: date })] }) }));
};
