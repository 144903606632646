import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import { Input, Select, LoaderOverlay } from 'sazzui/lib/main';

import NameFlattener from 'services/rest/name-flattener';
import Firms from 'services/rest/firms';

import useStateWithCallback from 'services/hooks/useStateWithCallback';
import useNotification from 'services/hooks/use_notification';

const TABS = {
	EXISTING: 'EXISTING',
	UNMATCHED: 'UNMATCHED',
};
const SORT_BY_FILTERS = {
	EXISTING: [
		{ value: '', label: '' },
		{ value: 'name:asc', label: 'Name (ASC)' },
		{ value: 'name:desc', label: 'Name (DESC)' },
		{ value: 'matches:asc', label: 'Matches (ASC)' },
		{ value: 'matches:desc', label: 'Matches (DESC)' },
	],
	UNMATCHED: [
		{ value: '', label: '' },
		{ value: 'name:asc', label: 'Name (ASC)' },
		{ value: 'name:desc', label: 'Name (DESC)' },
	],
};
const FILTERS_INIT = { name: '', sort_by: '', country: '' };

const DuplicateManagementMain = (props, ref) => {
	const [firms, setFirms] = useState([{ label: '', value: '' }]);
	const [activeTab, setActiveTab] = useState(TABS.EXISTING);
	const [listData, setListData] = useState([]);
	const [selectedListItemId, setSelectedListItemId] = useState('');
	const [variationsData, setVariationsData] = useState([]);
	const [selectedLeader, setSelectedLeader] = useState('');
	const [selectedFirm, setSelectedFirm] = useState('');
	const [filters, setFilters] = useStateWithCallback(FILTERS_INIT);
	const [sortByOptions, setSortByOptions] = useState(SORT_BY_FILTERS[TABS.EXISTING]);
	const [showLoader, setShowLoader] = useState(false);
	const sendNotification = useNotification();

	useEffect(async () => {
		try {
			let firmsData = await Firms.GetAllRaw();
			setFirms((prevState) => [
				...prevState,
				...firmsData.map((f) => {
					return { label: f.name, value: f.id };
				}),
			]);
		} catch (e) {
			console.log(e);
		}
	}, []);

	useEffect(async () => {
		try {
			setShowLoader(true);
			switch (activeTab) {
				case TABS.EXISTING:
					await fetchGroups(filters);
					break;
				case TABS.UNMATCHED:
					await fetchUnmatched(filters);
					break;
			}
			setShowLoader(false);
		} catch (e) {
			setShowLoader(false);
			console.log(e);
		}
	}, [activeTab]);

	useEffect(async () => {
		try {
			setShowLoader(true);
			switch (activeTab) {
				case TABS.EXISTING:
					if (selectedListItemId) {
						let gItems = await NameFlattener.GetOneGroup(props.entityType, selectedListItemId);
						setSelectedLeader(selectedListItemId);
						setSelectedFirm(gItems[0].firm_id);
						setVariationsData(gItems);
					}
					break;
				case TABS.UNMATCHED:
					if (selectedListItemId) {
						let uItems = await NameFlattener.GetVariations(props.entityType, selectedListItemId);
						setVariationsData(uItems);
					}
					break;
			}
			setShowLoader(false);
		} catch (e) {
			setShowLoader(false);
			console.log(e);
		}
	}, [selectedListItemId]);

	const fetchGroups = async (filterData) => {
		if (!filterData.country) return;
		let groups = await NameFlattener.GetAllGroups(props.entityType, filterData);
		setListData(groups);
		if (groups.length) {
			setSelectedListItemId(groups[0].id);
		} else {
			setSelectedListItemId('');
			setVariationsData([]);
		}
	};

	const fetchUnmatched = async (filterData) => {
		if (!filterData.country) return;
		let unmatched = await NameFlattener.GetAllUnmatched(props.entityType, filterData);
		setListData(unmatched);
		if (unmatched.length) {
			setSelectedListItemId(unmatched[0].id);
		} else {
			setSelectedListItemId('');
			setVariationsData([]);
		}
	};

	const onTabClick = (e) => {
		let { tab } = e.target.dataset;
		if (tab !== activeTab) {
			setSortByOptions(SORT_BY_FILTERS[TABS[tab]]);
			setListData([]);
			setVariationsData([]);
			setSelectedLeader('');
			setSelectedFirm('');
			setActiveTab(e.target.dataset.tab);
		}
	};

	const submitFilters = async (filterData) => {
		try {
			setShowLoader(true);
			switch (activeTab) {
				case TABS.EXISTING:
					await fetchGroups(filterData);
					break;
				case TABS.UNMATCHED:
					await fetchUnmatched(filterData);
					break;
			}
			setShowLoader(false);
		} catch (e) {
			setShowLoader(false);
			console.log(e);
		}
	};

	const onFiltersChange = (e) => {
		switch (e.target.name) {
			case 'sort_by':
			case 'country':
				setFilters({ ...filters, [e.target.name]: e.target.value }, (prevValue, newValue) => {
					submitFilters(newValue);
				});
				break;
			default:
				setFilters({ ...filters, [e.target.name]: e.target.value });
		}
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			submitFilters(filters);
		}
	};

	const onSelectListItem = (itemID) => {
		setSelectedListItemId(itemID);
	};

	const onSelectLeader = (e) => {
		let { leader_id } = e.target.dataset;
		setSelectedLeader(leader_id);
		setVariationsData((prevState) =>
			prevState.map((vi) => {
				if (vi.id === leader_id || vi.parent_id) {
					return { ...vi, parent_id: leader_id };
				}
				return vi;
			}),
		);
	};

	const onSelectExistingVariation = (e) => {
		let { variation_id } = e.target.dataset;
		setVariationsData((prevState) =>
			prevState.map((vi) => {
				if (vi.id === variation_id) {
					if (vi.parent_id) {
						return { ...vi, parent_id: null, removed: true }; // uncheck
					} else {
						return { ...vi, parent_id: selectedLeader, removed: false }; // check
					}
				}
				return vi;
			}),
		);
	};

	const onSelectUnmatchedVariation = (e) => {
		let { variation_id } = e.target.dataset;
		setVariationsData((prevState) =>
			prevState.map((vi) => {
				if (vi.id === variation_id) {
					if (vi.parent_id && vi.id !== selectedLeader) {
						return { ...vi, parent_id: null }; // uncheck
					} else {
						return { ...vi, parent_id: selectedLeader ? selectedLeader : variation_id }; // check
					}
				}
				return vi;
			}),
		);
	};

	const onSelectFirm = (e) => {
		setSelectedFirm(e.target.value);
	};

	useImperativeHandle(ref, () => ({
		async saveChanges() {
			try {
				if (!selectedLeader) return sendNotification({ type: 'error', title: 'Please select a group leader' });
				if (!selectedFirm) sendNotification({ type: 'info', title: 'The saved group will not be linked to V2 case, since no Firm was selected.' });

				setShowLoader(true);
				if (activeTab === TABS.EXISTING) {
					let data = {
						parent_id: selectedLeader,
						members: variationsData.filter((vi) => vi.parent_id).map((vi) => vi.id),
						removed: variationsData.filter((vi) => vi.removed).map((vi) => vi.id),
						firm_id: selectedFirm,
					};
					await NameFlattener.UpdateGroup(props.entityType, data);
					await fetchGroups(filters);
				} else if (activeTab === TABS.UNMATCHED) {
					let data = {
						parent_id: selectedLeader,
						members: variationsData.filter((vi) => vi.parent_id).map((vi) => vi.id),
						firm_id: selectedFirm,
					};
					await NameFlattener.CreateGroup(props.entityType, data);
					await fetchUnmatched(filters);
				}

				sendNotification({ type: 'success', title: 'Successfully saved varietion group' });
				setShowLoader(false);
			} catch (e) {
				setShowLoader(false);
				console.log(e);
			}
		},
	}));

	return (
		<div className="duplicate-management__container">
			<LoaderOverlay showLoader={showLoader} />
			<div className="duplicate-management__container__left">
				<span>Name</span>
				<ul className="duplicate-management__container__tabs">
					<li onClick={onTabClick} data-tab={TABS.EXISTING} className={activeTab === TABS.EXISTING ? 'active' : ''}>
						Existing
					</li>
					<li onClick={onTabClick} data-tab={TABS.UNMATCHED} className={activeTab === TABS.UNMATCHED ? 'active' : ''}>
						Unmatched
					</li>
				</ul>
				<div className="duplicate-management__container__filters">
					<Select label="Country" name="country" required={true} options={props.countries} value={filters.country} onChange={onFiltersChange} />
					<Input label="Filter By Name" name="name" type="text" value={filters.name} onChange={onFiltersChange} onKeyDown={handleKeyDown} />
					<Select label="Sort By" name="sort_by" options={sortByOptions} value={filters.sort_by} onChange={onFiltersChange} />
				</div>
				{activeTab === TABS.EXISTING ?
					<ul className="duplicate-management__container__list">
						{listData.map((li) => (
							<ListItem key={li.id} itemID={li.id} isActive={li.id === selectedListItemId} customClassName="duplicate-management__container__list-item" onClick={onSelectListItem}>
								<div className="item-title">
									<span>{li.name}</span>
									<span className="matches">{li.matches} matches</span>
								</div>
								<div className="item-subtitle">
									<span>{li.country}</span>
									<span className="dot-separator"></span>
									<span>{li.postcode}</span>
									<span className="dot-separator"></span>
									<span className="address-ellipsis" title={li.address}>
										{li.address}
									</span>
								</div>
							</ListItem>
						))}
					</ul>
				:	null}
				{activeTab === TABS.UNMATCHED ?
					<ul className="duplicate-management__container__list">
						{listData.map((li) => (
							<ListItem key={li.id} itemID={li.id} isActive={li.id === selectedListItemId} customClassName="duplicate-management__container__list-item" onClick={onSelectListItem}>
								<div className="item-title">
									<span>{li.name}</span>
								</div>
								<div className="item-subtitle">
									<span>{li.country}</span>
									<span className="dot-separator"></span>
									<span>{li.postcode}</span>
									<span className="dot-separator"></span>
									<span className="address-ellipsis" title={li.address}>
										{li.address}
									</span>
								</div>
							</ListItem>
						))}
					</ul>
				:	null}
			</div>
			{activeTab === TABS.EXISTING && variationsData.length ?
				<>
					<div className="duplicate-management__container__middle">
						<span>Variations</span>
						<ul className="duplicate-management__container__list existing-variations-list">
							{variationsData.map((vi) => (
								<li key={vi.id}>
									<label className="existing-variations-item__checkbox">
										<input type="checkbox" checked={vi.parent_id ? true : false} onChange={onSelectExistingVariation} data-variation_id={vi.id} />
										<div className="details">
											<span>{vi.name}</span>
											<div className="item-subtitle">
												<span>{vi.country}</span>
												<span className="dot-separator"></span>
												<span>{vi.postcode}</span>
												<span className="dot-separator"></span>
												<span className="address-ellipsis" title={vi.address}>
													{vi.address}
												</span>
											</div>
										</div>
									</label>
								</li>
							))}
						</ul>
					</div>
					<div className="duplicate-management__container__right">
						<div>
							<div class="specify-firm-id">
								<Select label="Specify V2 Firm" options={firms} value={selectedFirm} onChange={onSelectFirm} />
							</div>
							<span className="subtitle">{variationsData.length} Variations Selected</span>
							<ul className="selected-variations-list">
								{variationsData
									.filter((vi) => vi.parent_id)
									.map((vi) => (
										<li key={vi.id}>
											{vi.name}
											<button onClick={onSelectExistingVariation} data-variation_id={vi.id}>
												&times;
											</button>
										</li>
									))}
							</ul>
						</div>
					</div>
				</>
			:	null}
			{activeTab === TABS.UNMATCHED ?
				<>
					<div className="duplicate-management__container__middle">
						<span>Variations</span>
						<span>Select a leader (&#9733;):</span>
						<ul className="duplicate-management__container__list existing-variations-list">
							{variationsData.map((vi) => (
								<li key={vi.id} className="unmatched-variations-item">
									<label className="unmatched-variations-item__leader">
										<input type="checkbox" checked={vi.id === selectedLeader ? true : false} onChange={onSelectLeader} data-leader_id={vi.id} />
									</label>
									<label className="unmatched-variations-item__checkbox">
										<input type="checkbox" checked={vi.parent_id ? true : false} onChange={onSelectUnmatchedVariation} data-variation_id={vi.id} />
										<div className="details">
											<span>{vi.name}</span>
											<div className="item-subtitle">
												<span>{vi.country}</span>
												<span className="dot-separator"></span>
												<span>{vi.postcode}</span>
												<span className="dot-separator"></span>
												<span className="address-ellipsis" title={vi.address}>
													{vi.address}
												</span>
											</div>
										</div>
									</label>
								</li>
							))}
						</ul>
					</div>
					<div className="duplicate-management__container__right">
						<div class="specify-firm-id">
							<Select label="Specify V2 Firm" options={firms} value={selectedFirm} onChange={onSelectFirm} />
						</div>
					</div>
				</>
			:	null}
		</div>
	);
};

const ListItem = (props) => {
	const handleOnClick = () => {
		props.onClick(props.itemID);
	};

	return (
		<li className={`${props.customClassName} ${props.isActive && 'active'}`} onClick={handleOnClick}>
			{props.children}
		</li>
	);
};
ListItem.propTypes = {
	customClassName: PropTypes.string,
	isActive: PropTypes.bool,
	itemID: PropTypes.string,
	onClick: PropTypes.func,
};
ListItem.defaultProps = {
	customClassName: '',
	isActive: 0,
	itemID: '',
	onClick: () => {},
};

export default forwardRef(DuplicateManagementMain);
