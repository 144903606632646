import React, { useState } from 'react';

import { DataTable, Button, Select } from 'sazzui/lib/main';

import ICON_CLOSE from 'assets/images/icons/ico-close-grey.svg';

import './style.css';

const PrefsAssociateAddForm = (props) => {
	const errorMessages = {
		required: 'Required',
	};

	const [addingPreferred, setAddingPreferred] = useState({});
	const [filterdAssociates, setFilterdAssociates] = useState();
	const [errors, setErrors] = useState({});

	const addFormChanged = (e) => {
		const { name, value } = e.target;

		setAddingPreferred({
			...addingPreferred,
			[name]: value,
		});

		if (name === 'firm_id') {
			setFilterdAssociates([{ label: '', value: '' }, ...props.associates.filter((a) => a.firm_id === value)]);
		}
	};

	const validate = () => {
		let isValid = true;
		setErrors({});

		if (!addingPreferred.firm_id) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, firm_id: errorMessages.required }));
		}
		if (!addingPreferred.region_id) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, region_id: errorMessages.required }));
		}
		return isValid;
	};

	const submitPrefsAssociate = () => {
		if (!validate()) {
			return false;
		}
		props.onHandleAdd(addingPreferred);
	};

	return (
		<tr className="prefs_assoc__section-body__add-form">
			<DataTable.Cell>
				<Select name="firm_id" options={props.firms} value={addingPreferred.firm_id || ''} onChange={addFormChanged} error={errors.firm_id} ariaLabel="select firm" />
			</DataTable.Cell>
			{props.showAssociateColumn && (
				<DataTable.Cell>
					<Select name="associate_id" options={filterdAssociates} value={addingPreferred.associate_id || ''} onChange={addFormChanged} ariaLabel="select associate" />
				</DataTable.Cell>
			)}
			<DataTable.Cell>
				<Select name="region_id" options={props.regions} value={addingPreferred.region_id || ''} onChange={addFormChanged} error={errors.region_id} ariaLabel="select region" />
			</DataTable.Cell>
			<DataTable.Cell> </DataTable.Cell>
			<DataTable.Cell>
				<Button onClick={submitPrefsAssociate} customClassName="prefs_assoc__section-body__add-form-submit">
					Save
				</Button>
				<Button onClick={props.onCancelAddForm} customClassName="prefs_assoc__section-body__add-form-cancel">
					<img src={ICON_CLOSE} aria-label="cancel" />
				</Button>
			</DataTable.Cell>
		</tr>
	);
};

export default PrefsAssociateAddForm;
