import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux';

import { Checkbox, LoaderOverlay } from 'sazzui/lib/main';
import Settings from 'services/rest/settings';
import { inputACLCheck } from 'services/acl';

import { OwnerContext } from 'util/ownerContext';

import './style.css';

const ExpertiseList = (props) => {
	const ownerData = useContext(OwnerContext);
	const user = useSelector((state) => state.auth.user);

	const [technologies, setTechnologies] = useState([]);
	const [showLoader, setShowLoader] = useState(false);

	useEffect(() => {
		setShowLoader(true);
		Settings.GetTechnologies()
			.then((data) => {
				setTechnologies(data);
				setShowLoader(false);
			})
			.catch((err) => {
				console.log(err);
				setShowLoader(false);
			});
	}, []);

	const expertiseChange = (e) => {
		if (props.onChange) {
			props.onChange(e.target.value, e.target.checked);
		}
	};

	return (
		<div className="expertise-list">
			<LoaderOverlay showLoader={showLoader} />
			<span className="expertise-list__title">Areas of Expertise</span>
			<div className="expertise-list__header">
				<Checkbox
					label="Title"
					customClassName="expertise-list__header__checkbox"
					disabled={!!props.readOnly || inputACLCheck(user, ownerData, ['admin', 'sales', 'sales_manager', 'saas_user'])}
					ariaLabel="Title Checkbox"
				/>
			</div>
			{props.values &&
				technologies.map((t) => {
					let checked = props.values.includes(t.id) ? true : false;
					return (
						<div className="expertise-list__item" key={t.id}>
							<Checkbox
								label={t.name}
								customClassName="expertise-list__items__checkbox"
								onChange={expertiseChange}
								value={t.id}
								checked={checked}
								disabled={!!props.readOnly || inputACLCheck(user, ownerData, ['admin', 'sales', 'sales_manager', '$user'])}
								readOnly={!!props.readOnly}
							/>
						</div>
					);
				})}
		</div>
	);
};

export default ExpertiseList;
