import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Modal, Checkbox, Textarea, DataTable } from 'sazzui/lib/main';

import ICON_PAPER_CLIP from 'assets/images/icons/ico_paperclip.svg';
import ICON_INFO from 'assets/images/icons/ico_info_magenta.svg';

import EventLoggerEvents from 'services/rest/event_logger';

import './style.css';

const AddRegionModal = (props) => {
	const errorsInit = {
		confirm_checkbox: '',
	};

	const tableHeadersInit = [
		{ title: 'Region', field: 'region', sort: 'none', sortable: false },
		{ title: 'File', field: 'file', sort: 'none', sortable: false },
		{ title: 'Description', field: 'description', sort: 'none', sortable: false },
	];

	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [errors, setErrors] = useState(errorsInit);
	const [confirm, setConfirm] = useState(false);
	const user = useSelector((state) => state.auth.user);

	const onConfirm = (e) => {
		setConfirm(!confirm);
		if (!!e.target.value) {
			props.regionModalConfirm();
		}
	};

	const addRegions = () => {
		if (!confirm) {
			setErrors({ ...errors, confirm_checkbox: ' ' });
			return;
		}
		EventLoggerEvents.EventLogger({ event: `Member Added Regions`, data: { user: user, new_regions: props.newRegions } });
		props.updateDetails();
		props.closeHandler();
	};

	const footerActions = [
		{ primary: false, label: 'Cancel', action: props.closeHandler, theme: 'azami-ghost' },
		{ primary: true, label: 'Add Region(s) & Generate New Quote', action: addRegions, theme: 'azami-blue' },
	];

	const removeRegion = (e) => {
		props.onChange([...props.data.regions.filter((r) => r !== e.target.dataset.id)]);
	};

	return (
		<Modal title="Confirmation" footerActions={footerActions} closeHandler={props.closeHandler}>
			<div className="add-regions-modal">
				<p className="add-regions-modal-header ">Click Add Region(s) & Generate New Quote to update your current estimate. Click Cancel to return to the quote without making any changes.</p>
				<h4>The following regions have been added:</h4>
				<ul className="add-regions-modal-new-regions">
					{props.newRegions &&
						props.newRegions.map((r) => {
							let region = props.regions.find((re) => re.id === r);
							return (
								<li key={region.id}>
									{region.name}
									<button data-id={region.id} onClick={removeRegion}>
										&times;
									</button>
								</li>
							);
						})}
				</ul>
				<div className="add-regions-modal-attachements">
					<div className="add-regions-modal-attachements-header">
						<div title="Additional attachments for the newly added regions can be added in the Quote Documents section after clicking Add Region(s) & Generate New Quote">
							<span>Attachments</span>
							<img src={ICON_INFO} alt="" />
						</div>
					</div>
					{props.documents.length > 0 ?
						<DataTable fixedHeader={false} columns={tableHeaders} customClassName="attachments__table">
							{props.documents.map((d) => {
								return (
									<React.Fragment key={d.id}>
										<tr>
											{d.region_code ?
												<DataTable.CountryCell code={d.region_code}>{d.region_name}</DataTable.CountryCell>
											:	<DataTable.Cell>{d.region_name}</DataTable.Cell>}
											<DataTable.Cell customClassName="attachments__table__file-name">
												<img src={ICON_PAPER_CLIP} />
												<span>{d.name}</span>
											</DataTable.Cell>
											<DataTable.Cell>{d.description}</DataTable.Cell>
										</tr>
									</React.Fragment>
								);
							})}
						</DataTable>
					:	<div className="add-regions-modal-attachements-no-attachements">None</div>}
				</div>
				<div className="add-regions-modal-bottom">
					<div className="add-regions-modal-bottom-additional-information">
						<Textarea name="additional_info" label="Additional Information" onChange={props.fieldChange} value={props.data.additional_info} ariaLabel="Additional info Textarea" />
					</div>
					<div className="add-regions-modal-bottom-checkbox">
						<Checkbox
							multilineLabel={true}
							label="By clicking Add Region(s) & Generate New Quote, I understand that the quote I receive will be based on the application data previously entered. I understand that any data contained in ATTACHMENTS or provided in ADDITIONAL DETAILS that have a bearing on costs are not a part of the estimate provided at this time. Final costs will be based on actual application details."
							name="confirm_checkbox"
							value={confirm}
							checked={confirm}
							onClick={onConfirm}
							error={errors.confirm_checkbox}
							ariaLabel="Confirm disclaimer Checkbox"
						/>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default AddRegionModal;
