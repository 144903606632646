import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
//Icon Imports
import ICON_SORT_UP from '../../../../src/assets/images/icons/ico_sort_up.svg';
import ICON_SORT_DOWN from '../../../../src/assets/images/icons/ico_sort_down.svg';
;
;
export const HeaderCell = ({ sortable = false, field = '', type = '', sort = 'none', title = '', onClick = () => { } }) => {
    return (_jsx("th", { ...(sortable && {
            onClick: () => {
                onClick(field, type);
            },
        }), children: _jsxs("div", { className: "header-cell", children: [sortable && _jsx(Sorter, { dir: sort }), title] }) }));
};
const Sorter = ({ dir = 'none' }) => {
    return (_jsx("div", { className: "sorter-flags", children: dir === 'none' ?
            _jsxs(_Fragment, { children: [_jsx("img", { src: ICON_SORT_UP, alt: "sort up" }), _jsx("img", { src: ICON_SORT_DOWN, alt: "sort down" })] }) :
            _jsxs(_Fragment, { children: [_jsx("img", { src: ICON_SORT_UP, className: dir === 'down' ? 'sort-dir-inactive' : '', alt: "sort up" }), _jsx("img", { src: ICON_SORT_DOWN, className: dir === 'up' ? 'sort-dir-inactive' : '', alt: "sort down" })] }) }));
};
