import React from 'react';

import { DocumentUploader } from 'sazzui/lib/main';
import RatesList from 'com/widgets/RatesList';
import { RoleBox } from 'com/util/RoleBox';
import { INTERNAL_ROLES } from 'data/constants';

const UserDetailsRates = (props) => {
	return (
		<div className="user-details-rates">
			<RatesList data={props.userData} onChange={props.onChangeRate} revertRate={true} firmCurrency={props.userData.firm_data.currency_id} />
			<RoleBox roles={INTERNAL_ROLES}>
				<DocumentUploader title="Rates Confirmation" />
			</RoleBox>
		</div>
	);
};

export default UserDetailsRates;
