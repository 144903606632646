//vendor imports
import React, { useEffect, useState } from 'react';
//utils
import AppSwitch from 'com/util/AppSwitch';
import { AppRoute } from 'com/util/AppRoute';
//services
import Settings from 'services/rest/settings';
//ui components
import { TagSelect, Button } from 'sazzui/lib/main';
//constants
import { PAGE_TITLE } from 'data/constants';
//data
import Routes from 'data/routes';
//templates
import AppTemplate from 'com/templates/ApplicationTemplate';
//icons
import ICON_FUNNEL from 'assets/images/icons/ico_funnel.svg';
//components
import TranslationRequestsMain from './TranslationRequestsMain';
import TranslationRequestsForm from './TranslationRequestsForm';
//hooks
import useTitle from 'services/hooks/useTitle';
import useFilterStorage from 'services/hooks/useFilterStorage';
//styles
import './style.css';

const TranslationRequests = () => {
	useTitle(PAGE_TITLE.TRANSLATION_REQUESTS);

	const filterInit = {
		regions: [],
		languages: [],
		orderBy: [{ field: 'region_name', direction: 'up' }],
	};

	const typeOptionsAll = {
		REQUIRED: 'Required',
		NOT_REQUIRED: 'Not required',
		CLAIMS: 'Claims only',
		FULL_TRANSLATION: 'Full translation',
		CLAIMS_ENG_DESC: 'Claims only if Eng. description',
		NA: 'Not available',
	};

	const typeOptions = {
		REQUIRED: 'Required',
		NOT_REQUIRED: 'Not required',
		NA: 'Not available',
	};

	const [filter, setFilter] = useState(filterInit);
	const [regions, setRegions] = useState([{ label: '', value: '' }]);
	const [languagesList, setLanguagesList] = useState([{ label: '', value: '' }]);
	const [languagesDropDown, setLanguagesDropDown] = useState([]);
	const [requestsList, setRequestsList] = useState([]);
	const [type, setType] = useState([{ label: '', value: '' }]);
	const [typeAll, setTypeAll] = useState([{ label: '', value: '' }]);
	const [showLoader, setShowLoader] = useState(false);
	const { getFilterValue, setFilterValue } = useFilterStorage();

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			let f = getFilterValue();
			if (f) setFilter(f);
			setShowLoader(true);
			let pres = await Promise.all([Settings.GetRegions(), Settings.GetLanguages(), fetchTranslationRequests(f)]);
			setRegions((prevState) => [...prevState, ...pres[0].map((d) => ({ label: d.name, value: d.id }))]);
			setLanguagesList((prevState) => [...prevState, ...pres[1].map((d) => ({ label: d.name, value: d.id }))]);
			setLanguagesDropDown(pres[1].map((d) => ({ label: d.name, value: d.id })));
			setType((prevState) => [...prevState, ...Object.keys(typeOptions).map((v) => ({ label: typeOptions[v], value: v }))]);
			setTypeAll((prevState) => [...prevState, ...Object.keys(typeOptionsAll).map((v) => ({ label: typeOptionsAll[v], value: v }))]);
			setShowLoader(false);
		} catch (err) {
			setShowLoader(false);
			console.error(err);
		}
	};

	const fetchTranslationRequests = async (f) => {
		try {
			if (!!filter.orderBy) {
				filter.order_by = filter.orderBy.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(',');
			}
			let tr = await Settings.GetTranslationRequests(f ? f : filter);
			setRequestsList(tr);
		} catch (err) {
			console.log(err);
		}
	};

	const filterChange = (e) => {
		const { name, value, checked } = e.target;
		setFilter(!!filter[name].includes(value) ? filter[name].filter((f) => f !== value) : { ...filter, [name]: value });
	};

	const filterSubmit = async () => {
		try {
			setShowLoader(true);
			await fetchTranslationRequests();
			setFilterValue(filter);
			setShowLoader(false);
		} catch (err) {
			setShowLoader(false);
		}
	};

	const clearForm = () => {
		setFilter(filterInit);
		setFilterValue(null);
	};

	const sortChange = (orderBy) => {
		setFilter({
			...filter,
			orderBy: orderBy,
		});
	};

	const onChangeRequest = async (data, action) => {
		try {
			setShowLoader(true);
			switch (action) {
				case 'add':
					await Settings.CreateTranslationRequest(data);
					await fetchTranslationRequests();
					break;
				case 'edit':
					await Settings.UpdateTraslationRequest(data.id, data);
					await fetchTranslationRequests();
					break;
				case 'delete':
					await Settings.DeleteTranslationRequest(data.id);
					setRequestsList(requestsList.filter((r) => r.id !== data.id));
					break;
			}
			setShowLoader(false);
		} catch (err) {
			setShowLoader(false);
		}
	};

	return (
		<AppTemplate title="Translation requirements" headerIcon="translation_requests">
			<AppTemplate.Sidebar>
				<div className="translation-requirements__sidebar">
					<h4 className="translation-requirements__sidebar__header">
						<img src={ICON_FUNNEL} alt="picture of a funnel" />
						Filters
					</h4>
					<TagSelect label="Region" name="regions" options={regions} values={filter.regions} onChange={filterChange} theme="dark" />
					<TagSelect label="Language" name="languages" options={languagesList} values={filter.languages} onChange={filterChange} theme="dark" />
					<div className="translation-requirements__sidebar__actions sidebar_actions">
						<Button theme="ghost-primary" onClick={clearForm}>
							Clear
						</Button>
						<Button onClick={filterSubmit}>Apply Filters</Button>
					</div>
				</div>
			</AppTemplate.Sidebar>
			<AppTemplate.Content>
				<div>
					<AppSwitch>
						<AppRoute
							route={Routes.TRANSLATION_REQUESTS_MAIN}
							showLoader={showLoader}
							regions={regions}
							type={type}
							typeAll={typeAll}
							languagesList={languagesList}
							languagesDropDown={languagesDropDown}
							requestsList={requestsList}
							onSortChange={sortChange}
							onChange={onChangeRequest}
							exact
						/>
						<AppRoute
							route={Routes.TRANSLATION_REQUESTS_ADD}
							regions={regions}
							type={type}
							typeAll={typeAll}
							languagesList={languagesList}
							languagesDropDown={languagesDropDown}
							requestsList={requestsList}
							onChange={onChangeRequest}
							exact
						/>
						<AppRoute
							route={Routes.TRANSLATION_REQUESTS_EDIT}
							regions={regions}
							type={type}
							typeAll={typeAll}
							languagesList={languagesList}
							languagesDropDown={languagesDropDown}
							requestsList={requestsList}
							onChange={onChangeRequest}
							exact
						/>
					</AppSwitch>
				</div>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export { TranslationRequestsMain, TranslationRequestsForm };

export default TranslationRequests;
