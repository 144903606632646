import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//Component Imports
import { HeaderCell } from './HeaderCell';
import { Row } from './Row';
import { Cell } from './Cell';
import { DateCell } from './DateCell';
import { EditedByCell } from './EditedByCell';
import { DateRangeCell } from './DateRangeCell';
import { OverridePriceCell } from './OverridePriceCell';
import { AdvancedTagsCell } from './AdvancedTagsCell';
import { TagsCell } from './TagsCell';
import { MarkerCell } from './MarkerCell';
import { NullableCell } from './NullableCell';
import { BoolCell } from './BoolCell';
import { StatusCell } from './StatusCell';
import { CountryCell } from './CountryCell';
import { PointsCell } from './PointsCell';
import { ContentWithActionCell } from './ContentWithActionCell';
import { ActionsCell } from './ActionsCell';
import { RatesCell } from './RatesCell';
import { PriorityCell } from './PriorityCell';
import { ListGroupCell } from './ListGroupCell';
import { AccountCell } from './AccountCell';
import { StatusColorCell } from './StatusColorCell';
import { RatiosCell } from './RatiosCell';
import { DateTimeCell } from './DateTimeCell';
import { LoaderCell } from './LoaderCell';
import { CellWithBadge } from './CellWithBadge';
import { RuleRegionCell } from './RuleRegionCell';
import { ActionsMenuCell } from './ActionsMenuCell';
import { PersonCell } from './PersonCell';
import { Paging } from './Paging';
//Style imports
import './style.css';
export const DataTable = ({ customClassName = '', fixedHeader = false, columns = [], onColumnSort = () => { }, children = null, theme = 'primary' }) => {
    return (_jsxs("table", { cellPadding: "0", cellSpacing: "0", className: `data-table ${theme} ${customClassName}`, children: [_jsx("thead", { children: _jsx("tr", { className: fixedHeader ? 'fixed-header' : '', children: columns.map((c, i) => (_jsx(HeaderCell, { sortable: c.sortable, title: c.title, field: c.field, type: c.type, onClick: onColumnSort, sort: c.sort }, i))) }) }), _jsx("tbody", { children: children })] }));
};
DataTable.Row = Row;
DataTable.Cell = Cell;
DataTable.DateCell = DateCell;
DataTable.EditedByCell = EditedByCell;
DataTable.DateRangeCell = DateRangeCell;
DataTable.OverridePriceCell = OverridePriceCell;
DataTable.AdvancedTagsCell = AdvancedTagsCell;
DataTable.TagsCell = TagsCell;
DataTable.MarkerCell = MarkerCell;
DataTable.NullableCell = NullableCell;
DataTable.BoolCell = BoolCell;
DataTable.StatusCell = StatusCell;
DataTable.CountryCell = CountryCell;
DataTable.PointsCell = PointsCell;
DataTable.ContentWithActionCell = ContentWithActionCell;
DataTable.ActionsCell = ActionsCell;
DataTable.RatesCell = RatesCell;
DataTable.PriorityCell = PriorityCell;
DataTable.ListGroupCell = ListGroupCell;
DataTable.AccountCell = AccountCell;
DataTable.StatusColorCell = StatusColorCell;
DataTable.RatiosCell = RatiosCell;
DataTable.DateTimeCell = DateTimeCell;
DataTable.LoaderCell = LoaderCell;
DataTable.CellWithBadge = CellWithBadge;
DataTable.RuleRegionCell = RuleRegionCell;
DataTable.ActionsMenuCell = ActionsMenuCell;
DataTable.PersonCell = PersonCell;
export { Paging };
