import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import AppLink from 'com/util/AppLink';
import { STATUS_COLORS, ROLE_TYPES } from 'data/constants';

import ICON_CASES from 'assets/images/icons/ico_cases.svg';
import ICON_CASE_DETAILS from 'assets/images/icons/ico_case_details.svg';

import './style.css';

const CaseDetailsSidebar = (props) => {
	const user = useSelector((state) => state.auth.user);

	return (
		<div className="case-details__sidebar">
			<div className="case-details__sidebar__image-container">
				<picture>
					<source srcSet="" />
					<img className="case-details__sidebar__image-container__avatar" src={ICON_CASES} alt="cases" />
				</picture>
				{/*To be implemented later changing color depending on status */}
				<div
					className="case-details__sidebar__image-container-status-indicator case-details__sidebar__status-indicator-complete"
					style={{ backgroundColor: STATUS_COLORS[props.overallStatus] }}
				></div>
			</div>
			<div className="case-details__sidebar__firm-info">
				<span className="case-details__sidebar__firm-info__case-number">{`${props.caseNumber}`}</span>
				<span className="case-details__sidebar__firm-info__positon-company">
					{user.roleType === ROLE_TYPES.INTERNAL ?
						<>
							<AppLink type="regular" to={`/firm-management/${props.firmId}`} target="_blank" className="case-details__sidebar__firm-info__positon-company-link">
								{props.firmName}
							</AppLink>
							<span>&nbsp;-&nbsp;</span>
							<AppLink type="regular" to={`/user-management/${props.userId}`} target="_blank" className="case-details__sidebar__firm-info__positon-company-link">
								{props.user}
							</AppLink>
						</>
					:	<>
							<span className="case-details__sidebar__firm-info__positon-company-link">{props.firmName}</span>
							<span>&nbsp;-&nbsp;</span>
							<span className="case-details__sidebar__firm-info__positon-company-link">{props.user}</span>
						</>
					}
				</span>
			</div>
			<div className="case-details__sidebar__section">
				<div className="quotes-sidebar__section__title">
					<img src={ICON_CASE_DETAILS} alt="case details" />
					{props.title}
				</div>
				<div className="case-details__sidebar__section__body">{props.children}</div>
			</div>
		</div>
	);
};

CaseDetailsSidebar.propTypes = {
	caseNumber: PropTypes.string,
	firmName: PropTypes.string,
	user: PropTypes.string,
	title: PropTypes.string,
	overallStatus: PropTypes.string,
};

CaseDetailsSidebar.defaultProps = {
	caseNumber: '',
	firmName: '',
	user: '',
	title: '',
	overallStatus: '',
};

export default CaseDetailsSidebar;
