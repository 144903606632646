import React from 'react';
import PropTypes from 'prop-types';

import { Input } from 'sazzui/lib/ui/Input';

const FirmRegisteredInputs = (props) => {
	return (
		<div className="register-container__fields">
			<div className="register-container__input">
				<Input
					required={true}
					name="firm_code"
					value={props.values.firm_code}
					onChange={props.handleChange}
					error={props.errors.firm_code}
					label="Firm Registration Code"
					placeholder="PN-EIU5VK"
					type="text"
					onBlur={props.validate}
				/>
			</div>
		</div>
	);
};

FirmRegisteredInputs.propTypes = {
	values: PropTypes.object,
	handleChange: PropTypes.func,
	validate: PropTypes.func,
	errors: PropTypes.object,
};

FirmRegisteredInputs.defaultProps = {
	values: {},
	handleChange: () => {},
	validate: () => {},
	errors: {},
};

export default FirmRegisteredInputs;
