import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Button, DataTable, Tooltip, LoaderOverlay, AppDate } from 'sazzui/lib/main';

import InstructionDeadlineModal from 'com/widgets/InstructionDeadlineModal';
import QuoteAllAssociatesModal from 'com/widgets/QuoteModals/QuoteAllAssociatesModal';
import { RoleBox } from 'com/util/RoleBox';

import Estimates from 'services/rest/estimates';
import Settings from 'services/rest/settings';
import Reports from 'services/rest/reports';
import Firms from 'services/rest/firms';
import Users from 'services/rest/users';
import Quotes from 'services/rest/quotes';
import OONAssociates from 'services/rest/oon_associates';
import CasesRegions from 'services/rest/cases-regions';
import { PAGE_TITLE, RATE_CONFIRMED_BY, CaseRegionStatus, SERVICE_KEYS } from 'data/constants';
import { formatNumberWithSeparator, roundUp } from 'services/strings';
import { toENCADateString } from 'services/dates';
import { validateInstructionDeadline } from 'services/validators/quotes';

import useTitle from 'services/hooks/useTitle';

import ICON_CALENDAR from 'assets/images/icons/ico_calendar_azami.svg';
import ICON_EYE_VISIBLE from 'assets/images/icons/ico_eye_visible.svg';
import ICON_EYE_NOT_VISIBLE from 'assets/images/icons/ico_eye_not_visible.svg';
import ICON_INFO from 'assets/images/icons/ico_toast_info_violet.svg';
import ICON_PDF from 'assets/images/icons/ico_btn_pdf.svg';
import ICON_EXCEL from 'assets/images/icons/ico_btn_excel.svg';
import SWAP_ASSOCIATE from 'assets/images/icons/ico_swap_agent.svg';

import './style.css';

const QuoteDetailsViewQuoteEstimate = (props) => {
	useTitle(PAGE_TITLE.QUOTE_DETAILS);
	const { id } = useParams();
	const user = useSelector((state) => state.auth.user);
	const history = useHistory();

	const ipeerTableHeaders = [
		{ title: 'Region', field: 'regions', type: 'string', sort: 'none', sortable: true },
		{ title: 'Associate', field: 'associate', type: 'string', sort: 'none', sortable: true },
		{ title: 'Filing Fee', field: 'professional_fee', type: 'string', sort: 'none', sortable: true },
		{ title: 'Translation Fee', field: 'translation_fee', type: 'string', sort: 'none', sortable: true },
		{ title: 'Official Fee', field: 'official_fee', type: 'string', sort: 'none', sortable: true },
		{ title: 'Total', field: 'total_fees', type: 'string', sort: 'none', sortable: true },
		{ title: '', field: 'hide_region', type: 'string', sort: 'none', sortable: false },
	];

	const azamiTableHeaders = [
		{ title: 'Region', field: 'regions', type: 'string', sort: 'none', sortable: true },
		{ title: 'Professional Fee', field: 'professional_fee', type: 'string', sort: 'none', sortable: true },
		{ title: 'Translation Fee', field: 'translation_fee', type: 'string', sort: 'none', sortable: true },
		{ title: 'Official Fee', field: 'official_fee', type: 'string', sort: 'none', sortable: true },
		{ title: 'Total', field: 'total_fees', type: 'string', sort: 'none', sortable: true },
		{ title: '', field: 'hide_region', type: 'string', sort: 'none', sortable: false },
	];

	const modalTableHeadersInit = [
		{ title: 'Agent', field: 'associate', type: 'string', sort: 'none', sortable: false },
		{ title: 'Region', field: 'region', type: 'string', sort: 'none', sortable: false },
		{ title: 'Filing fee', field: 'filing_fee', type: 'string', sort: 'none', sortable: false },
		{ title: 'Translation fee', field: 'translation_fee', type: 'string', sort: 'none', sortable: false },
		{ title: 'Official fee', field: 'offical_fee', type: 'string', sort: 'none', sortable: false },
		{ title: 'total', field: 'total_fees', type: 'string', sortable: true, sort: 'up' },
		{ title: 'service', field: 'rating', type: 'string', sort: 'none', sortable: true },
		{ title: 'Price', field: 'price', type: 'string', sort: 'none', sortable: false },
		{ title: '', field: 'actions', type: 'string', sort: 'none', sortable: false },
	];

	const [estimates, setEstimate] = useState([]);
	const [priceTableData, setPriceTableData] = useState({});
	const [usedCurrency, setUsedCurrency] = useState('');
	const [currencies, setCurrencies] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	const [quoteData, setQuoteData] = useState('');
	const [regionAssociatesData, setRegionAssociatesData] = useState([]);
	const [showAllAssociatesModal, setShowAllAssociatesModal] = useState(false);
	const [newAssociates, setNewAssociates] = useState([]);
	const [associateModalData, setAssociateModalData] = useState([]);
	const [modalTableHeaders, setModalTableHeaders] = useState(modalTableHeadersInit);
	const [filterData, setFilterData] = useState({
		orderBy: [{ field: 'total_fees', direction: 'up' }],
	});
	const [instructionDeadlineModal, setInstructionDeadlineModal] = useState(false);
	const [showSaveAndInstructBtn, setShowSaveAndInstructBtn] = useState(false);
	const [caseRegions, setCaseRegions] = useState([]);

	useEffect(() => {
		(async () => {
			await loadData();
		})();
	}, []);

	const loadData = async () => {
		try {
			setShowLoader(true);
			let currencies = await Settings.GetCurrencies();
			setCurrencies(currencies);
			//This is hardcoded initial value on USD
			let c = currencies.find((c) => c.code === 'USD');
			let used_currency = c.id;
			if (id) {
				let [quoteData, est, regions, casesRegions] = await Promise.all([
					Quotes.GetOne(id),
					Estimates.GetLastPublishedEstimateByQuoteId(id),
					Settings.GetRegions(),
					CasesRegions.GetAllByCaseId(id),
				]);
				setCaseRegions(casesRegions?.data);
				if (quoteData) {
					if (quoteData.client_id) {
						let user = await Users.GetOneSlim(quoteData.client_id);
						let firm = await Firms.GetOneByIDSlim(user.firm_id);
						if (firm && firm.currency_id) {
							let currency = await Settings.GetExchangeRates();
							if (currency.some((c) => c.currency_id === firm.currency_id)) {
								used_currency = firm.currency_id;
								setUsedCurrency(firm.currency_id);
							} else {
								setUsedCurrency(used_currency);
							}
						} else {
							setUsedCurrency(used_currency);
						}
					}

					if (quoteData.exchange_rate_date) {
						if (quoteData.exchange_rate_date) {
							quoteData.exchange_rate_date = toENCADateString(quoteData.exchange_rate_date);
						}
					}
				}

				setQuoteData(quoteData);

				if (est) {
					let [overrides, estimate_rules] = await Promise.all([Estimates.getOverrideFees(est.id), Estimates.GetAllEstimateRules(est.id, { currency: used_currency })]);
					let pricings = pricingTransform(estimate_rules, overrides);
					if (est?.oon_associate) {
						let oon_pricing = await Estimates.GetOONForEstimate(est.id, { currency: used_currency });
						if (oon_pricing.length > 0) {
							oon_pricing.map((op) => {
								let status = '';
								if (casesRegions.data.length > 0) {
									let tmp = casesRegions.data.find((cr) => cr.region_id === op.region_id);
									status = tmp ? tmp.status : '';
								}
								pricings.push({
									firm_name: op.firm_name,
									firm_id: op.firm_id,
									region_id: op.region_id,
									complete: false,
									is_visible: op.is_visible,
									id: op.id,
									official_fee: roundUp(op.official_fee),
									professional_fee: roundUp(op.professional_fee),
									translation_fee: roundUp(op.translation_fee),
									region_name: op.region_name,
									total: roundUp(op.professional_fee) + roundUp(op.translation_fee) + roundUp(op.official_fee),
									professional_fee_override: false,
									official_fee_override: false,
									translation_fee_override: false,
									region_code: op.region_code,
									status: status,
									associate_changed: false,
								});
							});
						}
					}
					setPriceTableData(pricings);
					setEstimate(est);
					await fetchData(quoteData, regions);
				}
			}
			setShowLoader(false);
		} catch (err) {
			setShowLoader(false);
			console.log(err);
		}
	};

	const fetchData = async (qData, regions) => {
		let filter = {
			regions: qData.regions,
			service_id: qData.service_id,
		};
		let [res, mr] = await Promise.all([OONAssociates.QuoteAssociateRecommender(qData), Settings.GetAllMarketRatesByRegionsAndServiceID(filter)]);
		if (res) {
			let associates = Object.groupBy(res, ({ region_id }) => region_id);
			let data = qData.regions.map((r) => {
				let region = regions.find((rt) => rt.id === r);
				let associate = associates[region.id] || [];
				let highestTotalFee = null;
				associate = associate.map((a) => {
					if (a.firm_id !== '0') {
						if (a.rate_professional === 0) {
							a.rate_professional = mr.find((r) => r.region_id === region.id)?.rate_professional || 0;
							a.rate_confirmed_by = RATE_CONFIRMED_BY.MARKET;
						}
						if (a.rate_translation === 0) {
							a.rate_translation = mr.find((r) => r.region_id === region.id)?.rate_translation || 0;
							a.rate_confirmed_by = RATE_CONFIRMED_BY.MARKET;
						}
						a.total_fees = a.rate_professional + a.rate_translation + a.rate_official;
					}
					const totalFee = a.rate_professional + a.rate_translation;
					if (highestTotalFee === null || totalFee > highestTotalFee) {
						highestTotalFee = totalFee;
					}

					return {
						...a,
						total_fee: totalFee,
					};
				});
				return {
					region_id: region.id,
					region_name: region.name,
					region_code: region.code,
					associates: associate,
					highest_total_fee: highestTotalFee,
				};
			});
			setRegionAssociatesData(data);
		}
	};

	const sortAssociates = (data, field, direction) => {
		if (field === 'total_fees') {
			if (direction === 'up') {
				data = data.sort((a, b) => a.rate_professional + a.rate_translation - (b.rate_professional + b.rate_translation));
			}
			if (direction === 'down') {
				data = data.sort((a, b) => b.rate_professional + b.rate_translation - (a.rate_professional + a.rate_translation));
			}
		}
		if (field === 'rating') {
			if (direction === 'up') {
				data = data.sort((a, b) => a.rating - b.rating);
			}
			if (direction === 'down') {
				data = data.sort((a, b) => b.rating - a.rating);
			}
		}
		return data;
	};

	const columnSort = (col) => {
		setModalTableHeaders(
			modalTableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filterData.orderBy.filter((r) => r.field === col).length === 0) {
						setFilterData({
							...filterData,
							orderBy: [{ field: col, direction: h.sort }, ...filterData.orderBy],
						});
					} else {
						setFilterData({
							...filterData,
							orderBy: [{ field: col, direction: h.sort }, ...filterData.orderBy.filter((c) => c.field !== col)],
						});
					}
					if (regionAssociatesData.length > 0) {
						let tmp = regionAssociatesData.map((r) => {
							if (r.associates.length > 0) {
								return {
									...r,
									associate: sortAssociates(r.associates, col, h.sort),
								};
							} else {
								return r;
							}
						});
						setRegionAssociatesData(tmp);
					}
				}
				return h;
			}),
		);
	};

	const currencyIdToSymbol = (id) => {
		let cs = currencies.find((c) => c.id === id);
		return cs ? cs.symbol : '';
	};

	const pricingTransform = (data, overrides) => {
		let cout = {};
		for (let d of data) {
			if (!cout[d.region_code]) {
				cout[d.region_code] = {
					region_code: d.region_code,
					region_id: d.region_id,
					region_name: d.region_name,
					professional_fee: 0,
					translation_fee: 0,
					official_fee: 0,
					is_visible: d.is_visible,
					total: 0,
					complete: false, // TODI: THIS SHOULD BE REVISITED IN TH FITURE
				};
			}
			switch (d.fee_type) {
				case 'PROFESSIONAL':
					cout[d.region_code].professional_fee += Number(d.fee_value);
					break;
				case 'TRANSLATION':
					cout[d.region_code].translation_fee += Number(d.fee_value);
					break;
				case 'OFFICIAL':
					cout[d.region_code].official_fee += Number(d.fee_value);
					break;
			}
			cout[d.region_code].total += Number(d.fee_value);
		}

		for (let i in cout) {
			cout[i].professional_fee = roundUp(cout[i].professional_fee);
			cout[i].translation_fee = roundUp(cout[i].translation_fee);
			cout[i].official_fee = roundUp(cout[i].official_fee);
			cout[i].total = roundUp(cout[i].professional_fee + cout[i].translation_fee + cout[i].official_fee);
		}

		let out = [];
		for (let i in cout) {
			let region_pricing = calculateOverrides(cout[i], overrides);
			out.push(region_pricing);
		}
		return out;
	};

	const calculateOverrides = (region_prices, overrides) => {
		let override_region = overrides.find((r) => r.region_id === region_prices.region_id);
		if (override_region) {
			if (override_region.official_fee !== null) {
				region_prices.official_fee = override_region.official_fee;
				region_prices.official_fee_override = true;
			}
			if (override_region.professional_fee !== null) {
				region_prices.professional_fee = override_region.professional_fee;
				region_prices.professional_fee_override = true;
			}
			if (override_region.translation_fee !== null) {
				region_prices.translation_fee = override_region.translation_fee;
				region_prices.translation_fee_override = true;
			}
			region_prices.total = region_prices.translation_fee + region_prices.professional_fee + region_prices.official_fee;
		}
		return region_prices;
	};

	const calculateTotalVisible = (data) => {
		if (!data) return 0;
		let total = 0;
		for (let i in data) {
			if (!data[i].is_visible) continue;
			total += data[i].total;
		}
		return total;
	};

	const calculateGrandTotal = (data) => {
		if (!data) return 0;
		let total = 0;
		for (let i in data) {
			total += data[i].total;
		}
		return total;
	};

	const toggleRegionVisibility = (region_id) => {
		if (priceTableData.length > 0) {
			let data = priceTableData.map((d) => {
				if (d.region_id === region_id) {
					d.is_visible = !d.is_visible;
				}
				return d;
			});
			setPriceTableData(data);
		}
	};

	const printPdfReport = async (e) => {
		e.preventDefault();
		const eid = e.target.closest('div').dataset.id;
		try {
			await Reports.DownloadEstimateReport(eid, id, {});
		} catch (error) {
			console.log(error);
		}
	};

	const printExcelReport = async (e) => {
		try {
			await Reports.DownloadXlsxEstimateReport(estimates.id, id);
		} catch (error) {
			console.log('error', error);
		}
	};

	const saveHandler = async (e) => {
		if (!user.role.includes('saas_user')) return;
		setShowLoader(true);
		try {
			let last_overrides_fees = [];
			let lastEstimateAppliedRules = [];
			if (estimates) {
				last_overrides_fees = await Estimates.getOverrideFees(estimates.id);
				lastEstimateAppliedRules = await Estimates.GetAllEstimateRules(estimates.id, { currency: usedCurrency });
			}
			let lastAppliedRules = [];
			if (lastEstimateAppliedRules.length > 0) {
				lastAppliedRules = lastEstimateAppliedRules.map((r) => {
					return {
						id: r.rule_id,
						region_id: r.region_id,
					};
				});
			}
			let additional_region_info = [];
			if (estimates.oon_associate) {
				let oon_pricing = await Estimates.GetOONForEstimate(estimates.id);
				if (oon_pricing.length > 0) {
					oon_pricing.forEach((op) => {
						additional_region_info.push({
							firm_id: op.firm_id,
							region_id: op.region_id,
							associate_id: op.associate_id,
							rate_official: op.official_fee,
							rate_professional: op.professional_fee,
							rate_translation: op.translation_fee,
							is_visible: priceTableData.find((ptd) => ptd.id === op.id).is_visible,
						});
					});
				}
			}
			let new_overrides = [];
			if (last_overrides_fees.length > 0) {
				last_overrides_fees = last_overrides_fees.filter((o) => {
					return quoteData.regions.some((r) => {
						return r === o.region_id;
					});
				});
				new_overrides = last_overrides_fees.map((o) => {
					return {
						region_id: o.region_id,
						professional_fee: o.professional_fee,
						official_fee: o.official_fee,
						translation_fee: o.translation_fee,
					};
				});
				new_overrides = quoteData.regions.map((o) => {
					let region = new_overrides.find((r) => r.region_id === o);
					if (region) {
						return region;
					} else {
						return {
							region_id: o,
							professional_fee: null,
							official_fee: null,
							translation_fee: null,
						};
					}
				});
			}

			if (newAssociates.length > 0) {
				newAssociates.forEach((na) => {
					additional_region_info = additional_region_info.filter((ari) => ari.region_id !== na.region_id);
					additional_region_info.push(na);
				});
			}
			let ne = await Estimates.CreateEstimate({
				quote: { ...quoteData, additional_region_info },
				overridesFees: new_overrides,
			});
			if (ne) {
				await loadData();
			}
			setShowSaveAndInstructBtn(false);

			// UPDATING CASE REGIONS ASSOCICATE WHEN NEW ASSOCIATE GOT SELECTED
			for (let na of newAssociates) {
				let caseRegion = caseRegions.find((cr) => cr.region_id === na.region_id);
				await CasesRegions.Update(id, caseRegion.id, { associate_id: na.associate_id, case_id: id, region_id: caseRegion.region_id, status: caseRegion.status, _deleted: 0 });
			}
			setShowLoader(false);
		} catch (err) {
			console.log(err);
			setShowLoader(false);
		} finally {
			setNewAssociates([]);
		}
	};

	const selectAssociate = (region_id, firm_id) => {
		let region = regionAssociatesData.find((r) => r.region_id === region_id);
		let associate = region.associates.find((a) => a.firm_id === firm_id);
		let tmp_associates = [];
		if (newAssociates.length > 0) {
			tmp_associates = newAssociates.filter((a) => a.region_id !== region_id);
			tmp_associates.push({
				firm_id: firm_id,
				region_id: associate.region_id,
				associate_id: associate.associate_id,
				rate_official: associate.rate_official,
				rate_professional: associate.rate_professional,
				rate_translation: associate.rate_translation,
				is_visible: priceTableData.find((p) => p.region_id === region_id).is_visible,
			});
		} else {
			tmp_associates.push({
				firm_id: firm_id,
				region_id: associate.region_id,
				associate_id: associate.associate_id,
				rate_official: associate.rate_official,
				rate_professional: associate.rate_professional,
				rate_translation: associate.rate_translation,
				is_visible: priceTableData.find((p) => p.region_id === region_id).is_visible,
			});
		}
		setPriceTableData((prevState) =>
			prevState.map((ptd) => {
				if (ptd.region_id === region_id) {
					return {
						...ptd,
						associate_changed: true,
						professional_fee: associate.rate_professional,
						translation_fee: associate.rate_translation,
						official_fee: associate.rate_official,
						total: associate.rate_translation + associate.rate_professional + associate.rate_official,
						firm_name: associate.firm_name,
					};
				}
				return ptd;
			}),
		);
		setNewAssociates(tmp_associates);
	};

	const handleSelectAssociateClick = (e) => {
		let region_id = e.target.closest('tr').dataset.region_id;
		let rData = regionAssociatesData.find((s) => s.region_id === region_id);
		let associates = rData.associates?.map((a) => {
			return { ...a, highest_total_fee: rData?.highest_total_fee };
		});
		let applicableAssociates = associates.filter((f) => f.firm_id !== e.target.closest('tr').dataset.firm_id);
		setAssociateModalData(applicableAssociates);
		setShowAllAssociatesModal(true);
	};

	const showAllAssociatesModalClose = () => {
		setShowAllAssociatesModal(false);
	};

	const showAllAssociatesActions = [{ primary: true, label: 'Cancel', action: showAllAssociatesModalClose, theme: 'primary' }];

	const handleInstructAction = async () => {
		let services = await Settings.GetServices();
		let invalidDeadline = false;
		if (quoteData.due_date) {
			invalidDeadline = validateInstructionDeadline(new Date(quoteData.due_date), quoteData.service_id, services, quoteData.priority_type);
		}
		if (invalidDeadline) return setInstructionDeadlineModal(true);
		history.push(`/quotes/${id}/estimate/instruct`);
	};

	const deadlineModalCloseHandler = () => {
		setInstructionDeadlineModal(false);
	};

	const saveAndInstruct = async () => {
		await saveHandler();
		setShowSaveAndInstructBtn(false);
		history.push(`/quotes/${id}/estimate/instruct`);
	};

	const handleCancel = async () => {
		await loadData();
		if (showSaveAndInstructBtn) setShowSaveAndInstructBtn(false);
	};

	const deadlineModalFooterActions = [{ primary: true, label: 'Cancel', action: deadlineModalCloseHandler, theme: 'primary' }];
	return (
		<div className="quote-details-view-revisions single-quote-external">
			<LoaderOverlay showLoader={showLoader} />
			<div className="quote-details-view-exteral-estimate__body">
				<div className="quote-details-view-revisions__collapsible-body">
					{priceTableData.length > 0 ?
						<>
							<div className="quote-details-view-exteral-estimate__header">
								<div className="quote-details-view-revisions__header-date">
									<img src={ICON_CALENDAR} alt="calendar" />
									<AppDate>{estimates._created}</AppDate>
								</div>
								<div className="quote-details-view-revisions__header-exports-container" data-id={estimates.id}>
									{instructionDeadlineModal ?
										<InstructionDeadlineModal
											designService={quoteData.service_key === SERVICE_KEYS.DESIGN ? true : false}
											footerActions={deadlineModalFooterActions}
											closeHandler={deadlineModalCloseHandler}
										/>
									:	null}
									<button title="Export to PDF File" className="quote-details-view-revisions__header-exports" onClick={printPdfReport}>
										<img src={ICON_PDF} alt="pdf" />
									</button>
									<button title="Export to Excel File" className="quote-details-view-revisions__header-exports" onClick={printExcelReport}>
										<img src={ICON_EXCEL} alt="excel" />
									</button>
								</div>
							</div>
							<DataTable columns={user.role.includes('saas_user') ? ipeerTableHeaders : azamiTableHeaders}>
								{priceTableData.map((pd, i) => {
									return (
										<tr className={pd.is_visible ? '' : 'grayed-out'} data-region_id={pd.region_id} data-estimate_id={estimates.id} data-firm_id={pd.firm_id} key={i}>
											<DataTable.CountryCell code={pd.region_code}>{pd.region_name}</DataTable.CountryCell>
											<RoleBox roles={['saas_user']}>
												<DataTable.Cell
													customClassName={`${pd.associate_changed ? 'quote-details-view-revisions-associate-changed' : ''}`}
												>{`${pd.firm_name ? pd.firm_name : 'Azami'}`}</DataTable.Cell>
											</RoleBox>
											<DataTable.Cell>{`${currencyIdToSymbol(usedCurrency)} ${formatNumberWithSeparator(pd.professional_fee, 0, 0)}`}</DataTable.Cell>
											<DataTable.Cell>{`${currencyIdToSymbol(usedCurrency)} ${formatNumberWithSeparator(pd.translation_fee, 0, 0)}`}</DataTable.Cell>
											<DataTable.Cell customClassName="quote-details-view-revisions__official-fee">
												{`${currencyIdToSymbol(usedCurrency)} ${formatNumberWithSeparator(pd.official_fee, 0, 0)}`}
												<RoleBox roles={['user', 'member', 'member_assistant']}>
													<a href={`https://pctlegal.wipo.int/eGuide/view-doc.xhtml?doc-code=${pd.region_code}&doc-lang=en`} target="_blank">
														Official Fees
													</a>
												</RoleBox>
											</DataTable.Cell>
											<DataTable.Cell>{`${currencyIdToSymbol(usedCurrency)} ${formatNumberWithSeparator(pd.total, 0, 0)}`}</DataTable.Cell>
											<DataTable.Cell customClassName="quote-details-view-revisions__toggle__region-wrapper">
												<RoleBox roles={['saas_user']}>
													{pd.status === CaseRegionStatus.UNINSTRUCTED ?
														<div className="quote-details-view-revisions__toggle__region" onClick={handleSelectAssociateClick}>
															<span className="grayed-out__region__toggle__btn">
																<Tooltip icon={SWAP_ASSOCIATE} content={<span>{'Select Agents'}</span>} />
															</span>
														</div>
													:	null}
												</RoleBox>
												<div className="quote-details-view-revisions__toggle__region">
													<button className="grayed-out__region__toggle__btn" onClick={(e) => toggleRegionVisibility(pd.region_id)} aria-label="toggle region">
														<Tooltip
															icon={pd.is_visible ? ICON_EYE_VISIBLE : ICON_EYE_NOT_VISIBLE}
															content={<span>{pd.is_visible ? 'Hides region on PDF/Excel' : 'Unhides region on PDF/Excel'}</span>}
														/>
													</button>
												</div>
											</DataTable.Cell>
										</tr>
									);
								})}

								<tr>
									<DataTable.Cell>{'Total'}</DataTable.Cell>
									<RoleBox roles={['saas_user']}>
										<DataTable.Cell></DataTable.Cell>
									</RoleBox>
									<DataTable.Cell></DataTable.Cell>
									<DataTable.Cell></DataTable.Cell>
									<DataTable.Cell></DataTable.Cell>
									<DataTable.Cell>{`${currencyIdToSymbol(usedCurrency)} ${formatNumberWithSeparator(calculateGrandTotal(priceTableData), 0, 0)}`}</DataTable.Cell>
									<DataTable.Cell></DataTable.Cell>
								</tr>
								<tr>
									<DataTable.Cell>{'Total Visible'}</DataTable.Cell>
									<RoleBox roles={['saas_user']}>
										<DataTable.Cell></DataTable.Cell>
									</RoleBox>
									<DataTable.Cell></DataTable.Cell>
									<DataTable.Cell></DataTable.Cell>
									<DataTable.Cell></DataTable.Cell>
									<DataTable.Cell>{`${currencyIdToSymbol(usedCurrency)} ${formatNumberWithSeparator(calculateTotalVisible(priceTableData), 0, 0)}`}</DataTable.Cell>
									<DataTable.Cell></DataTable.Cell>
								</tr>
							</DataTable>
						</>
					:	<div className="quote-details-view-revisions__qute-info-container quote-details-view-revisions__qute-info">
							<div className="quote-info-image-container">
								<img src={ICON_INFO} alt="info" />
							</div>
							Estimate is pending. Our operations team is generating an estimate which will be sent to you shortly.
						</div>
					}
				</div>
			</div>
			<footer className="main-screen-section__details-content__footer quote-footer-a">
				<Button onClick={handleCancel} theme="ghost-primary" customClassName="quote-details__footer__btn-margin">
					Cancel
				</Button>
				<Button customClassName="quote-details__footer__btn-margin" onClick={saveHandler}>
					Save Changes
				</Button>
				<Button className="quote-details__footer__btn-margin" onClick={handleInstructAction}>
					Instruct
				</Button>
				{showSaveAndInstructBtn ?
					<Button onClick={saveAndInstruct}>Save &amp; Instruct</Button>
				:	null}
			</footer>
			{showAllAssociatesModal ?
				<QuoteAllAssociatesModal
					footerActions={showAllAssociatesActions}
					closeHandler={showAllAssociatesModalClose}
					displayedAssociates={associateModalData}
					selectAssociate={selectAssociate}
					data={regionAssociatesData}
					setData={setRegionAssociatesData}
					tableHeaders={modalTableHeaders}
					columnSort={columnSort}
					fromPlayGround={false}
					currencySymbol={currencyIdToSymbol(usedCurrency)}
					setShowSaveAndInstructBtn={setShowSaveAndInstructBtn}
				/>
			:	null}
		</div>
	);
};

export default QuoteDetailsViewQuoteEstimate;
