import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
// icons
import ICON_INFO from '../../assets/images/icons/ico_toast_info.svg';
import ICON_ERROR from '../../assets/images/icons/ico_toast_error.svg';
import ICON_WARNING from '../../assets/images/icons/ico_toast_warning.svg';
import ICON_SUCCESS from '../../assets/images/icons/ico_toast_success.svg';
// style
import './style.css';
export var ToastTypes;
(function (ToastTypes) {
    ToastTypes["INFO"] = "info";
    ToastTypes["ERROR"] = "error";
    ToastTypes["WARNING"] = "warning";
    ToastTypes["SUCCESS"] = "success";
})(ToastTypes || (ToastTypes = {}));
const toastIcons = {
    info: ICON_INFO,
    error: ICON_ERROR,
    warning: ICON_WARNING,
    success: ICON_SUCCESS,
};
export const Toast = ({ id = '0', type = ToastTypes.INFO, timeout = 4000, value = '', toastActions = [], removeToast = () => { } }) => {
    const [unmount, setUnmount] = useState('');
    useEffect(() => {
        setTimeout(() => {
            setUnmount('unmount');
            setTimeout(() => {
                removeToastNotification(id);
            }, 300);
        }, timeout);
    }, []);
    const removeToastNotification = (id) => {
        removeToast(id);
    };
    return (_jsxs("div", { className: `toast-notification ${type} ${unmount}`, children: [_jsx("img", { className: "icon", src: toastIcons[type], alt: "toast icon" }), _jsx("div", { className: "content", children: value }), toastActions.length > 0 ? (_jsx("div", { className: "actions", children: toastActions.map((t, i) => {
                    return (_jsx("button", { onClick: t.action, children: t.label }, i));
                }) })) : null, _jsx("button", { className: "remove", onClick: () => removeToast(id), children: "\u00D7" })] }));
};
