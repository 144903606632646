import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'sazzui/lib/main';

import './style.css';

const negatePoints = (value) => {
	return !isNaN(value) && Number(value) > 0 ? Number(value) * -1 : Number(value);
};

const ReciprocityPointsTable = (props) => {
	return (
		<table className="reciprocity-system-table">
			{props.services.map((s) => {
				return (
					<colgroup key={s.id}>
						<col width={`${100 / props.services.length}%`} />
					</colgroup>
				);
			})}
			<thead>
				<tr>
					{props.services.map((s, i) => {
						return <th key={s.id}>{s.name}</th>;
					})}
				</tr>
			</thead>
			<tbody>
				<tr>
					{props.services.map((s, i) => {
						let servicePoints;
						if (props.modal === true) {
							servicePoints = props.servicePointsForRegion.find((p) => p.service_id === s.id);
						} else {
							servicePoints = props.region.points.find((p) => p.service_id === s.id);
						}
						return (
							<td className="reciprocity-system-table__ratio" key={s.id}>
								{servicePoints ?
									<>
										{Math.abs(servicePoints.base_points / servicePoints.base_points) || ''}
										<span>:</span>
										{!servicePoints.reward_points || !servicePoints.base_points ?
											''
										: Number.isInteger(servicePoints.reward_points / servicePoints.base_points) ?
											Math.abs(servicePoints.reward_points / servicePoints.base_points)
										:	Math.abs(servicePoints.reward_points / servicePoints.base_points).toFixed(2)}
									</>
								:	<>
										<span>:</span>
									</>
								}
							</td>
						);
					})}
				</tr>
				<tr>
					<td colSpan={props.services.length} className="reciprocity-system-table__base-points-title">
						<span>base points</span>
						<span>&#40;associate loses&#41;</span>
					</td>
				</tr>
				<PointsRow
					services={props.services}
					basePointsRow={true}
					region={props.region}
					modal={props.modal}
					selectedRegionGroup={props.selectedRegionGroup}
					servicePointsForRegion={props.servicePointsForRegion}
					setServicePointsForRegion={props.setServicePointsForRegion}
					updatePoints={props.updatePoints}
					setRegionsWithPoints={props.setRegionsWithPoints}
				/>
				<tr>
					<td colSpan={props.services.length} className="reciprocity-system-table__reward-points-title">
						<span>reward points</span>
						<span>&#40;client gets&#41;</span>
					</td>
				</tr>
				<PointsRow
					services={props.services}
					basePointsRow={false}
					region={props.region}
					modal={props.modal}
					selectedRegionGroup={props.selectedRegionGroup}
					servicePointsForRegion={props.servicePointsForRegion}
					setServicePointsForRegion={props.setServicePointsForRegion}
					updatePoints={props.updatePoints}
					setRegionsWithPoints={props.setRegionsWithPoints}
				/>
			</tbody>
		</table>
	);
};

const PointsRow = (props) => {
	const regionPointsUpdate = (e, service) => {
		let newPoints = {};
		let regionPointsForService = props.region.points.find((p) => p.service_id === service.id);

		switch (props.basePointsRow) {
			case true:
				if (regionPointsForService) {
					newPoints = { ...regionPointsForService, base_points: negatePoints(e.target.value) };
					props.region.points = props.region.points.map((p) => {
						if (p.service_id === newPoints.service_id) {
							p = { ...p, ...newPoints, modified: true };
						}
						return p;
					});
				} else {
					newPoints = {
						object_type: 'SYSTEM',
						service_id: service.id,
						region_id: props.region.id,
						base_points: negatePoints(e.target.value),
						override: 0,
						modified: true,
					};
					props.region.points = [...props.region.points, newPoints];
				}
				break;

			case false:
				if (regionPointsForService) {
					newPoints = { ...regionPointsForService, reward_points: Number(e.target.value) };
					props.region.points = props.region.points.map((p) => {
						if (p.service_id === newPoints.service_id) {
							p = { ...p, ...newPoints, modified: true };
						}
						return p;
					});
				} else {
					newPoints = {
						object_type: 'SYSTEM',
						service_id: service.id,
						region_id: props.region.id,
						reward_points: Number(e.target.value),
						override: 0,
						modified: true,
					};
					props.region.points = [...props.region.points, newPoints];
				}
			default:
				break;
		}

		props.updatePoints(props.region);
	};

	const regionsGroupPointsUpdate = (e, s) => {
		let service = props.servicePointsForRegion.find((sr) => sr.service_id === s.id);
		let servicePoints = {
			service_id: s.id,
			base_points: '',
			reward_points: '',
		};
		if (service) {
			if (props.basePointsRow) {
				servicePoints = {
					...servicePoints,
					base_points: negatePoints(e.target.value),
					reward_points: service.reward_points,
				};
			} else {
				servicePoints = {
					...servicePoints,
					reward_points: Number(e.target.value),
					base_points: service.base_points,
				};
			}

			props.setServicePointsForRegion((prevState) => {
				return prevState.map((s) => {
					if (s.service_id === service.service_id) {
						s = servicePoints;
					}
					return s;
				});
			});
		}

		if (!service) {
			if (props.basePointsRow) {
				servicePoints = { ...servicePoints, base_points: negatePoints(e.target.value) };
			} else {
				servicePoints = { ...servicePoints, reward_points: Number(e.target.value) };
			}
			props.setServicePointsForRegion([...props.servicePointsForRegion, servicePoints]);
		}
	};

	return (
		<tr>
			{props.services.map((s) => {
				const points = props.region.points.find((p) => s.id === p.service_id);
				return (
					<td align="center" key={s.id}>
						{props.modal === false ?
							<Input
								type="number"
								value={
									props.basePointsRow && points ? `${points.base_points}`
									: !props.basePointsRow && points ?
										`${points.reward_points}`
									:	''
								}
								customClassName={props.basePointsRow ? 'reciprocity-system-table__base-points' : 'reciprocity-system-table__reward-points'}
								onChange={(e) => regionPointsUpdate(e, s)}
								ariaLabel="Points Input"
							/>
						:	<Input
								type="number"
								defaultValue=""
								customClassName={props.basePointsRow ? 'reciprocity-system-table__base-points' : 'reciprocity-system-table__reward-points'}
								onChange={(e) => {
									regionsGroupPointsUpdate(e, s);
								}}
								ariaLabel="Points Input"
							/>
						}
					</td>
				);
			})}
		</tr>
	);
};

ReciprocityPointsTable.propTypes = {
	region: PropTypes.object,
	services: PropTypes.array,
	modal: PropTypes.bool,
	selectedRegionGroup: PropTypes.array,
	servicePointsForRegion: PropTypes.array,
	setServicePointsForRegion: PropTypes.func,
	updatePoints: PropTypes.func,
	setRegionsWithPoints: PropTypes.func,
	points: PropTypes.array,
};

ReciprocityPointsTable.defaultProps = {
	region: { points: [] },
	services: [],
	modal: false,
	selectedRegionGroup: [],
	servicePointsForRegion: [],
	setServicePointsForRegion: () => {},
	updatePoints: () => {},
	setRegionsWithPoints: () => {},
	points: [],
};

PointsRow.propTypes = {
	services: PropTypes.array,
	basePointsRow: PropTypes.bool,
	region: PropTypes.object,
	modal: PropTypes.bool,
	selectedRegionGroup: PropTypes.array,
	servicePointsForRegion: PropTypes.array,
	setServicePointsForRegion: PropTypes.func,
	updatePoints: PropTypes.func,
	setRegionsWithPoints: PropTypes.func,
};

PointsRow.defaultProps = {
	services: [],
	basePointsRow: false,
	region: { points: [] },
	modal: false,
	selectedRegionGroup: [],
	servicePointsForRegion: [],
	setServicePointsForRegion: () => {},
	updatePoints: () => {},
	setRegionsWithPoints: () => {},
};

export default ReciprocityPointsTable;
