import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// sazzui
import { Input, Select, Checkbox, Modal, Loader } from 'sazzui/lib/main';
// services
import Firms from 'services/rest/firms';
import Users from 'services/rest/users';
import { random, validateEmailAddress } from 'services/strings';
import FirmAgents from 'services/rest/firm_agents';
import useNotification from 'services/hooks/use_notification';
import EventLoggerEvents from 'services/rest/event_logger';
import useDebounce from 'services/hooks/useDebounce';
import Settings from 'services/rest/settings';
// data
import { FIRM_TYPE, DEBOUNCE_DELAY_MS, USER_ROLES, AGENT_ASSOCIATIONS_LOCAL_STORAGE_KEY, ALLOWED_DUPLICATE_DOMAINS } from 'data/constants';
// util
import { getError } from 'util/errors';

import './style.css';

const CreateAgentModal = (props) => {
	const errorsInit = {
		first_name: '',
		last_name: '',
		email: '',
		phone: '',
		country: '',
		name: '',
		address: '',
		city: '',
		state: '',
		zipcode: '',
		firm_country: '',
	};

	const userDataInit = {
		id: '',
		first_name: '',
		last_name: '',
		email: '',
		phone: '',
		country: '',
		firm_code: '',
		firm_account_id: '',
		is_block_emails: 0,
	};

	const firmDataInit = {
		id: '',
		name: '',
		address: '',
		address2: '',
		city: '',
		country: props.regionCode && props.regionForNewAgentIsCountry ? props.regionCode : '',
		state: '',
		zipcode: '',
		code: '',
		_deleted: 0,
		rates: [],
	};

	const errMsg = {
		required: 'Required',
		email: 'Invalid email',
	};

	const AGENT_EXISTS_MESSAGE = 'An agent already exists';
	const CANT_ADD_YOUR_OWN_FIRM_MESSAGE = 'You can`t add your own firm as an agent';

	const history = useHistory();
	const sendNotification = useNotification();
	const user = useSelector((state) => state.auth.user);
	const dispatch = useDispatch();
	const [firmData, setFirmData] = useState({ ...firmDataInit });
	const [userData, setUserData] = useState(userDataInit);
	const [disabled, setDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState(errorsInit);
	const [agents, setAgents] = useState([]);
	const [requestSpinner, setRequestSpinner] = useState(false);
	const [disabledUser, setDisabledUser] = useState(false);
	const [userRoles, setUserRoles] = useState([]);
	const [createAgentBtnDisabled, setCreateAgentBtnDisabled] = useState(false);

	const searchEmailDebouncedValue = useDebounce(userData.email, DEBOUNCE_DELAY_MS);
	const loggedInUserEmailDomain = user.email.split('@')[1];

	useEffect(() => {
		(async () => {
			const r = await Settings.GetUserRoles();
			setUserRoles(r);
			if (props.agents?.length > 0) {
				setAgents(props.agents);
			} else {
				await fetchAgents();
			}
		})();
	}, []);

	//get the agents for this region/client combo
	const fetchAgents = async () => {
		try {
			const agents = await FirmAgents.GetAllByClientID(props.clientFirmId, { region: props.regionId });
			setAgents(agents);
		} catch (error) {
			console.log('error', error);
		}
	};

	const onChangeUserData = (e) => {
		let { name, value } = e.target;

		if (name === 'is_block_emails') {
			value = !userData[name];
		}
		setUserData({ ...userData, [name]: value });
	};

	const onChangeFirmData = (e) => {
		setFirmData({ ...firmData, [e.target.name]: e.target.value });
	};

	const runSearchEmail = async (e) => {
		try {
			setRequestSpinner(true);
			let domain = userData.email.split('@')[1];

			// Checking if the firm is adding itself as an agent
			if (domain === loggedInUserEmailDomain && (!ALLOWED_DUPLICATE_DOMAINS.includes(loggedInUserEmailDomain.split('.')[0]) || user.email === userData.email)) {
				setRequestSpinner(false);
				setCreateAgentBtnDisabled(true);
				sendNotification({ type: 'error', title: CANT_ADD_YOUR_OWN_FIRM_MESSAGE });
				setIsLoading(false);
				return;
			}

			setCreateAgentBtnDisabled(false);

			let users = await Users.GetAllRaw({ email: userData.email });
			let u = users && users.find((u) => u.email === userData.email);
			if (u) {
				let f = await Firms.GetOneByIDSlim(u.firm_id);
				setUserData(u);
				setFirmData(f);
				setDisabledUser(true);
				setDisabled(true);
				setRequestSpinner(false);
			} else {
				setUserData({
					...userDataInit,
					email: userData.email,
				});
				let f = await Firms.GetOneByDomainName(domain);
				setDisabled(true);
				setDisabledUser(false);
				setRequestSpinner(false);
				setFirmData(f);
			}
		} catch (error) {
			if (error === 'Not Found') {
				setDisabled(false);
				setFirmData({ ...firmDataInit });
				setRequestSpinner(false);
				setDisabledUser(false);
			}
			console.log('error', error);
		}
	};

	const validate = () => {
		let error = false;
		setErrors(errorsInit);

		if (!userData.first_name) {
			error = true;
			setErrors((prevState) => ({ ...prevState, first_name: errMsg.required }));
		}
		if (!userData.last_name) {
			error = true;
			setErrors((prevState) => ({ ...prevState, last_name: errMsg.required }));
		}
		if (!userData.email) {
			error = true;
			setErrors((prevState) => ({ ...prevState, email: errMsg.required }));
		} else if (!validateEmailAddress(userData.email)) {
			error = true;
			setErrors((prevState) => ({ ...prevState, email: errMsg.email }));
		}
		if (!userData.country) {
			error = true;
			setErrors((prevState) => ({ ...prevState, country: errMsg.required }));
		}
		if (!firmData.name) {
			error = true;
			setErrors((prevState) => ({ ...prevState, name: errMsg.required }));
		}
		if (!firmData.country) {
			error = true;
			setErrors((prevState) => ({ ...prevState, firm_country: errMsg.required }));
		}

		return error;
	};

	const onClickCreateAgent = async () => {
		try {
			setIsLoading(true);
			let firm = firmData;
			let payload = {
				associate_id: '',
				client_id: props.clientFirmId,
				default_associate_user: '',
				is_block_emails: userData.is_block_emails,
			};
			let agent_uid;
			let saasRole = userRoles.find((r) => r.name === USER_ROLES.SAAS_USER);
			// Checking if the agent already exists. If the agent exists, we are returning the function.
			let exists = agents.find((a) => a.associate_id === firmData.id);
			if (firmData.id && exists) {
				sendNotification({ type: 'error', title: AGENT_EXISTS_MESSAGE });
				setIsLoading(false);
				return;
			}
			// create firm if no firm exist
			if (!firmData.id || !userData.id) {
				if (validate()) {
					setIsLoading(false);
					return;
				}
				if (!firmData.code) {
					firm = await Firms.Create({
						...firmData,
						type: FIRM_TYPE.BOTH,
						isAgentFirm: true,
						is_active: props.activeFirm ? 1 : 0,
						email: userData.email,
					});
					// add region to the new firm
					let region = props.countries.find((c) => c.value === firmData.country);
					if (!props.regionForNewAgentIsCountry && props.fromPlayground) {
						region = props.countries.find((c) => c.value === props.regionCode);
					}
					let regionPayload = {
						firm_id: firm.id,
						is_archived: 0,
						region_code: region.value,
						region_id: region.id,
						region_name: region.label,
					};
					await Firms.UpdateRegions(firm.id, [regionPayload]);
				}
				// create new user
				let u = await Users.Create({
					...userData,
					firm_code: firm.code,
					firm_account_id: firm.default_account_id,
					role_id: saasRole.id,
					password: random(22), // autogenerated
				});
				payload = {
					...payload,
					associate_id: firm.id,
					default_associate_user: u.id,
				};
				agent_uid = u.id;
			}
			if (userData.id) {
				payload = {
					...payload,
					associate_id: firm.id,
					default_associate_user: userData.id,
				};
				agent_uid = userData.id;
			}

			// create empty available rate for the client/agent region
			// BulkCreateRates expects an array of objects, so we need to wrap the object in an array, even if it's just one object.
			if (props.createRegionRate) {
				await Firms.BulkCreateRates([
					{
						object_type: 'FIRM',
						object_id: firm.id,
						region_id: props.regionId,
						service_id: props.serviceId,
						rate_professional: 0,
						rate_translation_type: 'WORD',
						rate_translation: 0,
						currency: 'USD',
						available: 1,
						override: 0,
						rate_confirmed: false,
						rate_confirmed_by: '',
						client_id: props.clientFirmId,
						associate_type: 'OON',
					},
				]);
			}
			// Create all rates for firm (if its already existing firm might have more regions)
			if (props.createAllRates) {
				let regions = await Firms.GetRegions(firm.id);
				if (regions.length > 0) {
					let newRates = [];
					for (let r of regions) {
						if (r.is_archived) continue;
						for (let s of props.services) {
							if (s.id) {
								newRates.push({
									object_id: firm.id,
									region_id: r.region_id,
									service_id: s.id,
									available: true,
									rate_professional: 0,
									rate_translation_type: 'WORD',
									rate_translation: 0,
									currency: 'USD',
									object_type: 'FIRM',
									override: false,
									rate_confirmed: false,
									rate_confirmed_by: '',
									client_id: props.clientFirmId,
									associate_type: 'OON',
								});
							}
						}
					}
					await Firms.BulkCreateRates(newRates);
				}
			}

			try {
				await FirmAgents.checkAgentFirmByAssociateIDExists(firm.id);
				// link firm agent with the client firm
				await FirmAgents.LinkAgent(payload);
				await Users.NotifyExistingAgentAdded(agent_uid, payload);
			} catch (error) {
				if (error.code === 'AGENT_RELATIONSHIPS_NOT_FOUND') {
					// link firm agent with the client firm
					await FirmAgents.LinkAgent(payload);
					await Users.NewAgentUserWelcome(agent_uid, payload);
				}
			}

			EventLoggerEvents.EventLogger({ event: 'Add new OON Associate from Firm Profile: Agents tab', data: { user: user } });
			props.closeHandler();
			if (props.refreshData) {
				await props.refreshData();
			}
			props.setPreselectAgent({
				regionId: props.regionId,
				firmId: firm.id,
			});
			let agentAssociations = await FirmAgents.GetAssociations(user.fid);
			localStorage.setItem(AGENT_ASSOCIATIONS_LOCAL_STORAGE_KEY, JSON.stringify({ agentOfFirms: agentAssociations.firm_clients, clientOfFirms: agentAssociations.firm_agents }));
			setIsLoading(false);
			if (props.redirect) {
				history.push(`/firm-management/${firm.id}/rates`);
			}
		} catch (error) {
			setIsLoading(false);
			console.log('error', error);
			sendNotification({ type: 'error', title: getError(error.code) });
		}
	};

	const createAgentFooterActions = [
		{ primary: false, label: 'Cancel', action: props.closeHandler },
		{ primary: true, label: 'Create Agent', action: onClickCreateAgent, showSpinner: isLoading, disabled: createAgentBtnDisabled },
	];

	const onChangeEmailAddress = (e) => {
		setUserData({
			...userData,
			email: e.target.value,
		});
	};

	useEffect(async () => {
		if (validateEmailAddress(searchEmailDebouncedValue)) {
			setDisabledUser(true);
			setDisabled(true);
			await runSearchEmail();
		} else {
			setFirmData(firmDataInit);
			setDisabled(false);
			setDisabledUser(false);
		}
	}, [searchEmailDebouncedValue]);

	return (
		<Modal title="Create Agent" closeHandler={props.closeHandler} footerActions={createAgentFooterActions}>
			<div className="create-agent__modal">
				<div className="create-agent__modal-body">
					<div className="create-agent__modal-body__user">
						<div className="create-agent__modal__input-group">
							<Input required={true} label="Email" name="email" value={userData.email} onChange={onChangeEmailAddress} error={errors.email} ariaLabel="Email Input" theme="primary" />
							{requestSpinner && <Loader theme="darkblue" customClassName="create-agent__modal__input-group__spinner" />}
						</div>
						<div className="create-agent__modal__input-group">
							<Input
								disabled={disabledUser}
								theme={disabledUser ? 'dark' : 'primary'}
								required={true}
								label="first name"
								name="first_name"
								value={userData.first_name}
								onChange={onChangeUserData}
								error={errors.first_name}
								ariaLabel="First Name Input"
							/>
							<Input
								disabled={disabledUser}
								theme={disabledUser ? 'dark' : 'primary'}
								required={true}
								label="last name"
								name="last_name"
								value={userData.last_name}
								onChange={onChangeUserData}
								error={errors.last_name}
								ariaLabel="Last Name Input"
							/>
						</div>
						<div className="create-agent__modal__input-group">
							<Input
								disabled={disabledUser}
								theme={disabledUser ? 'dark' : 'primary'}
								label="Phone"
								name="phone"
								value={userData.phone}
								onChange={onChangeUserData}
								error={errors.phone}
								ariaLabel="Phone Input"
							/>
							<Select
								disabled={disabledUser}
								theme={disabledUser ? 'dark' : 'primary'}
								required={true}
								label="Country"
								options={[{ label: '', value: '' }, ...props.countries.filter((r) => r.is_country)]}
								name="country"
								value={userData.country}
								onChange={onChangeUserData}
								error={errors.country}
								ariaLabel="Country Select"
							/>
						</div>
					</div>
					<div className="create-agent__modal-body__firm">
						<span className="create-agent__modal-body__firm-header">Firm Details</span>
						<div className="create-agent__modal__input-group">
							<Input
								required={true}
								disabled={disabled}
								theme={disabled ? 'dark' : 'primary'}
								label="Firm name"
								name="name"
								value={firmData.name}
								onChange={onChangeFirmData}
								error={errors.name}
								ariaLabel="Firm Name Input"
							/>
							<Input
								disabled={disabled}
								theme={disabled ? 'dark' : 'primary'}
								label="Address 1"
								name="address"
								value={firmData.address}
								onChange={onChangeFirmData}
								error={errors.address}
								ariaLabel="Address 1 Input"
							/>
							<Input
								disabled={disabled}
								theme={disabled ? 'dark' : 'primary'}
								label="Address 2"
								name="address2"
								value={firmData.address2}
								onChange={onChangeFirmData}
								ariaLabel="Address 2 Input"
							/>
						</div>
						<div className="create-agent__modal__input-group">
							<Input
								disabled={disabled}
								theme={disabled ? 'dark' : 'primary'}
								label="City"
								name="city"
								value={firmData.city}
								onChange={onChangeFirmData}
								error={errors.city}
								ariaLabel="City Input"
							/>
							<Input
								disabled={disabled}
								theme={disabled ? 'dark' : 'primary'}
								label="State"
								name="state"
								value={firmData.state}
								onChange={onChangeFirmData}
								error={errors.state}
								ariaLabel="State Input"
							/>
							<Input
								disabled={disabled}
								theme={disabled ? 'dark' : 'primary'}
								label="Postal Code"
								name="zipcode"
								value={firmData.zipcode}
								onChange={onChangeFirmData}
								error={errors.zipcode}
								ariaLabel="Postal code Input"
							/>
							<Select
								required={true}
								disabled={disabled || (props.disableFirmCoutry && props.regionForNewAgentIsCountry)}
								theme={props.disabled || props.disableFirmCoutry ? 'dark' : 'primary'}
								label="Country"
								options={[{ label: '', value: '' }, ...props.countries.filter((r) => r.is_country)]}
								name="country"
								value={firmData.country}
								onChange={onChangeFirmData}
								error={errors.firm_country}
								ariaLabel="Firm Country Select"
							/>
						</div>
					</div>
					<Checkbox
						name="is_block_emails"
						checked={!!userData.is_block_emails}
						onChange={onChangeUserData}
						className="block-agent"
						label="Skip all emails notifications except instruction for this agent"
						ariaLabel="Block emails Checkbox"
					/>
				</div>
			</div>
		</Modal>
	);
};

CreateAgentModal.propTypes = {
	regionCode: PropTypes.string,
	regionForNewAgentIsCountry: PropTypes.bool,
	agents: PropTypes.array,
	clientFirmId: PropTypes.string,
	regionId: PropTypes.string,
	activeFirm: PropTypes.bool,
	countries: PropTypes.array,
	fromPlayground: PropTypes.bool,
	createRegionRate: PropTypes.bool,
	serviceId: PropTypes.string,
	createAllRates: PropTypes.bool,
	services: PropTypes.array,
	closeHandler: PropTypes.func,
	refreshData: PropTypes.func,
	setPreselectAgent: PropTypes.func,
	redirect: PropTypes.bool,
	disableFirmCoutry: PropTypes.bool,
};

CreateAgentModal.defaultProps = {
	regionCode: '',
	regionForNewAgentIsCountry: false,
	agents: [],
	clientFirmId: '',
	regionId: '',
	activeFirm: true,
	countries: [],
	fromPlayground: false,
	createRegionRate: false,
	serviceId: '',
	createAllRates: false,
	services: [],
	closeHandler: () => {},
	refreshData: () => {},
	setPreselectAgent: () => {},
	redirect: false,
	disableFirmCoutry: false,
};

export default CreateAgentModal;
