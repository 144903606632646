import React, { useEffect, useState } from 'react';

import Settings from 'services/rest/settings';
import Firms from 'services/rest/firms';
import Users from 'services/rest/users';
import { random } from 'services/strings';

import { Modal, DataTable, Button } from 'sazzui/lib/main';
import PrefsAssociateAddForm from 'com/widgets/PrefsAssociateAddForm';

import ICON_DELETE from 'assets/images/icons/ico_btn_delete_lightgrey.svg';
import ICON_AVATAR_SOLID from 'assets/images/icons/avatar_solid.svg';
import { RoleBox } from 'com/util/RoleBox';

const UserDetailsBlacklistedAssociates = (props) => {
	const tableHeadersInit = [
		{ title: 'Firm', field: 'firm_name', type: 'string', sortable: false },
		{ title: 'Region', field: 'region_name', type: 'string', sortable: false },
		{ title: 'Created', field: 'created', type: 'date', sortable: false },
		{ title: '', field: 'actions', type: 'string', sortable: false },
	];

	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [blacklistedAssociates, setBlacklistedAssociates] = useState([]);
	const [firms, setFirms] = useState([{ label: '', value: '' }]);
	const [associates, setAssociates] = useState([{ label: '', value: '' }]);
	const [regions, setRegions] = useState([{ label: '', value: '' }]);
	const [showAddForm, setShowAddForm] = useState(false);
	const [showPreferedModal, setShowPreferedModal] = useState(false);
	const [modalFirmName, setModalFirmName] = useState('');
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deletingBlacklisted, setDeletingBlacklisted] = useState(null);

	useEffect(() => {
		if (props.data.blacklisted_associates) {
			setBlacklistedAssociates(props.data.blacklisted_associates);
		}
	}, [props.data]);

	useEffect(() => {
		(async () => {
			await fetchData();
		})();
	}, []);

	const fetchData = async () => {
		try {
			let firmsData = await Firms.GetAllRaw();
			setFirms((prevState) => [
				...prevState,
				...firmsData.map((f) => {
					return {
						label: f.name,
						value: f.id,
					};
				}),
			]);

			let associatesData = await Users.GetAllRaw();
			setAssociates((prevState) => [
				...prevState,
				...associatesData
					.map((c) => {
						return !!(c.first_name && c.last_name) ?
								{
									label: `${c.first_name} ${c.last_name}`,
									value: c.id,
									firm_id: c.firm_id,
								}
							:	false;
					})
					.filter(Boolean),
			]);

			let regions = await Settings.GetRegions();
			setRegions((prevState) => [
				...prevState,
				...regions.map((r) => {
					return {
						label: r.name,
						value: r.id,
						code: r.code,
					};
				}),
			]);
		} catch (err) {
			console.log(err);
		}
	};
	const getRegionCode = (item) => {
		let region = regions.find((r) => r.value === item.region_id);
		return region && region.code;
	};

	const toggleAddForm = () => {
		setShowAddForm(!showAddForm);
	};

	const handleAddBlacklisted = (addingBlacklisted) => {
		let newBlacklisted = {
			id: random(8),
			...addingBlacklisted,
			firm_name: addingBlacklisted.firm_id && firms.find((f) => f.value === addingBlacklisted.firm_id).label,
			region_name: addingBlacklisted.region_id && regions.find((f) => f.value === addingBlacklisted.region_id).label,
		};

		let isPreferred = handlePreferred(newBlacklisted);
		if (!!isPreferred) {
			return;
		}

		let data = [...blacklistedAssociates, newBlacklisted];
		props.onChangeBlacklistedAssociates(data, 'add');
		setShowAddForm(!showAddForm);
	};

	const handlePreferred = (newBlacklisted) => {
		let isAlreadyPreferred = props.data.preferred_associates && props.data.preferred_associates.find((ba) => ba.firm_id === newBlacklisted.firm_id);

		if (!!isAlreadyPreferred) {
			setModalFirmName(newBlacklisted.firm_name);
			setShowPreferedModal(true);
		}

		return isAlreadyPreferred;
	};

	const openDeleteBlacklistedModal = (e) => {
		let { baid } = e.target.parentNode.dataset;
		let blacklisted = blacklistedAssociates.find((ba) => ba.id == baid);
		setDeletingBlacklisted(blacklisted);

		let name = blacklisted.firm_name;
		let associate = blacklisted.associate_name || '';
		setModalFirmName(name.concat(associate ? ` (${associate})` : ''));

		setShowDeleteModal(true);
	};

	const handleDeleteBlacklisted = () => {
		let data = blacklistedAssociates.filter((pa) => pa.id != deletingBlacklisted.id);
		props.onChangeBlacklistedAssociates(data);
		setShowDeleteModal(false);
	};

	const closePopupHandler = () => {
		setShowDeleteModal(false);
		setShowPreferedModal(false);
	};

	const popupWarningFooterActions = [{ primary: false, label: 'Close', action: closePopupHandler, theme: 'primary' }];

	const popupDeleteFooterActions = [
		{ primary: false, label: 'Cancel', action: closePopupHandler, theme: 'primary' },
		{ primary: true, label: 'Delete', action: handleDeleteBlacklisted, theme: 'primary' },
	];

	return (
		<div className="user-details-prefs_assoc">
			<div className="user-details-prefs_assoc__section-header">
				<span className="user-details-prefs_assoc__section-header__title">List of blacklisted associates</span>
				<RoleBox roles={['admin', 'sales', 'sales_manager']}>
					<Button onClick={toggleAddForm}>Add Blacklisted Associate</Button>
				</RoleBox>
			</div>
			<div className="user-details-prefs_assoc__section-body">
				<DataTable fixedHeader={true} columns={tableHeaders}>
					{showAddForm && (
						<PrefsAssociateAddForm firms={firms} associates={associates} regions={regions} showAssociateColumn={false} onCancelAddForm={toggleAddForm} onHandleAdd={handleAddBlacklisted} />
					)}
					{blacklistedAssociates.map((ba, i) => {
						return (
							<tr key={i}>
								<DataTable.PersonCell ico={ICON_DELETE} showIco={true}>
									{ba.firm_name}
								</DataTable.PersonCell>
								<DataTable.CountryCell code={getRegionCode(ba)}>{ba.region_name}</DataTable.CountryCell>
								<DataTable.DateCell date={ba._created || new Date()} />
								<DataTable.Cell>
									<RoleBox roles={['admin', 'sales', 'sales_manager']}>
										<button className="user-details-prefs_assoc__section-body__button-delete" data-baid={ba.id} onClick={openDeleteBlacklistedModal}>
											<img src={ICON_DELETE} title="Delete" alt="delete" />
										</button>
									</RoleBox>
								</DataTable.Cell>
							</tr>
						);
					})}
					{showPreferedModal && (
						<Modal title="Warning" icon={ICON_DELETE} closeHandler={closePopupHandler} footerActions={popupWarningFooterActions}>
							<strong>{modalFirmName}</strong> already exists in the Preferred Associates.
						</Modal>
					)}
					{showDeleteModal && (
						<Modal title="Delete Blacklisted Associate" icon={ICON_DELETE} closeHandler={closePopupHandler} footerActions={popupDeleteFooterActions}>
							Are you sure that you want to delete <strong>{modalFirmName}</strong> from the list?
						</Modal>
					)}
				</DataTable>
			</div>
		</div>
	);
};

export default UserDetailsBlacklistedAssociates;
