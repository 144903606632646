import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Input } from 'sazzui/lib/ui/Input';
import { Select } from 'sazzui/lib/ui/Select';

import Routes from '../../../data/routes';

const NewFirmRegisterInputs = (props) => {
	const { pathname } = useLocation();

	return (
		<div className="register-container__fields">
			<div className="register-container__input">
				<Input
					required={true}
					name="firm_name"
					value={props.values.firm_name}
					onChange={props.handleChange}
					error={props.errors.firm_name}
					label="Firm Name"
					type="text"
					onBlur={props.validate}
				/>
			</div>
			{pathname === Routes.FIRM_ADD.path && (
				<div className="register-container__input">
					<Input
						name="email"
						value={props.values.email}
						onChange={props.handleChange}
						error={props.errors.email}
						label="Firm Email"
						placeholder="email@domain.com"
						type="text"
						onBlur={props.validate}
					/>
				</div>
			)}
			<div className="register-container__input">
				<Input
					required={true}
					name="address_1"
					value={props.values.address_1}
					onChange={props.handleChange}
					error={props.errors.address_1}
					label="Address 1"
					type="text"
					onBlur={props.validate}
				/>
			</div>
			<div className="register-container__input">
				<Input name="address2" value={props.values.address2} onChange={props.handleChange} error={props.errors.address2} label="Address 2" type="text" onBlur={props.validate} />
			</div>
			<div className="register-container__input">
				<Input required={true} name="city" value={props.values.city} onChange={props.handleChange} error={props.errors.city} label="City" type="text" onBlur={props.validate} />
			</div>
			<div className="register-container__input">
				<Input name="state" value={props.values.state} onChange={props.handleChange} error={props.errors.state} label="State" type="text" onBlur={props.validate} />
			</div>
			<div className="register-container__input">
				<Input
					required={true}
					name="postal_code"
					value={props.values.postal_code}
					onChange={props.handleChange}
					error={props.errors.postal_code}
					label="Postal Code"
					type="text"
					onBlur={props.validate}
				/>
			</div>
			<div className="register-container__input">
				<Select
					required={true}
					name="firm_country"
					value={props.values.firm_country}
					onChange={props.handleChange}
					error={props.errors.firm_country}
					label="Firm Country"
					options={props.countries}
					onBlur={props.validate}
				/>
			</div>
		</div>
	);
};

NewFirmRegisterInputs.propTypes = {
	values: PropTypes.object,
	handleChange: PropTypes.func,
	validate: PropTypes.func,
	errors: PropTypes.object,
	countries: PropTypes.array,
};

NewFirmRegisterInputs.defaultProps = {
	values: {},
	handleChange: () => {},
	validate: () => {},
	errors: {},
	countries: [],
};

export default NewFirmRegisterInputs;
