//vendor imports
import React, { useState } from 'react';
//icons
import ICON_STATUS_CHECKED from 'assets/images/icons/ico_status_checked.svg';
import ICON_STATUS_UNCHECKED from 'assets/images/icons/ico_status_unchecked.svg';
import ICON_REQUIREMENTS_CLAIMS from 'assets/images/icons/ico_requirements_claims.svg';
import ICON_EDIT_ACTION from 'assets/images/icons/ico_btn_edit_lightgrey.svg';
import ICON_DELETE_ACTION from 'assets/images/icons/ico_btn_delete_lightgrey.svg';
//widgets
import { DataTable, Modal, LoaderOverlay } from 'sazzui/lib/main';
//util
import AppLink from 'com/util/AppLink';

const TranslationRequestsMain = (props) => {
	const tableHeadersInit = [
		{ title: 'Region', field: 'region_id', type: 'string', sort: 'none', sortable: true },
		{ title: 'Primary Language', field: 'primary_language_id', type: 'string', sort: 'none', sortable: true },
		{ title: 'Secondary Languages', field: 'flat_secondary_languages', type: 'string', sort: 'none', sortable: true },
		{ title: 'PCT/Paris/Utility', field: 'pct_paris_utility', type: 'string', sort: 'none', sortable: true },
		{ title: 'Ep', field: 'ep', type: 'string', sort: 'none', sortable: true },
		{ title: 'Design', field: 'design', type: 'string', sort: 'none', sortable: false },
		{ title: 'Trademarks', field: 'trademarks', type: 'string', sort: 'none', sortable: false },
		{ title: '', field: 'action', type: 'string', sort: 'none', sortable: false },
	];

	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [orderBy, setOrderBy] = useState([]);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [deletingRequest, setDeletingRequest] = useState(null);

	const columnSort = async (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (orderBy.filter((r) => r.field === col).length === 0) {
						setOrderBy([{ field: col, direction: h.sort }, ...orderBy]);
					} else {
						setOrderBy([{ field: col, direction: h.sort }, ...orderBy.filter((c) => c.field !== col)]);
					}
				}
				return h;
			}),
		);

		props.onSortChange(orderBy);
	};

	const getStatus = (status) => {
		const statuses = {
			REQUIRED: {
				ico: ICON_STATUS_CHECKED,
				title: 'Required',
			},
			NOT_REQUIRED: {
				ico: ICON_STATUS_UNCHECKED,
				title: 'Not Required',
			},
			CLAIMS: {
				ico: ICON_REQUIREMENTS_CLAIMS,
				title: 'Claims only',
			},
			CLAIMS_ENG_DESC: {
				ico: ICON_REQUIREMENTS_CLAIMS,
				title: 'Claims only if Eng. description',
			},
			FULL_TRANSLATION: {
				ico: ICON_REQUIREMENTS_CLAIMS,
				title: 'Full translation',
			},
			NA: {
				ico: ICON_STATUS_UNCHECKED,
				title: 'Not available',
			},
		};

		return statuses[status];
	};

	const statusDesc = {
		REQUIRED: 'required',
		NOT_REQUIRED: 'not required',
		CLAIMS: 'claims',
		CLAIMS_ENG_DESC: 'claims only if Eng. desc',
		FULL_TRANSLATION: 'full translation',
		NA: 'not available',
	};

	const openDeleteModal = (e) => {
		let { id } = e.target.closest('[data-id]').dataset;
		let request = props.requestsList.find((r) => r.id === id);
		setDeletingRequest(request);
		setShowDeleteModal(true);
	};

	const closeDeleteModal = () => {
		setShowDeleteModal(false);
	};

	const applyDeleteRequest = () => {
		props.onChange(deletingRequest, 'delete');
		closeDeleteModal();
	};

	const handleEditRequest = (e) => {
		let { id } = e.target.closest('[data-id]').dataset;
		props.history.push(`/tools/translation-requests/${id}`);
	};

	const deleteModalFooterActions = [
		{ primary: false, label: 'Cancel', action: closeDeleteModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Delete', action: applyDeleteRequest, theme: 'azami-blue' },
	];

	const requestActions = (id) => {
		return [
			{ label: 'Edit', action: handleEditRequest, dataset: { id: id }, ico: ICON_EDIT_ACTION, roles: ['sales', 'admin'] },
			{ label: 'Delete', action: openDeleteModal, dataset: { id: id }, ico: ICON_DELETE_ACTION, roles: ['sales', 'admin'] },
		];
	};

	const getLanguageById = (lid) => {
		return props.languagesList.find((ll) => ll.value === lid)?.label;
	};

	return (
		<div className="main-screen-section white full-height no-padding">
			<LoaderOverlay showLoader={props.showLoader} />
			<div className="translation-requests-main white">
				<div className="translation-requests__header">
					<span className="translation-requests__header__title">Translation requirements per region</span>
					<AppLink type="nav" to={`/tools/translation-requests/add`} exact={true} className="form-button small primary">
						Add Region
					</AppLink>
				</div>
				<div className="translation-requests__body">
					<DataTable fixedHeader={true} columns={tableHeaders} onColumnSort={columnSort} customClassName="translation-requests__data-table">
						{props.requestsList &&
							props.requestsList.map((r, i) => {
								return (
									<tr key={i}>
										<DataTable.CountryCell code={r.region_code || ''}>{r.region_name}</DataTable.CountryCell>
										<DataTable.TagsCell tags={[getLanguageById(r.primary_language_id)]} />
										<DataTable.TagsCell tags={r.flat_secondary_languages?.map((fsl) => getLanguageById(fsl))} />
										<DataTable.StatusCell status={getStatus(r.pct)}></DataTable.StatusCell>
										<DataTable.StatusCell status={getStatus(r.ep)} customClassName="ep-desc">
											{statusDesc[r.ep]}
										</DataTable.StatusCell>
										<DataTable.StatusCell status={getStatus(r.design)}></DataTable.StatusCell>
										<DataTable.StatusCell status={getStatus(r.trademarks)}></DataTable.StatusCell>
										<DataTable.ActionsMenuCell actions={requestActions(r.id)}></DataTable.ActionsMenuCell>
									</tr>
								);
							})}
					</DataTable>
				</div>
				{showDeleteModal ?
					<Modal title="Delete translation request" closeHandler={closeDeleteModal} footerActions={deleteModalFooterActions}>
						Are you sure you want to delete the translation request?
					</Modal>
				:	null}
			</div>
		</div>
	);
};

export default TranslationRequestsMain;
