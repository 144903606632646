import { Modal, DataTable } from 'sazzui/lib/main';

import './style.css';

const QuoteDetailsWipoModal = (props) => {
	const wipoFields = {
		application_title: 'Title of Application',
		applicant_name: 'Applicant Name',
		earliest_priority_date: 'Earliest Priority Date',
		number_of_priorities: 'Number of Priorities',
		intl_filing_date: 'International Filing Date',
		publication_number: 'Publication Number',
		number_of_words: 'Total Number of Words',
		chapter_two_demand: 'Chapter II Demand',
		number_of_pages: 'Total Number of Pages',
		number_of_claims: 'Number of Claims',
		number_of_independent_claims: 'Number of Independent Claims',
		number_of_page_drawings: 'Number of Pages of Drawings',
		number_of_page_sequences: 'Number of Pages of Sequences',
		application_language: 'Application Language',
		location_of_search: 'Location of Search',
	};

	const epoFields = {
		application_title: 'Title of Application',
		applicant_name: 'Applicant Name',
		earliest_priority_date: 'Earliest Priority Date',
		publication_number: 'Publication Number',
		number_of_words: 'Total Number of Words',
		number_of_words_in_claims: 'Number of Words in Claims',
		number_of_pages: 'Total Number of Pages',
		number_of_claims: 'Number of Claims',
		number_of_page_drawings: 'Number of Pages of Drawings',
		number_of_page_sequences: 'Number of Pages of Sequences',
		application_language: 'Application Language',
	};

	const tableHeaders = [
		{ title: 'FIELD', field: 'field', type: 'string', sort: 'none', sortable: false },
		{ title: 'WIPO DATA', field: 'wipo_data', type: 'string', sort: 'none', sortable: false },
		{ title: 'Current Data If Differs', field: 'changed_data', type: 'string', sort: 'none', sortable: false },
	];

	const overwriteDataHandler = () => {
		props.overwriteDataHandler(props.wipoData);
		props.closeHandler();
	};

	const modalActions = [
		{ primary: false, label: 'Cancel', action: props.closeHandler, theme: 'ghost' },
		{ primary: true, label: 'Continue', action: overwriteDataHandler, theme: 'light' },
	];

	const fields = props.service == 'EPO' ? epoFields : wipoFields;

	return (
		<Modal title="Confirmation" closeHandler={props.closeHandler} footerActions={modalActions}>
			<div className="override-wipo-modal">
				<div className="override-wipo-modal__body">
					<div className="override-wipo-modal__header">
						WARNING! Clicking Continue will delete all previously entered data with data retrieved from WIPO/EPO. Click Cancel to return to the quote without making any changes.
					</div>
					<DataTable fixedHeader={false} columns={tableHeaders}>
						{Object.keys(fields).map((key, i) => {
							let wipo_data = props.wipoData[key];
							let field_data = props.data[key];
							if (key === 'application_language') {
								wipo_data = props.languages.find((l) => l.value === wipo_data)?.label;
								field_data = props.languages.find((l) => l.value === field_data)?.label;
							}
							if (key === 'chapter_two_demand') {
								wipo_data = wipo_data ? 'Yes' : 'No';
								field_data = field_data ? 'Yes' : 'No';
							}
							if (key === 'earliest_priority_date' || key === 'intl_filing_date') {
								let [year, month, day] = wipo_data ? wipo_data.split('-') : '';
								wipo_data = wipo_data ? `${month}/${day}/${year}` : '';
								let [yearf, monthf, dayf] = field_data ? field_data.split('-') : '';
								field_data = field_data ? `${monthf}/${dayf}/${yearf}` : '';
							}
							field_data = field_data != wipo_data ? field_data : '';
							return (
								<tr key={i}>
									<DataTable.Cell>{fields[key]}</DataTable.Cell>
									<DataTable.Cell>{wipo_data}</DataTable.Cell>
									<DataTable.Cell customClassName={'override-wipo-modal-data'}>{field_data}</DataTable.Cell>
								</tr>
							);
						})}
					</DataTable>
				</div>
			</div>
		</Modal>
	);
};

export default QuoteDetailsWipoModal;
