import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Checkbox, DataTable, LoaderOverlay } from 'sazzui/lib/main';

import Documents from 'services/rest/documents';

import { DOCUMENT_OBJECT_TYPE, FILE_CATEGORY } from 'data/constants';
import { API_HOST } from 'data/constants';
import { random } from 'services/strings';

import ICON_WEBSITE from 'assets/images/icons/ico_website.svg';
import ICON_PAPER_CLIP from 'assets/images/icons/ico_paperclip.svg';
import ICON_DOWNLOAD from 'assets/images/icons/ico-download.svg';
import ICON_INFO from 'assets/images/icons/ico_info_magenta.svg';

import './style.css';

const DocumentPicker = (props) => {
	const filterInit = {
		// object_type_region: props.objectTypeRegion,
		// object_type_state: props.objectTypeState,
		object_type_region: DOCUMENT_OBJECT_TYPE.CASE_REGION,
		object_type_state: DOCUMENT_OBJECT_TYPE.CASE,
		order_by: [],
	};

	const [documents, setDocuments] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	const [filterData, setFilterData] = useState(filterInit);
	const [selectAll, setSelectAll] = useState(false);
	const [selectedDocuments, setSelectedDocuments] = useState([]);
	const { id } = useParams();
	const ALL_REGIONS = 'General (All Regions)';

	const selectAllToggle = (e) => {
		setSelectAll(e.target.checked);
	};

	const tableHeadersInit = [
		{ title: <Checkbox name="selectAll" onChange={selectAllToggle} value="SELECT_ALL" />, field: '', type: '', sort: 'none', sortable: false },
		{ title: 'region', field: 'region_name', sort: 'none', sortable: true },
		{ title: 'category', field: 'category', sort: 'none', sortable: true },
		{ title: 'file', field: 'name', sort: 'none', sortable: true },
		{ title: 'description', field: 'description', sort: 'none', sortable: true },
		{ title: '', field: '', sort: 'none', sortable: false },
		{ title: '', field: '', sort: 'none', sortable: false },
	];
	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);

	useEffect(() => {
		if (selectAll && selectedDocuments.length < documents.length) {
			setSelectedDocuments(documents.map((d) => d.id));
		} else if (!selectAll && selectedDocuments.length > 0) {
			setSelectedDocuments([]);
		}
	}, [selectAll]);

	useEffect(() => {
		props.setSelectedDocuments(selectedDocuments);
	}, [selectedDocuments]);

	useEffect(() => {
		fetchDocuments();
	}, []);

	const columnSort = async (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filterData.order_by.filter((r) => r.field === col).length === 0) {
						setFilterData({
							...filterData,
							order_by: [{ field: col, direction: h.sort }, ...filterData.order_by],
						});
					} else {
						setFilterData({
							...filterData,
							order_by: [{ field: col, direction: h.sort }, ...filterData.order_by.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
	};

	const fetchDocuments = async () => {
		setShowLoader(true);
		try {
			let filter = { ...filterData };
			let data = await Documents.getByCase(id, filter);
			data = data.map((d) => {
				const category = FILE_CATEGORY.find((e) => e.value === d.category);
				d.category_label = category.label;
				d.region_name = d.region_name || ALL_REGIONS;
				return d;
			});
			setDocuments(sortByRegionName(data));
			setShowLoader(false);
		} catch (error) {
			console.log(error);
			setShowLoader(false);
		}
	};

	const sortByRegionName = (data) => {
		data = data.sort((a, b) => {
			const currentName = a.region_name.toLowerCase();
			const nextName = b.region_name.toLowerCase();
			if (currentName === ALL_REGIONS.toLowerCase()) {
				return -1;
			}
			if (currentName < nextName) {
				return -1;
			}
			if (currentName > nextName) {
				return 1;
			}

			return 0;
		});
		return data;
	};

	const toggleSelectRow = (e) => {
		if (selectedDocuments.includes(e.target.name)) {
			setSelectedDocuments(selectedDocuments.filter((d) => d != e.target.name));
		} else {
			setSelectedDocuments([...selectedDocuments, e.target.name]);
		}
	};

	return (
		<div className="documents-picker">
			<LoaderOverlay showLoader={showLoader} />
			<div className="documents-picker__header">
				<div className="documents-picker_header_description">Please Select the files you would like to include in this email</div>
				<h4>Case Documents</h4>
			</div>
			<div className="documents-picker-main">
				<DataTable fixedHeader={true} columns={tableHeaders} onColumnSort={columnSort} customClassName="documents-main__table">
					{!!documents &&
						documents.map((d) => {
							let checked = selectedDocuments.includes(d.id);
							return (
								<DataTable.Row key={random(8)}>
									<DataTable.Cell>
										<Checkbox checked={checked} name={d.id} onChange={toggleSelectRow} />
									</DataTable.Cell>
									{d.region_code ?
										<DataTable.CountryCell code={d.region_code}>{d.region_name}</DataTable.CountryCell>
									:	<DataTable.CellWithBadge badge={ICON_WEBSITE}>{d.region_name}</DataTable.CellWithBadge>}
									<DataTable.Cell>{d.category_label}</DataTable.Cell>
									<DataTable.Cell>
										<img src={ICON_PAPER_CLIP} alt="" />
										<span>{d.name}</span>
									</DataTable.Cell>
									<DataTable.Cell>{d.description}</DataTable.Cell>
									<DataTable.Cell>
										<div key={d.id} className="documents-main__table__info">
											<img src={ICON_INFO} />
											<ToolTip>
												<span>File Size: {(d.size / (1024 * 1024)).toFixed(2)} MB</span>
												<span>Attached By: {d.attached_by}</span>
											</ToolTip>
										</div>
									</DataTable.Cell>
									<DataTable.Cell>
										<a href={`${API_HOST}/storage/document/${d.name}?id=${d.id}`}>
											<img src={ICON_DOWNLOAD} alt="" />
										</a>
									</DataTable.Cell>
								</DataTable.Row>
							);
						})}
				</DataTable>
			</div>
		</div>
	);
};

export const ToolTip = (props) => {
	return <div className="info__tooltip">{props.children}</div>;
};

DocumentPicker.propTypes = {
	objectTypeRegion: PropTypes.string,
	objectTypeState: PropTypes.string,
	setSelectedDocuments: PropTypes.func,
};

DocumentPicker.defaultProps = {
	objectTypeRegion: '',
	objectTypeState: '',
	setSelectedDocuments: () => {},
};

export default DocumentPicker;
