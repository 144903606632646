//vendors import
import React, { useEffect, useState } from 'react';
import { NavLink, useHistory, useParams } from 'react-router-dom';
//services
import Settings from 'services/rest/settings';
//constants
import { TRANSLATIONS_REQUIREMENT_TYPES } from 'data/constants';
//ui components
import { Select, MultiSelect, Button } from 'sazzui/lib/main';
//styles
import './style.css';

const TranslationRequestsForm = (props) => {
	const formDataInit = {
		region_id: '',
		primary_language_id: '',
		flat_secondary_languages: [],
		pct: '',
		paris: '',
		utility: '',
		ep: '',
		design: TRANSLATIONS_REQUIREMENT_TYPES.NOT_REQUIRED,
		trademarks: TRANSLATIONS_REQUIREMENT_TYPES.NOT_REQUIRED,
	};
	const errorsInit = {
		region_id: '',
		primary_language_id: '',
		flat_secondary_languages: [],
		pct: '',
		paris: '',
		utility: '',
		ep: '',
		design: '',
		trademarks: '',
	};

	const errorMessages = {
		required: 'Required',
	};

	const [formData, setFormData] = useState(formDataInit);
	const [errors, setErrors] = useState(errorsInit);
	const history = useHistory();
	const { id } = useParams();
	const isCreateMode = !id;

	useEffect(async () => {
		if (!isCreateMode) {
			let tr = await Settings.GetOneTranslationRequest(id);
			setFormData(tr);
		}
	}, [props]);

	const fieldChange = (e) => {
		switch (e.target.name) {
			case 'pct':
				setFormData({ ...formData, pct: e.target.value, paris: e.target.value, utility: e.target.value });
				break;
			default:
				setFormData({ ...formData, [e.target.name]: e.target.value });
		}
	};

	const validate = () => {
		let isValid = true;
		setErrors(errorsInit);

		if (!formData.region_id) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, region_id: errorMessages.required }));
		}
		if (!formData.primary_language_id) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, primary_language_id: errorMessages.required }));
		}
		if (!formData.pct) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, pct: errorMessages.required }));
		}
		if (!formData.ep) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, ep: errorMessages.required }));
		}
		return isValid;
	};

	const submitForm = () => {
		return isCreateMode ? applyNewRequest() : applyEditRequest();
	};

	const applyNewRequest = async () => {
		if (!validate()) {
			return false;
		}
		props.onChange(formData, 'add');
		history.push('/tools/translation-requests');
	};

	const applyEditRequest = async () => {
		if (!validate()) {
			return false;
		}
		props.onChange(formData, 'edit');
		history.push('/tools/translation-requests');
	};

	const cancelForm = () => {
		history.push('/tools/translation-requests');
	};

	return (
		<div className="main-screen-section white full-height no-padding">
			<div className="translation-requests-main white">
				<div className="translation-requests__header">
					<span className="transaltion-requests__header__title">{isCreateMode ? 'New Region' : 'Edit Region'}</span>
				</div>
				<div className="translation-requests__body">
					<form>
						<div className="single">
							<Select name="region_id" label="Region" options={props.regions} value={formData.region_id} onChange={fieldChange} error={errors.region_id} />
						</div>
						<div className="row">
							<Select
								name="primary_language_id"
								label="Primary Language"
								options={props.languagesList}
								value={formData.primary_language_id}
								onChange={fieldChange}
								error={errors.primary_language_id}
							/>
							<MultiSelect
								name="flat_secondary_languages"
								label="Secondary Languages"
								customClassName="translation-requests-multiselect__languages"
								selectAllEnabled={true}
								options={props.languagesDropDown}
								values={formData.flat_secondary_languages}
								onChange={fieldChange}
								error={errors.flat_secondary_languages}
							/>
						</div>
						<div className="row">
							<Select name="pct" label="PCT/Paris/Utility" options={props.type} value={formData.pct} onChange={fieldChange} error={errors.pct} />
							<Select name="ep" label="European Validation" options={props.typeAll} value={formData.ep} onChange={fieldChange} error={errors.ep} />
						</div>
						<div className="row">
							<Select name="design" label="Design" disabled={true} />
							<Select name="trademarks" label="Trademarks" disabled={true} />
						</div>
					</form>
				</div>
			</div>
			<footer className="translation-requests__footer">
				<Button onClick={cancelForm} theme="ghost-primary">
					Cancel
				</Button>
				<Button onClick={submitForm}>Apply</Button>
			</footer>
		</div>
	);
};

export default TranslationRequestsForm;
