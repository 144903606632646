//System Imports
import PropTypes from 'prop-types';
//UI Imports
import { Input } from 'sazzui/lib/ui/Input';
import AppLink from 'com/util/AppLink';
import { Button } from 'sazzui/lib/ui/Button';
import { ErrorMessage } from 'sazzui/lib/ui/ErrorMessage';
//Validator imports
import { Formik, Form } from 'formik';
import { LoginSchema } from 'services/validators/login';
//Template imports
import AuthBox from 'com/templates/AuthBox';
//Local Component imports
import LoginFooterIPeer from './LoginFooterIPeer';

const LoginIPeer = (props) => {
	return (
		<div className="login-container">
			<div className="login-container-ipeer__header">
				<span>Log in</span>
			</div>
			<AuthBox footer={<LoginFooterIPeer />}>
				<Formik initialValues={props.initialState} validationSchema={LoginSchema} onSubmit={props.handleSubmit} validateOnChange={false}>
					{({ values, handleChange, validateField, errors, status }) => {
						const validate = (e) => validateField(e.target.name);

						return (
							<Form>
								<Input name="email" value={values.email} onChange={handleChange} onBlur={validate} error={errors.email} label="Email" placeholder="email@domain.com" type="text" />
								<Input
									name="password"
									value={values.password}
									onChange={handleChange}
									onBlur={validate}
									error={errors.password}
									label="Password"
									placeholder="your password"
									type="password"
								/>
								<div className="login-container__actions">
									<AppLink type="regular" to="/forgot-password">
										Forgot password?
									</AppLink>
									<Button type="submit" theme="primary">
										Login
									</Button>
								</div>
								{status && <ErrorMessage error={status} />}
							</Form>
						);
					}}
				</Formik>
			</AuthBox>
		</div>
	);
};

LoginIPeer.propTypes = {
	initialState: PropTypes.object,
	handleSubmit: PropTypes.func,
};

LoginIPeer.defaultProps = {
	initialState: {},
	handleSubmit: () => {},
};

export default LoginIPeer;
