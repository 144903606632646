export const inputACLCheck = (uData, ownerData, roles) => {
	if (uData.role.includes('admin')) return false;
	if (roles.includes('$user') && ownerData.userMatch) return false;
	if (roles.includes('$firm') && ownerData.firmMatch) return false;
	if (roles.includes('$clientOfFirm') && ownerData.clientOfFirmMatch) return false;
	if (roles.includes('$agentOfFirm') && ownerData.agentOfFirmMatch) return false;
	if (roles.length === 0) return false;
	let uRoles = uData.role.filter((r) => {
		return roles.includes(r);
	});
	return uRoles.length === 0;
};
