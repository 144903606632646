import { useEffect, useState } from 'react';

import { DataTable, Button, Input, Select } from 'sazzui/lib/main';

import Settings from 'services/rest/settings';
import Preferences from 'services/rest/preferences';
import { PREFERENCES_KEYS, PREFERENCES_GROUP } from 'data/constants';

import ICON_CLOSE from 'assets/images/icons/ico-close-grey.svg';
import ICON_INFO from 'assets/images/icons/ico_toast_info_violet.svg';

import './style.css';

const SystemSettingsExchangeRates = () => {
	const adjustRateIcon = {
		icon: ICON_INFO,
		tooltip: 'Enter the percent value to adjust the exchange rate by when converting currencies.',
	};

	const filterInit = {
		convert_to: '',
		orderBy: [],
	};

	const errorMessages = {
		required: 'Required',
	};

	const tableHeadersInit = [
		{ title: 'Currency Name', field: 'name', type: 'string', sort: 'none', sortable: true },
		{ title: 'Code', field: 'code', type: 'string', sort: 'none', sortable: true },
		{ title: 'Exchange Rate', field: 'value', type: 'number', sort: 'none', sortable: true },
		{ title: 'Exchange Rate Date', field: 'value', type: 'date', sort: 'none', sortable: true },
	];

	const [CurrencySelect, setCurrencySelect] = useState('');
	const [errors, setErrors] = useState({});
	const [exchange_rates, setExchangeRates] = useState([]);
	const [tableHeaders, setTableHeaders] = useState(tableHeadersInit);
	const [filter, setFilter] = useState(filterInit);
	const [displayCurrency, setDisplayCurrency] = useState('');
	const [unusedCurrencies, setUnusedCurrencies] = useState([]);
	const [showAddForm, setShowAddForm] = useState(false);
	const [fxObject, setFxObject] = useState({ preference_value: 5 });
	const [adjustRateIcons, setAdjustRateIcons] = useState([adjustRateIcon]);

	useEffect(async () => {
		try {
			await fetchData();
		} catch (err) {}
	}, []);

	useEffect(async () => {
		try {
			let filters = {
				convert_to: filter.convert_to,
			};
			if (!!filter.orderBy) {
				filters.order_by = filter.orderBy.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(',');
			}
			let currenciesData = await Settings.GetExchangeRates(filters);
			if (currenciesData) {
				currenciesData.sort((a, b) =>
					a.code > b.code ? 1
					: b.code > a.code ? -1
					: 0,
				);
				setExchangeRates(currenciesData);
			}
		} catch (err) {}
	}, [filter]);

	const fetchData = async () => {
		try {
			let currenciesData = await Settings.GetExchangeRates({ order_by: [], convert_to: filter.convert_to });
			if (currenciesData) {
				currenciesData.sort((a, b) =>
					a.code > b.code ? 1
					: b.code > a.code ? -1
					: 0,
				);
				setExchangeRates(currenciesData);
				let displayCurrency = currenciesData.map((c) => {
					return {
						label: c.code,
						value: c.currency_id,
					};
				});
				setDisplayCurrency(displayCurrency);
			}
			let fx = await Preferences.GetSystemRecordsByKey(PREFERENCES_GROUP.GENERAL_SETTINGS, PREFERENCES_KEYS.EXCHANGE_RATE_PADDING);
			if (fx) {
				setFxObject(fx);
			}
			let unusedCurrencies = await Settings.GetAvailableExchangeRatesCurrencies();
			if (unusedCurrencies) {
				unusedCurrencies.sort((a, b) =>
					a.name > b.name ? 1
					: b.name > a.name ? -1
					: 0,
				);
				setUnusedCurrencies([
					{ label: '', value: '' },
					...unusedCurrencies.map((c) => {
						return { label: `${c.name} ${c.symbol}`, value: c.id };
					}),
				]);
			}
		} catch (err) {
			console.log(err);
		}
	};

	const onFilterChange = (e) => {
		setFilter({
			...filter,
			[e.target.name]: e.target.value,
		});
	};

	const columnSort = async (col) => {
		setTableHeaders(
			tableHeaders.map((h) => {
				if (h.field === col && h.sortable) {
					h.sort = h.sort === 'up' ? 'down' : 'up';
					if (filter.orderBy.filter((r) => r.field === col).length === 0) {
						setFilter({
							...filter,
							orderBy: [{ field: col, direction: h.sort }, ...filter.orderBy],
						});
					} else {
						setFilter({
							...filter,
							orderBy: [{ field: col, direction: h.sort }, ...filter.orderBy.filter((c) => c.field !== col)],
						});
					}
				}
				return h;
			}),
		);
	};

	const toggleAddForm = () => {
		setShowAddForm(!showAddForm);
	};

	const decimalFormatter = new Intl.NumberFormat('en-US', {
		minimumFractionDigits: 0,
		maximumFractionDigits: 5,
	});

	const validate = () => {
		let isValid = true;
		setErrors({});

		if (!CurrencySelect) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, new_currency: errorMessages.required }));
		}
		return isValid;
	};

	const addFormChanged = (e) => {
		const { value } = e.target;
		setCurrencySelect(value);
	};

	const submitAddCurrency = async () => {
		if (!validate()) {
			return false;
		}
		try {
			await Settings.CreateExchangeRates({ currency_id: CurrencySelect });
			await fetchData();
			toggleAddForm();
		} catch (err) {
			console.log(err);
		}
	};

	const fxValueChange = (e) => {
		setFxObject({
			...fxObject,
			[e.target.name]: e.target.value,
		});
	};

	const submitFxValue = () => {
		try {
			Preferences.Update([fxObject]);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div className="main-screen-section white full-height no-padding system-details-content">
			<div className="system-settings-exchange-rates__header">
				<div className="system-settings-exchange-rates__header__left">
					<Select label="Exchange Rate to" name="convert_to" options={displayCurrency} value={filter.convert_to} onChange={onFilterChange} ariaLabel="Exchange rate Select" />
					<Input
						label="Adjust Exchange Rate By"
						name="preference_value"
						type="number"
						value={fxObject.preference_value}
						icons={adjustRateIcons}
						onChange={fxValueChange}
						onBlur={submitFxValue}
						ariaLabel="Adjust Exchange rate Input"
					/>
				</div>
				<Button onClick={toggleAddForm} ariaLabel="Add currency Button" size="small">
					Add Currency
				</Button>
			</div>
			<div className="system-settings-exchange-rates__info">
				<span>
					<img src={ICON_INFO} alt="Info icon" />
					Exchange rates are updated daily at 1:00 am UTC.
				</span>
			</div>
			<div className="system-details-content__body system-settings-exchange-rates-table-wrapper">
				<DataTable fixedHeader={true} columns={tableHeaders} onColumnSort={columnSort}>
					{showAddForm && (
						<tr className="system-settings-exchange-rates__section-body__add-form">
							<DataTable.Cell>
								<Select
									name="add_currency_selet"
									options={unusedCurrencies}
									value={CurrencySelect}
									placeholder="Select Currency"
									onChange={addFormChanged}
									error={errors.new_currency}
									ariaLabel="Currency Select"
								/>
							</DataTable.Cell>
							<DataTable.Cell> </DataTable.Cell>
							<DataTable.ActionsCell>
								<Button onClick={submitAddCurrency} customClassName="system-settings-exchange-rates__section-body__add-form-submit" ariaLabel="Add currency Button">
									Save
								</Button>
								<Button onClick={toggleAddForm} customClassName="system-settings-exchange-rates__section-body__add-form-cancel" ariaLabel="Cancel Button">
									<img src={ICON_CLOSE} alt="Icon close" />
								</Button>
							</DataTable.ActionsCell>
						</tr>
					)}
					{exchange_rates.map((er, i) => {
						return (
							<tr key={er.id}>
								<DataTable.Cell>{er.name}</DataTable.Cell>
								<DataTable.Cell>{er.code}</DataTable.Cell>
								<DataTable.Cell>{decimalFormatter.format(er.value)}</DataTable.Cell>
								<DataTable.Cell>{er._created}</DataTable.Cell>
							</tr>
						);
					})}
				</DataTable>
			</div>
		</div>
	);
};

export default SystemSettingsExchangeRates;
