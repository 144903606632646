import React, { createContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { AGENT_ASSOCIATIONS_LOCAL_STORAGE_KEY } from 'data/constants';

const OwnerContext = createContext();

const OwnerContextProvider = (props) => {
	const [userId, setUserId] = useState('');
	const [firmId, setFirmId] = useState('');
	const [userMatch, setUserMatch] = useState(false);
	const [firmMatch, setFirmMatch] = useState(false);
	const userData = useSelector((state) => state.auth.user);
	const [clientOfFirmMatch, setClientOfFirmMatch] = useState(false);
	const [agentOfFirmMatch, setAgentForFirmMatch] = useState(false);

	let agentAssociations;
	try {
		agentAssociations = JSON.parse(localStorage.getItem(AGENT_ASSOCIATIONS_LOCAL_STORAGE_KEY));
	} catch (error) {
		console.log('error', error);
	}
	useEffect(() => {
		setUserId(props.userId);
		setUserMatch(userData.uid === props.userId);
	}, [props.userId]);

	useEffect(() => {
		setFirmId(props.firmId);
		setFirmMatch(userData.fid === props.firmId);
		setClientOfFirmMatch(agentAssociations && agentAssociations.clientOfFirms && agentAssociations.clientOfFirms.length > 0 ? agentAssociations.clientOfFirms.includes(props.firmId) : false);
		setAgentForFirmMatch(agentAssociations && agentAssociations.agentOfFirms && agentAssociations.agentOfFirms.length > 0 ? agentAssociations.agentOfFirms.includes(props.firmId) : false);
	}, [props.firmId]);

	return <OwnerContext.Provider value={{ userId, firmId, userMatch, firmMatch, agentOfFirmMatch, clientOfFirmMatch }}>{props.children}</OwnerContext.Provider>;
};

OwnerContextProvider.propTypes = {
	userId: PropTypes.string,
	firmId: PropTypes.string,
	userMatch: PropTypes.bool,
	firmMatch: PropTypes.bool,
};

export { OwnerContextProvider, OwnerContext };
