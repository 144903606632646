import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Select } from '../../../ui/Select';
const PAGE_SIZE = [
    { value: '20', label: '20' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
];
export const Paging = (props) => {
    const [paging, setPaging] = useState([1, 2, 3, 4, 5]);
    const [pages, setPages] = useState({ first: 1, prev: 1, next: 1, last: 1 });
    useEffect(() => {
        const mask = Number(props.mask);
        const page = Number(props.page);
        const trs = Number(props.totalRows);
        const limit = Number(props.limit);
        const pagesNum = (trs % limit > 0 ? 1 : 0) + (trs - (trs % limit)) / limit;
        setPages({
            first: 1,
            prev: page > 1 ? page - 1 : 1,
            next: page < pagesNum ? page + 1 : pagesNum,
            last: pagesNum,
        });
        setPaging(generatePagingArray(page, pagesNum, mask));
    }, [props.page, props.totalRows, props.limit]);
    const generatePagingArray = (p, max, mask) => {
        const out = [];
        const half = Math.floor(mask / 2);
        let start = p - half + 1 - (mask % 2);
        let end = p + half;
        if (mask > max) {
            mask = max;
        }
        // handle start boundary case
        if (start <= 0) {
            start = 1;
            end = mask;
        }
        // handle end boundary case
        if (end > max) {
            start = max - mask + 1;
            end = max;
        }
        // populate the visible paging numbers array
        for (let i = start; i <= end; i++) {
            out.push(i);
        }
        return out;
    };
    const onPageChange = (e) => {
        if (!e.currentTarget.dataset.page) {
            return;
        }
        props.onClick(Number(e.currentTarget.dataset.page), props.limit);
    };
    const onLimitChange = (e) => {
        props.onClick(pages.first, Number(e.target.value));
    };
    const displayingFrom = props.limit * (props.page - 1) + 1;
    const displayingTo = props.limit * props.page > props.totalRows ? props.totalRows : props.limit * props.page;
    return (_jsxs("div", { className: "table-paging", children: [_jsxs("div", { className: "right", children: [_jsx("span", { children: "Entries per page" }), _jsx(Select, { name: "paging_entries", options: PAGE_SIZE, customClassName: `select`, value: String(props.limit), onChange: onLimitChange, ariaLabel: 'paging entries' }), _jsxs("span", { children: ["Displaying ", displayingFrom, " - ", displayingTo, " of ", props.totalRows, " entries"] })] }), _jsxs("div", { className: "left", children: [_jsx("button", { onClick: onPageChange, "data-page": pages.first, className: "paging-button dark", children: "first" }), _jsx("button", { onClick: onPageChange, "data-page": pages.prev, className: "paging-button dark", children: "prev" }), paging.map((v, i) => (_jsx("button", { onClick: onPageChange, "data-page": v, className: `paging-button ${v === Number(props.page) ? 'active' : ''}`, children: v }, i))), _jsx("button", { onClick: onPageChange, "data-page": pages.next, className: "paging-button dark", children: "next" }), _jsx("button", { onClick: onPageChange, "data-page": pages.last, className: "paging-button dark", children: "last" })] })] }));
};
Paging.defaultProps = {
    mask: 5,
};
Paging.PAGE_SIZE = PAGE_SIZE;
