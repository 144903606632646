import React, { useEffect, useState } from 'react';

import Settings from 'services/rest/settings';

import { Select, Input, Button, Textarea, Switch } from 'sazzui/lib/main';

import './style.css';

const SystemSettingsPoasRegionalSetting = (props) => {
	const formDataInit = {
		low_cost_vendor: '',
		is_required: 0,
		is_general_allowed: 0,
		is_esignatures_accepted: 0,
		is_physically_signed_required: 0,
		will_physically_signed_be_required: 0,
		is_separate_required_per_applicant: 0,
	};

	const errorMessages = {
		required: 'Required',
	};
	const errorsInit = {
		region_code: '',
	};

	const [formData, setFormData] = useState(formDataInit);
	const [errors, setErrors] = useState(errorsInit);
	const [showCreatePoaForm, setShowCreatePoaForm] = useState(false);
	const [showEditPoaForm, setShowEditPoaForm] = useState(false);

	useEffect(async () => {
		let action = props.history.location.pathname.split('/').at(-1);
		if (action === 'add') {
			setShowCreatePoaForm(true);
		} else {
			setShowEditPoaForm(true);
			let pid = props.history.location.pathname.split('/').at(-2);
			let poa = await Settings.GetOnePoa(pid);
			setFormData(poa);
		}
	}, []);

	const fieldChange = (e) => {
		let value = e.target.value;
		if (e.target.type === 'checkbox') {
			value = !formData[e.target.name];
		}

		setFormData({
			...formData,
			[e.target.name]: value,
		});
	};

	const validate = () => {
		let isValid = true;
		setErrors(errorsInit);

		if (!formData.region_code) {
			isValid = false;
			setErrors((prevState) => ({ ...prevState, region_code: errorMessages.required }));
		}
		return isValid;
	};

	const cancelCreatePoa = () => {
		props.history.push('/system-settings/poa-management');
	};

	const handleCreatePoa = async () => {
		if (!validate()) {
			return false;
		}

		if (formData.is_general_allowed) {
			formData.general_poa = formData.region_code;
		} else {
			formData.specific_poa = formData.region_code;
		}

		await Settings.CreatePoa(formData);
		props.history.push('/system-settings/poa-management');
	};

	const handleEditPoa = async () => {
		if (!validate()) {
			return false;
		}

		await Settings.UpdatePoa(formData.id, formData);
		props.history.push('/system-settings/poa-management');
	};

	return (
		<div>
			{showCreatePoaForm && (
				<CreatePoaForm
					title="Add POA Regional Settings"
					regions={props.regions}
					formData={formData}
					errors={errors}
					fieldChange={fieldChange}
					cancelCreatePoa={cancelCreatePoa}
					actionHandler={handleCreatePoa}
					actionLabel="Create POA"
				/>
			)}
			{showEditPoaForm && (
				<CreatePoaForm
					title="Edit POA Regional Settings"
					regions={props.regions}
					formData={formData}
					errors={errors}
					fieldChange={fieldChange}
					cancelCreatePoa={cancelCreatePoa}
					actionHandler={handleEditPoa}
					actionLabel="Save POA"
				/>
			)}
		</div>
	);
};

const CreatePoaForm = (props) => {
	return (
		<div className="main-screen-section white full-height no-padding system-details-content">
			<div className="system-settings-poas-main white">
				<div className="system-settings-poas__header">
					<span className="system-settings-poas__header__title">{props.title}</span>
				</div>
				<div className="system-settings-poas__body system-settings-table-wrapper">
					<form>
						<Select name="region_code" options={props.regions} label="Region" value={props.formData.region_code} onChange={props.fieldChange} error={props.errors.region_code} />
						<Input name="low_cost_vendor" label="Low cost vendor" value={props.formData.low_cost_vendor} onChange={props.fieldChange} />
						<Switch onChange={props.fieldChange} value={props.formData.is_required || 0} label="Region requires POA" name="is_required" />
						<Switch onChange={props.fieldChange} value={props.formData.is_general_allowed} label="General POA allowed" name="is_general_allowed" />
						<Switch onChange={props.fieldChange} value={props.formData.is_esignatures_accepted} label="E-signatures accepted" name="is_esignatures_accepted" />
						<Switch onChange={props.fieldChange} value={props.formData.is_physically_signed_required} label="Physical POA required at filing" name="is_physically_signed_required" />
						<Switch
							onChange={props.fieldChange}
							value={props.formData.will_physically_signed_be_required}
							label="Physical POA could be requested post-filing"
							name="will_physically_signed_be_required"
						/>
						<Switch onChange={props.fieldChange} value={props.formData.is_separate_required_per_applicant} label="Separate POAs per applicant" name="is_separate_required_per_applicant" />
						<Textarea name="who_is_authorized_to_sign" label="Who is authorized to sign" value={props.formData.who_is_authorized_to_sign} onChange={props.fieldChange} />
					</form>
				</div>
			</div>
			<footer className="system-details-content__footer">
				<Button onClick={props.cancelCreatePoa} className="ghost-primary">
					Cancel
				</Button>
				<Button onClick={props.actionHandler}>{props.actionLabel}</Button>
			</footer>
		</div>
	);
};
export default SystemSettingsPoasRegionalSetting;
