//Template Imports
import AuthBox from 'com/templates/AuthBox';
//UI Imports
import AppLink from 'com/util/AppLink';
import { BackButton } from 'sazzui/lib/ui/BackButton';
//Style Imports
import './style.css';

const RegisterThankyouAzami = () => {
	return (
		<div className="thankyou-container">
			<AuthBox title="Thank you for your registration" titleColor="azami-blurple" icon="create_account" footer={<BackButton to="/" AppLink={AppLink} label="Back to login" />}>
				An email with a verification link is on it's way. Please verify your account in order to proceed with using The Azami Global portal.
			</AuthBox>
		</div>
	);
};

export default RegisterThankyouAzami;
