import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
export const LoaderCell = ({ rows = 1, cols = 1, }) => {
    let rowsContent = [];
    for (let i = 0; i < rows; i++) {
        let colsContent = [];
        for (let y = 0; y < cols; y++) {
            colsContent = [
                ...colsContent,
                _jsx("td", { children: _jsx("span", { className: "table-cell-loader__animated-fake-content" }) }, Math.random()),
            ];
        }
        rowsContent = [...rowsContent, _jsx("tr", { children: colsContent }, i)];
    }
    return _jsx(_Fragment, { children: rowsContent });
};
