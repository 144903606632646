import Ajax from './base';

const CreateCustomer = async () => {
	return Ajax.Request(`/payments/customer`, Ajax.POST, false);
};

const GetPaymentsToken = async (payload) => {
	return Ajax.Request(`/payments/token`, Ajax.POST, false, payload);
};

const CreatePaymentsIntent = async (payload) => {
	return Ajax.Request(`/payments/intent`, Ajax.POST, false, payload);
};

const CreatePaymentsConsent = async (payload) => {
	return Ajax.Request(`/payments/consent`, Ajax.POST, false, payload);
};

export default {
	CreateCustomer,
	GetPaymentsToken,
	CreatePaymentsIntent,
	CreatePaymentsConsent,
};
