import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Input, Select, Textarea } from 'sazzui/lib/main';
import { RoleBox } from 'com/util/RoleBox';
import AppLink from 'com/util/AppLink';

import Settings from 'services/rest/settings';
import { titles, reciprocity_relationship } from 'data/lists';
import { INTERNAL_ROLES, EXTERNAL_ROLES } from 'data/constants';

const UserDetailsMain = (props) => {
	const [userRoles, setUserRoles] = useState([]);
	const isUpdated = useSelector((state) => state.redirect.isUpdated);
	useEffect(async () => {
		try {
			let roles = await Settings.GetUserRoles();

			setUserRoles([
				...roles.map((d) => {
					return { label: d.title, value: d.id };
				}),
			]);
		} catch (err) {
			console.log(err);
		}
	}, []);

	return (
		<RoleBox roles={['$user', 'admin', 'sales', 'sales_manager']}>
			<div className="user-details-main">
				<div className="user-details-main__left">
					<h3 className="user-details-main__section-title">Basic Information</h3>
					<div className="user-details-main__input-list">
						<RoleBox roles={[...INTERNAL_ROLES, ...EXTERNAL_ROLES.filter((r) => r !== 'saas_user')]}>
							<Select label="Title" customClassName="user-details-main__input-list__half" name="title" options={titles} value={props.userData.title} onChange={props.onChange} />
						</RoleBox>
						<Input label="Email" disabled={true} customClassName="user-details-main__input-list__half" name="email" value={props.userData.email} onChange={props.onChange} />
						<Input label="First name" disabled={true} customClassName="user-details-main__input-list__half" name="first_name" value={props.userData.first_name} onChange={props.onChange} />
						<Input label="Last name" disabled={true} customClassName="user-details-main__input-list__half" name="last_name" value={props.userData.last_name} onChange={props.onChange} />
						<Input label="Position" customClassName="user-details-main__input-list__half" name="position" value={props.userData.position} onChange={props.onChange} />
						<Input
							label="CC email for quotes"
							disabled={true}
							customClassName="user-details-main__input-list__half"
							value={props.userData.email_for_quotes}
							name="email_for_quotes"
							onChange={props.onChange}
						/>
						<Input
							label="CC email for case mgmt."
							disabled={true}
							customClassName="user-details-main__input-list__half"
							value={props.userData.email_for_cases}
							name="email_for_cases"
							onChange={props.onChange}
						/>
						<RoleBox roles={INTERNAL_ROLES}>
							<Input
								label="Days Req Before Filing"
								customClassName="user-details-main__input-list__half"
								type="number"
								value={props.userData.days_required_rush}
								name="days_required_rush"
								onChange={props.onChange}
								error={props.errors.days_required_rush}
							/>
						</RoleBox>
						<Textarea label="Short bio" customClassName="user-details-main__input-list__textarea" value={props.userData.bio} name="bio" onChange={props.onChange} />
					</div>
				</div>
				<div className="user-details-main__right">
					<RoleBox roles={['admin', 'sales', 'sales_manager']}>
						<div className="user-details-main__input-list">
							<Select label="Role" customClassName="user-details-main__input-list__half" name="role_id" options={userRoles} value={props.userData.role_id} onChange={props.onChange} />
						</div>
					</RoleBox>
					<h3 className="user-details-main__section-title">Firm Relationship</h3>
					<div className="user-details-main__input-list">
						<AppLink
							type="regular"
							to={`/firm-management/${props.userData.firm_data.id}`}
							className="user-details-main__input-list__firm-link"
							checkRedirect={isUpdated}
							checkRedirectFrom={['user-management']}
						>
							{props.userData.firm_data.name}
						</AppLink>
						<RoleBox roles={['admin', 'sales', 'sales_manager']}>
							<Select
								label="Reciprocity relationship to firm"
								customClassName="user-details-main__input-list__textarea"
								options={reciprocity_relationship}
								value={props.userData.is_centralized}
								name="is_centralized"
								onChange={props.onChange}
							/>
						</RoleBox>
					</div>
				</div>
			</div>
		</RoleBox>
	);
};

export default UserDetailsMain;
