import React, { useState, useEffect, memo, useRef } from 'react';

import { Input, Select, Button, Switch, LoaderOverlay, DataTable } from 'sazzui/lib/main';

import useNotification from 'services/hooks/use_notification';

import Settings from 'services/rest/settings';

import ICON_ARROW_UP from 'assets/images/icons/ico_sort_up.svg';
import ICON_ARROW_DOWN from 'assets/images/icons/ico_sort_down.svg';

import './style.css';

const SystemSettingsReciprocityFeeThresholds = () => {
	const [showLoader, setShowLoader] = useState(false);
	const [regions, setRegions] = useState([]);
	const [services, setServices] = useState([]);
	const [currencies, setCurrencies] = useState([]);
	const [thresholds, setThresholds] = useState([]);
	const [regionsFilter, setRegionsFilter] = useState('');
	const overReciprocateMap = useRef({});
	const sendNotification = useNotification();

	useEffect(async () => {
		try {
			await fetchData();
		} catch (err) {
			console.log(err);
		}
	}, []);

	const fetchData = async () => {
		try {
			setShowLoader(true);
			let [rs, ss, cs, ts] = await Promise.all([Settings.GetRegions(), Settings.GetServices(), Settings.GetCurrencies(), Settings.GetReciprocityFeeThresholds()]);

			rs.forEach((r) => {
				let rt = ts.filter((t) => t.region_id === r.id);
				let or = rt.length && rt.every((t) => t.over_reciprocate === 1) ? true : false;
				overReciprocateMap.current[r.id] = or;
			});

			setCurrencies(cs.map((c) => ({ value: c.code, label: c.code })));
			setServices(ss);
			setThresholds(ts.map((t) => ({ ...t, modified: false })));
			setRegions(rs);
			setShowLoader(false);
		} catch (err) {
			console.log(err);
			setShowLoader(false);
		}
	};

	const onRegionsFilterChange = (e) => {
		setRegionsFilter(e.target.value);
	};

	const updateServiceThresholds = (field, region_id, service_id, value) => {
		let defaultValues = {
			region_id: region_id,
			service_id: service_id,
			over_reciprocate: overReciprocateMap.current[region_id],
			professional_fee_max: 0,
			professional_fee_min: 0,
			translation_fee: 0,
			currency: 'USD',
		};

		switch (field) {
			case 'over_reciprocate':
				// find all thresholds records for current region
				let regionThresholds = thresholds.filter((t) => t.region_id === region_id);
				if (regionThresholds.length) {
					overReciprocateMap.current[region_id] = !overReciprocateMap.current[region_id];
					setThresholds((prevState) =>
						prevState.map((t) => {
							if (t.region_id === region_id) {
								return { ...t, over_reciprocate: overReciprocateMap.current[region_id] ? 1 : 0, modified: true };
							}
							return t;
						}),
					);
				}
				break;
			default:
				// check if specified record exists (if not empty)
				let exists = thresholds.find((t) => t.service_id === service_id && t.region_id === region_id);
				if (!exists) {
					setThresholds((prevState) => [...prevState, { ...defaultValues, [field]: value, modified: true }]);
				} else {
					setThresholds((prevState) =>
						prevState.map((t) => {
							if (t.region_id === region_id && t.service_id === service_id) {
								return { ...t, [field]: value, modified: true };
							}
							return t;
						}),
					);
				}
				break;
		}
	};

	const saveThresholds = async () => {
		try {
			setShowLoader(true);
			await Settings.UpdateReciprocityFeeThresholds(thresholds.filter((t) => t.modified).map(({ modified, ...rest }) => rest));
			setShowLoader(false);
			sendNotification({ type: 'success', title: 'Successfully saved reciprocity fee thresholds' });
			await fetchData();
		} catch (e) {
			setShowLoader(false);
			sendNotification({ type: 'error', title: 'Reciprocity fee thresholds save failed' });
		}
	};

	return (
		<div className="main-screen-section white full-height no-padding system-details-content">
			<LoaderOverlay showLoader={showLoader} />
			<div className="system-details-content__body system-settings-reciprocity-fee-thresholds">
				<div className="system-settings-reciprocity-fee-thresholds__header">
					<div className="system-settings-reciprocity-fee-thresholds__header__filter">
						<Input label="Filter Regions" name="FilterRegions" type="text" onChange={onRegionsFilterChange} value={regionsFilter} />
					</div>
				</div>
				<div className="system-settings-reciprocity-fee-thresholds__regions-list">
					{regions
						.filter((r) => regionsFilter === '' || r.name.toLowerCase().includes(regionsFilter.toLowerCase()))
						.map((r) => (
							<MemoizedRegionSection
								key={r.id}
								region={r}
								services={services}
								currencies={currencies}
								thresholds={thresholds.filter((t) => t.region_id === r.id)}
								overReciprocate={overReciprocateMap.current[r.id]}
								onChange={updateServiceThresholds}
							/>
						))}
				</div>
			</div>
			<footer className="system-details-content__footer">
				<Button onClick={saveThresholds}>Save</Button>
			</footer>
		</div>
	);
};

const RegionSection = (props) => {
	const tableHeaders = [
		{ title: 'Service', field: '', type: 'string', sortable: false },
		{ title: 'High Filing Fee', field: '', type: 'number', sortable: false },
		{ title: 'Low Filing Fee', field: '', type: 'number', sortable: false },
		{ title: 'Default Translation Fee', field: '', type: 'number', sortable: false },
		{ title: 'Currency', field: '', type: 'string', sortable: false },
	];

	const [active, setActive] = useState(false);
	const [arrow, setArrow] = useState(ICON_ARROW_DOWN);

	useEffect(() => {
		setArrow(active ? ICON_ARROW_UP : ICON_ARROW_DOWN);
	}, [active]);

	const showHide = () => {
		setActive(!active);
	};

	const nameGenerator = (serviceID, name) => {
		return `${name}__${props.region.id}__${serviceID}`;
	};

	const getServiceThresholds = (serviceID) => {
		let serviceThresholds = props.thresholds?.find((ts) => ts.service_id == serviceID);
		if (serviceThresholds) return serviceThresholds;
		return {
			professional_fee_max: '',
			professional_fee_min: '',
			translation_fee: '',
			currency: 'USD',
		};
	};

	const fieldChange = (e) => {
		let args = e.target.name.split('__');
		props.onChange(args[0], args[1], args[2], e.target.value);
	};

	const onOverReciprocateChange = (e) => {
		let args = e.target.name.split('__');
		props.onChange(args[0], args[1], null, e.target.value);
	};

	const stopPropagation = (e) => {
		e.stopPropagation();
	};

	return (
		<div className={`region-section-content ${active ? 'active' : ''}`}>
			<div className="region-section-content__header" onClick={showHide}>
				<img src={arrow} />
				<span className="region-section-content__header__title">{props.region.name}</span>
				<Switch label="OVER RECIPROCATE" name={`over_reciprocate__${props.region.id}`} value={props.overReciprocate} onChange={onOverReciprocateChange} onClickParent={stopPropagation} />
			</div>
			{active ?
				<DataTable fixedHeader={true} columns={tableHeaders}>
					{props.services.map((srv) => {
						let t = getServiceThresholds(srv.id);
						return (
							<tr key={`${props.region.id}_${srv.id}`}>
								<DataTable.Cell>{srv.name}</DataTable.Cell>
								<td>
									<Input name={nameGenerator(srv.id, 'professional_fee_max')} type="number" value={t.professional_fee_max} onChange={fieldChange} />
								</td>
								<td>
									<Input name={nameGenerator(srv.id, 'professional_fee_min')} type="number" value={t.professional_fee_min} onChange={fieldChange} />
								</td>
								<td>
									<Input name={nameGenerator(srv.id, 'translation_fee')} type="number" value={t.translation_fee} onChange={fieldChange} />
								</td>
								<td>
									<Select name={nameGenerator(srv.id, 'currency')} options={props.currencies} value={t.currency} onChange={fieldChange} />
								</td>
							</tr>
						);
					})}
				</DataTable>
			:	null}
		</div>
	);
};

const arePropsEqual = (oldProps, newProps) => {
	return JSON.stringify(oldProps.thresholds) === JSON.stringify(newProps.thresholds);
};
const MemoizedRegionSection = memo(RegionSection, arePropsEqual);

export default SystemSettingsReciprocityFeeThresholds;
