import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import { Input, Select, Button, Modal } from 'sazzui/lib/main';

import ReciprocityPointsTable from 'com/widgets/ReciprocityPointsTable';

const SystemSettingsReciprocityRatios = (props) => {
	const [filter, setFilter] = useState('');
	const [filteredRegions, setFilteredRegions] = useState([]);
	const [selectedRegionGroupId, setSelectedRegionGroupId] = useState('');
	const [selectedRegionGroup, setSelectedRegionGroup] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [servicePointsForRegion, setServicePointsForRegion] = useState([]);

	const filterRegions = (term) => {
		if (term === '') {
			setFilteredRegions([]);
			setSelectedRegionGroupId('');
		} else {
			const filteredData = props.regionsWithPoints.filter((region) => {
				return region.name.toLowerCase().includes(term.toLowerCase());
			});
			setFilteredRegions(filteredData);
			setSelectedRegionGroupId('');
		}
	};

	const handleSelectRegion = (e) => {
		const regionGroup = props.regionsGroups.find((rg) => rg.id == e.target.value);
		const regions = regionGroup.regions.map((r) => {
			const regionWithPoints = props.regionsWithPoints.find((rp) => rp.id === r.id);
			r = regionWithPoints;
			return r;
		});

		setFilteredRegions(regions);
		setSelectedRegionGroupId(e.target.value);
		setSelectedRegionGroup(regions);
	};

	const closeGroupPointsModal = () => {
		setModalOpen(false);
		setServicePointsForRegion([]);
	};

	const openGroupPointsModal = () => {
		setModalOpen(true);
	};

	const handleChange = (e) => {
		setFilter(e.target.value);
	};

	const setGroupPointsChange = () => {
		const regionIds = selectedRegionGroup.map((r) => r.id);
		setServicePointsForRegion([]);
		props.applyRegionGroupPoints(servicePointsForRegion, regionIds);
		setModalOpen(false);
	};

	const createGroupPointsModalFooterActions = [
		{ primary: false, label: 'Cancel', action: closeGroupPointsModal, theme: 'azami-ghost' },
		{ primary: true, label: 'Apply', action: setGroupPointsChange, theme: 'azami-blue' },
	];

	useEffect(() => {
		if (filter.length > 2 || filter === '') {
			filterRegions(filter);
		}
	}, [filter]);

	return (
		<div className="system-settings-reciprocity-ratios">
			<div className="system-settings-reciprocity-ratios__header">
				<div className="system-settings-reciprocity-ratios__header__left">
					<Input label="Filter Regions" name="FilterRegions" type="text" value={filter} onChange={(e) => handleChange(e)} />
					<Select label="Select Group" name="SelectGroup" options={props.regionsGroupsSelectOptions} value={selectedRegionGroupId} onChange={(e) => handleSelectRegion(e)} />
				</div>
				{selectedRegionGroupId && <Button onClick={openGroupPointsModal}>Set Group Ratios</Button>}
			</div>
			<div className="system-settings-reciprocity-ratios__main-screen">
				<List
					regionsWithPoints={props.regionsWithPoints}
					services={props.services}
					updatePoints={props.updatePoints}
					filteredRegions={filteredRegions}
					setRegionsWithPoints={props.setRegionsWithPoints}
				/>
			</div>
			{modalOpen && (
				<Modal title="Set Group Points" closeHandler={closeGroupPointsModal} footerActions={createGroupPointsModalFooterActions}>
					<div>
						<ReciprocityPointsTable
							services={props.services}
							modal={modalOpen}
							selectedRegionGroup={selectedRegionGroup}
							servicePointsForRegion={servicePointsForRegion}
							setServicePointsForRegion={setServicePointsForRegion}
							updatePoints={props.updatePoints}
						/>
					</div>
				</Modal>
			)}
		</div>
	);
};

const List = (props) => {
	return (
		<>
			{props.filteredRegions.length > 0 ?
				props.filteredRegions.map((r) => <MemoizedListItem key={r.id} region={r} services={props.services} updatePoints={props.updatePoints} points={r ? r.points : []} />)
			:	props.regionsWithPoints.map((r) => <MemoizedListItem key={r.id} region={r} services={props.services} updatePoints={props.updatePoints} points={r ? r.points : []} />)}
		</>
	);
};

const ListItem = (props) => {
	return (
		<details className="system-settings-reciprocity-ratios__main-screen__details">
			<summary className="system-settings-reciprocity-ratios__main-screen__summary">{props.region.name}</summary>
			<ReciprocityPointsTable
				region={props.region}
				services={props.services}
				modal={false}
				updatePoints={props.updatePoints}
				points={props.region ? props.region.points : []}
				setRegionsWithPoints={props.setRegionsWithPoints}
			/>
		</details>
	);
};

const MemoizedListItem = memo(ListItem);

SystemSettingsReciprocityRatios.propTypes = {
	regions: PropTypes.array,
	regionsGroups: PropTypes.array,
	services: PropTypes.array,
	regionsGroupsSelectOptions: PropTypes.array,
	regionsWithPoints: PropTypes.array,
	applyRegionGroupPoints: PropTypes.func,
	updatePoints: PropTypes.func,
	setRegionsWithPoints: PropTypes.func,
};

SystemSettingsReciprocityRatios.defaultProps = {
	regions: [],
	regionsGroups: [],
	services: [],
	regionsGroupsSelectOptions: [],
	regionsWithPoints: [],
	applyRegionGroupPoints: () => {},
	updatePoints: () => {},
	setRegionsWithPoints: () => {},
};

List.propTypes = {
	regionsWithPoints: PropTypes.array,
	services: PropTypes.array,
	updatePoints: PropTypes.func,
	filteredRegions: PropTypes.array,
	setRegionsWithPoints: PropTypes.func,
};

List.defaultProps = {
	regionsWithPoints: [],
	services: [],
	updatePoints: () => {},
	filteredRegions: [],
	setRegionsWithPoints: () => {},
};

MemoizedListItem.propTypes = {
	region: PropTypes.object,
	services: PropTypes.array,
	updatePoints: PropTypes.func,
	points: PropTypes.array,
};

MemoizedListItem.defaultProps = {
	region: { points: [] },
	services: [],
	updatePoints: () => {},
	points: [],
};

export default SystemSettingsReciprocityRatios;
