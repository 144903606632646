import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//System Imports
import { useState } from 'react';
//Icon Imports
import ICON_ACTIONS_MENU from '../../../../src/assets/images/icons/ico_actions_menu.svg';
;
export const ActionsMenuCell = ({ actions = [], customClassName = "", }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenuOpen = (e) => {
        e.stopPropagation();
        setMenuOpen(!menuOpen);
    };
    return (_jsx("td", { children: _jsxs("div", { className: `table-cell table-cell-actions-menu ${customClassName}`, children: [_jsx("button", { className: 'toggle-menu-btn', onClick: toggleMenuOpen, "aria-label": 'toggle-menu-action', children: _jsx("img", { src: ICON_ACTIONS_MENU, alt: "menu" }) }), menuOpen ? _jsx("ul", { className: "actions-menu-list", children: actions.length > 0 ? actions.map((item, key) => {
                        return (_jsx("li", { className: `${item.disabled ? 'disabled' : ""}`, "data-id": item.dataset.id || "", children: _jsxs("button", { onClick: item.disabled ? (e) => { e.stopPropagation(); } : item.action, title: item.title, children: [item.ico && _jsx("img", { src: item.ico, alt: "" }), item.label] }) }, key));
                    }) : null }) : null] }) }));
};
