import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { setQuoteData } from 'redux/ducks/quote';
import { setIsUpdated, REDIRECT_FROM_QUOTE_MSG } from 'redux/ducks/redirect';

import { getFlag } from 'data/flags';

import Settings from 'services/rest/settings';
import Firms from 'services/rest/firms';

import { Input, Checkbox, Loader } from 'sazzui/lib/main';
import { RoleBox } from 'com/util/RoleBox';

import { INTERNAL_ROLES, SERVICE_KEYS, EUROPEAN_VALIDATION_EXT_REGIONS, ROLE_TYPES, REGION_KNOWLEDGE_URL } from 'data/constants';

import ICON_HYPERLINK from 'assets/images/icons/ico_hyperlink.svg';

import './style.css';

const QuoteRegionPicker = (props) => {
	const [search, setSearch] = useState('');
	const [regions, setRegions] = useState([]);
	const [displayRegions, setDisplayRegions] = useState([]);
	const [loader, setLoader] = useState(false);
	const user = useSelector((state) => state.auth.user);
	const quote = useSelector((state) => state.quote);
	const dispatch = useDispatch();

	useEffect(() => {
		(async () => {
			try {
				if (quote.service_id) {
					setLoader(true);
					let regionData = await Settings.GetRegions({ service: quote.service_id });
					if (user.roleType === ROLE_TYPES.EXTERNAL) {
						if (quote.application_language) {
							let service = props.services.find((s) => s.id === quote.service_id);
							let english_l = props.languages.find((l) => l.code === 'EN');
							let selected_l = props.languages.find((l) => l.value === quote.application_language);
							if (service && service.key !== SERVICE_KEYS.TRANSLATIONS) {
								regionData = regionData.filter(
									(r) => !r.is_translation || (selected_l.value !== english_l.value && r.translation_from === selected_l.value && r.translation_to === english_l.value),
								);
							}
						}
						let firm = await Firms.GetOneByIDSlim(user.fid);
						regionData = regionData.filter(
							(r) =>
								!firm.regions.some((rd) => {
									return rd.region_id === r.id && !rd.is_archived;
								}),
						);
					}

					setRegions([
						...regionData.map((c) => {
							return { ...c, label: c.name, value: c.id };
						}),
					]);

					setDisplayRegions([
						...regionData.map((c) => {
							return { ...c, label: c.name, value: c.id };
						}),
					]);

					if (quote.regions.length > 0) {
						// Filter regions that might have been selected but are not available for changed service.
						let ar = quote.regions.filter((r) =>
							regionData.some((rd) => {
								return rd.id === r;
							}),
						);
						dispatch(setQuoteData({ name: 'regions', value: ar }));
						if (quote.additional_region_info.length > 0) {
							let ari = quote.additional_region_info.filter((r) =>
								ar.some((rd) => {
									return r.region_id === rd;
								}),
							);
							dispatch(setQuoteData({ name: 'additional_region_info', value: ari }));
						}
					}
					setLoader(false);
				}
			} catch (error) {
				setLoader(false);
				console.log(error);
			}
		})();
	}, [quote.service_id, quote.application_language]);

	const sort = (regions) => {
		// Generate an array of selected region objects with name, id, code, and sort them by their name
		let selected_regions = [];
		selected_regions = regions.map((r) => {
			// use all regions for searching of selected regions in case there is region added by internal user.
			let region = props.regions.find((reg) => reg.id === r);
			if (region) {
				return { id: region.id, code: region.code, name: region.name, is_knowledge_base_item: region.is_knowledge_base_item };
			}
		});
		selected_regions.sort((a, b) => {
			let na = a.name.toLowerCase(),
				nb = b.name.toLowerCase();
			if (na < nb) return -1;
			if (na > nb) return 1;
			return 0;
		});
		return selected_regions;
	};

	const inputChange = (e) => {
		setSearch(e.target.value);
		let str = e.target.value.toLowerCase().trim();
		let matches = regions.filter((element) => {
			let region_label = `${element.name}${element.code}`.toLowerCase().trim();
			if (props.selectedServiceKey === SERVICE_KEYS.PCT) {
				region_label = `${element.name}${element.code}${element.deadline ? element.deadline : ''}`;
			}
			region_label = region_label.toLowerCase().trim();
			if (region_label.includes(str)) {
				return true;
			}
		});
		setDisplayRegions(matches);
	};

	const selectAll = () => {
		let tmp = [];
		let region_data = [];
		regions.map((r) => {
			tmp.push(r.id);
			region_data.push({
				region_id: r.id,
				associate_id: '',
				region_name: r.name,
				region_code: r.code,
				rate_official: 0,
				rate_professional: 0,
				rate_translation: 0,
				total: 0,
				comment_for_agent: '',
				is_visible: 1,
			});
		});
		dispatch(setQuoteData({ name: 'regions', value: tmp }));
		dispatch(setQuoteData({ name: 'additional_region_info', value: region_data }));
		dispatch(setIsUpdated({ isUpdated: true, checkExact: true, message: REDIRECT_FROM_QUOTE_MSG }));
	};

	const selectAllExt = () => {
		let tmp = [];
		let region_data = [];
		regions.map((r) => {
			if (!EUROPEAN_VALIDATION_EXT_REGIONS.includes(r.code) && !r.name.startsWith('Translation:')) {
				tmp.push(r.id);
				region_data.push({
					region_id: r.id,
					associate_id: '',
					region_name: r.name,
					region_code: r.code,
					rate_official: 0,
					rate_professional: 0,
					rate_translation: 0,
					total: 0,
					comment_for_agent: '',
					is_visible: 1,
				});
			}
		});
		dispatch(setQuoteData({ name: 'regions', value: tmp }));
		dispatch(setQuoteData({ name: 'additional_region_info', value: region_data }));
		dispatch(setIsUpdated({ isUpdated: true, checkExact: true, message: REDIRECT_FROM_QUOTE_MSG }));
	};

	const removeAll = () => {
		dispatch(setQuoteData({ name: 'regions', value: [] }));
		dispatch(setQuoteData({ name: 'additional_region_info', value: [] }));
		dispatch(setIsUpdated({ isUpdated: true, checkExact: true, message: REDIRECT_FROM_QUOTE_MSG }));
		if (!props.editQuote) {
			props.setSelectedRegionsForInstruct([]);
		}
	};

	const regionPickerOnChange = (e) => {
		if (props.blockAddRegions) return;
		if (!isRegionRemovable(e.target.name)) return;
		let regions = [];
		let region_data = [];
		if (e.target.checked) {
			let region = props.regions.find((reg) => reg.id === e.target.name);
			regions = [...quote.regions, e.target.name];
			region_data = [
				...quote.additional_region_info,
				{
					region_id: region.id,
					associate_id: '',
					region_name: region.name,
					region_code: region.code,
					rate_official: 0,
					rate_professional: 0,
					rate_translation: 0,
					total: 0,
					comment_for_agent: '',
					is_visible: 1,
				},
			];
		} else {
			regions = [...quote.regions.filter((r) => r !== e.target.name)];
			region_data = [...quote.additional_region_info.filter((r) => r.region_id !== e.target.name)];
			if (!props.editQuote) {
				props.setSelectedRegionsForInstruct([...props.selectedRegionsForInstruct.filter((r) => r !== e.target.name)]);
			}
		}
		dispatch(setQuoteData({ name: 'regions', value: regions }));
		dispatch(setQuoteData({ name: 'additional_region_info', value: region_data }));
		dispatch(setIsUpdated({ isUpdated: true, checkExact: true, message: REDIRECT_FROM_QUOTE_MSG }));
	};

	const isRegionRemovable = (region_id) => {
		return !props.addRegions || (user.roleType === ROLE_TYPES.INTERNAL && quote.state === 'QUOTE') || !props.quoteRegions.includes(region_id);
	};

	const removeRegion = (e) => {
		let regions = [...quote.regions.filter((r) => r !== e.target.dataset.id)];
		let region_data = [...quote.additional_region_info.filter((r) => r.region_id !== e.target.dataset.id)];
		dispatch(setQuoteData({ name: 'regions', value: regions }));
		dispatch(setQuoteData({ name: 'additional_region_info', value: region_data }));
		dispatch(setIsUpdated({ isUpdated: true, checkExact: true, message: REDIRECT_FROM_QUOTE_MSG }));
		if (!props.editQuote) {
			props.setSelectedRegionsForInstruct([...props.selectedRegionsForInstruct.filter((r) => r !== e.target.dataset.id)]);
		}
	};

	return (
		<div className="quote-details__section-container quote-region-picker">
			{loader ?
				<div className="services_technologies-loader">
					<div className="services_technologies-loader__positioner">
						{loader ?
							<Loader theme="primary" />
						:	null}
					</div>
				</div>
			:	null}
			{/* <h4 className={`quote-details__subtitle ${props.errors.regions ? 'section-title-error' : ''}`}>3 Regions</h4> */}
			<div className="region-picker-container">
				<div className="region-picker-container__left">
					<Input name="search" placeholder="Search" value={search} onChange={inputChange} ariaLabel="Search Input" />
					<div className="region-picker-container__left-filters">
						<div className="region-picker-container__left-filters-left">
							{props.selectedServiceKey !== SERVICE_KEYS.EP ?
								<RoleBox roles={INTERNAL_ROLES}>
									<span onClick={selectAll}>Select All</span>
								</RoleBox>
							:	null}

							{props.selectedServiceKey === SERVICE_KEYS.EP ?
								<span onClick={selectAllExt}>Select All (Except Ext. States)</span>
							:	null}
						</div>
						{!props.addRegions ?
							<div className="region-picker-container__left-filters-right">
								<span onClick={removeAll}>Remove All</span>
							</div>
						:	null}
					</div>
					<ul className="region-picker-container__left-list">
						{displayRegions.map((o, i) => {
							return (
								<li key={i}>
									<Checkbox
										name={o.value}
										label={o.label}
										onChange={regionPickerOnChange}
										img={getFlag(o.code)}
										checked={quote.regions.includes(o.value)}
										ariaLabel="Regions Checkbox"
									/>
									<span className="region-picker-container__left-list-code">{`${o.code}${
										props.selectedServiceKey === SERVICE_KEYS.PCT ?
											o.deadline ?
												` ${o.deadline}`
											:	''
										:	''
									}`}</span>
								</li>
							);
						})}
					</ul>
				</div>

				<div className={`region-picker-container__right ${user.role.includes('saas_user') && 'border'}`}>
					<div className="region-picker-container__right-counter">{quote.regions && quote.regions.length > 0 ? `${quote.regions.length} Regions Selected` : `No Regions Selected`}</div>
					<ul className="region-picker-container__right-selected">
						{sort(quote.regions).map((r) => {
							return r ?
									<li key={r?.id}>
										{r?.name}
										{isRegionRemovable(r.id) ?
											<button data-id={r?.id} onClick={removeRegion}>
												&times;
											</button>
										:	null}
									</li>
								:	null;
						})}
					</ul>
				</div>
				<RoleBox roles={['saas_user']}>
					<div className="region-picker-container__knowledgebase">
						<span className="region-picker-container__knowledgebase-header">Knowledgebase</span>
						<span className="region-picker-container__knowledgebase-notice">Please take a moment to review the attached links: </span>
						<ul className="region-picker-container__knowledgebase-list">
							{sort(quote.regions).map((r) => {
								return r?.is_knowledge_base_item ?
										<li class="region-picker-container__knowledgebase-list-item">
											<img src={ICON_HYPERLINK} />
											<a href={`${REGION_KNOWLEDGE_URL}/${r?.code.toLowerCase()}`} target="_blank">
												{r?.name}
											</a>
										</li>
									:	<></>;
							})}
						</ul>
					</div>
				</RoleBox>
			</div>
		</div>
	);
};

QuoteRegionPicker.propTypes = {
	setDocuments: PropTypes.func,
};

QuoteRegionPicker.defaultProps = {
	setDocuments: () => {},
};

export default QuoteRegionPicker;
