import React, { useState, useEffect } from 'react';

import { AppRoute } from 'com/util/AppRoute';
import Routes from 'data/routes';

import { LoaderOverlay } from 'sazzui/lib/main';

import Banner from 'services/rest/banner';

import './style.css';

const SystemSettingsBanners = () => {
	const filterInit = {
		orderBy: [],
	};
	const [bannerData, setBannerData] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	const [filterData, setFilterData] = useState(filterInit);

	const fetchData = async () => {
		setShowLoader(true);
		const filter = {
			...filterData,
			order_by: filterData.orderBy.map((c) => `${c.field}:${c.direction == 'up' ? 'asc' : 'desc'}`).join(','),
		};

		try {
			let res = await Banner.GetAll(filter);
			setBannerData(res);
			setShowLoader(false);
		} catch (error) {
			console.log(error);
			setShowLoader(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [filterData]);

	return (
		<div className="main-screen-section white full-height system-details-content">
			<LoaderOverlay showLoader={showLoader} />
			<AppRoute
				exact
				route={Routes.SYSTEM_SETTINGS_BANNER_LIST}
				bannerData={bannerData}
				setShowLoader={setShowLoader}
				showLoader={showLoader}
				filterData={filterData}
				setFilterData={setFilterData}
				fetchData={fetchData}
			/>
			<AppRoute exact route={Routes.SYSTEM_SETTINGS_BANNER_CREATE} setShowLoader={setShowLoader} fetchData={fetchData} />
			<AppRoute exact route={Routes.SYSTEM_SETTINGS_BANNER_UPDATE} setShowLoader={setShowLoader} fetchData={fetchData} />
		</div>
	);
};

export default SystemSettingsBanners;
