import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import defaultIcon from '../../assets/images/icons/ico_comment_blue.svg';
import './style.css';
export const Tooltip = ({ theme = 'primary', icon = defaultIcon, ...props }) => {
    const [active, setActive] = useState(false);
    const showTip = () => {
        setActive(true);
    };
    const hideTip = () => {
        setActive(false);
    };
    return (_jsxs("div", { className: "tooltip-wrapper", onMouseEnter: showTip, onMouseLeave: hideTip, children: [_jsx("span", { className: `icon ${theme}`, style: { maskImage: `url(${icon})` } }), active && _jsx("div", { className: `tooltip ${props.direction || 'top'} ${props.content.length > 50 ? 'long' : ''}`, children: props.content })] }));
};
