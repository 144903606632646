import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from '../Select';
import './style.css';
export const TagSelect = ({ theme = 'primary', name = '', label = '', options = [], values = [], onChange = () => { }, tagsOnTop = false, customClassName = '', error = '', value = '', ariaLabel = '' }) => {
    const propsName = name ? name : 'tags';
    const ariaLabelValue = ariaLabel || label || '';
    const addTag = (e) => {
        const out = {
            target: {
                name: propsName,
                value: [...values.filter((v) => v != e.target.value), e.target.value],
            },
        };
        onChange(out);
    };
    const removeTag = (e) => {
        const out = {
            target: {
                name: propsName,
                value: values.filter((v) => v != e.target.dataset.tag),
            },
        };
        onChange(out);
    };
    return (_jsxs("div", { className: `form-tag-select ${customClassName}`, children: [!tagsOnTop ? _jsx(Select, { label: label, name: propsName, theme: theme, options: options, onChange: addTag, error: error, value: String(value), ariaLabel: ariaLabelValue }) : null, _jsx("ul", { className: "tag-list", children: values
                    ? values.map((t, i) => {
                        const opt = options.filter((o) => o.value === t);
                        const label = opt.length > 0 ? opt[0].label : '';
                        return (_jsxs("li", { className: "tag", children: [_jsx("span", { className: "tag-label", children: label }), _jsx("button", { className: "tag-close", name: propsName, onClick: removeTag, "data-tag": t, children: "\u00D7" })] }, i));
                    })
                    : null }), tagsOnTop ? _jsx(Select, { label: label, name: propsName, theme: theme, options: options, onChange: addTag, error: error, value: String(value), ariaLabel: ariaLabelValue }) : null] }));
};
