import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AppRoute } from 'com/util/AppRoute';
import { RoleBox } from 'com/util/RoleBox';
import Routes from 'data/routes';

import AppTemplate from 'com/templates/ApplicationTemplate';
import { DataTable } from 'sazzui/lib/main';

import { DownloadCaseReport, GetCaseReport } from 'services/rest/reports';
import Reciprocity from 'services/rest/reciprocity';

import { POINTS_TRANSCATION_DIRECTION, PAGE_TITLE } from 'data/constants';

import { Input, Button, IconButton, LoaderOverlay, Tooltip } from 'sazzui/lib/main';

import useTitle from 'services/hooks/useTitle';
import useFilterStorage from 'services/hooks/useFilterStorage';

import './style.css';

import ICON_FUNNEL from 'assets/images/icons/ico_funnel.svg';
import ICON_PDF from 'assets/images/icons/ico_pdf.svg';
import ICON_CLIENT from 'assets/images/icons/ico_client_azami.svg';
import ICON_ASSOCIATE from 'assets/images/icons/ico_associate_azami.svg';

const CaseReport = () => {
	useTitle(PAGE_TITLE.CASE_REPORT);

	let initCasesData = [];

	const [casesData, setCasesData] = useState(initCasesData);
	const [caseNumber, setCaseNumber] = useState('');
	const [showLoader, setShowLoader] = useState(false);
	const { getFilterValue, setFilterValue } = useFilterStorage();
	const [caseNumberError, setCaseNumberError] = useState(false);

	const validateFilter = (caseNumber) => {
		let caseNum = caseNumber.toUpperCase();
		let pattern = /^PN\d{4,8}$/;
		let result = pattern.test(caseNum);
		return result;
	};

	const onFilterChange = (e) => {
		setCaseNumber(e.target.value);
	};

	const filterSubmit = () => {
		let validCase = validateFilter(caseNumber);
		if (validCase) {
			setCaseNumberError(false);
			fetchCaseData(caseNumber);
			setFilterValue(caseNumber);
		} else {
			setCaseNumberError(true);
			return;
		}
	};

	const clearFilter = () => {
		setCaseNumber('');
		setCasesData(initCasesData);
		setFilterValue(null);
	};

	const fetchCaseData = (filter) => {
		try {
			setShowLoader(true);
			GetCaseReport(filter ? filter : caseNumber)
				.then((data) => {
					setCasesData(data);
					setShowLoader(false);
				})
				.catch((err) => {
					console.log(err);
					setShowLoader(false);
				});
		} catch (err) {
			setShowLoader(false);
		}
	};

	useEffect(() => {
		let f = getFilterValue();
		if (f) {
			setCaseNumber(f);
			fetchCaseData(f);
		}
	}, []);

	const reportTableHeadersInit = [
		{ title: 'Date', field: 'case_instruction_date', type: 'date', sortable: true, sort: 'none' },
		{ title: 'Case Number', field: 'case_number', type: 'casenum', sortable: true, sort: 'none' },
		{ title: 'Region', field: 'region', type: 'string', sortable: true, sort: 'none' },
		{ title: 'Service', field: 'service', type: 'string', sortable: true, sort: 'none' },
		{ title: 'Client Firm', field: 'client_firm_name', sortable: true, type: 'string', sort: 'none' },
		{ title: 'Client Name', field: 'client_first_name', sortable: true, type: 'string', sort: 'none' },
		{ title: 'Client Points', field: 'client_points', sortable: true, type: 'number', sort: 'none' },
		{ title: 'Associate Firm', field: 'associate_firm_name', sortable: true, type: 'string', sort: 'none' },
		{ title: 'Associate Name', field: 'associate_first_name', sortable: true, type: 'string', sort: 'none' },
		{ title: 'Associate Points', field: 'associate_points', sortable: true, type: 'number', sort: 'none' },
	];

	const [reportTableHeaders, setReportTableHeaders] = useState(reportTableHeadersInit);

	const columnSort = (f, t) => {
		let dir = 'up';

		setReportTableHeaders(
			reportTableHeaders.map((e) => {
				if (e.field === f) {
					e.sort = e.sort === 'up' ? 'down' : 'up';
					dir = e.sort === 'up' ? 'down' : 'up';
				}
				return e;
			}),
		);

		let sorted = casesData.sort((a, b) => {
			let x = null;
			let y = null;

			switch (t) {
				case 'date':
					x = new Date(a[f]).getTime();
					y = new Date(b[f]).getTime();
					break;
				case 'number':
					x = Number(a[f]);
					y = Number(b[f]);
					break;
				case 'string':
					x = a[f].toLowerCase();
					y = b[f].toLowerCase();
					break;
				case 'casenum':
					x = Number(a[f].replace('PN', ''));
					y = Number(b[f].replace('PN', ''));
					break;
				default:
			}

			if (dir === 'up') {
				if (x > y) {
					return -1;
				}
				if (x < y) {
					return 1;
				}
			} else if (dir === 'down') {
				if (y > x) {
					return -1;
				}
				if (y < x) {
					return 1;
				}
			}
			return 0;
		});

		setCasesData(sorted);
	};

	const printReport = async () => {
		await DownloadCaseReport(casesData);
	};

	const handleKeyDown = (e) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			filterSubmit();
		}
	};

	return (
		<AppTemplate title="Case Report">
			<AppTemplate.Sidebar>
				<div className="reports-sidebar">
					<h4 className="reports-sidebar__header">
						<img src={ICON_FUNNEL} alt="" />
						filters
					</h4>
					<Input required={true} error={caseNumberError} type="text" label="Case number" theme="dark" onChange={onFilterChange} value={caseNumber} onKeyDown={handleKeyDown} />
					<div className="reports-sidebar_actions sidebar_actions">
						<Button onClick={filterSubmit} size="small">
							Generate Report
						</Button>
						<Button theme="ghost-primary" onClick={clearFilter} size="small">
							Clear
						</Button>
					</div>
				</div>
			</AppTemplate.Sidebar>
			<AppTemplate.Content>
				<div className="main-screen-section subheader">
					<h2 className="main-screen-section__title">Report Summary</h2>
					<IconButton icon={ICON_PDF} onClick={printReport} />
				</div>
				<div className="main-screen-section white case-report">
					<LoaderOverlay showLoader={showLoader} />
					<DataTable fixedHeader={true} columns={reportTableHeaders} onColumnSort={columnSort}>
						{casesData.map((d, i) => {
							return <ExpandableRow key={i} data={d} />;
						})}
					</DataTable>
				</div>
				<AppRoute route={Routes.REPORTS_CASE_ADJUSTMENT} onChange={filterSubmit} exact />
			</AppTemplate.Content>
		</AppTemplate>
	);
};

const ExpandableRow = (props) => {
	const history = useHistory();
	const [detailsShow, setDetailsShow] = useState(false);
	const [transactions, setTransactions] = useState([]);

	const toggleDetails = async () => {
		if (!detailsShow) {
			let ts = await Reciprocity.GetAllTransactions({ case_number: props.data.case_number, region_id: props.data.region_id });
			setTransactions(ts);
		}
		setDetailsShow(!detailsShow);
	};

	const toggleShow = () => {
		return detailsShow && transactions.length;
	};

	const setQueryParams = () => {
		let qsa = [`case_number=${props.data.case_number}`, `regions=${props.data.region_code}`];
		return qsa.join('&');
	};

	const openAdjustPointsModal = (e) => {
		e.stopPropagation(); // stop toggleDetails event
		let { fid } = e.target.dataset;
		history.push(`/reports/case/firm/${fid}/adjustment?${setQueryParams()}`);
	};

	const openFirmDetails = (e) => {
		e.stopPropagation(); // stop toggleDetails event
	};

	return (
		<>
			<tr onClick={toggleDetails} className="pointer" style={{ backgroundColor: toggleShow() ? 'var(--clr-lightblue)' : '' }}>
				<DataTable.DateCell date={props.data.case_instruction_date} />
				<DataTable.Cell>{props.data.case_number}</DataTable.Cell>
				<DataTable.Cell>{props.data.region}</DataTable.Cell>
				<DataTable.Cell>{props.data.service}</DataTable.Cell>
				<DataTable.PersonCell subtext={props.data.client_firm_account_name} showIco={false}>
					<a onClick={openFirmDetails} href={`/firm-management/${props.data.client_firm_id}`}>
						{props.data.client_firm_name}
					</a>
				</DataTable.PersonCell>
				<DataTable.Cell>
					{props.data.client_first_name} {props.data.client_last_name}
				</DataTable.Cell>
				<DataTable.ContentWithActionCell>
					<span>{props.data.client_points}</span>
					<RoleBox roles={['admin', 'case_manager_manager', 'case_manager']}>
						<button className="adjust" onClick={openAdjustPointsModal} data-fid={props.data.client_firm_id} title="Adjust Points" />
					</RoleBox>
				</DataTable.ContentWithActionCell>
				<DataTable.PersonCell subtext={props.data.associate_firm_account_name} showIco={false}>
					<a onClick={openFirmDetails} href={`/firm-management/${props.data.associate_firm_id}`}>
						{props.data.associate_firm_name}
					</a>
				</DataTable.PersonCell>
				<DataTable.Cell>
					{props.data.associate_first_name} {props.data.associate_last_name}
				</DataTable.Cell>
				<DataTable.ContentWithActionCell>
					<span>{props.data.associate_points}</span>
					<RoleBox roles={['admin', 'case_manager_manager', 'case_manager']}>
						<button className="adjust" onClick={openAdjustPointsModal} data-fid={props.data.associate_firm_id} title="Adjust Points" />
					</RoleBox>
				</DataTable.ContentWithActionCell>
			</tr>
			{transactions.length ?
				<tr style={{ display: toggleShow() ? 'table-row' : 'none' }}>
					<td colSpan="10" className="drawer">
						<div>
							<table cellSpacing="0" cellPadding="0" className="nested-table">
								<thead>
									<tr>
										<th></th>
										<th>Date</th>
										<th>Case Number</th>
										<th>Firm Name</th>
										<th>Region</th>
										<th>Service</th>
										<th>Points</th>
										<th>Adjusted By</th>
										<th>Reason</th>
									</tr>
								</thead>
								<tbody>
									{transactions.map((d, i) => {
										return (
											<tr key={i} className={d.parent_id ? 'adjustment-row' : ''}>
												<DataTable.Cell>
													{d.direction === POINTS_TRANSCATION_DIRECTION.CLIENT ?
														<img src={ICON_CLIENT} alt="" />
													:	<img src={ICON_ASSOCIATE} alt="" />}
												</DataTable.Cell>
												<DataTable.DateCell date={d.case_created} />
												<DataTable.Cell>{d.case_number}</DataTable.Cell>
												<DataTable.Cell>{d.firm_name}</DataTable.Cell>
												<DataTable.CountryCell code={d.region_code}>{d.region_name}</DataTable.CountryCell>
												<DataTable.Cell>{d.service_name}</DataTable.Cell>
												<DataTable.PointsCell isAdjustmentRow={d.parent_id}>{d.amount}</DataTable.PointsCell>
												{d.parent_id ?
													<DataTable.StatusCell date={d._modified}>{d.points_adjusted_by}</DataTable.StatusCell>
												:	<DataTable.Cell></DataTable.Cell>}
												<DataTable.Cell>{d.parent_id && <Tooltip content={d.reason || d.custom_reason} direction="bottom"></Tooltip>}</DataTable.Cell>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</td>
				</tr>
			:	null}
		</>
	);
};

export default CaseReport;
