import { Button } from 'sazzui/lib/main';

import './style.css';

const QuoteActionsFooter = (props) => {
	return (
		<div className={`quote-actions-footer ${props.activeIndex !== 6 ? 'fixed' : ''}`}>
			<Button disabled={props.activeIndex === 1} onClick={props.footerPrevious} theme="light-primary" customClassName="new-quote__footer__btn-margin" ariaLabel="Previous Button">
				Previous
			</Button>
			<Button onClick={props.footerNext} showSpinner={props.requestSpinner} customClassName="new-quote__footer__btn-margin" ariaLabel="Next/Save Button">
				{props.footerBtnLabel}
			</Button>
			{props.activeIndex == 6 && (
				<Button showSpinner={props.requestSpinner} onClick={props.showInstructModal} ariaLabel="Show Instruct Cases Button">
					Instruct Cases
				</Button>
			)}
		</div>
	);
};

export default QuoteActionsFooter;
