//Template Imports
import AuthBox from 'com/templates/AuthBox';
//UI Imports
import AppLink from 'com/util/AppLink';
import { BackButton } from 'sazzui/lib/ui/BackButton';
import { Button } from 'sazzui/lib/ui/Button';
//ICON imports
import ICON_SUCCESS from 'assets/images/icons/ico_checked_success.svg';
//Hooks Imports
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
//Service Imports
import Auth from 'services/rest/auth';

//Style Imports
import './style.css';

const RegisterThankyouIPeer = () => {
	const [showEmailResentMessage, setShowEmailResentMessage] = useState(false);
	const location = useLocation();

	const handleOnClick = async () => {
		try {
			if (location.state) {
				await Auth.SendRegistrationEmail(location.state.email);
				setShowEmailResentMessage(true);
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div className="thankyou-container">
			<AuthBox
				title="Thank you for your registration"
				titleColor="dark-grey"
				icon="create_account"
				footer={
					<>
						{showEmailResentMessage ?
							<div className="thankyou-container__footer">
								<img src={ICON_SUCCESS} className="thankyou-container__footer__icon" />
								<div>Verification email resent. Please check you inbox or spam folder.</div>
							</div>
						:	null}
						<BackButton to="/" AppLink={AppLink} label="Back to login" />
					</>
				}
			>
				An email with a verification link is on it's way. Please verify your account in order to proceed with using The Azami Global portal.
				<div className="resend-email-container">
					Didn't get the email?
					<Button onClick={handleOnClick} theme="ghost-primary">
						Click here to resend
					</Button>
				</div>
			</AuthBox>
		</div>
	);
};

export default RegisterThankyouIPeer;
