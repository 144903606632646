import { useState } from 'react';

import { Modal, Switch } from 'sazzui/lib/main';

import Estimates from 'services/rest/estimates';
import EventLoggerEvents from 'services/rest/event_logger';

import { ESTIMATE_STATUS } from 'data/constants';

import './style.css';

const switchInitialState = {
	publish: true,
	push_to_system: true,
	send_email: true,
};

const QuoteDetailsViewRevisionsPublishModal = (props) => {
	const [switchState, setSwitchState] = useState(switchInitialState);

	const submit = async () => {
		let id = props.estimates[0].id;
		let event;
		if (switchState.publish && props.estimates[0].status === ESTIMATE_STATUS.DRAFT) {
			await Estimates.PublishEstimate(id, { published: true, quote_id: props.quoteID });
			if (switchState.send_email) {
				Estimates.SendEmail(props.quoteID, id);
			}
			if (switchState.push_to_system) {
				Estimates.SyncToCAS(props.caseNumber, 'QUOTE');
			}
			event = `Published Quote`;
		} else if (props.estimates[0].status === ESTIMATE_STATUS.PUBLISHED) {
			await Estimates.PublishEstimate(id, { published: false, quote_id: props.quoteID });
			event = `Unpublished Quote`;
		}

		if (!!event) {
			EventLoggerEvents.EventLogger({ event: event, data: { quote_id: id, user: props.user } });
		}
		props.refreshQuoteStatus(true);
		props.refreshEstimatesData();
		props.closeHandler();
	};

	const modalActions = [
		{ primary: false, label: 'Cancel', action: props.closeHandler, theme: 'primary' },
		{ primary: true, label: 'Confirm', action: submit, theme: 'primary' },
	];

	const switchChange = (e) => {
		setSwitchState({
			...switchState,
			[e.target.name]: e.target.checked,
		});
	};

	return (
		<Modal title={props.estimates[0].status === ESTIMATE_STATUS.DRAFT ? 'Additional Actions' : 'Unpublish Estimate'} closeHandler={props.closeHandler} footerActions={modalActions}>
			<div className="publish-estimate-modal">
				<div className="publish-estimate-modal__body">
					{props.estimates[0].status === ESTIMATE_STATUS.DRAFT ?
						<>
							<div>Select additional actions to perform on this estimate.</div>
							<div className="modify-currency-modal__body-switch">
								<ul>
									<li>
										<div>
											<Switch label="Publish this Estimate" name="publish" onChange={switchChange} value={switchState.publish} theme={'primary'} />
										</div>
									</li>
									<li>
										<div>
											<Switch label="Push to System" name="push_to_system" onChange={switchChange} value={switchState.push_to_system} theme={'primary'} />
										</div>
									</li>
									<li>
										<div>
											<Switch label="Send Email to AE" name="send_email" onChange={switchChange} value={switchState.send_email} theme={'primary'} />
										</div>
									</li>
								</ul>
							</div>
						</>
					:	<div>Are you sure you want to unpublish this estimate?</div>}
				</div>
			</div>
		</Modal>
	);
};

export default QuoteDetailsViewRevisionsPublishModal;
