import React, { useState, useEffect, useRef } from 'react';

import AppSwitch from 'com/util/AppSwitch';
import { AppRoute } from 'com/util/AppRoute';

import Routes from 'data/routes';

import AppTemplate from 'com/templates/ApplicationTemplate';
import AppLink from 'com/util/AppLink';

import { Button } from 'sazzui/lib/main';

import DuplicateManagementMain from './DuplicateManagementMain';

import { PAGE_TITLE } from 'data/constants';

import useTitle from 'services/hooks/useTitle';
import Settings from 'services/rest/settings';

import './style.css';

const APPLICANT = 'applicant';
const INVENTOR = 'inventor';
const AGENT = 'agent';
const DuplicateManagement = () => {
	const applicantsRef = useRef(null);
	useTitle(PAGE_TITLE.DUPLICATE_MANAGEMENT);

	const [countries, setCountries] = useState([{ label: '', value: '' }]);

	useEffect(async () => {
		try {
			let cs = await Settings.GetRegions();
			setCountries([...countries, ...cs.map((c) => ({ ...c, label: c.name, value: c.code }))]);
		} catch (e) {
			console.log(e);
		}
	}, []);

	const handleSave = async () => {
		await applicantsRef.current.saveChanges();
	};

	return (
		<AppTemplate title="Duplicate Management" headerIcon="duplicate_management">
			<AppTemplate.Content>
				<div className="main-screen-section white full-height no-padding tools-details-content">
					<header className="tools-details-content__header">
						<nav>
							<AppLink type="nav" to={`/tools/duplicate-management`} activeClassName="active" exact={true}>
								Applicants
							</AppLink>
							<AppLink type="nav" to={`/tools/duplicate-management/inventors`} activeClassName="active">
								Inventors
							</AppLink>
							<AppLink type="nav" to={`/tools/duplicate-management/agents`} activeClassName="active">
								Agents
							</AppLink>
						</nav>
					</header>
					<div className="tools-details-content__body">
						<AppSwitch>
							<AppRoute exact route={Routes.DUPLICATE_MANAGEMENT_APPLICANTS} outerRef={applicantsRef} countries={countries} entityType={APPLICANT} />
							<AppRoute exact route={Routes.DUPLICATE_MANAGEMENT_INVENTORS} outerRef={applicantsRef} countries={countries} entityType={INVENTOR} />
							<AppRoute exact route={Routes.DUPLICATE_MANAGEMENT_AGENTS} outerRef={applicantsRef} countries={countries} entityType={AGENT} />
						</AppSwitch>
					</div>
					<footer className="tools-details-content__footer">
						<Button onClick={handleSave}>Save</Button>
					</footer>
				</div>
			</AppTemplate.Content>
		</AppTemplate>
	);
};

export { DuplicateManagementMain };
export default DuplicateManagement;
