import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Button, Input, Textarea, Switch, Select, RadioButton, TagSelect, Checkbox, AppDate } from 'sazzui/lib/main';

import RegionPicker from 'com/widgets/RegionPicker';
import PricingLevelPreview from 'com/widgets/PricingLevelPreview';

import useNotification from 'services/hooks/use_notification';
import Settings from 'services/rest/settings';
import PricingLevel from 'services/rest/pricing_levels';
import Estimates from 'services/rest/estimates';
import { validateRule, regroupRanges, populateMissingAbsoluteFeesLevels, populateMissingByAmountFeesLevels } from 'services/validators/rules';

import ICON_ARROW_UP from 'assets/images/icons/ico_sort_up.svg';
import ICON_ARROW_DOWN from 'assets/images/icons/ico_sort_down.svg';
import ICON_INFO from 'assets/images/icons/ico_info_magenta.svg';

import Lists from './lists';
import './style.css';

const RULE_TYPE = {
	STANDARD: 'STANDARD',
	DISCOUNT: 'DISCOUNT',
};
const CALCULATION_TYPE = {
	ABSOLUTE_VALUE: 'ABSOLUTE_VALUE',
	BY_AMOUNT: 'BY_AMOUNT',
	SELECTED_UNSELECTED: 'SELECTED_UNSELECTED',
	FROM_LIST: 'FROM_LIST',
	VALUELESS: 'VALUELESS',
	DISCOUNT: 'DISCOUNT',
};

const SystemSettingsEstimatorRulesDetails = (props) => {
	const feesInit = [{ level_id: '', value: 10, range_from: 0, range_to: 0, group: 0 }];
	const feesInitByAmount = [{ level_id: '', value: 0, range_from: 0, range_to: 0, group: 0, range_error: '' }];
	const feesInitAbsoluteValue = [{ level_id: '', value: 0, range_from: 0, range_to: 0, group: 0 }];

	const user = useSelector((state) => state.auth.user);
	const dataInit = {
		charge_once_multi_regions: false,
		review_needed: false,
		use_pricing_level: false,
		services: [],
		regions: [],
		name: '',
		external_note: '',
		internal_note: '',
		currency: '',
		optional_rule: false,
		optional_rule_preselected: false,
		optional_rule_text: '',
		calculation_type: CALCULATION_TYPE.ABSOLUTE_VALUE,
		fee_type: '',
		object_level: 'SYSTEM',
		calculation_field: '',
		calculation_field_equals: false,
		calculation_mode: '',
		calculation_value1: null,
		calculation_value2: '',
		fees: feesInit,
		created_by: '',
		calculation_values: [],
		calculation_value_condition: 'IS',
		created_by_name: '',
		disbursement_fee: false,
	};

	const errorsInit = {
		name: '',
		optional_rule_text: '',
		services: '',
		regions: '',
		optionsError: false,
		feesError: false,
	};

	const sectionsInit = {
		services: false,
		regions: false,
		options: false,
		fees: false,
	};

	const sendNotification = useNotification();
	const { id } = useParams();
	const [services, setServices] = useState([]);
	const [currencies, setCurrencies] = useState([]);
	const [pricingLevels, setPricingLevels] = useState([]);
	const [data, setData] = useState(dataInit);
	const [feesAbsoluteValue, setFeesAbsoluteValue] = useState(feesInitAbsoluteValue);
	const [feesByAmount, setFeesByAmount] = useState(feesInitByAmount);
	const [calculation_value1_list, setValueFromList] = useState('');
	const [calculation_value1_select_unselect, setValueSelectUnselect] = useState('');
	const [spinner, setSpinner] = useState(false);
	const [regions, setRegions] = useState([]);
	const [calculationValueFromListValue, setCalculationValueFromListValue] = useState(Lists.calculationValueFromListValueEntitySize);
	const history = useHistory();
	const [errors, setErrors] = useState(errorsInit);
	const [sections, setSections] = useState(sectionsInit);
	const [showAllSections, setShowAllSections] = useState(false);
	const [languages, setLanguages] = useState([{ label: 'Select Language', value: '' }]);

	useEffect(() => {
		(async () => {
			try {
				let [rs, ss, cs, pl, ls] = await Promise.all([
					Settings.GetRegions(),
					Settings.GetServices(),
					Settings.GetExchangeRates(),
					PricingLevel.GetAll({ show_is_archived: '0' }),
					Settings.GetLanguages(),
				]);
				setRegions(rs.map((r) => ({ ...r, label: r.name, value: r.id })));
				setServices(ss);
				if (cs) {
					setCurrencies(cs.map((c) => ({ label: c.code, value: c.currency_id })));
					// set usd as default currency
					let usd = cs.find((c) => c.code === 'USD');
					setData({ ...dataInit, currency: usd.currency_id });
				}
				setPricingLevels(pl);
				setLanguages([...languages, ...ls.map((l) => ({ label: l.name, value: l.id }))]);

				// dataSet
				if (id !== 'add') {
					let ruleData = await Estimates.GetOneRulesByID(id);
					switch (ruleData.calculation_type) {
						case CALCULATION_TYPE.ABSOLUTE_VALUE:
							// check if there is record for all active levels if not create empty one so it can be edited
							let fees = populateMissingAbsoluteFeesLevels(pl, ruleData.fees);
							setFeesAbsoluteValue(fees);
							break;
						case CALCULATION_TYPE.BY_AMOUNT:
							let t = populateMissingByAmountFeesLevels(pl, ruleData.fees);
							setFeesByAmount(t);
							break;
						case CALCULATION_TYPE.SELECTED_UNSELECTED:
							setValueSelectUnselect(ruleData.calculation_value1);
							break;
						case CALCULATION_TYPE.FROM_LIST:
						case CALCULATION_TYPE.DISCOUNT:
							setValueFromList(ruleData.calculation_value1);
							break;
					}
					setData(ruleData);
				}
			} catch (err) {
				console.log(err);
			}
		})();
	}, []);

	useEffect(() => {
		setFeesAbsoluteValue([...generateAbsFees()]);
		setFeesByAmount([...generateAbsFees()]);
	}, [pricingLevels]);

	useEffect(() => {
		switch (data.calculation_field) {
			case 'ENTITY_SIZE':
				setCalculationValueFromListValue(Lists.calculationValueFromListValueEntitySize);
				break;
			case 'LOCATION_OF_SEARCH':
				setCalculationValueFromListValue(Lists.calculationValueFromListValueLocationOfSearch);
				break;
			case 'APPLICATION_LANGUAGE':
				setCalculationValueFromListValue(languages);
				break;
			case 'UTILITY_MODEL_PRIORITY':
				setCalculationValueFromListValue(Lists.calculationValueFromListValueUtilityModelPriority);
				break;
			default:
				setCalculationValueFromListValue(Lists.calculationValueFromListValueEntitySize);
		}
	}, [data.calculation_field]);

	const tabClick = (e) => {
		let calculation_type = e.target.dataset.tab;
		let resetCalcData = getResetCalculationFields(calculation_type);
		setData({
			...data,
			...resetCalcData,
			calculation_type: calculation_type,
		});
	};

	const getResetCalculationFields = (type) => {
		let resetFieldsByType = {
			[CALCULATION_TYPE.ABSOLUTE_VALUE]: [
				'calculation_field',
				'calculation_mode',
				'calculation_field_equals',
				'calculation_value1',
				'calculation_value2',
				'calculation_values',
				'calculation_value_condition',
			],
			[CALCULATION_TYPE.BY_AMOUNT]: ['calculation_field_equals', 'calculation_value1', 'calculation_value2', 'calculation_values', 'calculation_value_condition'],
			[CALCULATION_TYPE.SELECTED_UNSELECTED]: ['calculation_mode', 'calculation_value2', 'calculation_values', 'calculation_value_condition'],
			[CALCULATION_TYPE.FROM_LIST]: ['calculation_mode'],
			[CALCULATION_TYPE.VALUELESS]: [
				'calculation_field',
				'calculation_mode',
				'calculation_field_equals',
				'calculation_value1',
				'calculation_value2',
				'calculation_values',
				'calculation_value_condition',
			],
		};

		let resetFields = resetFieldsByType[type];
		return Object.assign(
			{},
			...resetFields.map((field) => {
				return { [field]: dataInit[field] };
			}),
		);
	};

	const regionPickerChange = (regions) => {
		setData({
			...data,
			regions: regions,
		});
	};

	const serviceChange = (e) => {
		if (e.target.checked) {
			setData({
				...data,
				services: [...data.services, e.target.name],
			});
		} else {
			setData({
				...data,
				services: data.services.filter((ds) => ds !== e.target.name),
			});
		}
	};

	const fieldUpdate = (e) => {
		switch (e.target.name) {
			case 'calculation_field_equals':
				setData({
					...data,
					[e.target.name]: e.target.checked,
				});
				break;
			case 'calculation_value1':
				setData({
					...data,
					[e.target.name]: Number(e.target.value),
				});
				break;
			case 'optional_rule':
				setData({
					...data,
					[e.target.name]: e.target.value.toLowerCase() === 'true',
				});
				break;
			case 'calculation_field':
				setData({
					...data,
					[e.target.name]: e.target.value,
					calculation_values: [],
				});
				break;
			case 'rule_type':
				setData({
					...data,
					calculation_type: e.target.value === RULE_TYPE.DISCOUNT ? CALCULATION_TYPE.DISCOUNT : CALCULATION_TYPE.ABSOLUTE_VALUE,
				});
				break;
			default:
				setData({
					...data,
					[e.target.name]: e.target.value,
				});
		}
	};

	const pricingLevelChange = (e) => {
		const [field, level_id, group] = e.target.name.split('__');
		if (data.calculation_type === CALCULATION_TYPE.ABSOLUTE_VALUE) {
			setFeesAbsoluteValue(
				feesAbsoluteValue.map((f, i) => {
					if (level_id === f.level_id) {
						f[field] = Number(e.target.value);
					}
					return f;
				}),
			);
		} else {
			setFeesByAmount(
				feesByAmount.map((f, i) => {
					if (level_id === f.level_id && group == f.group) {
						f[field] = Number(e.target.value);
					}
					return f;
				}),
			);
		}
	};

	const pricingRangeChange = (e) => {
		const [field, group] = e.target.name.split('__');
		let b = feesByAmount.find((f) => f.group == group);
		let c = {};
		let range_error = '';
		if (field === 'range_to' && !isNaN(b.range_from)) {
			c = { range_from: b.range_from, range_to: Number(e.target.value) };
		} else if (field === 'range_from' && b.range_to) {
			c = { range_from: Number(e.target.value), range_to: b.range_to };
		}
		if (!isNaN(c.range_from) && !isNaN(c.range_to)) {
			let range = c.range_from > c.range_to;
			feesByAmount.forEach((element) => {
				if (group != element.group) {
					if (range || areOverlapping(element, c)) {
						range_error = 'error';
					}
				}
			});
		}

		setFeesByAmount(
			feesByAmount.map((f) => {
				if (f.group == group) {
					f[field] = Number(e.target.value);
					f.range_error = range_error;
				}
				return f;
			}),
		);
	};

	const areOverlapping = (rangeA, rangeB) => {
		if (rangeB.range_from < rangeA.range_from) {
			return rangeB.range_to >= rangeA.range_from;
		} else {
			return rangeB.range_from <= rangeA.range_to;
		}
	};

	const generateAbsFees = (group = 0) => {
		return pricingLevels.map((pl) => {
			return {
				level_id: pl.id,
				value: undefined,
				range_from: undefined,
				range_to: undefined,
				group: group,
			};
		});
	};

	const switchChange = (e) => {
		setData({
			...data,
			[e.target.name]: e.target.checked,
		});
	};

	const calculationValue1OnChange = (e) => {
		if (data.calculation_type === CALCULATION_TYPE.SELECTED_UNSELECTED) {
			setValueSelectUnselect(e.target.value);
		} else {
			setValueFromList(e.target.value);
		}
	};

	const addRange = () => {
		let groups = getFeeGroups(feesByAmount);
		let g = groups[groups.length - 1];
		if (g !== undefined) {
			g = g + 1;
		} else {
			g = 0;
		}
		setFeesByAmount([...feesByAmount, ...generateAbsFees(g)]);
	};

	const getFeeGroups = (fees) => {
		let groups = [];
		for (let f of fees) {
			if (!groups.includes(f.group)) groups.push(f.group);
		}
		return groups.sort();
	};

	const removeRange = (e) => {
		let fees_by_amount = feesByAmount.filter((f) => f.group != e.target.dataset.group);
		// corect fees groups
		fees_by_amount = fees_by_amount.map((f) => {
			if (f.group > e.target.dataset.group) {
				return { ...f, group: f.group - 1 };
			}
			return f;
		});
		setFeesByAmount(fees_by_amount);
	};

	const submit = async () => {
		if (![CALCULATION_TYPE.ABSOLUTE_VALUE, CALCULATION_TYPE.BY_AMOUNT].includes(data.calculation_type)) {
			setData({
				...data,
				fees: [],
			});
			if ([CALCULATION_TYPE.FROM_LIST, CALCULATION_TYPE.DISCOUNT].includes(data.calculation_type)) {
				data.calculation_value1 = calculation_value1_list;
			}
			if (data.calculation_type == CALCULATION_TYPE.SELECTED_UNSELECTED) {
				data.calculation_value1 = calculation_value1_select_unselect;
			}
		} else {
			if (data.calculation_type == CALCULATION_TYPE.ABSOLUTE_VALUE) {
				data.fees = feesAbsoluteValue;
			} else {
				data.fees = regroupRanges(feesByAmount);
			}
		}

		let validation = validateRule(data, pricingLevels);
		setErrors(validation.errors);
		if (!validation.isValid) {
			addNewErrorToast('Rule creation failed. Fix errors above.');
			return;
		}

		try {
			setSpinner(true);
			if (id === 'add') {
				await Estimates.CreateRule({ ...data, created_by: user.uid });
				setSpinner(false);
				addNewInfoToast('Rule created.');
			} else {
				let ur = await Estimates.UpdateRule(id, { ...data, created_by: user.uid });
				history.push(`/system-settings/estimator-rules/${ur.id}`);
				addNewInfoToast('Rule updated.');
			}
		} catch (err) {
			console.log(err);
			addNewErrorToast('Rule update failed.');
		} finally {
			setSpinner(false);
		}
	};

	const cancel = () => {
		history.goBack();
	};

	const addNewInfoToast = (text) => {
		sendNotification({ type: 'info', title: text });
	};

	const addNewErrorToast = (text) => {
		sendNotification({ type: 'error', title: text });
	};

	const groupBy = (xs, key) => {
		return xs.reduce(function (rv, x) {
			(rv[x[key]] = rv[x[key]] || []).push(x);
			return rv;
		}, {});
	};

	const objToArray = (obj) => {
		let out = [];
		Object.keys(obj).forEach(function (key, index) {
			out.push(obj[key]);
		});
		return out;
	};

	const showAllSectionsClick = () => {
		if (showAllSections) {
			setSections({
				services: false,
				regions: false,
				options: false,
				fees: false,
			});
		} else {
			setSections({
				services: true,
				regions: true,
				options: true,
				fees: true,
			});
		}
		setShowAllSections(!showAllSections);
	};

	const sectionClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		let state = {
			...sections,
			[e.target.dataset.name]: !sections[e.target.dataset.name],
		};
		if (Object.values(state).every((item) => item)) {
			setShowAllSections(true);
		} else {
			setShowAllSections(false);
		}
		setSections(state);
	};

	return (
		<div className="main-screen-section white full-height no-padding system-details-content settings-estimator-rules">
			<div className="system-details-content__body">
				<span className="system-details-content__body__title">
					{id !== 'add' ? 'Edit Rule' : 'Add Rule'}
					{data._deleted ? ' (Archived)' : ''}
				</span>
				<div className="settings-estimator-rules-rule-name-input-container">
					<Input label="Rule Name" customClassName="text-input" name="name" value={data.name} onChange={fieldUpdate} error={errors.name} />
					{id !== 'add' ?
						<div className="settings-estimator-rules-rule-name-input-container-last-edited">
							<label>Last&nbsp;Edited</label>
							<span>{data.created_by_name ? data.created_by_name : ''}</span>
							<span className="customize-rule-modal__body-rule-content-last-edited-date">
								<AppDate>{data._modified}</AppDate>
							</span>
						</div>
					:	null}
				</div>
				<Textarea label="External Note" customClassName="textarea-input" name="external_note" value={data.external_note} onChange={fieldUpdate} />
				<Textarea label="Internal Note" customClassName="textarea-input" name="internal_note" value={data.internal_note} onChange={fieldUpdate} />

				{/* SERVICES */}
				<details className="settings-colapsible" open={sections.services}>
					<summary
						className={`settings-colapsible__header ${sections.services ? 'open' : ''} ${errors.services ? 'estimator-rules-summary-error' : ''}`}
						data-name="services"
						onClick={sectionClick}
					>
						<i>
							{sections.services ?
								<img src={ICON_ARROW_UP} alt="arrow up" />
							:	<img src={ICON_ARROW_DOWN} alt="arrow down" />}
						</i>
						Services
						{data.services && data.services.length > 0 ?
							<span>{data.services.length}</span>
						:	''}
					</summary>
					<div className="settings-colapsible__body">
						<ul className="settings-estimator-rules__service-list">
							{services.map((s) => {
								let checked = data.services && data.services.includes(s.id);
								return (
									<li key={s.id}>
										<Checkbox label={s.name} name={s.id} onChange={serviceChange} checked={checked} />
									</li>
								);
							})}
						</ul>
					</div>
				</details>

				{/* REGIONS */}
				<details className="settings-colapsible" open={sections.regions}>
					<summary
						className={`settings-colapsible__header ${sections.regions ? 'open' : ''} ${errors.regions ? 'estimator-rules-summary-error' : ''}`}
						data-name="regions"
						onClick={sectionClick}
					>
						<i>
							{sections.regions ?
								<img src={ICON_ARROW_UP} alt="arrow up" />
							:	<img src={ICON_ARROW_DOWN} alt="arrow down" />}
						</i>
						Regions
						{data.regions && data.regions.length > 0 ?
							<span>{data.regions.length}</span>
						:	''}
					</summary>
					<div className="settings-colapsible__body">
						<RegionPicker onChange={regionPickerChange} selectedRegions={data.regions} regions={regions}></RegionPicker>
					</div>
				</details>

				{/* OPTIONS */}
				<details className="settings-colapsible" open={sections.options}>
					<summary
						className={`settings-colapsible__header ${sections.options ? 'open' : ''} ${errors.optionsError ? 'estimator-rules-summary-error' : ''}`}
						data-name="options"
						onClick={sectionClick}
					>
						<i>
							{sections.options ?
								<img src={ICON_ARROW_UP} alt="arrow up" />
							:	<img src={ICON_ARROW_DOWN} alt="arrow down" />}
						</i>
						Options
					</summary>
					<div className="settings-colapsible__body estimator-rules-options">
						<Select label="Currency" customClassName="text-input" options={currencies} onChange={fieldUpdate} name="currency" value={data.currency} />
						<span className={`settings-colapsible__body__subtitle ${errors.fee_type ? 'estimator-rules-required-error' : ''}`}>Fees</span>
						<div className="settings-colapsible__body__section">
							<RadioButton
								label="Filing"
								customClassName="fees-radio"
								name="fee_type"
								value="PROFESSIONAL"
								onChange={fieldUpdate}
								checked={data.fee_type == 'PROFESSIONAL' ? true : false}
							/>
							<RadioButton
								label="Translation"
								customClassName="fees-radio"
								name="fee_type"
								value="TRANSLATION"
								onChange={fieldUpdate}
								checked={data.fee_type == 'TRANSLATION' ? true : false}
							/>
							<RadioButton label="Official" customClassName="fees-radio" name="fee_type" value="OFFICIAL" onChange={fieldUpdate} checked={data.fee_type == 'OFFICIAL' ? true : false} />
						</div>
						<span className={`settings-colapsible__body__subtitle`}>Rule</span>
						<div className="settings-colapsible__body__section">
							<RadioButton
								label="Standard"
								customClassName="fees-radio"
								name="rule_type"
								value={RULE_TYPE.STANDARD}
								onChange={fieldUpdate}
								checked={data.calculation_type !== CALCULATION_TYPE.DISCOUNT ? true : false}
							/>
							<RadioButton
								label="Discount"
								customClassName="fees-radio"
								name="rule_type"
								value={RULE_TYPE.DISCOUNT}
								onChange={fieldUpdate}
								checked={data.calculation_type === CALCULATION_TYPE.DISCOUNT ? true : false}
							/>
						</div>
						<span className="settings-colapsible__body__subtitle">Importance</span>
						<div className="settings-colapsible__body__section">
							<RadioButton label="Mandatory" customClassName="fees-radio" name="optional_rule" value={false} onChange={fieldUpdate} checked={data.optional_rule ? false : true} />
							<RadioButton label="Optional" customClassName="fees-radio" name="optional_rule" value={true} onChange={fieldUpdate} checked={!data.optional_rule ? false : true} />
						</div>
						{data.optional_rule ?
							<blockquote>
								<Switch label="Preselected" customClassName="switch-list" name="optional_rule_preselected" value={data.optional_rule_preselected} onChange={switchChange} />
								<Textarea
									label="Note"
									customClassName="textarea-input"
									name="optional_rule_text"
									onChange={fieldUpdate}
									value={data.optional_rule_text}
									error={errors.optional_rule_text}
								/>
							</blockquote>
						:	null}
						<Switch
							name="charge_once_multi_regions"
							value={data.charge_once_multi_regions}
							onChange={switchChange}
							label="Charge only once if activated for multiple regions"
							customClassName="switch-list"
						/>
						<Switch name="review_needed" value={data.review_needed} onChange={switchChange} label="Quote must be reviewed by Estimates Team" customClassName="switch-list" />
						{data.calculation_type !== CALCULATION_TYPE.DISCOUNT ?
							<Switch
								name="use_pricing_level"
								value={data.use_pricing_level}
								onChange={switchChange}
								label="Pricing Level (Pricing Level is not applicable to 'Value from List' and 'Selected/Unselected' calculation types)"
								customClassName="switch-list"
							/>
						:	null}
						<Switch name="disbursement_fee" value={data.disbursement_fee} onChange={switchChange} label="Disbursement Fee" customClassName="switch-list" />
					</div>
				</details>

				{/* CALCULATION TYPE */}
				{data.calculation_type !== CALCULATION_TYPE.DISCOUNT ?
					<details className="settings-colapsible" open={sections.fees}>
						<summary
							className={`settings-colapsible__header ${sections.fees ? 'open' : ''} ${errors.feesError ? 'estimator-rules-summary-error' : ''}`}
							data-name="fees"
							onClick={sectionClick}
						>
							<i>
								{sections.fees ?
									<img src={ICON_ARROW_UP} alt="arrow up" />
								:	<img src={ICON_ARROW_DOWN} alt="arrow down" />}
							</i>
							Calculation type
						</summary>
						<div className="settings-colapsible__body">
							<ul className="settings-colapsible__body__tabs">
								<li onClick={tabClick} className={data.calculation_type === CALCULATION_TYPE.ABSOLUTE_VALUE ? 'active' : ''} data-tab={CALCULATION_TYPE.ABSOLUTE_VALUE}>
									Absolute Value
									<span className={`settings-estimator-rules__calculation-type-error-indicator ${errors.absolute_value_fees ? 'show-error' : ''}`}></span>
								</li>
								<li onClick={tabClick} className={data.calculation_type === CALCULATION_TYPE.BY_AMOUNT ? 'active' : ''} data-tab={CALCULATION_TYPE.BY_AMOUNT}>
									By Amount
									<span className={`settings-estimator-rules__calculation-type-error-indicator ${errors.by_amount_fees ? 'show-error' : ''}`}></span>
								</li>
								<li onClick={tabClick} className={data.calculation_type === CALCULATION_TYPE.SELECTED_UNSELECTED ? 'active' : ''} data-tab={CALCULATION_TYPE.SELECTED_UNSELECTED}>
									Selected/Unselected
									<span className={`settings-estimator-rules__calculation-type-error-indicator ${errors.select_unselect_fees ? 'show-error' : ''}`}></span>
								</li>
								<li onClick={tabClick} className={data.calculation_type === CALCULATION_TYPE.FROM_LIST ? 'active' : ''} data-tab={CALCULATION_TYPE.FROM_LIST}>
									Value From List
									<span className={`settings-estimator-rules__calculation-type-error-indicator ${errors.from_list_fee ? 'show-error' : ''}`}></span>
								</li>
								<li onClick={tabClick} className={data.calculation_type === CALCULATION_TYPE.VALUELESS ? 'active' : ''} data-tab={CALCULATION_TYPE.VALUELESS}>
									Valueless
								</li>
							</ul>
							{data.calculation_type === CALCULATION_TYPE.ABSOLUTE_VALUE ?
								<div className="settings-colapsible__body__tab-content-container">
									<ul className="levels-input-list">
										{data.use_pricing_level ?
											pricingLevels.map((pl) => {
												return (
													<li key={pl.id}>
														<span className="levels-input-list__label">{pl.name}</span>
														<PricingLevelPreview
															onChange={pricingLevelChange}
															ruleServices={data.services}
															pricingLevel={pl}
															services={services}
															pricingLevels={pricingLevels}
															fees={feesAbsoluteValue}
														/>
													</li>
												);
											})
										:	pricingLevels.map((pl) => {
												if (pl.is_base) {
													let v = feesAbsoluteValue.find((f) => f.level_id === pl.id);
													return (
														<li key={pl.id}>
															<span className="levels-input-list__label">{pl.name}</span>
															<Input type="number" name={`value__${pl?.id}__0`} value={v?.value} onChange={pricingLevelChange} ariaLabel="pricing level" />
														</li>
													);
												}
											})
										}
									</ul>
								</div>
							:	null}
							{data.calculation_type === CALCULATION_TYPE.BY_AMOUNT ?
								<div className="settings-colapsible__body__tab-content-container">
									<div className="settings-colapsible__body__tab-content-container__line">
										<Select
											label="Field"
											customClassName="option-dd"
											options={Lists.calculationByAmountField}
											name="calculation_field"
											value={data.calculation_field}
											onChange={fieldUpdate}
										/>
										<Select
											label="Mode"
											customClassName="option-dd"
											options={Lists.calculationByAmountMode}
											name="calculation_mode"
											value={data.calculation_mode}
											onChange={fieldUpdate}
										/>
										<Button className="option-btn" onClick={addRange}>
											Add Range
										</Button>
									</div>
									<div className="input-ranges">
										{objToArray(groupBy(feesByAmount, 'group')).map((bar) => {
											return (
												<ul className="levels-input-list" key={bar[0].group}>
													<li>
														<span className="system-details-content__body__title">Range</span>
														<button className="levels-input-list__delete-button" data-group={bar[0].group} onClick={removeRange} aria-label="delete"></button>
													</li>
													<li>
														<Input
															type="number"
															placeholder="from"
															name={`range_from__${bar[0].group}`}
															value={bar[0].range_from}
															error={bar[0].range_error}
															onChange={pricingRangeChange}
															customClassName="customize-rule-modal-levels-input-list__range"
															ariaLabel="from"
														/>
														<span className="levels-input-list__separator"> - </span>
														<Input
															type="number"
															placeholder="to"
															name={`range_to__${bar[0].group}`}
															value={bar[0].range_to}
															onChange={pricingRangeChange}
															error={bar[0].range_error}
															customClassName="customize-rule-modal-levels-input-list__range"
															ariaLabel="to"
														/>
													</li>
													{data.use_pricing_level ?
														pricingLevels.map((pl) => {
															return (
																<li key={`${pl.id}__${bar[0].group}`}>
																	<span className="levels-input-list__label">{pl.name}</span>
																	<PricingLevelPreview
																		onChange={pricingLevelChange}
																		ruleServices={data.services}
																		pricingLevel={pl}
																		services={services}
																		pricingLevels={pricingLevels}
																		fees={feesByAmount}
																		group={bar[0].group}
																	/>
																</li>
															);
														})
													:	pricingLevels.map((pl) => {
															if (pl.is_base) {
																let v = feesByAmount.find((f) => f.level_id === pl.id && f.group === bar[0].group);
																return (
																	<li key={`${pl.id}__${bar[0].group}`}>
																		<span className="levels-input-list__label">{pl.name}</span>
																		<Input
																			type="number"
																			name={`value__${pl.id}__${bar[0].group}`}
																			value={v?.value}
																			onChange={pricingLevelChange}
																			ariaLabel="pricing level"
																		/>
																	</li>
																);
															}
														})
													}
												</ul>
											);
										})}
									</div>
								</div>
							:	null}
							{data.calculation_type === CALCULATION_TYPE.SELECTED_UNSELECTED ?
								<div className="settings-colapsible__body__tab-content-container">
									<div className="settings-colapsible__body__tab-content-container__line">
										<Input
											type="number"
											label=" "
											customClassName="option-dd no-label-input"
											placeholder="Value"
											name="calculation_value1"
											value={!isNaN(parseFloat(calculation_value1_select_unselect)) ? calculation_value1_select_unselect : undefined}
											onChange={calculationValue1OnChange}
											error={errors.calculationValue1}
										/>
										<Switch
											label="If following field is"
											customClassName="option-sw"
											value={data.calculation_field_equals}
											name="calculation_field_equals"
											onChange={fieldUpdate}
										/>
										<Select
											label="Field"
											customClassName="option-dd"
											options={Lists.calculationSelectUnselectedField}
											value={data.calculation_field}
											name="calculation_field"
											onChange={fieldUpdate}
											error={errors.calculationField}
										/>
									</div>
								</div>
							:	null}
							{data.calculation_type === CALCULATION_TYPE.FROM_LIST ?
								<div className="settings-colapsible__body__tab-content-container">
									<div className="settings-colapsible__body__tab-content-container__line">
										<Input
											label=" "
											type="number"
											customClassName="option-dd no-label-input"
											placeholder="Value"
											value={!isNaN(parseFloat(calculation_value1_list)) ? calculation_value1_list : undefined}
											name="calculation_value1"
											onChange={calculationValue1OnChange}
											error={errors.calculationValue1}
											ariaLabel="calculation value"
										/>
										<span className="line-text">for field</span>
										<Select
											label="Field"
											customClassName="option-dd"
											options={Lists.calculationValueFromListField}
											value={data.calculation_field}
											name="calculation_field"
											onChange={fieldUpdate}
											error={errors.calculationField}
										/>
										<span className="line-text">and its value</span>
										<Select
											label="Condition"
											customClassName="option-dd"
											options={Lists.calculationValueConditions}
											value={data.calculation_value_condition}
											name="calculation_value_condition"
											onChange={fieldUpdate}
										/>
										{['IS', 'IS_NOT'].includes(data.calculation_value_condition) ?
											<Select
												label="Value"
												customClassName="option-dd"
												options={calculationValueFromListValue}
												value={data.calculation_value2}
												name="calculation_value2"
												onChange={fieldUpdate}
												error={errors.calculationValue2}
											/>
										:	<TagSelect
												label="Value"
												customClassName="option-dd"
												options={calculationValueFromListValue}
												values={data.calculation_values}
												name="calculation_values"
												onChange={fieldUpdate}
												error={errors.calculationValues}
											/>
										}
									</div>
								</div>
							:	null}
							{data.calculation_type === CALCULATION_TYPE.VALUELESS ?
								<div className="settings-colapsible__body__tab-content-container">
									<span className="system-details-content__body__title line-text">This rule is valueless</span>
								</div>
							:	null}
						</div>
					</details>
				:	null}
				{/* DISCOUNT CONDITIONS */}
				{data.calculation_type === CALCULATION_TYPE.DISCOUNT ?
					<details className="settings-colapsible" open={sections.discounts}>
						<summary
							className={`settings-colapsible__header ${sections.discounts ? 'open' : ''} ${errors.discountsError ? 'estimator-rules-summary-error' : ''}`}
							data-name="discounts"
							onClick={sectionClick}
						>
							<i>
								{sections.discounts ?
									<img src={ICON_ARROW_UP} alt="arrow up" />
								:	<img src={ICON_ARROW_DOWN} alt="arrow down" />}
							</i>
							Discount Conditions
						</summary>
						<div className="settings-colapsible__body">
							<div className="settings-colapsible__body__info">
								<img src={ICON_INFO} alt="info" />
								<span>
									Small/Individual Entity discounts are given on all official fees, excluding disbursements, in the regions shown for the PCT NPE, Paris Convention, and Design
									services.
								</span>
							</div>
							<div className="settings-colapsible__body__tab-content-container">
								<div className="settings-colapsible__body__tab-content-container__line">
									<Select
										label="Field"
										customClassName="option-dd"
										options={Lists.calculationValueFromListField}
										value={data.calculation_field}
										name="calculation_field"
										onChange={fieldUpdate}
									/>
									<Select
										label="Value"
										customClassName="option-dd"
										options={calculationValueFromListValue}
										value={data.calculation_value2}
										name="calculation_value2"
										onChange={fieldUpdate}
									/>
									<Input
										label="Discount"
										type="number"
										customClassName="option-dd"
										placeholder="%"
										value={calculation_value1_list}
										name="calculation_value1"
										onChange={calculationValue1OnChange}
									/>
								</div>
							</div>
						</div>
					</details>
				:	null}
			</div>
			<footer className="system-details-content__footer">
				<div className="estimator-rules__footer-switch-wrapper">
					<Switch label="Show all sections" value={showAllSections} onChange={showAllSectionsClick} />
				</div>
				<Button onClick={cancel} theme={'ghost-primary'} size="small">
					Cancel
				</Button>
				<Button onClick={submit} showSpinner={spinner}>
					Save
				</Button>
			</footer>
		</div>
	);
};

export { RULE_TYPE, CALCULATION_TYPE };
export default SystemSettingsEstimatorRulesDetails;
